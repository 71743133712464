import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";

export enum ReportTypeTabsOption {
  Social = "Social",
  Sustainability = "Sustainability",
}
interface ReportTypeTabsProps {
  isLoading: boolean;
  value: ReportTypeTabsOption;
  setReportType: (newValue: ReportTypeTabsOption) => void;
}

export const ReportTypeTabs = ({
  isLoading,
  setReportType,
  value,
}: ReportTypeTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: ReportTypeTabsOption,
  ) => {
    if (newValue) {
      setReportType(newValue);
    }
  };

  return (
    <Box marginTop="20px" marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button One</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button Two</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            data-test-id="standard-button"
            value={ReportTypeTabsOption.Social}
            size="small"
          >
            Social Reports
          </ToggleButton>
          <ToggleButton
            data-test-id="trainee-button"
            value={ReportTypeTabsOption.Sustainability}
            size="small"
          >
            Sustainability Reports
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
