export const wordsToThreeLetterAcronym = (input: string) => {
  // Split the input string by spaces and remove empty strings
  const words = input.split(/\s+/).filter((word) => word.trim().length > 0);

  // Define words to ignore
  const ignoredWords = new Set(["and", "for", "the", "with", "from", "to"]);

  // Initialize an empty array to store the acronym letters
  const acronym: string[] = [];

  // Iterate through the words and add the first character of each non-ignored word to the acronym
  for (const word of words) {
    // Remove brackets from the word
    const cleanedWord = word.replace(/[()]/g, "");

    // Add the first character of the word to the acronym if it's not an ignored word
    if (!ignoredWords.has(cleanedWord.toLowerCase())) {
      acronym.push(cleanedWord[0].toUpperCase());
    }
  }

  // Join the acronym letters and return
  return acronym.join("");
};
