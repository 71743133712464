import { ChangeEvent, FocusEvent, useState } from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import {
  ProjectCommentType,
  projectCommentTypeToString,
} from "social-pro-common/interfaces/projectComment";

interface ProjectReportConfirmationProps {
  loading: boolean;
  project?: ProjectLineItem;
  reportId: string;
  commentMap: Map<ProjectCommentType, string>;
  reportCommentType: ProjectCommentType;
  updateComment: (
    commentType: ProjectCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
}

export const ProjectReportConfirmation = ({
  commentMap,
  loading,
  project,
  reportCommentType,
  reportId,
  updateComment,
}: ProjectReportConfirmationProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0 auto !important",
          padding: "20px 40px 40px !important",
          width: "100%",
        }}
      >
        <Grid item xs={12} md={12} sx={{ padding: "0" }}>
          {loading ? (
            <Stack>
              <Skeleton animation="wave">
                <Typography variant="h2">
                  {"Project Report Confirmation"}
                </Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="subtitle1">{"September 2000"}</Typography>
              </Skeleton>
            </Stack>
          ) : (
            <Stack>
              <Typography variant="h2">
                {"Project Report Confirmation"}
              </Typography>
              <Typography variant="subtitle1">
                {`${project?.projectName}: ${
                  reportId ? formatReportDate(stringToDate(reportId)) : ""
                }`}
              </Typography>
            </Stack>
          )}
        </Grid>

        <Grid item md={12} sx={{ padding: "20px 0" }}>
          {loading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave">
                <Typography variant="body1" component="div">
                  I confirm that the information provided in this report is
                  accurate to the best of my knowledge and that it is subject to
                  review and verification (the reporting entity may request
                  additional evidence or supporting documentation to
                  substantiate the reported information). I agree that I am in
                  possession of appropriate supporting documentation for all
                  information reported, and to promptly provide such
                  documentation to support the accuracy and integrity of the
                  reported data.
                </Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="body1" component="div">
                  By submitting this report, I affirm my commitment to
                  transparent and accurate reporting in compliance with
                  applicable standards and regulations.
                </Typography>
              </Skeleton>
            </Stack>
          ) : (
            <Stack spacing={3}>
              <Typography variant="body1" component="div">
                I confirm that the information provided in this report is
                accurate to the best of my knowledge and that it is subject to
                review and verification (the reporting entity may request
                additional evidence or supporting documentation to substantiate
                the reported information). I agree that I am in possession of
                appropriate supporting documentation for all information
                reported, and to promptly provide such documentation to support
                the accuracy and integrity of the reported data.
              </Typography>
              <Typography variant="body1" component="div">
                By submitting this report, I affirm my commitment to transparent
                and accurate reporting in compliance with applicable standards
                and regulations.
              </Typography>
            </Stack>
          )}
        </Grid>
        {project ? (
          <Grid
            key={`${projectCommentTypeToString(
              ProjectCommentType.ProjectSummary,
            )}-key`}
            item
            md={12}
          >
            <StyledTextField
              variant="outlined"
              loading={loading}
              disabled={loading || isSubmitting}
              id={`${projectCommentTypeToString(
                ProjectCommentType.ProjectSummary,
              )}`}
              name={`${projectCommentTypeToString(
                ProjectCommentType.ProjectSummary,
              )}`}
              label={`${projectCommentTypeToString(
                ProjectCommentType.ProjectSummary,
              )} Comments`}
              fullWidth
              margin="dense"
              multiline
              rows={5}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                setIsSubmitting(true);
                await updateComment(reportCommentType, e.target.value, false);
                setIsSubmitting(false);
              }}
              onBlur={async (e: FocusEvent<HTMLInputElement>) => {
                setIsSubmitting(true);
                await updateComment(reportCommentType, e.target.value, true);
                setIsSubmitting(false);
              }}
              value={commentMap.get(ProjectCommentType.ProjectSummary)}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
