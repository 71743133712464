import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

interface CreateProfileFormProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  finalizeProfile: (profile: ProfileLineItem) => void;
}

export const CreateProfileForm = ({
  finalizeProfile,
  loading,
  userProfile,
}: CreateProfileFormProps) => {
  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    name: yup.string().required("Name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userProfile ? userProfile.email : "",
      name: userProfile ? userProfile.name : "",
    },
    onSubmit: (values) => {
      if (userProfile) {
        finalizeProfile({
          ...userProfile,
          email: values.email,
          name: values.name,
        } as ProfileLineItem);
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ margin: "0", padding: "20px" }}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            loading={loading}
            id="Name"
            name="name"
            label="Name"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            loading={loading}
            id="email"
            name="email"
            label="Email"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ""}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ padding: "12px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton loading={loading} variant="contained" type="submit">
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
