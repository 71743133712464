"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyType = void 0;
var PolicyType;
(function (PolicyType) {
    PolicyType["DefaultPolicy"] = "policy-type_default-policy";
    PolicyType["FamilyViolencePolicy"] = "policy-type_family-violence";
    PolicyType["EnvironmentalSustainabilityPolicy"] = "policy-type_environmental-sustainability";
    PolicyType["EntityLevelGenderEqualityActionPlan"] = "policy-type_entity-level-gender-equality-action-plan";
    PolicyType["ProjectLevelGenderEqualityActionPlan"] = "policy-type_project-level-gender-equality-action-plan";
    PolicyType["FairJobsCode"] = "policy-type_fair-jobs-code";
})(PolicyType || (exports.PolicyType = PolicyType = {}));
