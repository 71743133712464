import { Chip, TableCell, TableRow, Tooltip, Zoom } from "@mui/material";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { wordsToThreeLetterAcronym } from "@utils/string";
import {
  EmployeeLineItem,
  genderToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import {
  labourHourCommitmentTypeToAbbreviatedString,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

interface ViewEmployeeTableRowProps {
  employee: EmployeeLineItem;
  project?: ProjectLineItem;
}

export const ViewEmployeeTableRow = ({
  employee,
  project,
}: ViewEmployeeTableRowProps) => {
  const matchingLabourHourCommitments = getEmployeeFactors(employee, project);

  return (
    <>
      <TableRow key={employee.id}>
        <TableCell align="center" scope="row" sx={{ width: "30%" }}>
          {`${employee.employeeName}${
            employee.employeeIdentifier
              ? ` (${employee.employeeIdentifier})`
              : ""
          }`}
        </TableCell>

        <TableCell align="center">{employee.primaryEmployer}</TableCell>
        <TableCell align="center">{employee.occupation}</TableCell>
        <TableCell align="center">
          {occupationTypeOptionToString(employee.occupationType)}
        </TableCell>
        <TableCell align="left" sx={{ width: "15%" }}>
          {matchingLabourHourCommitments.map((sf, i) => {
            return (
              <Tooltip
                TransitionComponent={Zoom}
                key={`tool-tip-employee-social-factor-${i}`}
                title={
                  sf.targetDescription ||
                  labourHourCommitmentTypeToString(sf.targetName)
                }
              >
                <Chip
                  size="small"
                  label={
                    sf.targetDescription
                      ? wordsToThreeLetterAcronym(sf.targetDescription)
                      : labourHourCommitmentTypeToAbbreviatedString(
                          sf.targetName,
                        )
                  }
                  onMouseDown={(event) => event.stopPropagation()}
                  style={{
                    backgroundColor: sf.colour,
                    color: "#fff",
                  }}
                />
              </Tooltip>
            );
          })}
        </TableCell>
        <TableCell align="center">{genderToString(employee.gender)}</TableCell>
      </TableRow>
    </>
  );
};
