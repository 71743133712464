import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import EditIcon from "@mui/icons-material/Edit";

interface GreenMetricTableRowProps {
  metric: GreenMetricLineItem;
  handleEdit: (metric: GreenMetricLineItem) => void;
  onDelete: () => void;
}

export const GreenMetricTableRow = ({
  handleEdit,
  metric,
  onDelete,
}: GreenMetricTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography>{metric.reportTargetName}</Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: "25%" }}
          className="projectTextField center"
        >
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
              <IconButton
                color="primary"
                size="large"
                onClick={() => handleEdit(metric)}
              >
                <EditIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
              <IconButton
                color="error"
                size="large"
                onClick={() => setOpenConfirmDialog(true)}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this commitment?"}
        open={openConfirmDialog}
        title={"Delete Commitment"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          setOpenConfirmDialog(false);
          onDelete();
        }}
      />
    </>
  );
};
