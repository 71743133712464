"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NO_ORGANISATION_ID = exports.createInvite = exports.createDefaultProfile = exports.profileStatusToString = exports.profileTypeToString = exports.ProfileStatus = void 0;
var uuid_1 = require("uuid");
var ProfileStatus;
(function (ProfileStatus) {
    ProfileStatus["Active"] = "profile-status_active";
    ProfileStatus["Invited"] = "profile-status_invited";
})(ProfileStatus || (exports.ProfileStatus = ProfileStatus = {}));
var profileTypeToString = function (profileType) {
    return profileType ? "Admin" : "Standard";
};
exports.profileTypeToString = profileTypeToString;
var profileStatusToString = function (profileStatus) {
    switch (profileStatus) {
        case ProfileStatus.Active:
            return "Active";
        case ProfileStatus.Invited:
            return "Invited";
    }
};
exports.profileStatusToString = profileStatusToString;
var createDefaultProfile = function (organisation, user) {
    return {
        created: new Date(),
        email: user ? user.email : "",
        emailNotifications: true,
        id: user ? user.id : (0, uuid_1.v4)(),
        isAdmin: false,
        isSetup: false,
        name: user ? user.email : "",
        organisationId: organisation ? organisation.id : (0, uuid_1.v4)(),
        status: ProfileStatus.Active,
    };
};
exports.createDefaultProfile = createDefaultProfile;
var createInvite = function (email) {
    return {
        created: new Date(),
        email: email,
        emailNotifications: false,
        id: (0, uuid_1.v4)(),
        isAdmin: false,
        isSetup: false,
        name: "",
        organisationId: "",
        status: ProfileStatus.Invited,
    };
};
exports.createInvite = createInvite;
exports.NO_ORGANISATION_ID = "NO-ORGANISATION-ID";
