import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ChangeEvent } from "react";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

interface UserFormProps {
  loading: boolean;
  user: ProfileLineItem;
  isEdit: boolean;
  existingProfiles: ProfileLineItem[];
  handleSubmit: (user: ProfileLineItem) => void;
}

export const UserForm = ({
  existingProfiles,
  handleSubmit,
  isEdit,
  loading,
  user,
}: UserFormProps) => {
  const validationSchema = yup.object({
    admin: yup.boolean(),
    email: yup
      .string()
      .email()
      .required("Email required")
      .test("User does not exist", "User already exists", function (item) {
        if (isEdit) {
          return true;
        }
        const emails = existingProfiles.map((p) => p.email);
        return !emails.includes(item.toLowerCase());
      }),
    name: yup.string().required("Name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      admin: user.isAdmin,
      email: user.email,
      name: user.name,
    },
    onSubmit: (values) => {
      handleSubmit({
        ...user,
        email: values.email.toLowerCase(),
        isAdmin: values.admin,
        name: values.name,
      });
    },
    validationSchema: validationSchema,
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <StyledTextField
              loading={loading}
              id="name"
              name="name"
              label="Name"
              fullWidth
              margin="dense"
              disabled={loading || formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <StyledTextField
              loading={loading}
              id="email"
              name="email"
              label="Email"
              fullWidth
              margin="dense"
              disabled={loading || formik.isSubmitting || isEdit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              id={"admin"}
              name={"admin"}
              defaultChecked={formik.values.admin}
              disabled={loading}
              control={
                <Checkbox
                  checked={formik.values.admin}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue("admin", e.target.checked);
                  }}
                />
              }
              label="Super Admin"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
              padding: "0 !important",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={loading || formik.isSubmitting}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
