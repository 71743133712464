import { Grid, Table, TableBody, TableHead } from "@mui/material";
import { useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import {
  PolicySubmissionLineItem,
  createDefaultPolicySubmission,
} from "social-pro-common/interfaces/policySubmission";
import { PolicyRow } from "./PolicyRow";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PolicyRowSkeleton } from "./PolicyRowSkeleton";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { PackageType } from "social-pro-common/entities/contractorPackage";

interface PoliciesProps {
  loading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  project?: ProjectLineItem;
  policySubmissions: PolicySubmissionLineItem[];
  createPolicySubmission: (
    policySubmission: PolicySubmissionLineItem,
  ) => Promise<PolicySubmissionLineItem>;
  updatePolicySubmission: (
    policySubmission: PolicySubmissionLineItem,
  ) => Promise<void>;
}

export const Policies = ({
  contractorPackage,
  createPolicySubmission,
  loading,
  policySubmissions,
  project,
  updatePolicySubmission,
}: PoliciesProps) => {
  const [selectedTargetId, setSelectedTargetId] = useState<string>();

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0 &&
      project &&
      contractorPackage
    ) {
      const file = e.currentTarget?.files[0] || null;
      if (file && selectedTargetId) {
        setIsUploading(true);

        const fileKey = `policies/${contractorPackage.id}/${selectedTargetId}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          (error: any) => {
            setUploadProgress(0);
            console.log(error);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );

        const matchingProjectPolicy = project.policies.find(
          (p) => p.id === selectedTargetId,
        );

        const matchingPackagePolicy = policySubmissions.find(
          (p) => p.projectPolicyId === selectedTargetId,
        );

        if (matchingProjectPolicy) {
          if (matchingPackagePolicy) {
            matchingPackagePolicy.uri = fileKey;
            await updatePolicySubmission(matchingPackagePolicy);
          } else {
            const policySubmission = createDefaultPolicySubmission(
              project.id,
              contractorPackage.id,
              matchingProjectPolicy.id,
            );
            policySubmission.uri = fileKey;
            await createPolicySubmission(policySubmission);
          }
        } else {
          toast("Could not find policySubmissionx.", {
            type: "error",
          } as ToastOptions);
        }

        // const urlToImage = URL.createObjectURL(file);
        setIsUploading(false);
      }
    }
  };

  const policiesForPackage = project?.policies.filter(
    (p) => !p.isProjectLevelPolicy,
  );
  const policiesForProject = project?.policies.filter(
    (p) =>
      p.isProjectLevelPolicy &&
      contractorPackage &&
      contractorPackage.packageType === PackageType.PrimaryPackage,
  );

  const tableHeader = (
    <TableHead>
      <TableHeader
        title={"Policy Name"}
        align={"left"}
        loading={loading}
        sx={{ width: "70%" }}
      />
      <TableHeader
        title={"Upload Date"}
        loading={loading}
        sx={{ width: "10%" }}
        align={"center"}
      />
      <TableHeader title={""} loading={loading} sx={{ width: "10%" }} />
      <TableHeader
        title={"Action"}
        loading={loading}
        sx={{ textAlign: "right !important", width: "10%" }}
        align={"center"}
      />
    </TableHead>
  );

  return (
    <>
      <Grid
        item
        md={12}
        className="glb-table-sty"
        sx={{ margin: "0px 40px", padding: "15px 0 !important" }}
      >
        {loading ? (
          <Table size="small" className="policytable">
            {tableHeader}
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <PolicyRowSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table
            size="small"
            className="policytable"
            data-test-id="policy-commitments-table"
          >
            {tableHeader}
            <TableBody>
              {!loading &&
                policiesForProject?.map((p) => {
                  const policySubmission = policySubmissions.find(
                    (pp) => pp.projectPolicyId === p.id,
                  );
                  if (
                    p.isProjectLevelPolicy &&
                    contractorPackage &&
                    contractorPackage.packageType !== PackageType.PrimaryPackage
                  ) {
                    return null;
                  }
                  return (
                    <PolicyRow
                      key={p.id}
                      projectPolicy={p}
                      policySubmission={policySubmission}
                      isUploading={isUploading}
                      isDownloading={isDownloading}
                      setSelectedTargetId={setSelectedTargetId}
                      selectedTargetId={selectedTargetId}
                      uploadProgress={uploadProgress}
                      onUpload={onUpload}
                      onDownload={onDownload}
                    />
                  );
                })}
              {!loading &&
                policiesForPackage?.map((p) => {
                  const policySubmission = policySubmissions.find(
                    (pp) => pp.projectPolicyId === p.id,
                  );
                  return (
                    <PolicyRow
                      key={p.id}
                      projectPolicy={p}
                      policySubmission={policySubmission}
                      isUploading={isUploading}
                      isDownloading={isDownloading}
                      setSelectedTargetId={setSelectedTargetId}
                      selectedTargetId={selectedTargetId}
                      uploadProgress={uploadProgress}
                      onUpload={onUpload}
                      onDownload={onDownload}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}
      </Grid>
    </>
  );
};
