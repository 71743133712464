import {
  DisabilityCategory,
  EmployeeLineItem,
  employeeDisabilityCategoryToString,
} from "social-pro-common/interfaces/contractorEmployee";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import React, { Dispatch } from "react";
import DisabilityModal from "@stories/organisms/DisabilityModal/DisabilityModal";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";
import EditIcon from "@mui/icons-material/Edit";

interface EmployeeSupportingDocsFormRowSelectDisablityProps {
  employee: EmployeeLineItem;
  setEmployee: Dispatch<React.SetStateAction<EmployeeLineItem>>;
}

export const EmployeeSupportingDocsFormRowSelectDisablity = ({
  employee,
  setEmployee,
}: EmployeeSupportingDocsFormRowSelectDisablityProps) => {
  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const supportingDocument = employee.supportingDocuments.find(
    (d) =>
      d.supportingDocumentType === EmployeeSocialFactor.PersonWithDisability,
  );
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan={1}>
          <Typography>{"Disability Details"}</Typography>
          <Stack>
            <Typography variant="caption">
              Program Provider:{" "}
              {supportingDocument?.documentDescription ? (
                supportingDocument?.documentDescription
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Category:{" "}
              {supportingDocument?.documentCategory ? (
                employeeDisabilityCategoryToString(
                  supportingDocument?.documentCategory as DisabilityCategory,
                )
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                size="large"
                onClick={() => setModalIsOpen(true)}
              >
                <EditIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
      <DisabilityModal
        open={modalIsOpen}
        supportingDocument={supportingDocument}
        employee={employee}
        setOpen={setModalIsOpen}
        setEmployee={setEmployee}
      />
    </>
  );
};
