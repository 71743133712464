"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeReportDocument = exports.decodeReportDocument = void 0;
var string_1 = require("../../utils/string");
var decodeReportDocument = function (reportSubmission) {
    return {
        contractorPackageId: reportSubmission.contractor_package_id,
        created: reportSubmission.created,
        id: reportSubmission.report_document_id,
        reportDocumentType: reportSubmission.report_document_type,
        reportId: reportSubmission.report_id,
        uri: reportSubmission.uri,
    };
};
exports.decodeReportDocument = decodeReportDocument;
var encodeReportDocument = function (reportSubmission) {
    var safeReportSubmission = (0, string_1.makePostgresSafe)(reportSubmission);
    return {
        contractor_package_id: safeReportSubmission.contractorPackageId,
        created: safeReportSubmission.created,
        report_document_id: safeReportSubmission.id,
        report_document_type: safeReportSubmission.reportDocumentType,
        report_id: safeReportSubmission.reportId,
        uri: safeReportSubmission.uri,
    };
};
exports.encodeReportDocument = encodeReportDocument;
