import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { Boxes } from "lucide-react";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const getOneTimeReportingNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();

  const children = [];

  children.push({
    counter: sideBarMenuModel?.packageReportSocialCount,
    icon: <Boxes />,
    id: "report-packages",
    title: "Report",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.wizardOneTime(project.id, contractorPackage.id)
        : "",
  });

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "social-reporting",
    title: "Social Reporting",
    type: "group",
  };
};
