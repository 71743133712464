import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import {
  ProjectPolicyLineItem,
  policyTypeToString,
} from "social-pro-common/interfaces/projectPolicy";
import {
  CircularProgress,
  Button,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { getDocumentName } from "social-pro-common/utils/string";

interface PackageAnalyticsPolicyRowProps {
  projectPolicy: ProjectPolicyLineItem;
  policySubmission?: PolicySubmissionLineItem;

  isDownloading: boolean;
  selectedTargetId?: string;
  setSelectedTargetId: (id: string) => void;
  onDownload: (uri: string, policyName: string) => void;
}
export const PackageAnalyticsPolicyRow = ({
  isDownloading,
  onDownload,
  policySubmission,
  projectPolicy,
  selectedTargetId,
}: PackageAnalyticsPolicyRowProps) => {
  return (
    <TableRow>
      <TableCell align="left" sx={{ width: "60%" }}>
        {policyTypeToString(projectPolicy.policyType)}
      </TableCell>
      <TableCell sx={{ display: "table-cell" }}>
        {policySubmission ? (
          <Typography align="center">
            {policySubmission?.created.toLocaleDateString("en-GB")}
          </Typography>
        ) : (
          <Typography align="center" sx={{ margin: "auto !important" }}>
            <DangerousOutlinedIcon
              color="error"
              sx={{ margin: "auto", padding: "0" }}
            />
          </Typography>
        )}
      </TableCell>
      <TableCell align="center" className="center">
        {policySubmission ? (
          isDownloading &&
          projectPolicy.id === selectedTargetId &&
          policySubmission?.uri ? (
            <CircularProgress />
          ) : policySubmission?.uri ? (
            <Button
              color="primary"
              size="large"
              disabled={isDownloading}
              onClick={() =>
                policySubmission.uri
                  ? onDownload(
                      policySubmission.uri,
                      getDocumentName(
                        policyTypeToString(projectPolicy.policyType),
                        policySubmission.uri,
                      ),
                    )
                  : undefined
              }
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              Download
            </Button>
          ) : null
        ) : null}
      </TableCell>
    </TableRow>
  );
};
