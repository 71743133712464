import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { SpendProgressModelLineItem } from "social-pro-common/interfaces/dashboard";
import { useState } from "react";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";
import { TitleStack } from "../../Dashboard/ProjectProgress/TitleStack";
import { ChipProgress } from "../../Dashboard/ProjectProgress/ChipProgress";
import { DashboardProgressbar } from "../../Dashboard/ProjectProgress/DashboardProgressBar";
import { OrganisationSocialSpendProgressSubData } from "./OrganisationSocialSpendProgressSubData";

interface OrganisationSocialSpendProgressProps {
  loading: boolean;
  socialSpendProgress?: SpendProgressModelLineItem;
}

export const OrganisationSocialSpendProgress = ({
  loading,
  socialSpendProgress,
}: OrganisationSocialSpendProgressProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid
      item
      md={12}
      sx={{
        marginTop: "16px",
      }}
    >
      <Accordion
        square={true}
        sx={{
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          padding: "5px",
        }}
        disableGutters={true}
        expanded={open}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          setOpen(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={{ color: "#C4D5E7" }} />}
          aria-controls="social-spend-content"
          id="social-spend-panel"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item md={3}>
              <Stack spacing={2}>
                <TitleStack
                  loading={loading}
                  header="Social Spend"
                  subTitle="Overall Performance"
                />
                <ChipProgress
                  loading={loading}
                  value={`+${formatDecimalPlaces(socialSpendProgress?.pcChange ? Math.max(socialSpendProgress.pcChange, 0) : 0)}%`}
                  title="previous month"
                  primaryFontColour="#2C9F45"
                  secondaryFontColour="#4F8349"
                  chipColour="#D1F4D9"
                  dataTestId="social-spend-pc-change"
                />
              </Stack>
            </Grid>
            <Grid item md={9}>
              <Stack
                spacing={3}
                sx={{ paddingLeft: "5vw", paddingRight: "1vw", width: "100%" }}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`$${nFormatter(socialSpendProgress?.targetSpend || 0, 2)}`}
                      subTitle="Target ($)"
                      dataTestId="social-spend-total-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`$${nFormatter(socialSpendProgress?.achievedSpend || 0, 2)}`}
                      subTitle="Achieved ($)"
                      dataTestId="social-spend-achieved-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${nFormatter(socialSpendProgress?.pcComplete || 0, 2)}%`}
                      subTitle="Complete"
                      dataTestId="social-spend-pc-complete"
                    />
                  </Grid>
                </Grid>
                <DashboardProgressbar
                  loading={loading}
                  progress={socialSpendProgress?.pcComplete || 0}
                  startDate={"0%"}
                  endDate={"100%"}
                  progressTestId="social-spend-progress-bar"
                  prefixTestId="social-spend-start"
                  suffixTestId="social-spend-end"
                />
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {open ? (
            <OrganisationSocialSpendProgressSubData loading={loading} />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
