import { TableHeaderCell } from "social-pro-common/interfaces/tables";
import { Grid, Pagination, Table, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ViewEmployeeTableRowSkeleton } from "./ViewEmployeeTableRowSkeleton";
import { ViewEmployeeTableRow } from "./ViewEmployeeTableRow";
import { NoRows } from "../ListTable/NoRows";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";

interface ViewEmployeeTableProps {
  loading: boolean;
  employees: EmployeeLineItem[];
  project?: ProjectLineItem;
  employeeCount: number;
  setPage: (page: number) => void;
}

export const ViewEmployeeTable = ({
  employeeCount,
  employees,
  loading,
  project,
  setPage,
}: ViewEmployeeTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  const TABLE_HEAD: TableHeaderCell[] = [
    { align: "left", id: "employee-name", label: "Name or Code" },
    { align: "left", id: "primary-employer", label: "Primary Employer" },
    { align: "left", id: "occupation", label: "Occupation" },
    { align: "left", id: "occupationType", label: "Occupation Type" },
    { align: "left", id: "social-factors", label: "Social Factors" },
    { align: "left", id: "gender", label: "Gender" },
  ];

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          padding: "0px 0 !important",
        }}
      >
        <Table data-test-id="package-employees-table">
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {!loading && employees.length > 0
              ? employees.map((row) => {
                  return (
                    <ViewEmployeeTableRow
                      key={row.id}
                      employee={row}
                      project={project}
                    />
                  );
                })
              : null}
            {loading
              ? Array.from({ length: 10 }, (_, index) => (
                  <ViewEmployeeTableRowSkeleton key={index} />
                ))
              : null}
            {!loading && employees.length === 0 ? (
              <NoRows title={"Employees"} colSpan={5} />
            ) : null}
          </TableBody>
        </Table>
      </Grid>
      {employeeCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(employeeCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </>
  );
};
