// @mui
import { TableHeaderCell } from "social-pro-common/interfaces/tables";
import { TableRow, TableHead, TableCell } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";

interface ListTableHeadProps {
  loading: boolean;
  headLabel: TableHeaderCell[];
}

export default function ListTableHead({
  headLabel,
  loading,
}: ListTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell: TableHeaderCell, i) => {
          if (headCell?.id === "") {
            return (
              <TableCell
                key={`table-header-key-${i}`}
                sx={{
                  margin: "auto",
                  minWidth: headCell?.minWidth,
                  width: headCell?.width || "5%",
                }}
              />
            );
          }

          return (
            <TableHeader
              key={`table-header-key-${i}`}
              loading={loading}
              title={headCell?.label || ""}
              sx={{
                margin: "auto",
                minWidth: headCell?.minWidth,
                width: headCell?.width,
              }}
              align={headCell?.align || "center"}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}
