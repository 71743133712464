import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { Grid, Skeleton, Typography } from "@mui/material";
import PackageCommentsSection from "@stories/molecules/PackageCommentsSection/PackageCommentsSection";
import { useSubcontractorNotes } from "@hooks/crud/subcontractorNotes/useSubcontractorNotes";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { createDefaultSubcontractorNote } from "social-pro-common/interfaces/subcontractorNote";

interface PackageDetailsCommentsProps {
  isLoading: boolean;
  organisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
}
export const PackageDetailsComments = ({
  contractorPackage,
  isLoading,
  organisation,
  project,
}: PackageDetailsCommentsProps) => {
  const { comments, isCommentsLoading, upsertSubcontractorNotes } =
    useSubcontractorNotes(project?.id, contractorPackage?.id);

  const loading = isLoading || isCommentsLoading;

  return (
    <>
      <Grid
        item
        md={12}
        sx={{ margin: "0 0 20px", padding: "0 !important", width: "100%" }}
      >
        {loading ? (
          <Skeleton animation="wave">
            <Typography variant="h2" sx={{ fontSize: "24px" }}>
              {"Notes"}
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h2" sx={{ fontSize: "24px" }}>
            {"Notes"}
          </Typography>
        )}
      </Grid>
      <Grid item md={12}>
        <PackageCommentsSection
          loading={loading}
          notes={comments}
          organisation={organisation}
          onSubmit={async (commentText: string) => {
            if (project && contractorPackage) {
              const newComment = createDefaultSubcontractorNote(
                project.id,
                contractorPackage?.id,
                commentText,
              );
              await upsertSubcontractorNotes([newComment]);
            }
          }}
        />
      </Grid>
    </>
  );
};
