import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import {
  ProjectPolicyLineItem,
  createDefaultProjectPolicy,
  policyTypeToDescription,
  policyTypeToString,
} from "social-pro-common/interfaces/projectPolicy";
import PolicyModal from "@stories/organisms/PolicyModal/PolicyModal";
import { PolicyTableRow } from "../PolicyTableRow/PolicyTableRow";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "../ListTable/NoRows";
import { PolicyTableRowSkeleton } from "../PolicyTableRow/PolicyTableRowSkeleton";

interface PolicyRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  projectPolicies: ProjectPolicyLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const PolicyRequirementForm = ({
  handleBack,
  handleNext,
  loading,
  project,
  projectPolicies,
  setDirtyOnChange,
  setProject,
}: PolicyRequirementFormProps) => {
  const [policies, setPolicies] =
    useState<ProjectPolicyLineItem[]>(projectPolicies);
  const [selectedPolicy, setSelectedPolicy] = useState<
    ProjectPolicyLineItem | undefined
  >();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const createPolicy = (policy: ProjectPolicyLineItem) => {
    setPolicies([...policies, policy]);
    setDirtyOnChange(true);
  };

  const goBack = () => {
    project.policies = policies;
    setProject({ ...project });
    handleBack();
  };

  const setProjectPolicies = () => {
    project.policies = policies;
    setProject({ ...project });
    handleNext();
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
            // margin: "",
          }}
        >
          <SubTitle title={"Policies"} />

          <StyledButton
            data-test-id="add-policy-button"
            className="blackBtn"
            loading={loading}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setSelectedPolicy(createDefaultProjectPolicy(project.id));
              setOpen(true);
            }}
          >
            Add Policy
          </StyledButton>
        </Box>

        <Grid item xs={12} md={12}>
          <Box>
            {loading ? (
              <Table data-test-id="project-policy-table">
                <TableHead>
                  <TableRow>
                    <TableHeader
                      loading={loading}
                      title={"Policy Name"}
                      align="left"
                    />

                    <TableHeader
                      loading={loading}
                      title={"Action"}
                      align="right"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <PolicyTableRowSkeleton key={index} />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table data-test-id="project-policy-table">
                <TableHead>
                  <TableRow>
                    <TableHeader
                      loading={loading}
                      title={"Policy Name"}
                      align="left"
                    />

                    <TableHeader
                      loading={loading}
                      title={"Action"}
                      align="right"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policies.map(({ id, policyDescription, policyType }) => {
                    return (
                      <PolicyTableRow
                        key={`policy-table-row-${id}`}
                        loading={loading}
                        title={
                          policyDescription || policyTypeToString(policyType)
                        }
                        description={policyTypeToDescription(policyType)}
                        onDelete={() => {
                          setPolicies(policies.filter((f) => f.id !== id));
                        }}
                      />
                    );
                  })}
                  {policies.length === 0 ? (
                    <NoRows title={"Policies"} colSpan={4} />
                  ) : null}
                </TableBody>
              </Table>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          padding: "20px 40px 30px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={goBack}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledButton
            loading={loading}
            onClick={setProjectPolicies}
            variant="contained"
            type="submit"
          >
            Next
          </StyledButton>
        </Stack>
      </Grid>
      {selectedPolicy ? (
        <PolicyModal
          open={open}
          loading={loading}
          projectPolicy={selectedPolicy}
          createPolicy={createPolicy}
          handleClose={handleClose}
        />
      ) : null}
    </Box>
  );
};
