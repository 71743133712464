import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { Skeleton } from "@mui/material";
import { StepConfig, Steps } from "@stories/atoms/Steps/Steps";

interface ProjectWizardStepsProps {
  loading: boolean;
  project?: ProjectLineItem;
  activeStep: number;
}

export const ProjectWizardSteps = ({
  activeStep,
  loading,
}: ProjectWizardStepsProps) => {
  const steps = [
    { label: "Submission Summary" },
    { label: "Submit" },
  ] as StepConfig[];
  if (loading) {
    return (
      <Skeleton animation="wave" sx={{ margin: "auto" }}>
        <Steps activeStep={activeStep} steps={steps} />
      </Skeleton>
    );
  }

  return <Steps steps={steps} activeStep={activeStep} />;
};
