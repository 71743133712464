"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSustainabilitysAnalyticsModel = exports.decodeSustainabilityTargetAnalytics = exports.decodePolicyAnalyticsModel = exports.decodePolicyTargetAnalytics = exports.decodeSocialSpendAnalyticsModel = exports.decodeSocialSpendTargetAnalytics = exports.decodeLabourHoursAnalyticsModel = exports.decodeLabourHourTargetAnalytics = void 0;
var decodeLabourHourTargetAnalytics = function (data) {
    return {
        actual: data.actual,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeLabourHourTargetAnalytics = decodeLabourHourTargetAnalytics;
var decodeLabourHoursAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeLabourHourTargetAnalytics)(target);
        }),
    };
};
exports.decodeLabourHoursAnalyticsModel = decodeLabourHoursAnalyticsModel;
var decodeSocialSpendTargetAnalytics = function (data) {
    return {
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeSocialSpendTargetAnalytics = decodeSocialSpendTargetAnalytics;
var decodeSocialSpendAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeSocialSpendTargetAnalytics)(target);
        }),
    };
};
exports.decodeSocialSpendAnalyticsModel = decodeSocialSpendAnalyticsModel;
var decodePolicyTargetAnalytics = function (data) {
    return {
        expectedSubmissions: data.expected_submissions,
        id: data.id,
        isProjectLevelPolicy: data.is_project_level_policy,
        policyDescription: data.policy_description,
        policyType: data.policy_type,
        progress: data.progress,
        totalSubmissions: data.total_submissions,
        uri: data.uri,
    };
};
exports.decodePolicyTargetAnalytics = decodePolicyTargetAnalytics;
var decodePolicyAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) { return (0, exports.decodePolicyTargetAnalytics)(target); }),
    };
};
exports.decodePolicyAnalyticsModel = decodePolicyAnalyticsModel;
var decodeSustainabilityTargetAnalytics = function (data) {
    return {
        category: data.category,
        greenMetricId: data.green_metric_id,
        id: data.id,
        pcIncrease: data.pc_increase,
        thisMonth: data.this_month,
        toDate: data.to_date,
        type: data.type,
        uri: data.uri,
    };
};
exports.decodeSustainabilityTargetAnalytics = decodeSustainabilityTargetAnalytics;
var decodeSustainabilitysAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeSustainabilityTargetAnalytics)(target);
        }),
    };
};
exports.decodeSustainabilitysAnalyticsModel = decodeSustainabilitysAnalyticsModel;
