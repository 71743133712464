"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultSubcontractorNote = void 0;
var uuid_1 = require("uuid");
var createDefaultSubcontractorNote = function (projectId, contractorPackageId, comment) {
    return {
        comment: comment,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
    };
};
exports.createDefaultSubcontractorNote = createDefaultSubcontractorNote;
