import { useProjectContext } from "@hooks/context/useProjectContext";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { Grid, Skeleton, Typography } from "@mui/material";
import {
  ReportTypeTabs,
  ReportTypeTabsOption,
} from "@stories/molecules/ReportTypeTabs/ReportTypeTabs";
import { useState } from "react";
import { ReportTableSocial } from "@stories/molecules/ReportTable/ReportTableSocial";
import { ReportTableSustainability } from "@stories/molecules/ReportTable/ReportTableSustainability";

interface PackageReportProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
}
export const PackageReports = ({
  contractorPackage,
  isLoading,
}: PackageReportProps) => {
  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const [reportType, setReportType] = useState<ReportTypeTabsOption>(
    ReportTypeTabsOption.Social,
  );

  const loading = isLoading || isProjectLoading;

  return (
    <>
      <Grid
        item
        md={12}
        sx={{ margin: "0 0 20px", padding: "0 !important", width: "100%" }}
      >
        {loading ? (
          <Skeleton animation="wave">
            <Typography variant="h2" sx={{ fontSize: "24px" }}>
              {"Reports"}
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h2" sx={{ fontSize: "24px" }}>
            {"Reports"}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <ReportTypeTabs
          isLoading={loading}
          value={reportType}
          setReportType={(employmentLevel: ReportTypeTabsOption) => {
            setReportType(employmentLevel);
          }}
        />
      </Grid>
      <Grid item md={12}>
        {reportType === ReportTypeTabsOption.Social ? (
          <ReportTableSocial
            isLoading={loading}
            project={selectedProject}
            contractorPackage={contractorPackage}
            isPrimaryPackage={isPrimaryPackage}
          />
        ) : (
          <ReportTableSustainability
            isLoading={loading}
            project={selectedProject}
            contractorPackage={contractorPackage}
            isPrimaryPackage={isPrimaryPackage}
          />
        )}
      </Grid>
    </>
  );
};
