import { Divider, Grid } from "@mui/material";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { useOrganisationDashboard } from "@hooks/crud/organisationDashboard/useOrganisationDashboard";
import { OrganisationSidebar } from "./OrganisationSidebar";
import "react-circular-progressbar/dist/styles.css";
import { OrganisationProgress } from "./OrganisationProgress";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { WidgetContainer } from "../Dashboard/WidgetContainer/WidgetContainer";
import { OrganisationLabourHourProgress } from "./OrganisationLabourHourProgress/OrganisationLabourHourProgress";
import { OrganisationSocialSpendProgress } from "./OrganisationSocialSpendProgress/OrganisationSocialSpendProgress";
import { OrganisationLocalContentProgress } from "./OrganisationLocalContentProgress/OrganisationLocalContentProgress";
import { OrganisationSustainabilityProgress } from "./OrganisationSustainabilityProgress/OrganisationSustainabilityProgress";

export const OrganisationDashboard = () => {
  const { isAuthLoading } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { dashboardData, isAnalysisLoading } = useOrganisationDashboard();

  const loading = isAuthLoading || isAuthProfileLoading || isAnalysisLoading;

  const numberOfDaysForPackageSubmission = 0;
  return (
    <>
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid item md={9}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 30px !important",
            }}
          >
            <OrganisationProgress
              loading={loading}
              organisationProgress={dashboardData?.organisationProgress}
              title={userOrganisation?.organisationName}
            />
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ marginTop: "16px", width: "100%" }}
            />
            <WidgetContainer
              loading={loading}
              widgets={dashboardData?.widgets}
            />
            <OrganisationLabourHourProgress
              loading={loading}
              labourHourProgress={dashboardData?.labourHourProgress}
            />
            <OrganisationSocialSpendProgress
              loading={loading}
              socialSpendProgress={dashboardData?.spendProgress}
            />
            <OrganisationLocalContentProgress
              loading={loading}
              localContentProgress={dashboardData?.localContentProgress}
            />
            <OrganisationSustainabilityProgress
              loading={loading}
              sustainabilityProgress={dashboardData?.sustainabilityProgress}
            />
          </Grid>
        </Grid>
        <OrganisationSidebar
          loading={loading}
          subcontractorActivity={dashboardData?.subcontractorActivity}
          numberOfDaysForPackageSubmission={numberOfDaysForPackageSubmission}
        />
      </Grid>
    </>
  );
};
