import { Box, Tab, Tabs } from "@mui/material";

interface EmployeeTableTabProps {
  tabIndex: number;
  setTabIndex: (tab: number) => void;
}
export const EmployeeTableTabs = ({
  setTabIndex,
  tabIndex,
}: EmployeeTableTabProps): JSX.Element => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  return (
    <Box className="popuptabs">
      <Tabs
        value={tabIndex}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
      >
        <Tab label={"Labour Hours"} data-test-id="labour-hour-button" />;
        <Tab
          label={"Supporting Documents"}
          data-test-id="supporting-document-button"
        />
        ;
      </Tabs>
    </Box>
  );
};
