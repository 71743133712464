import { useEffect, useState } from "react";
import {
  OrganisationLineItem,
  createDefaultOrganisation,
} from "social-pro-common/interfaces/organisation";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import {
  ProfileLineItem,
  ProfileStatus,
  createDefaultProfile,
} from "social-pro-common/interfaces/profile";
import { ToastOptions, toast } from "react-toastify";
import { CreateAccountForm } from "@stories/molecules/CreateAccountForm/CreateAccountForm";
import * as RouteHelper from "@utils/routes";
import { catchSentryError } from "@utils/sentry";

// const stripePromise = null; //loadStripe("pk_test_JnqbQaHNT8HAWBT4hEiTQ72N");

export const CreateAccount = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    createUserProfile,
    isAuthProfileLoading,
    setUpUserProfileAndOrg,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const [loading, setLoading] = useState(false);
  const [defaultOrganisation, setDefaultOrganisation] = useState(
    createDefaultOrganisation(user.email),
  );
  const [defaultUserProfile, setDefaultUserProfile] = useState(
    createDefaultProfile(defaultOrganisation, user),
  );

  useEffect(() => {
    if (userOrganisation) {
      setDefaultOrganisation(userOrganisation);
    }
    if (userProfile) {
      setDefaultUserProfile(userProfile);
    }
  }, [userOrganisation, userProfile, navigate]);

  const finalizeAccount = async (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem,
  ) => {
    try {
      setLoading(true);
      if (userOrganisation && userProfile) {
        await setUpUserProfileAndOrg(
          {
            ...organisation,
            isSetup: true,
            organisationLogo: organisation.organisationLogoSrc,
          },
          {
            ...profile,
            isAdmin: true,
            isSetup: true,
            status: ProfileStatus.Active,
          },
        );
      } else {
        await createUserProfile(
          {
            ...organisation,
            isSetup: true,
            organisationLogo: organisation.organisationLogoSrc,
          },
          {
            ...profile,
            isAdmin: true,
            isSetup: true,
            status: ProfileStatus.Active,
          },
        );
      }
      if (profile.status === ProfileStatus.Invited) {
        toast("Profile created!", {
          type: "success",
        } as ToastOptions);
        navigate(RouteHelper.listProjects);
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save account, please contact support.", {
        type: "error",
      } as ToastOptions);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateAccountForm
      loading={loading || isAuthProfileLoading}
      organisation={defaultOrganisation}
      userProfile={defaultUserProfile}
      finalizeAccount={finalizeAccount}
    />
  );
};
