import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LidpForm } from "@stories/molecules/LidpForm/LidpForm";

interface LidpModalProps {
  open: boolean;
  loading: boolean;
  isCreateModal: boolean;
  projectId: string;
  packageId?: string;
  packageValue: number;
  isPrimaryPackage: boolean;
  existingLocalContent?: PackageLocalContentLineItem;
  reportId?: string;
  createLocalContent: (policy: PackageLocalContentLineItem[]) => Promise<void>;
  updateLocalContent: (policy: PackageLocalContentLineItem[]) => Promise<void>;
  handleClose: () => void;
}

export default function LidpModal({
  createLocalContent,
  existingLocalContent,
  handleClose,
  isCreateModal,
  isPrimaryPackage,
  loading,
  open,
  packageId,
  packageValue,
  projectId,
  reportId,
  updateLocalContent,
}: LidpModalProps) {
  const onSubmit = async (lc: PackageLocalContentLineItem) => {
    if (!existingLocalContent) {
      await createLocalContent([lc]);
    } else {
      await updateLocalContent([lc]);
    }
    handleClose();
  };

  const title = "Create Local Content";

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle sx={{ padding: "13px 24px !important" }}>{title}</DialogTitle>
        <DialogContent>
          <LidpForm
            existingLocalContent={existingLocalContent}
            projectId={projectId}
            packageId={packageId}
            packageValue={packageValue}
            isPrimaryPackage={isPrimaryPackage}
            isCreateModal={isCreateModal}
            reportId={reportId}
            handleSubmit={onSubmit}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
