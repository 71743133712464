import { useProfileContext } from "@hooks/context/useProfileContext";
import { useSocialProAdmin } from "@hooks/crud/socialProAdmin/useSocialProAdmin";
import { Grid, Stack } from "@mui/material";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { OrganisationTable } from "@stories/organisms/Tables/OrganisationTable";
import { useState } from "react";

export const SocialProAdmin = (): JSX.Element => {
  const { userOrganisation } = useProfileContext();
  const [page, setPage] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : "");
  };
  const {
    isSocialProAdminLoading,
    joinOrganisation,
    organisationCount,
    organisations,
    updateMaxProjectCount,
    updateOrganisationContractor,
  } = useSocialProAdmin(searchValue, page);
  const loading = isSocialProAdminLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Social Pro Admin"
          subTitle={"Manage Subcontractors"}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            overflow: "hidden",
            padding: "0px 0 !important",
          }}
          className="contract-table"
        >
          <OrganisationTable
            loading={loading}
            userOrganisation={userOrganisation}
            organisations={organisations}
            organisationCount={organisationCount}
            setPage={setPage}
            handleJoinOrganisation={joinOrganisation}
            handleUpdateOrganisationContractor={updateOrganisationContractor}
            handleUpdateMaxProjectCount={updateMaxProjectCount}
          />
        </Grid>
      </Grid>
    </>
  );
};
