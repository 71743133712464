import * as React from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import { ReportDocument } from "social-pro-common/entities/reportDocument";
import { ReportDocumentLineItem } from "social-pro-common/interfaces/reportDocument";
import {
  decodeReportDocument,
  encodeReportDocument,
} from "social-pro-common/decoders/reportDocument";
import { catchSentryError } from "@utils/sentry";

export const useReportDocument = (
  contractorPackageId?: string,
  reportId?: string,
) => {
  const [reportDocuments, setReportDocuments] = React.useState<
    ReportDocumentLineItem[]
  >([]);
  const [isReportDocumentsLoading, setIsLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listReportDocuments = React.useCallback(
    async (id: string, reportId: string): Promise<void> => {
      try {
        setIsLoading(true);
        const reportResult = await listApiData(
          "listReportDocuments",
          "reportDocument",
          id,
          { reportId },
        );
        reportResult.data.reverse();
        setReportDocuments(
          reportResult.data.map((p) =>
            decodeReportDocument(p as ReportDocument),
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not list report documents");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeReportDocument, catchSentryError],
  );

  const createReportDocument = React.useCallback(
    async (report: ReportDocumentLineItem): Promise<ReportDocumentLineItem> => {
      try {
        setIsLoading(true);
        const encodedReportDocument = encodeReportDocument(report);
        const upsertedReport = await postApiData(
          "createReportDocument",
          "reportDocument",
          encodedReportDocument,
        );
        const decodedReport = decodeReportDocument(
          upsertedReport.data as ReportDocument,
        );
        setReportDocuments((prevState) => [...prevState, decodedReport]);
        return decodedReport;
      } catch (error) {
        catchSentryError(error);
        setError("Could not create report document");
      } finally {
        setIsLoading(false);
      }
      return report;
    },
    [encodeReportDocument, postApiData, decodeReportDocument, catchSentryError],
  );

  const updateReportDocument = React.useCallback(
    async (report: ReportDocumentLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportDocument(report);
        await postApiData(
          "updateReportDocument",
          "reportDocument",
          encodedReport,
        );
        setReportDocuments((prevState) =>
          prevState.map((p) => (p.id === report.id ? report : p)),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not update report document");
      } finally {
        setIsLoading(false);
      }
    },
    [encodeReportDocument, postApiData, catchSentryError],
  );

  React.useEffect(() => {
    if (contractorPackageId && reportId) {
      listReportDocuments(contractorPackageId, reportId);
    }
  }, [contractorPackageId, reportId, listReportDocuments]);

  return {
    createReportDocument,
    error,
    isReportDocumentsLoading,
    listReportDocuments,
    reportDocuments,
    updateReportDocument,
  };
};