import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const UserTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ pr: 3 }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <IconButton color="primary" size="large">
              <EditIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <IconButton
              color="error"
              sx={{
                "&:hover ": {
                  background: "green",
                },
                borderColor: "red",
                color: "red",
              }}
              size="large"
            >
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
