import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePolicyAnalytics } from "@hooks/crud/analytics/usePolicyAnalytics";
import { Grid } from "@mui/material";
import { ContractorAnalyticsPolicy } from "@stories/molecules/ContractorAnalyticsPolicy/ContractorAnalyticsPolicy";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const AnalyticsPolicy = () => {
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, policyAnalytics } = usePolicyAnalytics(
    selectedProject?.id,
    contractorPackage?.id,
  );

  const loading = isProjectLoading || isAnalysisLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Policies"
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{
            margin: "0",
            padding: "15px 40px!important",
            width: "100%",
          }}
        >
          <ContractorAnalyticsPolicy
            isLoading={loading}
            policyAnalytics={policyAnalytics}
          />
        </Grid>
      </Grid>
    </>
  );
};
