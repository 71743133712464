import {
  Button,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";
import { getSeverityColour } from "social-pro-common/utils/color";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { ReportStatusChip } from "@stories/atoms/ReportStatusChip/SuccessChip";
import { stringToDate } from "social-pro-common/utils/date";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { CoolDownButton } from "@stories/atoms/CooldownButton/CooldownButton";
import { RejectReportDialog } from "../RejectReportDialog/RejectReportDialog";
import { useState } from "react";
import { AcceptReportDialog } from "../AcceptReportDialog/AcceptReportDialog";

interface ReporSubmissiontTableRowProps {
  project: ProjectLineItem;
  contractorPackage: ContractorPackageLineItem;
  reportSubmissions: ReportSubmissionLineItem[];
  reportId: string;
  handleDownloadReport: (contractorPackageId: string) => void;
  handlViewReport: (reportMonthId: string, rowId: string) => void;
  handleRevertReport: (
    reportSubmission: ReportSubmissionLineItem,
    rejectionMessage: string,
  ) => Promise<void>;
  handleAcceptReport: (
    reportSubmission: ReportSubmissionLineItem,
  ) => Promise<void>;
  sendReportReminder: (contractorPackageId: string, reportId: string) => void;
}

export const ReportSubmssionTableRow = ({
  contractorPackage,
  handlViewReport,
  handleAcceptReport,
  handleDownloadReport,
  handleRevertReport,
  reportId,
  reportSubmissions,
  sendReportReminder,
}: ReporSubmissiontTableRowProps) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  let reportStatus = ReportStatus.Upcoming;
  const packageReportSubmission = reportSubmissions.find(
    (g) =>
      g.reportId === reportId && g.contractorPackageId === contractorPackage.id,
  );
  reportStatus = !packageReportSubmission
    ? ReportStatus.Upcoming
    : packageReportSubmission.reportStatus;

  if (reportStatus === ReportStatus.Upcoming) {
    if (new Date().getTime() > stringToDate(reportId).getTime()) {
      reportStatus = ReportStatus.Overdue;
    }
  }

  return (
    <>
      <TableRow key={contractorPackage.id}>
        <TableCell align="left">{`${contractorPackage.packageNumber}`}</TableCell>
        <TableCell align="center">
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ justifySelf: "center" }}
          >
            <Grid item>
              <Avatar
                alt="User 1"
                src={contractorPackage.contractorLogo}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography align="left" variant="subtitle1" component="div">
                {contractorPackage.contractorName}
              </Typography>
              <Typography align="left" variant="subtitle2" noWrap>
                {`${contractorPackage.created.toLocaleDateString("en-GB")}`}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">
          {`${
            contractorPackage.packageDescription
              ? contractorPackage.packageDescription
              : packageTypeToString(contractorPackage.packageType)
          }`}
        </TableCell>
        <TableCell align="center">
          <CircularProgressWithLabel
            value={contractorPackage.estimatedProgress}
            color={getSeverityColour(contractorPackage.estimatedProgress)}
          />
        </TableCell>
        <TableCell align="center" className="center">
          <ReportStatusChip reportStatus={reportStatus} />
        </TableCell>
        <TableCell
          align="right"
          className="right"
          sx={{ paddingRight: "20px !important" }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            {reportStatus === ReportStatus.Complete ||
            reportStatus === ReportStatus.Accepted ? (
              <Button
                color="primary"
                size="large"
                onClick={() => handlViewReport(reportId, contractorPackage.id)}
                className="blackBtn"
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
              >
                View
              </Button>
            ) : null}
            {reportStatus === ReportStatus.Complete ? (
              <Button
                color="primary"
                size="large"
                className="blackBtn"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
                onClick={() => setOpenAcceptModal(true)}
              >
                Accept
              </Button>
            ) : null}
            {reportStatus === ReportStatus.Complete ||
            reportStatus === ReportStatus.Accepted ? (
              <Button
                color="primary"
                size="large"
                className="blackBtn"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
                onClick={() => setOpenRejectModal(true)}
              >
                Reject
              </Button>
            ) : null}

            {reportStatus === ReportStatus.Complete ||
            reportStatus === ReportStatus.Accepted ? (
              <Button
                color="primary"
                size="large"
                onClick={() => handleDownloadReport(contractorPackage.id)}
                className="blackBtn"
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
              >
                Download
              </Button>
            ) : null}

            {reportStatus === ReportStatus.Overdue ? (
              <CoolDownButton
                cooldownDuration={5000}
                onResend={() => {
                  sendReportReminder(contractorPackage.id, reportId);
                }}
                title={"Send Reminder"}
              />
            ) : null}
          </Stack>
        </TableCell>
        {packageReportSubmission ? (
          <RejectReportDialog
            open={openRejectModal}
            reportSubmission={packageReportSubmission}
            onCancel={(): void => {
              setOpenRejectModal(false);
            }}
            onConfirm={async (
              reportSubmission: ReportSubmissionLineItem,
              rejectionMessage: string,
            ): Promise<void> => {
              setOpenRejectModal(false);
              await handleRevertReport(reportSubmission, rejectionMessage);
            }}
          />
        ) : null}
        {packageReportSubmission ? (
          <AcceptReportDialog
            open={openAcceptModal}
            reportSubmission={packageReportSubmission}
            onCancel={(): void => {
              setOpenAcceptModal(false);
            }}
            onConfirm={async (
              reportSubmission: ReportSubmissionLineItem,
            ): Promise<void> => {
              setOpenAcceptModal(false);
              await handleAcceptReport(reportSubmission);
            }}
          />
        ) : null}
      </TableRow>
    </>
  );
};
