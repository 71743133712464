"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectAccessLevel = void 0;
var ProjectAccessLevel;
(function (ProjectAccessLevel) {
    ProjectAccessLevel["Admin"] = "project-access-level_admin";
    ProjectAccessLevel["Manager"] = "project-access-level_manager";
    ProjectAccessLevel["Write"] = "project-access-level_write-only";
    ProjectAccessLevel["ReadOnly"] = "project-access-level_read-only";
    ProjectAccessLevel["NoAccess"] = "project-access-level_no-access";
})(ProjectAccessLevel || (exports.ProjectAccessLevel = ProjectAccessLevel = {}));
