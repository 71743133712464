import * as React from "react";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { Typography, Box, CircularProgress } from "@mui/material";

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number; maxValue?: number },
) => {
  const clampedValue = Math.min(Math.round(props.value), props.maxValue || 100);
  return (
    <Box sx={{ display: "inline-flex", position: "relative" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        value={clampedValue}
        sx={{ width: "20px" }}
      />
      <Box
        sx={{
          alignItems: "center",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <Typography
          paddingTop={"3px"}
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${clampedValue}%`}</Typography>
      </Box>
    </Box>
  );
};
