"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatForecastDataTypeToString = exports.createDefaultForecastData = exports.createDefaultForecast = void 0;
var uuid_1 = require("uuid");
var forecastData_1 = require("../../entities/forecastData");
var date_1 = require("../../utils/date");
var createDefaultForecast = function (project, contractorPackage) {
    var reportMonths = project.projectReportMonths;
    var reportIds = reportMonths === null || reportMonths === void 0 ? void 0 : reportMonths.map(function (rm) { return (0, date_1.dateToString)(rm); });
    var forecastModelId = (0, uuid_1.v4)();
    return {
        active: true,
        colour: "#0081E2",
        contractorPackageId: contractorPackage.id,
        created: new Date(),
        data: reportIds.map(function (rm) { return (0, exports.createDefaultForecastData)(forecastModelId, rm); }),
        id: forecastModelId,
        modelName: "",
        projectId: project.id,
        units: forecastData_1.ForecastUnitType.Hour,
    };
};
exports.createDefaultForecast = createDefaultForecast;
var createDefaultForecastData = function (forecastModelId, reportId) {
    return {
        created: new Date(),
        forecastModelId: forecastModelId,
        id: (0, uuid_1.v4)(),
        reportId: reportId,
        value: 0,
    };
};
exports.createDefaultForecastData = createDefaultForecastData;
var formatForecastDataTypeToString = function (type) {
    switch (type) {
        case forecastData_1.ForecastUnitType.Hour:
            return "Hours";
        case forecastData_1.ForecastUnitType.Spend:
            return "Spend";
        default:
            return "";
    }
};
exports.formatForecastDataTypeToString = formatForecastDataTypeToString;
