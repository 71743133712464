import { useProjectContext } from "@hooks/context/useProjectContext";
import { Box, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { PackageDetailsHours } from "@stories/organisms/PackageDetails/PackageDetailsHours";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageDetailsEnviroSustainability } from "@stories/organisms/PackageDetails/PackageDetailsEnviroSustainability";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsHoursTarget } from "@stories/organisms/PackageDetails/PackageDetailsHoursTarget";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import {
  PackageDetailsTab,
  PackageDetailsTabOption,
} from "@stories/molecules/PackageDetailsTab/PackageDetailsTab";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { dateToString, stringToDate } from "social-pro-common/utils/date";
import { PackageAnalyticsLocalContent } from "@stories/molecules/PackageAnalyticsLocalContent/PackageAnalyticsLocalContent";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const MyPackage = () => {
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();
  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();

  const [reportId, setReportDate] = useState<string>(dateToString(new Date()));

  const onLabourHourTargetChange = (targetId?: string) => {
    setLabourHourTargetId(targetId);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (targetId?: string) => {
    setSocialSpendTargetId(targetId);
  };

  const [activeStep, setActiveStep] = useState<PackageDetailsTabOption>(
    PackageDetailsTabOption.Hours,
  );

  const setActiveTab = (activeTab?: PackageDetailsTabOption) => {
    if (activeTab) {
      setLabourHourTargetId(undefined);
      setSocialSpendTargetId(undefined);
      setActiveStep(activeTab);
    }
  };

  const loading = isAuthProfileLoading || isProjectLoading;

  const selectedPackageStep = (() => {
    switch (activeStep) {
      default:
      case PackageDetailsTabOption.Details:
        ``;
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={userOrganisation}
            contractorPackage={contractorPackage}
          />
        );
      case PackageDetailsTabOption.Hours:
        return !labourHourTargetId ? (
          <PackageDetailsHours
            isLoading={loading}
            contractorPackage={contractorPackage}
            reportId={reportId}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : (
          <PackageDetailsHoursTarget
            isLoading={loading}
            contractorPackage={contractorPackage}
            targetId={labourHourTargetId}
            reportId={reportId}
            handlOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
          />
        );

      case PackageDetailsTabOption.Spend:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            contractorPackage={contractorPackage}
            reportId={reportId}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            contractorPackage={contractorPackage}
            targetId={socialSpendTargetId}
            reportId={reportId}
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case PackageDetailsTabOption.LocalContent:
        return (
          <PackageAnalyticsLocalContent
            loading={loading}
            reportId={reportId}
            isPrimaryPackage={isPrimaryPackage}
            contractorPackage={contractorPackage}
            localContents={getSortedLocalContent(
              selectedProject?.localContents,
            )}
          />
        );
      case PackageDetailsTabOption.Policy:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            contractorPackage={contractorPackage}
          />
        );
      case PackageDetailsTabOption.Enviro:
        return (
          <PackageDetailsEnviroSustainability
            loading={loading}
            contractorPackage={contractorPackage}
            project={selectedProject}
            reportId={reportId}
          />
        );
      case PackageDetailsTabOption.Forecast:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            contractorPackage={contractorPackage}
          />
        );
      case PackageDetailsTabOption.Reports:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
          />
        );
    }
  })();

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="My Package"
          subTitle={userOrganisation?.organisationName}
        />

        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Box className="newStyleField monthSelect">
                <MonthSelect
                  data-test-id="month-select"
                  loading={loading}
                  project={selectedProject}
                  contractorPackage={contractorPackage}
                  isPrimaryPackage={isPrimaryPackage}
                  setDate={(date: Date) => {
                    setReportDate(dateToString(date));
                  }}
                  date={stringToDate(reportId)}
                />
              </Box>
              <PackageDetailsTab
                isLoading={loading}
                activeTab={activeStep}
                project={selectedProject}
                contractorPackage={contractorPackage}
                setActiveTab={setActiveTab}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ padding: "20px 0px 50px !important" }}
            className="contract-table glb-table-sty"
          >
            {selectedPackageStep}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
