import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

export type StepConfig = { label: string; icon?: React.ReactNode };

interface StepsProps {
  activeStep: number;
  steps: StepConfig[];
}

export const Steps = ({ activeStep, steps }: StepsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step
            key={step.label}
            sx={{
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "grey.500", // Just text label (COMPLETED)
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "secondary.main", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "secondary.dark", // circle color (COMPLETED)
              },
              "& .MuiStepLabel-root .MuiStepIcon-text": {
                fill: "#fff", // circle's number (ACTIVE)
              },
            }}
          >
            <StepLabel sx={{ color: "#fff" }} icon={step.icon}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
