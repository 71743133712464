import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid, Stack } from "@mui/material";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Sustainability } from "@stories/organisms/Sustainability/Sustainability";
import { SustainabilitySteps } from "@stories/molecules/SustainabilitySteps/SustainabilitySteps";
import { useEffect, useState } from "react";
import * as RouteHelper from "@utils/routes";
import { useNavigate, useParams } from "react-router-dom";
import SustainabilityWizardCompleteModal from "./SustainabilityWizardCompleteModal";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useGreenMetricComment } from "@hooks/crud/greenMetricComment/useGreenMetricComment";
import {
  GreenMetricCommentLineItem,
  createDefaultGreenMetricComment,
} from "social-pro-common/interfaces/greenMetricComment";
import { useGreenMetricCategorySubmission } from "@hooks/crud/greenMetricCategorySubmission/useGreenMetricCategorySubmission";
import { GreenMetricCategorySubmissionLineItem } from "social-pro-common/interfaces/greenMetricCategorySubmission";
import { ReportConfirmation } from "@stories/organisms/ReportConfirmation/ReportConfirmation";
import { createDefaultReportSubmission } from "social-pro-common/interfaces/reportSubmission";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { useReportSustainabilitySubmission } from "@hooks/crud/reportSubmissionSustainability/useReportSubmissionSustainability";

export const SustainabilityWizard = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  if (!reportId) {
    throw Error("Not report number");
  }

  const [showSuccess, setShowSuccess] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { isAuthProfileLoading } = useProfileContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { createReportSubmission, isReportLoading } =
    useReportSustainabilitySubmission();

  const {
    greenMetricCategorySubmissions,
    isGreenMetricCategorySubmissionLoading,
    updateGreenMetricCategorySubmission,
  } = useGreenMetricCategorySubmission(contractorPackage?.id, reportId);

  const { greenMetricComments, updateGreenMetricComment } =
    useGreenMetricComment(contractorPackage?.id, reportId);

  const loading =
    (isAuthProfileLoading ||
      isReportLoading ||
      isGreenMetricCategorySubmissionLoading ||
      isProjectLoading) &&
    !isSubmitting;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const greenMetrics = selectedProject?.greenMetrics || [];

  const selectedMetric = selectedProject?.greenMetrics.find(
    (g, index) => index === activeStep,
  );

  const handleUpdateComment = async (
    newComment: GreenMetricCommentLineItem,
  ) => {
    setIsSubmitting(() => true);
    await updateGreenMetricComment(newComment);
    setIsSubmitting(() => false);
  };

  const greenMetric = selectedProject?.greenMetrics[activeStep];

  const [comment, setComment] = useState<string>("");

  const updateComment = async (comment: string, save: boolean) => {
    if (selectedProject && contractorPackage && reportId) {
      if (save) {
        const matchingPackageComment = greenMetricComments.find(
          (c) => c.greenMetricId === greenMetric?.id,
        );
        if (matchingPackageComment) {
          handleUpdateComment({
            ...matchingPackageComment,
            comment: comment,
          });
        } else {
          const greenMetric = greenMetrics[activeStep];
          handleUpdateComment(
            createDefaultGreenMetricComment(
              greenMetric?.id,
              contractorPackage?.id,
              reportId,
              comment,
            ),
          );
        }
      }
      setComment(comment);
    }
  };

  const handleUpdate = async (
    greenMetricCategorySubmission: GreenMetricCategorySubmissionLineItem,
  ) => {
    setIsSubmitting(true);
    await updateGreenMetricCategorySubmission(greenMetricCategorySubmission);
    setIsSubmitting(false);
  };

  const onSubmit = async () => {
    if (contractorPackage) {
      const reportSubmission = createDefaultReportSubmission(
        contractorPackage.projectId,
        contractorPackage.id,
        reportId,
      );
      reportSubmission.reportStatus = ReportStatus.Complete;
      await createReportSubmission(reportSubmission);
      setShowSuccess(true);
    }
  };

  const currentComment = greenMetricComments.find(
    (gc) => gc.greenMetricId === selectedMetric?.id,
  );

  useEffect(() => {
    setComment(currentComment?.comment || "");
  }, [currentComment]);

  const wizardComponents = greenMetrics.map((metric) => (
    <Sustainability
      key={metric.id}
      loading={loading}
      project={selectedProject}
      reportId={reportId}
      greenMetric={metric}
      contractorPackage={contractorPackage}
      greenMetricCategorySubmissions={greenMetricCategorySubmissions}
      isSubmitting={isSubmitting}
      commentValue={comment}
      setIsSubmitting={setIsSubmitting}
      createGreenMetricCategorySubmission={handleUpdate}
      updateGreenMetricCategorySubmission={handleUpdate}
      updateComment={updateComment}
    />
  ));

  wizardComponents.push(
    <ReportConfirmation
      loading={loading}
      project={selectedProject}
      reportId={reportId}
    />,
  );

  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className="steps"
          sx={{ margin: "0", padding: "30px 20px" }}
        >
          <SustainabilitySteps
            loading={loading}
            activeStep={activeStep}
            greenMetrics={selectedProject?.greenMetrics || []}
          />
        </Grid>
        {selectedProject && contractorPackage ? selectedWizardStep : undefined}
        {!selectedProject || !contractorPackage ? (
          <Sustainability
            loading={true}
            project={selectedProject}
            reportId={reportId}
            contractorPackage={contractorPackage}
            greenMetricCategorySubmissions={greenMetricCategorySubmissions}
            isSubmitting={isSubmitting}
            commentValue={comment}
            setIsSubmitting={setIsSubmitting}
            createGreenMetricCategorySubmission={handleUpdate}
            updateGreenMetricCategorySubmission={handleUpdate}
            updateComment={updateComment}
          />
        ) : undefined}
        <Grid
          container
          sx={{
            display: "flex !important",
            justifyContent: "space-between",
            padding: "0 40px 80px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack direction="row" spacing={1} mt={2}>
              {activeStep > 0 ? (
                <StyledButton
                  loading={loading}
                  onClick={handleBack}
                  variant="outlined"
                >
                  Back
                </StyledButton>
              ) : null}

              {activeStep < greenMetrics.length ? (
                <StyledButton loading={loading} onClick={handleNext}>
                  Next
                </StyledButton>
              ) : (
                <StyledButton
                  loading={loading}
                  disabled={loading}
                  variant="contained"
                  onClick={onSubmit}
                >
                  Submit
                </StyledButton>
              )}
            </Stack>
          </Grid>
        </Grid>
        {showSuccess ? (
          <SustainabilityWizardCompleteModal
            handleClose={() => {
              setShowSuccess(false);
              if (selectedProject) {
                navigate(RouteHelper.sustainability(selectedProject?.id));
              }
            }}
          />
        ) : null}
      </Grid>
    </>
  );
};
