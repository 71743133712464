import { useFormik } from "formik";
import * as yup from "yup";
import {
  FormControl,
  Box,
  Grid,
  InputAdornment,
  Tooltip,
  Zoom,
  TextField,
  Autocomplete,
  Skeleton,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Country, ICountry } from "country-state-city";
import {
  EmployeeLineItem,
  genderToString,
  existingJobTypeToString,
  occupationTypeOptionToString,
  employmentLevelToString,
  employeeSocialFactorToString,
  employmentTypeToString,
  contractTypeToString,
} from "social-pro-common/interfaces/contractorEmployee";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { validatePostCode } from "@utils/location";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import dayjs from "dayjs";
import { validateABN } from "social-pro-common/utils/abn";
import InfoIcon from "@mui/icons-material/Info";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  ContractType,
  EmployeeSocialFactor,
  EmploymentLevel,
  EmploymentType,
  Gender,
  NewExistingJobType,
  OccupationType,
} from "social-pro-common/entities/contractorEmployee";
import { useSearchEmployee } from "@hooks/crud/employee/useSearchEmployee";
import { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { cleanString } from "social-pro-common/utils/string";

interface EmployeeFormProps {
  loading: boolean;
  employee: EmployeeLineItem;
  handleSubmit: (employee: EmployeeLineItem) => Promise<void>;
}

export const EmployeeForm = ({
  employee,
  handleSubmit,
  loading,
}: EmployeeFormProps) => {
  const [selectedEmployeeSocialFactors, setSelectedEmployeeSocialFactor] =
    useState<EmployeeSocialFactor[]>(employee.socialFactors);

  const [isUniqueEmployeeName, setIsUniqueEmployeeName] = useState<string>();

  const { isSearchEmployeeLoading, searchEmployeesByNameOrCode } =
    useSearchEmployee();

  const searchEmployee = (val: string) => {
    searchEmployeesByNameOrCode([val]).then((res) => {
      if (res.length) {
        setIsUniqueEmployeeName("Active employee exists with this name");
      } else {
        setIsUniqueEmployeeName(undefined);
      }
    });
  };

  const search = useRef(debounce(searchEmployee, 500));

  useEffect(() => {
    if (!isUniqueEmployeeName) {
      formik.validateForm();
    }
  }, [isUniqueEmployeeName]);

  const validationSchema = yup.object({
    contractType: yup.string(),
    country: yup.string().required("Country is required"),
    dob: yup.date().nullable(),
    employeeIdentifier: yup.string().nullable(),
    employeeName: yup
      .string()
      .required("Employee name or Code is required")
      .min(3, "Employee name or Code is too short"),
    employerAbn: yup
      .string()
      .required("ABN is required")
      .test(
        "Valid ABN",
        "Not a valid ABN - i.e. 43 665 205 185",
        function (item) {
          return validateABN(item);
        },
      ),
    employmentLevel: yup.string().required("Employment level is required"),
    employmentType: yup.string().required("Employment type is required"),
    gender: yup.string().required("Gender required"),
    newExistingJob: yup.string().required("New/Existing job is required"),
    occupation: yup.string().required("Occupation is required"),
    occupationType: yup.string().required("Occupation type is required"),
    postCode: yup
      .string()
      .nullable()
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item) {
          if (item) {
            return validatePostCode(this.parent.country, item);
          }
          return true;
        },
      ),
    primaryEmployer: yup.string().required("Primary Employer is required"),
    socialFactors: yup.array(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contractType: employee.contractType,
      country: employee.country,
      dob: employee?.dob ? dayjs(employee.dob) : null,
      employeeIdentifier: employee.employeeIdentifier,
      employeeName: employee.employeeName,
      employerAbn: employee.employerAbn,
      employmentLevel: employee.employmentLevel,
      employmentType: employee.employmentType,

      gender: employee.gender,
      newExistingJob: employee.newExistingJob,
      occupation: employee.occupation,
      occupationType: employee.occupationType,
      postCode: employee.postCode,
      primaryEmployer: employee.primaryEmployer,
      socialFactors: employee.socialFactors,
    },
    onSubmit: async (values) => {
      if (isUniqueEmployeeName) {
        return;
      }
      await handleSubmit({
        ...employee,
        contractType: values.contractType,
        country: values.country,
        dob: values?.dob ? values.dob.toDate() : undefined,
        employeeIdentifier: values.employeeIdentifier,
        employeeName: values.employeeName,
        employerAbn: cleanString(values.employerAbn),
        employmentLevel: values.employmentLevel,
        employmentType: values.employmentType,
        gender: values.gender,
        newExistingJob: values.newExistingJob,
        occupation: values.occupation,
        occupationType: values.occupationType,
        postCode: values.postCode,
        primaryEmployer: values.primaryEmployer,
        socialFactors: selectedEmployeeSocialFactors,
      });
    },
    validationSchema: validationSchema,
  });

  const countries = Country.getAllCountries();

  const isDisabled = loading || isSearchEmployeeLoading || formik.isSubmitting;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Box>
      <form className="employee-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              id="employeeName"
              name="employeeName"
              label="Employee Name/Code"
              fullWidth
              disabled={isDisabled}
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 3) {
                  search.current(e.target.value);
                } else {
                  setIsUniqueEmployeeName(undefined);
                }
                formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.employeeName}
              error={
                (formik.touched.employeeName &&
                  Boolean(formik.errors.employeeName)) ||
                Boolean(isUniqueEmployeeName)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    variant="standard"
                    sx={{
                      color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                    }}
                  >
                    {isSearchEmployeeLoading ? (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                        }}
                      >
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ) : (
                      <Tooltip
                        TransitionComponent={Zoom}
                        placement="top"
                        title={
                          <div
                            style={{
                              textAlign: "left",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {`1) If an employee has a registered training contract with the VRQA, enter their registered training number.
                          2) If an employee has already been assigned a unique employee ID for the project, enter this unique employee ID.`}
                          </div>
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={
                formik.touched.employeeName
                  ? formik.errors.employeeName || isUniqueEmployeeName
                  : isUniqueEmployeeName
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              id="employeeIdentifier"
              name="employeeIdentifier"
              label="Identifier (Optional)"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employeeIdentifier}
              error={
                formik.touched.employeeIdentifier &&
                Boolean(formik.errors.employeeIdentifier)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                    }}
                  >
                    <Tooltip
                      TransitionComponent={Zoom}
                      placement="top"
                      title={
                        <div
                          style={{
                            textAlign: "left",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {`Unique identifier only used in SocialPro and never exported, i.e. Name, Code, or ID`}
                        </div>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              helperText={
                formik.touched.employeeIdentifier
                  ? formik.errors.employeeIdentifier
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledDatePicker
              label="DOB"
              loading={loading}
              fullwidth
              disabled={isDisabled}
              onChange={(value: dayjs.Dayjs) => {
                if (value) {
                  formik.setFieldValue("dob", value, true);
                }
              }}
              value={formik.values.dob}
              format={"DD MMMM YYYY"}
              slotProps={{
                textField: {
                  "data-test-id": "employee-dob",
                  error: formik.touched.dob && Boolean(formik.errors.dob),
                  fullWidth: true,
                  helperText:
                    formik.touched.dob && Boolean(formik.errors.dob)
                      ? "Invalid date"
                      : "",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="gender"
                  options={Object.values(Gender)}
                  disabled={isDisabled}
                  defaultValue={Gender.Male}
                  getOptionLabel={(option) => genderToString(option)}
                  value={formik.values.gender}
                  onChange={(e: any, value: Gender | null) => {
                    formik.setFieldValue("gender", value);
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="gender"
                      {...params}
                      name="gender"
                      label="Gender"
                      fullWidth
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={
                        formik.touched.gender ? formik.errors.gender : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="country"
                  options={countries}
                  disabled={isDisabled}
                  getOptionLabel={(option) => option.name}
                  value={Country.getCountryByCode(formik.values.country)}
                  onChange={(e: any, value: ICountry | null) => {
                    formik.setFieldValue("country", value?.isoCode);
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="country"
                      {...params}
                      name="country"
                      label="Country"
                      fullWidth
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country ? formik.errors.country : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              loading={loading}
              id="postCode"
              name="postCode"
              label="Postcode"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postCode}
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode ? formik.errors.postCode : ""}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              id="primaryEmployer"
              name="primaryEmployer"
              label="Primary Employer"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryEmployer}
              error={
                formik.touched.primaryEmployer &&
                Boolean(formik.errors.primaryEmployer)
              }
              helperText={
                formik.touched.primaryEmployer
                  ? formik.errors.primaryEmployer
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              id="employerAbn"
              name="employerAbn"
              label="Employer ABN"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employerAbn}
              error={
                formik.touched.employerAbn && Boolean(formik.errors.employerAbn)
              }
              helperText={
                formik.touched.employerAbn ? formik.errors.employerAbn : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              id="occupation"
              name="occupation"
              label="Occupation"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.occupation}
              error={
                formik.touched.occupation && Boolean(formik.errors.occupation)
              }
              helperText={
                formik.touched.occupation ? formik.errors.occupation : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="occupationType"
                  options={Object.values(OccupationType)}
                  getOptionLabel={(option) =>
                    occupationTypeOptionToString(option) || ""
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  disabled={isDisabled}
                  value={formik.values.occupationType}
                  onChange={(e: any, value: OccupationType | null) => {
                    formik.setFieldValue("occupationType", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="occupationType"
                      label="Occupation Type"
                      fullWidth
                      error={
                        formik.touched.occupationType &&
                        Boolean(formik.errors.occupationType)
                      }
                      helperText={
                        formik.touched.occupationType
                          ? formik.errors.occupationType
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {loading ? (
              <Skeleton animation="wave" variant="rounded" width={"100%"}>
                <TextField
                  label="DEFAULT TEXT FIELD CONTENT - LOADING"
                  fullWidth
                />
              </Skeleton>
            ) : (
              <Autocomplete
                id="newExistingJob"
                options={Object.values(NewExistingJobType)}
                getOptionLabel={(option) =>
                  existingJobTypeToString(option) || ""
                }
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "8px",
                  },
                }}
                disabled={isDisabled}
                value={formik.values.newExistingJob}
                onChange={(e: any, value: NewExistingJobType | null) => {
                  formik.setFieldValue("newExistingJob", value);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="newExistingJob"
                    label="New/Existing Job"
                    fullWidth
                    size="medium"
                    error={
                      formik.touched.newExistingJob &&
                      Boolean(formik.errors.newExistingJob)
                    }
                    helperText={
                      formik.touched.newExistingJob
                        ? formik.errors.newExistingJob
                        : ""
                    }
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {loading ? (
              <Skeleton animation="wave" variant="rounded" width={"100%"}>
                <TextField
                  label="DEFAULT TEXT FIELD CONTENT - LOADING"
                  fullWidth
                />
              </Skeleton>
            ) : (
              <Autocomplete
                fullWidth
                id="employmentType"
                options={Object.values(EmploymentType)}
                getOptionLabel={(option) =>
                  employmentTypeToString(option) || ""
                }
                disabled={isDisabled}
                value={formik.values.employmentType}
                onChange={(e: any, value: EmploymentType | null) => {
                  formik.setFieldValue("employmentType", value);
                }}
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "8px",
                  },
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="employmentType"
                    label="Employment Type"
                    fullWidth
                    error={
                      formik.touched.employmentType &&
                      Boolean(formik.errors.employmentType)
                    }
                    helperText={
                      formik.touched.employmentType
                        ? formik.errors.employmentType
                        : ""
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="employmentLevel"
                  options={Object.values(EmploymentLevel)}
                  getOptionLabel={(option) =>
                    employmentLevelToString(option) || ""
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  disabled={isDisabled}
                  value={formik.values.employmentLevel}
                  onChange={(event: any, value: EmploymentLevel | null) => {
                    formik.setFieldValue("employmentLevel", value, true);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="employmentLevel"
                      label="Employment Level"
                      fullWidth
                      error={
                        formik.touched.employmentLevel &&
                        Boolean(formik.errors.employmentLevel)
                      }
                      helperText={
                        formik.touched.employmentLevel
                          ? formik.errors.employmentLevel
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="contractType"
                  options={Object.values(ContractType)}
                  getOptionLabel={(option) =>
                    contractTypeToString(option) || ""
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  disabled={isDisabled}
                  value={formik.values.contractType}
                  onChange={(e: any, value: ContractType | null) => {
                    formik.setFieldValue("contractType", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="contractType"
                      label="Contract Type"
                      fullWidth
                      error={
                        formik.touched.employmentLevel &&
                        Boolean(formik.errors.contractType)
                      }
                      helperText={
                        formik.touched.contractType
                          ? formik.errors.contractType
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  id="socialFactors"
                  options={Object.values(EmployeeSocialFactor)}
                  disabled={isDisabled}
                  getOptionLabel={(option) =>
                    employeeSocialFactorToString(option)
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  value={selectedEmployeeSocialFactors}
                  onChange={(event: any, value: EmployeeSocialFactor[]) => {
                    setSelectedEmployeeSocialFactor(value);
                  }}
                  onBlur={formik.handleBlur}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => employeeSocialFactorToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {employeeSocialFactorToString(option)}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="socialFactors"
                      {...params}
                      name="socialFactors"
                      label="Social Factors"
                      fullWidth
                      error={
                        formik.touched.socialFactors &&
                        Boolean(formik.errors.socialFactors)
                      }
                      helperText={
                        formik.touched.socialFactors
                          ? formik.errors.socialFactors
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} />
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={isDisabled}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
