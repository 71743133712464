import { useCallback, useState, useEffect } from "react";
import { listApiData, postBatchApiData } from "@hooks/utils/api";
import { ProjectCommentLineItem } from "social-pro-common/interfaces/projectComment";
import {
  decodeProjectComment,
  encodeProjectComment,
} from "social-pro-common/decoders/projectComment";
import { ProjectComment } from "social-pro-common/entities/projectComment";
import { catchSentryError } from "@utils/sentry";

export const useProjectComments = (
  projectId?: string,
  reportId?: string,
  contractorPackageId?: string,
) => {
  const [comments, setComments] = useState<ProjectCommentLineItem[]>([]);
  const [isCommentsLoading, setIsCommentLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listCommentForProject = useCallback(
    async (projectId: string, reportId: string) => {
      try {
        setIsCommentLoading(true);
        const commentResult = await listApiData(
          "listProjectComments",
          "projectComments",
          projectId,
          { reportId },
        );
        setComments(
          commentResult.data.map((p) =>
            decodeProjectComment(p as ProjectComment),
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not list project comments");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [setComments, setIsCommentLoading, setError, decodeProjectComment, catchSentryError],
  );

  const upsertProjectComments = useCallback(
    async (commentsToUpsert: ProjectCommentLineItem[]) => {
      try {
        setIsCommentLoading(true);
        const encodedComments = commentsToUpsert.map((e) =>
          encodeProjectComment(e),
        );
        await postBatchApiData(
          "upsertProjectComments",
          "projectComments",
          encodedComments,
        );

        const updatedComments = comments.reduce((acc, comment) => {
          const updatedComment = commentsToUpsert.find(
            (e) => e.id === comment.id,
          );
          if (updatedComment) {
            return [...acc, updatedComment];
          }
          return [...acc, comment];
        }, [] as ProjectCommentLineItem[]);

        const createdComments = commentsToUpsert.filter(
          (comment) => !updatedComments.find((e) => e.id === comment.id),
        );

        setComments([...updatedComments, ...createdComments]);
      } catch (error) {
        catchSentryError(error);
        setError("Could not update employee");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [comments, setComments, setIsCommentLoading, encodeProjectComment, postBatchApiData, catchSentryError],
  );

  useEffect(() => {
    if (projectId && reportId) {
      listCommentForProject(projectId, reportId);
    } else {
      setIsCommentLoading(false);
    }
  }, [projectId, reportId, contractorPackageId, listCommentForProject]);

  return {
    comments,
    error,
    isCommentsLoading,
    upsertProjectComments,
  };
};