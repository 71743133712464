import { useCallback, useState } from "react";
import { getApiData } from "@hooks/utils/api";
import { decodeSideBarMenuModel } from "social-pro-common/decoders/sidebarMenu";
import { SideBarMenuModel } from "social-pro-common/entities/sidebarMenu";
import { catchSentryError } from "@utils/sentry";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";

export const useSideBarInfo = () => {
  const [isSideBarLoading, setIsSidebarLoading] = useState<boolean>(true);
  const [sideBarMenuModel, setSideBarMenuModel] = useState<
    SideBarMenuModelLineItem | undefined
  >();
  const [error, setError] = useState<string | undefined>();

  const getSidebarMenuModel = useCallback(
    async (contractorPackageId: string) => {
      try {
        setIsSidebarLoading(true);
        const analysisResult = await getApiData(
          "getSidebarMenuModel",
          "sideBarInfo",
          contractorPackageId,
        );
        const res = decodeSideBarMenuModel(
          analysisResult.data as SideBarMenuModel,
        );
        setSideBarMenuModel(res);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsSidebarLoading(false);
      }
    },
    [getApiData, decodeSideBarMenuModel, catchSentryError],
  );

  return {
    error,
    getSidebarMenuModel,
    isSideBarLoading,
    sideBarMenuModel,
  };
};