"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeLabourHourTargetName = exports.decodeLabourHourTargetName = exports.encodeProjectLabourHourCommitment = exports.decodeProjectLabourHourCommitment = void 0;
var packageLabourHourCommitment_1 = require("../../interfaces/packageLabourHourCommitment");
var string_1 = require("../../utils/string");
var decodeProjectLabourHourCommitment = function (socialCommitment) {
    return {
        abns: socialCommitment.abns,
        achievedValue: socialCommitment.achieved_value,
        colour: socialCommitment.colour,
        created: new Date(socialCommitment.created),
        employmentLevel: socialCommitment.employment_level,
        gender: socialCommitment.gender,
        id: socialCommitment.project_labour_hour_commitment_id,
        maxDistance: socialCommitment.max_distance
            ? socialCommitment.max_distance
            : undefined,
        occupationType: socialCommitment.occupation_type,
        postCodes: socialCommitment.post_codes,
        projectId: socialCommitment.project_id,
        socialFactors: socialCommitment.social_factors,
        targetDescription: socialCommitment.target_description
            ? socialCommitment.target_description
            : undefined,
        targetName: (0, exports.decodeLabourHourTargetName)(socialCommitment.target_type),
        targetValue: socialCommitment.target_value,
        targetValueRealised: socialCommitment.target_value_realised,
    };
};
exports.decodeProjectLabourHourCommitment = decodeProjectLabourHourCommitment;
var encodeProjectLabourHourCommitment = function (socialCommitment) {
    var safeSocialCommitment = (0, string_1.makePostgresSafe)(socialCommitment);
    return {
        abns: socialCommitment.abns,
        achieved_value: safeSocialCommitment.achievedValue,
        colour: safeSocialCommitment.colour,
        created: safeSocialCommitment.created,
        employment_level: socialCommitment.employmentLevel,
        gender: socialCommitment.gender,
        max_distance: safeSocialCommitment.maxDistance,
        occupation_type: socialCommitment.occupationType,
        post_codes: socialCommitment.postCodes,
        project_id: safeSocialCommitment.projectId,
        project_labour_hour_commitment_id: safeSocialCommitment.id,
        social_factors: socialCommitment.socialFactors,
        target_description: safeSocialCommitment.targetDescription,
        target_type: safeSocialCommitment.targetName,
        target_value: safeSocialCommitment.targetValue,
        target_value_realised: safeSocialCommitment.targetValueRealised,
    };
};
exports.encodeProjectLabourHourCommitment = encodeProjectLabourHourCommitment;
var decodeLabourHourTargetName = function (targetName) {
    switch (targetName) {
        default:
            return undefined;
        case "social-commitment-type_default-hours":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour;
        case "social-commitment-type_local-jobs-first":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.LocalJobsFirst;
        case "social-commitment-mpsg-hours-requirement":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.MPSGHoursRequirement;
        case "social-commitment-type_victorian-priority-workforce":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce;
        case "social-commitment-type_female-participation":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleParticipation;
        case "social-commitment-type_bep-trade":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours;
        case "social-commitment-type_bep-non-trade":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours;
        case "social-commitment-type_bep-management":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours;
        case "social-commitment-type_female-apprentices-and-trainees":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleApprenticeAndTrainee;
        case "social-commitment-type_aboriginal-torres-strait-island-participation":
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation;
    }
};
exports.decodeLabourHourTargetName = decodeLabourHourTargetName;
var encodeLabourHourTargetName = function (targetType) {
    switch (targetType) {
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour:
            return "social-commitment-type_default-hours";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.LocalJobsFirst:
            return "social-commitment-type_local-jobs-first";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.MPSGHoursRequirement:
            return "social-commitment-mpsg-hours-requirement";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce:
            return "social-commitment-type_victorian-priority-workforce";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleParticipation:
            return "social-commitment-type_female-participation";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours:
            return "social-commitment-type_bep-trade";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours:
            return "social-commitment-type_bep-non-trade";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours:
            return "social-commitment-type_bep-management";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleApprenticeAndTrainee:
            return "social-commitment-type_female-apprentices-and-trainees";
        case packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation:
            return "social-commitment-type_aboriginal-torres-strait-island-participation";
    }
};
exports.encodeLabourHourTargetName = encodeLabourHourTargetName;
