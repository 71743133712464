import { Grid } from "@mui/material";
import { Widget } from "./Widget";
import { WidgetModelLineItem } from "social-pro-common/interfaces/dashboard";
import { WidgetSkeleton } from "./WidgetSkeleton";

interface WidgetContainerProps {
  loading: boolean;
  widgets?: WidgetModelLineItem[];
}

export const WidgetContainer = ({ loading, widgets }: WidgetContainerProps) => {
  return (
    <Grid item md={12} sx={{ paddingTop: "16px" }}>
      <Grid container justifyContent={"space-between"}>
        {!loading
          ? widgets?.map((widget, index) => (
              <Widget
                loading={loading}
                title={widget.title}
                value={widget.value}
                pcChange={widget.pcChange}
                colour={widget.colour}
                units={widget.units}
                tooltip={widget?.tooltip}
                key={index}
              />
            ))
          : null}
        {loading
          ? Array.from({ length: 5 }, (_, index) => (
              <WidgetSkeleton colour="#E3E8EA" key={index} />
            ))
          : null}
      </Grid>
    </Grid>
  );
};
