import { TableHeaderCell } from "social-pro-common/interfaces/tables";
import { Table, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "../ListTable/NoRows";
import { ForecastTableRow } from "./ForecastTableRow";
import { ForecastTableRowSkeleton } from "./ForecastTableRowSkeleton";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ChartTargetType } from "social-pro-common/entities/analysis";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";

interface ForecastTableProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  forecastData: ForecastModelLineItem[];
  chartType: ChartTargetType;
  updateForecastModel: (forecast: ForecastModelLineItem) => void;
  editForecastModel: (forecast: ForecastModelLineItem) => Promise<void>;
}

export const ForecastTable = ({
  chartType,
  disabled,
  editForecastModel,
  forecastData,
  loading,
  project,
  updateForecastModel,
}: ForecastTableProps) => {
  const suffix = chartType === ChartTargetType.Hour ? "Hours" : "Spend";
  const TABLE_HEAD: TableHeaderCell[] = [
    {
      id: "",
      label: "",
    },
    {
      align: "left",
      id: "target",
      label: "Target",
    },
    {
      align: "center",
      id: "total-target",
      label: `Total Target ${suffix}`,
    },
    {
      align: "center",
      id: "reported",
      label: `Projected ${suffix}`,
    },
    {
      align: "center",
      id: "remaining",
      label: `Remaining ${suffix}`,
    },
    { align: "center", id: "value", label: "Display Data" },
  ];

  const filteredForecastData =
    chartType === ChartTargetType.Hour
      ? forecastData
          .filter((f) => f.units === ForecastUnitType.Hour)
          .sort((a, b) => a.modelName.localeCompare(b.modelName))
      : forecastData
          .filter((f) => f.units === ForecastUnitType.Spend)
          .sort((a, b) => a.modelName.localeCompare(b.modelName));

  const commitments =
    chartType === ChartTargetType.Hour
      ? project?.commitmentsHours.map((c) => c.id) || []
      : project?.commitmentsSpend.map((c) => c.id) || [];

  const combinedTargets = filteredForecastData.filter((f) => {
    const matchingCommitment = commitments.find((c: string) => c === f.id);
    return !matchingCommitment;
  });

  const allTargets = filteredForecastData.filter((f) => {
    const matchingCommitment = commitments.find((c: string) => c === f.id);
    return matchingCommitment;
  });

  const finalTargets = [...combinedTargets, ...allTargets];
  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading={true} headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ForecastTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : null}
      {!loading ? (
        <Table data-test-id="forecast-table">
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {finalTargets.length
              ? finalTargets.map((row) => {
                  return (
                    <ForecastTableRow
                      key={row.id}
                      project={project}
                      forecastModel={row}
                      loading={loading}
                      disabled={disabled}
                      editForecastData={editForecastModel}
                      updateForecastModel={updateForecastModel}
                    />
                  );
                })
              : null}
            {!finalTargets.length ? (
              <NoRows title={"Forecast Data"} colSpan={7} />
            ) : null}
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};
