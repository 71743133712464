import * as React from "react";
import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import {
  decodeReportSubmission,
  encodeReportSubmission,
} from "social-pro-common/decoders/reportSubmission";
import { ReportSubmission } from "social-pro-common/entities/reportSubmission";
import { ToastOptions, toast } from "react-toastify";
import { catchSentryError } from "@utils/sentry";

export const useReportSocialSubmission = (contractorPackageId?: string) => {
  const [reportSubmissions, setReportSubmissions] = React.useState<
    ReportSubmissionLineItem[]
  >([]);
  const [isReportLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listReportSubmissions = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        setIsLoading(true);
        const reportResult = await listApiData(
          "listReportSubmissions",
          "reportSubmissionSocial",
          id,
        );
        reportResult.data.reverse();
        setReportSubmissions(
          reportResult.data.map((p) =>
            decodeReportSubmission(p as ReportSubmission),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list reports");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeReportSubmission, setReportSubmissions, setIsLoading, setError, catchSentryError],
  );

  const createReportSubmission = React.useCallback(
    async (
      report: ReportSubmissionLineItem,
    ): Promise<ReportSubmissionLineItem> => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        const upsertedReport = await postApiData(
          "createReportSubmission",
          "reportSubmissionSocial",
          encodedReport,
        );
        const decodedReport = decodeReportSubmission(
          upsertedReport.data as ReportSubmission,
        );
        setReportSubmissions((prevState) => [...prevState, decodedReport]);
        return decodedReport;
      } catch (e) {
        catchSentryError(e);
        setError("Could not create report submission");
      } finally {
        setIsLoading(false);
      }
      return report;
    },
    [encodeReportSubmission, postApiData, decodeReportSubmission, setReportSubmissions, setIsLoading, setError, catchSentryError],
  );

  const updateReportSubmission = React.useCallback(
    async (report: ReportSubmissionLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "updateReportSubmission",
          "reportSubmissionSocial",
          encodedReport,
        );
        setReportSubmissions((prevState) =>
          prevState.map((p) => (p.id === report.id ? report : p)),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update report");
      } finally {
        setIsLoading(false);
      }
    },
    [encodeReportSubmission, postApiData, setReportSubmissions, setIsLoading, setError, catchSentryError],
  );

  const deleteReportSubmission = React.useCallback(
    async (report: ReportSubmissionLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "deleteReportSubmission",
          "reportSubmissionSocial",
          encodedReport,
        );
        setReportSubmissions((prevState) =>
          prevState.filter((p) => p.id !== report.id),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not delete report");
      } finally {
        setIsLoading(false);
      }
    },
    [encodeReportSubmission, postApiData, setReportSubmissions, setIsLoading, setError, catchSentryError],
  );

  const sendReportReminder = React.useCallback(
    async (contractorPackageId: string, reportId: string) => {
      try {
        await getApiData(
          "sendReportReminder",
          "reportSubmissionSocial",
          contractorPackageId,
          { reportId },
        );
        toast("Reminder resent!", { type: "success" } as ToastOptions);
      } catch (e) {
        catchSentryError(e);
        toast("Could not resend reminder!", { type: "error" } as ToastOptions);
        setError("Could not resend reminder");
      }
    },
    [getApiData, toast, catchSentryError, setError],
  );

  React.useEffect(() => {
    if (contractorPackageId) {
      listReportSubmissions(contractorPackageId);
    } else {
      setIsLoading(false);
    }
  }, [contractorPackageId, listReportSubmissions]);

  return {
    createReportSubmission,
    deleteReportSubmission,
    error,
    isReportLoading,
    listReportSubmissions,
    reportSubmissions,
    sendReportReminder,
    updateReportSubmission,
  };
};