import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";

import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ProjectPolicyLineItem } from "social-pro-common/interfaces/projectPolicy";
import { PolicyType } from "social-pro-common/entities/projectPolicy";

interface PolicyFormProps {
  loading: boolean;
  policy: ProjectPolicyLineItem;
  handleSubmit: (policy: ProjectPolicyLineItem) => void;
}

export const PolicyForm = ({
  handleSubmit,
  loading,
  policy,
}: PolicyFormProps) => {
  const validationSchema = yup.object({
    policyDescription: yup
      .string()
      .test(
        "Description required",
        "Policy description required",
        function (item) {
          if (this.parent.targetName === PolicyType.DefaultPolicy) {
            if (item && item.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        },
      ),
    policyLevel: yup.boolean().required("Policy level is required"),
    targetName: yup.string().required("Policy name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      policyDescription: policy.policyDescription || "",
      policyLevel: policy.isProjectLevelPolicy,
      targetName: policy.policyType,
    },
    onSubmit: (values) => {
      handleSubmit({
        ...policy,
        isProjectLevelPolicy: values.policyLevel,
        policyDescription: values.policyDescription,
        policyType: values.targetName,
      });
    },
    validationSchema: validationSchema,
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                id="policyDescription"
                name="policyDescription"
                disabled={formik.values.targetName !== PolicyType.DefaultPolicy}
                value={formik.values.policyDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.policyDescription &&
                  Boolean(formik.errors.policyDescription)
                }
                helperText={
                  formik.touched.policyDescription
                    ? formik.errors.policyDescription
                    : ""
                }
                label="Policy Description"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    margin="dense"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="policyLevel"
                  options={[true, false]}
                  defaultValue={formik.values.policyLevel}
                  disabled={loading || formik.isSubmitting}
                  getOptionLabel={(option) =>
                    option ? "Project Level" : "Package Level"
                  }
                  onChange={(event: any, newValue: boolean | null) => {
                    formik.setFieldValue("packageType", newValue);
                    // formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="policyLevel"
                      {...params}
                      name="policyLevel"
                      label="Policy Level"
                      fullWidth
                      error={
                        formik.touched.policyLevel &&
                        Boolean(formik.errors.policyLevel)
                      }
                      helperText={
                        formik.touched.policyLevel
                          ? formik.errors.policyLevel
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
              paddingTop: "20px",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={loading || formik.isSubmitting}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
