import {
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";

interface UploadButtonProps {
  title: string;
  loading?: boolean;
  disabled: boolean;
  onClickUpload: () => void;
  onClickDownload?: () => void;
}
export const UploadButton = ({
  disabled,
  loading,
  onClickDownload,
  onClickUpload,
  title,
}: UploadButtonProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <>
        {loading ? (
          <Skeleton animation="wave" variant="circular">
            <IconButton
              color="primary"
              size="large"
              disabled={disabled}
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.primary.dark,
                marginRight: "10px",
              }}
            >
              <UploadTwoToneIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Skeleton>
        ) : (
          <Tooltip TransitionComponent={Zoom} placement="top" title="Upload">
            <IconButton
              color="primary"
              size="large"
              disabled={disabled}
              onClick={onClickUpload}
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.primary.dark,
                marginRight: "10px",
              }}
            >
              <UploadTwoToneIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Tooltip>
        )}

        {onClickDownload ? (
          <Tooltip TransitionComponent={Zoom} placement="top" title="Download">
            <IconButton
              color="primary"
              size="large"
              disabled={disabled}
              onClick={onClickDownload}
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.primary.dark,
                marginRight: "10px",
              }}
            >
              <DownloadTwoToneIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Tooltip>
        ) : null}
        {loading ? (
          <Skeleton animation="wave" variant="text" sx={{ ml: 1 }}>
            <Typography>{"Upload Invoice"}</Typography>
          </Skeleton>
        ) : (
          <Typography>{title}</Typography>
        )}
      </>
    </Stack>
  );
};
