import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { useState } from "react";
import { downloadFile } from "@hooks/utils/useUpload";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { EmployeeSupportingDocumentSubTableSkeleton } from "./EmployeeSupportingDocumentSubTableSkeleton";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { getDocumentName } from "social-pro-common/utils/string";
import { NoRows } from "../ListTable/NoRows";
import { supportingDocumentTypeToString } from "social-pro-common/interfaces/supportingDocument";
import { useSupportingDocumentForEmployee } from "@hooks/crud/supportingDocuments/useSupportingDocumentForEmployee";

interface EmployeeSupportingDocumentSubTableProps {
  employee: EmployeeLineItem;
}

export const EmployeeSupportingDocumentSubTable = ({
  employee,
}: EmployeeSupportingDocumentSubTableProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { isSupportingDocumentLoading, supportingDocuments } =
    useSupportingDocumentForEmployee(employee.id);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const loading = isSupportingDocumentLoading;
  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableHeader
                title="Date"
                loading={loading}
                sx={{ width: "25%" }}
                align="left"
              />
              <TableHeader
                title="Document Name"
                loading={loading}
                sx={{ width: "25%" }}
                align="left"
              />
              <TableHeader
                title="Download"
                loading={loading}
                sx={{ width: "25%" }}
                align="center"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading
              ? supportingDocuments.map((d) => {
                  return (
                    <TableRow key={d.id}>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        {d.created.toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "70%" }}>
                        {supportingDocumentTypeToString(
                          d.supportingDocumentType,
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ textAlign: "right !important", width: "15%" }}
                      >
                        <label htmlFor="containedButtonFile">
                          <StyledButton
                            loading={loading}
                            variant="contained"
                            size="small"
                            disabled={isDownloading}
                            onClick={() =>
                              d.uri
                                ? onDownload(
                                    d.uri,
                                    getDocumentName(
                                      supportingDocumentTypeToString(
                                        d.supportingDocumentType,
                                      ),
                                      d.uri,
                                    ),
                                  )
                                : undefined
                            }
                            className="blackBtn"
                            sx={{
                              color: "white",
                              fontSize: "14px",
                              lineHeight: "16px",
                              padding: "10px 15px",
                            }}
                          >
                            Download
                          </StyledButton>
                        </label>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}

            {supportingDocuments?.length === 0 && !loading ? (
              <NoRows colSpan={3} title={"supporting documents for employee"} />
            ) : null}
            {loading ? (
              <>
                <EmployeeSupportingDocumentSubTableSkeleton />
                <EmployeeSupportingDocumentSubTableSkeleton />
                <EmployeeSupportingDocumentSubTableSkeleton />
                <EmployeeSupportingDocumentSubTableSkeleton />
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
