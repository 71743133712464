import {
  Button,
  Chip,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const ReportTableSubRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left">
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center">
        <Skeleton variant={"rounded"} sx={{ margin: "auto" }}>
          <Chip label="Overdue" size="medium" />
        </Skeleton>
      </TableCell>
      <TableCell align="center" className="right">
        <Stack direction="row" justifyContent="right" alignItems="right">
          <Skeleton variant={"rounded"} animation={"wave"}>
            <Button size="large">Submit</Button>
          </Skeleton>
        </Stack>
      </TableCell>
      <TableCell align="center" className="right">
        <Stack direction="row" justifyContent="right" alignItems="right">
          <Skeleton variant={"rounded"} animation={"wave"}>
            <Button size="large">Submit</Button>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
