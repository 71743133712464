"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSortedLocalContent = exports.aggregateLocalContentItems = exports.aggregateContentOrigin = exports.contentOriginToString = exports.createDefaultPackageLocalContent = void 0;
var uuid_1 = require("uuid");
var packageLocalContent_1 = require("../../entities/packageLocalContent");
var date_1 = require("../../utils/date");
var createDefaultPackageLocalContent = function (projectId, reportId, packageId) {
    return {
        anzValueAdd: 0,
        contractContent: 0,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        importValueAdd: 0,
        itemDescription: "",
        localValueAdd: 0,
        manufacturer: "",
        packageId: packageId,
        projectId: projectId,
        reportId: reportId,
        smeCount: 0,
        steelMass: 0,
        supplier: "",
        supplierCount: 0,
        value: 0,
    };
};
exports.createDefaultPackageLocalContent = createDefaultPackageLocalContent;
var contentOriginToString = function (origin) {
    switch (origin) {
        default:
            throw new Error("Unknown content origin: ".concat(origin));
        case packageLocalContent_1.ContentOrigin.Victoria:
            return "Victoria";
        case packageLocalContent_1.ContentOrigin.NewSouthWales:
            return "New South Wales";
        case packageLocalContent_1.ContentOrigin.Queensland:
            return "Queensland";
        case packageLocalContent_1.ContentOrigin.SouthAustralia:
            return "South Australia";
        case packageLocalContent_1.ContentOrigin.WesternAustralia:
            return "Western Australia";
        case packageLocalContent_1.ContentOrigin.Tasmania:
            return "Tasmania";
        case packageLocalContent_1.ContentOrigin.NorthernTerritory:
            return "Northern Territory";
        case packageLocalContent_1.ContentOrigin.AustralianCapitalTerritory:
            return "Australian Capital Territory";
        case packageLocalContent_1.ContentOrigin.AllAustralia:
            return "All Australia";
        case packageLocalContent_1.ContentOrigin.NewZealand:
            return "New Zealand";
        case packageLocalContent_1.ContentOrigin.International:
            return "International";
    }
};
exports.contentOriginToString = contentOriginToString;
var aggregateContentOrigin = function (items) {
    if (items.length === 0) {
        return packageLocalContent_1.ContentOrigin.AllAustralia;
    }
    var australianOrigins = new Set([
        packageLocalContent_1.ContentOrigin.Victoria,
        packageLocalContent_1.ContentOrigin.NewSouthWales,
        packageLocalContent_1.ContentOrigin.Queensland,
        packageLocalContent_1.ContentOrigin.SouthAustralia,
        packageLocalContent_1.ContentOrigin.WesternAustralia,
        packageLocalContent_1.ContentOrigin.Tasmania,
        packageLocalContent_1.ContentOrigin.NorthernTerritory,
        packageLocalContent_1.ContentOrigin.AustralianCapitalTerritory,
        packageLocalContent_1.ContentOrigin.AllAustralia,
    ]);
    var hasInternationalOrNZ = items.includes(packageLocalContent_1.ContentOrigin.International) ||
        items.includes(packageLocalContent_1.ContentOrigin.NewZealand);
    var hasAustralian = items.some(function (origin) { return australianOrigins.has(origin); });
    if (hasInternationalOrNZ && hasAustralian) {
        return packageLocalContent_1.ContentOrigin.International;
    }
    var firstOrigin = items[0];
    var allSame = items.every(function (origin) { return origin === firstOrigin; });
    if (allSame) {
        return firstOrigin;
    }
    return packageLocalContent_1.ContentOrigin.AllAustralia;
};
exports.aggregateContentOrigin = aggregateContentOrigin;
var aggregateLocalContentItems = function (items) {
    var result = {};
    items.forEach(function (item) {
        var description = item.itemDescription;
        if (!result[description]) {
            result[description] = {
                anzValueProduct: 0,
                item: __assign(__assign({}, item), { anzValueAdd: 0, contractContent: 0, importValueAdd: 0, localValueAdd: 0, manufacturer: "", smeCount: 0, steelMass: 0, supplier: "", supplierCount: 0, value: 0 }),
                manufacturers: [],
                origins: [],
                suppliers: [],
            };
        }
        var aggregatedItem = result[description].item;
        aggregatedItem.value += item.value;
        aggregatedItem.steelMass += item.steelMass;
        aggregatedItem.importValueAdd += item.importValueAdd;
        aggregatedItem.localValueAdd += (item.localValueAdd * item.value) / 100;
        aggregatedItem.contractContent += item.contractContent;
        aggregatedItem.anzValueAdd += item.anzValueAdd;
        aggregatedItem.smeCount += item.smeCount;
        aggregatedItem.supplierCount += item.supplierCount;
        result[description].anzValueProduct += item.value * item.anzValueAdd;
        if (item.contentOrigin) {
            result[description].origins.push(item.contentOrigin);
        }
        result[description].manufacturers.push({
            name: item.manufacturer,
            value: item.value,
        });
        result[description].suppliers.push({
            name: item.supplier,
            value: item.value,
        });
    });
    var aggregatedItems = Object.values(result).map(function (entry) {
        var item = entry.item, manufacturers = entry.manufacturers, origins = entry.origins, suppliers = entry.suppliers;
        item.contentOrigin = (0, exports.aggregateContentOrigin)(origins);
        item.manufacturer = manufacturers
            .sort(function (a, b) { return b.value - a.value; })
            .slice(0, 3)
            .map(function (m) { return m.name; })
            .filter(function (value, index, self) { return self.indexOf(value) === index; })
            .join(", ");
        item.supplier = suppliers
            .sort(function (a, b) { return b.value - a.value; })
            .slice(0, 3)
            .map(function (s) { return s.name; })
            .filter(function (value, index, self) { return self.indexOf(value) === index; })
            .join(", ");
        return item;
    });
    var totalValue = aggregatedItems.reduce(function (acc, item) { return acc + item.value; }, 0);
    aggregatedItems.forEach(function (item) {
        item.localValueAdd = (item.localValueAdd / item.value) * 100;
        item.importValueAdd = 100 - item.localValueAdd;
        item.contractContent = (item.value / totalValue) * 100;
    });
    aggregatedItems.forEach(function (item) {
        item.anzValueAdd = item.contractContent * (item.localValueAdd / 100);
    });
    return (0, exports.getSortedLocalContent)(aggregatedItems);
};
exports.aggregateLocalContentItems = aggregateLocalContentItems;
var getSortedLocalContent = function (items, reportId) {
    var itemsToSort = items || [];
    var sortedItems = __spreadArray([], itemsToSort, true).sort(function (a, b) {
        return a.itemDescription
            .toLowerCase()
            .localeCompare(b.itemDescription.toLowerCase());
    });
    if (reportId) {
        var reportDate_1 = (0, date_1.stringToDate)(reportId);
        return sortedItems.filter(function (item) {
            var itemDate = (0, date_1.stringToDate)(item.reportId);
            return reportDate_1.getTime() >= itemDate.getTime();
        });
    }
    return sortedItems;
};
exports.getSortedLocalContent = getSortedLocalContent;
