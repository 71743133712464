import { IconButton, TableCell, TableRow, Tooltip, Zoom } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { GreenMetricCategoryLineItem } from "social-pro-common/interfaces/greenMetricCategory";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface GreenMetricFormTableRowProps {
  loading: boolean;
  greenMetricCategory: GreenMetricCategoryLineItem;
  handleChange: (greenMetricCategory: GreenMetricCategoryLineItem) => void;
  handleDelete: (greenMetricCategory: GreenMetricCategoryLineItem) => void;
}

export const GreenMetricFormTableRow = ({
  greenMetricCategory,
  handleChange,
  handleDelete,
  loading,
}: GreenMetricFormTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <StyledTextField
            fullWidth
            id="targetCategory"
            name="targetCategory"
            placeholder="e.g. Diesel"
            value={greenMetricCategory.targetCategory}
            loading={loading}
            disabled={loading}
            onChange={(e) =>
              handleChange({
                ...greenMetricCategory,
                targetCategory: e.target.value,
              })
            }
          />
        </TableCell>
        <TableCell>
          <StyledTextField
            fullWidth
            id="metricType"
            name="metricType"
            placeholder="e.g. Plant and Equipment"
            value={greenMetricCategory.metricType}
            loading={loading}
            disabled={loading}
            onChange={(e) =>
              handleChange({
                ...greenMetricCategory,
                metricType: e.target.value,
              })
            }
          />
        </TableCell>
        <TableCell>
          <StyledTextField
            fullWidth
            id="targetType"
            name="targetType"
            placeholder="e.g. Litres (L)"
            value={greenMetricCategory.targetType}
            loading={loading}
            disabled={loading}
            onChange={(e) =>
              handleChange({
                ...greenMetricCategory,
                targetType: e.target.value,
              })
            }
          />
        </TableCell>
        <TableCell align="right">
          <Tooltip placement="top" title="Archive" TransitionComponent={Zoom}>
            <IconButton
              color="error"
              size="large"
              disabled={loading}
              onClick={() => setOpenConfirmDialog(true)}
            >
              <DeleteIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to remove this category?"}
        open={openConfirmDialog}
        title={"Delete Category"}
        buttonText={"Delete"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          handleDelete(greenMetricCategory);
          setOpenConfirmDialog(false);
        }}
      />
    </>
  );
};
