import { useCallback, useState, useEffect } from "react";
import { getApiData } from "@hooks/utils/api";
import { OrganisationLocalContentSubDataModelLineItem } from "social-pro-common/interfaces/organisationDashboard";
import { decodeOrganisationLocalContentSubDataModel } from "social-pro-common/decoders/organisationDashboard";
import { catchSentryError } from "@utils/sentry";
import { OrganisationLocalContentSubDataModel } from "social-pro-common/entities/organisationDashboard";

export const useOrganisationLocalContentSubData = () => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [localContentSubData, setLocalContentSubData] =
    useState<OrganisationLocalContentSubDataModelLineItem>();

  const getLocalContentSubDataModel = useCallback(async () => {
    try {
      setIsAnalysisLoading(true);
      const analysisResult = await getApiData(
        "getLocalContentSubData",
        "organisationDashboard",
        "",
      );
      const localContentSubData = decodeOrganisationLocalContentSubDataModel(
        analysisResult.data as OrganisationLocalContentSubDataModel,
      );
      setLocalContentSubData(localContentSubData);
    } catch (error) {
      catchSentryError(error);
      setError("Could not fetch chart data");
    } finally {
      setIsAnalysisLoading(false);
    }
  }, [getApiData, decodeOrganisationLocalContentSubDataModel, catchSentryError]);

  useEffect(() => {
    getLocalContentSubDataModel();
  }, [getLocalContentSubDataModel]);

  return {
    error,
    isAnalysisLoading,
    localContentSubData,
  };
};