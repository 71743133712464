import { Grid, Stack } from "@mui/material";
import { TitleStack } from "./TitleStack";
import { ChipProgress } from "./ChipProgress";
import { LoadingTitle } from "./LoadingTitle";
import { DashboardProgressbar } from "./DashboardProgressBar";
import { ProjectProgressModelLineItem } from "social-pro-common/interfaces/dashboard";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatChartDate, stringToDate } from "social-pro-common/utils/date";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";
import { toTitleCase } from "social-pro-common/utils/string";

interface ProjectProgressV2Props {
  loading: boolean;
  project?: ProjectLineItem;
  projectProgress?: ProjectProgressModelLineItem;
}

export const ProjectProgressV2 = ({
  loading,
  project,
  projectProgress,
}: ProjectProgressV2Props) => {
  return (
    <Grid item md={12} sx={{ marginTop: "16px" }}>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} justifyContent={"space-between"}>
          <LoadingTitle loading={loading} title={project?.projectName || ""} />
        </Stack>
        <Grid container justifyContent={"space-between"}>
          <Grid item md={3}>
            <Stack direction="column" spacing={2}>
              <TitleStack
                loading={loading}
                header="Project Dashboard"
                subTitle="Overall Performance"
              />
              <ChipProgress
                loading={loading}
                value={projectProgress?.activeSubcontractors.toString() || "0"}
                title="Active Subcontractors"
                primaryFontColour="#00000"
                secondaryFontColour="#3B75A5"
                chipColour="#DBEDFF"
                dataTestId="active-subcontractors"
              />
            </Stack>
          </Grid>
          <Grid item md={1} />
          <Grid item md={8}>
            <Stack spacing={3} sx={{ width: "100%" }}>
              <Grid container justifyContent={"space-between"}>
                <Grid item md={4}>
                  <TitleStack
                    loading={loading}
                    header={`${formatDecimalPlaces(projectProgress?.completedWorks || 0)}%`}
                    subTitle="Completed Project Works"
                    dataTestId="project-progress-pc"
                  />
                </Grid>
                <Grid item md={4}>
                  <TitleStack
                    loading={loading}
                    header={`$${nFormatter(project?.financial.totalValue || 0, 2)}`}
                    subTitle="Total Project Value"
                    dataTestId="project-total-value"
                  />
                </Grid>
                <Grid item md={4}>
                  <TitleStack
                    loading={loading}
                    header={
                      toTitleCase(
                        formatChartDate(stringToDate(project?.projectEndDate)),
                      ) || ""
                    }
                    subTitle="Target Practical Completion"
                    dataTestId="project-current-date"
                  />
                </Grid>
              </Grid>
              <DashboardProgressbar
                loading={loading}
                progress={projectProgress?.timeProgress || 0}
                startDate={
                  toTitleCase(
                    formatChartDate(stringToDate(project?.projectStartDate)),
                  ) || ""
                }
                endDate={
                  toTitleCase(
                    formatChartDate(stringToDate(project?.projectEndDate)),
                  ) || ""
                }
                progressTestId="project-progress-bar"
                prefixTestId="project-start-date"
                suffixTestId="project-end-date"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};
