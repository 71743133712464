"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileProjectAccessLevel = void 0;
var ProfileProjectAccessLevel;
(function (ProfileProjectAccessLevel) {
    ProfileProjectAccessLevel["Admin"] = "profile_group_membership_type_admin";
    ProfileProjectAccessLevel["Write"] = "profile_group_membership_type_write";
    ProfileProjectAccessLevel["View"] = "profile_group_membership_type_view";
    ProfileProjectAccessLevel["NoAccess"] = "profile_group_membership_type_no_access";
})(ProfileProjectAccessLevel || (exports.ProfileProjectAccessLevel = ProfileProjectAccessLevel = {}));
