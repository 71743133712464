import { Skeleton, TableCell, TableRow, useTheme } from "@mui/material";
export const LidpTableSumRowSkeleton = () => {
  const theme = useTheme();
  return (
    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
      <TableCell colSpan={7} align="right">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" />
    </TableRow>
  );
};
