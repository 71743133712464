import { v4 as uuid } from "uuid";
import { useEmployee } from "@hooks/crud/employee/useEmployee";
import {
  EmployeeLineItem,
  EployeeTabStatus,
} from "social-pro-common/interfaces/contractorEmployee";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useState } from "react";
import { EmployeeTable } from "@stories/molecules/EmployeeTable/EmployeeTable";
import EmployeeEditModal from "@stories/organisms/EmployeeEditModal/EmployeeEditModal";
import EmployeeListModal from "@stories/organisms/EmployeeListModal/EmployeeListModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmployeeImport, {
  ImportStep,
} from "@stories/molecules/EmployeeImport/EmployeeImport";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { stringToSearchQuery } from "@utils/utils";
import { useEmployeeExport } from "@hooks/crud/downloadFiles/useEmployeExport";
import { ListEmployeesTabs } from "@stories/molecules/ListEmployeesTabs/ListEmployeesTabs";
import { useSearchEmployee } from "@hooks/crud/employee/useSearchEmployee";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { EmployeeNameExistsDialog } from "@stories/molecules/EmployeeNameExistsDialog/EmployeeNameExistsDialog";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";

export const Employees = () => {
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const [value, setValue] = useState<EployeeTabStatus>(EployeeTabStatus.Active);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: EployeeTabStatus,
  ) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const [page, setPage] = useState(0);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const [openDupeNameModal, setOpenDupeNameModal] = useState(false);

  const [openImportModal, setOpenImportModal] = useState(false);
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);

  const { downloadProgress, downloadStep, getEmployeExcel } =
    useEmployeeExport();
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeLineItem>();
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const searchQuery = stringToSearchQuery(searchValue);

  const { isSearchEmployeeLoading, searchEmployeesByNameOrCode } =
    useSearchEmployee();
  const {
    createEmployee,
    employeeCount,
    employees,
    isEmployeeLoading,
    listEmployees,
    updateEmployee,
    upsertEmployees,
  } = useEmployee(
    userProfile?.organisationId,
    searchQuery,
    page,
    value === EployeeTabStatus.Archived,
  );

  const handleEditEmployee = async (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenEditModal(true);
  };

  const handleArchiveEmployee = async (employee: EmployeeLineItem) => {
    let allowUser = true;
    if (employee.archived) {
      const employeeRes = await searchEmployeesByNameOrCode([
        employee.employeeName,
      ]);
      if (employeeRes.length) {
        allowUser = false;
      }
    }

    if (allowUser) {
      updateEmployee({ ...employee, archived: !employee.archived });
    } else {
      setOpenDupeNameModal(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseListModal = () => {
    setOpenListModal(false);
    setSelectedEmployee(undefined);
  };

  const handleOpeEditModal = () => {
    setSelectedEmployee(undefined);
    setOpenEditModal(true);
  };

  const handleOpenListModal = (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenListModal(true);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const handleCloseImportModal = () => {
    setOpenImportModal(false);
    setStep(ImportStep.Init);
  };

  const handleReloadEmployees = () => {
    if (userProfile) {
      listEmployees(userProfile?.organisationId, searchQuery, page);
    }
  };

  const loading =
    isSearchEmployeeLoading || isAuthProfileLoading || isEmployeeLoading;

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Employees"
          subTitle={`Manage (${employeeCount >= 0 ? employeeCount : 0})`}
        />

        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <ListEmployeesTabs
              isLoading={loading}
              value={value}
              handleChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
              {value === EployeeTabStatus.Active ? (
                <>
                  <StyledButton
                    className="blackBtn grey-outline-btn"
                    loading={loading}
                    disabled={loading || !employees || employees.length === 0}
                    variant="contained"
                    onClick={() => {
                      //TODO: Fix this to remove redundant data
                      getEmployeExcel(uuid(), uuid(), uuid());
                    }}
                    startIcon={<DownloadIcon />}
                  >
                    Export
                  </StyledButton>
                  <StyledButton
                    className="blackBtn"
                    loading={loading}
                    variant="contained"
                    onClick={handleOpenImportModal}
                    startIcon={<CloudUploadIcon />}
                  >
                    Import
                  </StyledButton>
                  <StyledButton
                    className="blackBtn"
                    loading={loading}
                    variant="contained"
                    onClick={handleOpeEditModal}
                    startIcon={<AddCircleIcon />}
                  >
                    Add Employee
                  </StyledButton>
                </>
              ) : null}
            </Stack>
          </Grid>
        </Grid>

        <EmployeeTable
          employees={employees}
          employeeCount={employeeCount}
          loading={loading}
          setPage={setPage}
          handleOpenListModal={handleOpenListModal}
          handleEditEmployee={handleEditEmployee}
          handleArchiveEmployee={handleArchiveEmployee}
        />

        {openEditModal && userOrganisation ? (
          <EmployeeEditModal
            loading={loading}
            open={openEditModal}
            contractorOrganisation={userOrganisation}
            existingEmployee={selectedEmployee}
            createEmployee={createEmployee}
            updateEmployee={updateEmployee}
            handleClose={handleCloseEditModal}
            handleReloadEmployees={handleReloadEmployees}
          />
        ) : null}
        {openListModal && selectedEmployee ? (
          <EmployeeListModal
            open={openListModal}
            existingEmployee={selectedEmployee}
            handleClose={handleCloseListModal}
          />
        ) : null}

        {openImportModal ? (
          <EmployeeImport
            open={openImportModal}
            step={step}
            userProfile={userProfile}
            userOrganisation={userOrganisation}
            handleCloseImportModal={handleCloseImportModal}
            setStep={setStep}
            upsertEmployees={upsertEmployees}
            handleReloadEmployees={handleReloadEmployees}
          />
        ) : null}
        <PdfDownlodDialog
          downloadStep={downloadStep}
          total={downloadProgress}
          title={"Employee Export"}
        />
        <EmployeeNameExistsDialog
          isOpen={openDupeNameModal}
          setIsOpen={setOpenDupeNameModal}
        />
      </Grid>
    </>
  );
};
