import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SpendTargetForm } from "@stories/molecules/SpendTargetForm/SpendTargetForm";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ProjectSocialSpendCommitmentLineItem,
  getBaseMultiplierForSpend,
} from "social-pro-common/interfaces/projectSocialSpendCommitment";

interface SocialSpendTargetModalProps {
  open: boolean;
  loading: boolean;
  project: ProjectLineItem;
  socialSpendCommitment: ProjectSocialSpendCommitmentLineItem;
  createSocialCommitment: (
    target: ProjectSocialSpendCommitmentLineItem,
  ) => void;
  handleClose: () => void;
}

export default function SocialSpendTargetModal({
  createSocialCommitment,
  handleClose,
  loading,
  open,
  project,
  socialSpendCommitment,
}: SocialSpendTargetModalProps) {
  const baseMultiplier = getBaseMultiplierForSpend(project.financial);
  const onSubmit = async (
    socialRequirement: ProjectSocialSpendCommitmentLineItem,
  ) => {
    if (socialRequirement) {
      await createSocialCommitment(socialRequirement);
      handleClose();
    }
  };

  const title = "Create New Spend Target";

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle sx={{ padding: "13px 24px !important" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <SpendTargetForm
            socialCommitment={socialSpendCommitment}
            project={project}
            baseMultiplier={baseMultiplier}
            handleSubmit={onSubmit}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
