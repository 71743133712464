"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeSocialSpend = exports.decodeSocialSpend = exports.decodeSocialSpendTargetModel = void 0;
var string_1 = require("../../utils/string");
var decodeSocialSpendTargetModel = function (socialSpendModel) {
    return {
        socialSpend: socialSpendModel.social_spend.map(function (e) { return (0, exports.decodeSocialSpend)(e); }),
        totalSocialSpend: socialSpendModel.total_social_spend,
    };
};
exports.decodeSocialSpendTargetModel = decodeSocialSpendTargetModel;
var decodeSocialSpend = function (socialSpend) {
    return {
        abn: socialSpend.abn,
        certifications: (socialSpend === null || socialSpend === void 0 ? void 0 : socialSpend.certifications) || [],
        contractorPackageId: socialSpend.contractor_package_id,
        created: new Date(socialSpend.created),
        entityType: socialSpend === null || socialSpend === void 0 ? void 0 : socialSpend.entity_type,
        id: socialSpend.social_spend_id,
        invoiceValue: socialSpend.invoice_value,
        natureOfExpenditure: socialSpend.nature_of_expenditure,
        organisationName: socialSpend.organisation_name,
        projectId: socialSpend.project_id,
        purchaserOrganisationAbn: socialSpend.purchaser_organisation_abn,
        purchaserOrganisationName: socialSpend.purchaser_organisation_name,
        purchaseType: socialSpend.purchase_type,
        registered: socialSpend.registered,
        reportId: socialSpend.report_id,
        socialFactors: socialSpend.social_factors,
        supplier: socialSpend.supplier,
        supplierCountry: socialSpend.supplier_country,
        supplierPostCode: socialSpend.supplier_post_code,
        supplierState: socialSpend.supplier_state,
        transactionDate: new Date(socialSpend.transaction_date),
        uri: socialSpend.uri,
        victorianPostCode: socialSpend.victorian_post_code,
    };
};
exports.decodeSocialSpend = decodeSocialSpend;
var encodeSocialSpend = function (socialSpend) {
    var safeSocialSpend = (0, string_1.makePostgresSafe)(socialSpend);
    return {
        abn: safeSocialSpend.abn,
        certifications: safeSocialSpend.certifications,
        contractor_package_id: safeSocialSpend.contractorPackageId,
        created: safeSocialSpend.created,
        entity_type: safeSocialSpend.entityType,
        invoice_value: safeSocialSpend.invoiceValue,
        nature_of_expenditure: safeSocialSpend.natureOfExpenditure,
        organisation_name: safeSocialSpend.organisationName,
        project_id: safeSocialSpend.projectId,
        purchase_type: safeSocialSpend.purchaseType,
        purchaser_organisation_abn: safeSocialSpend.purchaserOrganisationAbn,
        purchaser_organisation_name: safeSocialSpend.purchaserOrganisationName,
        registered: safeSocialSpend.registered,
        report_id: safeSocialSpend.reportId,
        social_factors: safeSocialSpend.socialFactors,
        social_spend_id: safeSocialSpend.id,
        supplier: safeSocialSpend.supplier,
        supplier_country: safeSocialSpend.supplierCountry,
        supplier_post_code: safeSocialSpend.supplierPostCode,
        supplier_state: safeSocialSpend.supplierState,
        transaction_date: safeSocialSpend.transactionDate,
        uri: safeSocialSpend.uri,
        victorian_post_code: safeSocialSpend.victorianPostCode,
    };
};
exports.encodeSocialSpend = encodeSocialSpend;
