"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgeGroup = exports.ContractType = exports.EmployeeSocialFactor = exports.EmploymentType = exports.EmploymentLevel = exports.OccupationType = exports.NewExistingJobType = exports.Gender = exports.DEFAULT_PAGINATION_LIMIT = void 0;
exports.DEFAULT_PAGINATION_LIMIT = 10;
var Gender;
(function (Gender) {
    Gender["Male"] = "gender_male";
    Gender["Female"] = "gender_female";
    Gender["Other"] = "gender_other";
})(Gender || (exports.Gender = Gender = {}));
var NewExistingJobType;
(function (NewExistingJobType) {
    NewExistingJobType["New"] = "new-existing-job-type_new";
    NewExistingJobType["Existing"] = "new-existing-job-type_existing";
})(NewExistingJobType || (exports.NewExistingJobType = NewExistingJobType = {}));
var OccupationType;
(function (OccupationType) {
    OccupationType["TradeRole"] = "occupation-type-options_trade-role";
    OccupationType["NonTradePosition"] = "occupation-type-options_non-trade-position";
    OccupationType["ManagementSupervisorSpecialistLabourRoles"] = "occupation-type-options_management-supervisor-specialist";
    OccupationType["Other"] = "occupation-type-options_other";
})(OccupationType || (exports.OccupationType = OccupationType = {}));
var EmploymentLevel;
(function (EmploymentLevel) {
    EmploymentLevel["FullEmployment"] = "employment-level_full-employment";
    EmploymentLevel["Apprentice"] = "employment-level_apprentice";
    EmploymentLevel["Trainee"] = "employment-level_trainee";
    EmploymentLevel["Cadet"] = "employment-level_cadet";
})(EmploymentLevel || (exports.EmploymentLevel = EmploymentLevel = {}));
var EmploymentType;
(function (EmploymentType) {
    EmploymentType["Direct"] = "employment-type_direct";
    EmploymentType["Indirect"] = "employment-type_indirect";
})(EmploymentType || (exports.EmploymentType = EmploymentType = {}));
var EmployeeSocialFactor;
(function (EmployeeSocialFactor) {
    EmployeeSocialFactor["AboriginalOrTorresStraitIslander"] = "employee-social-procurement-factor_aboriginal-or-torres-strait-islander";
    EmployeeSocialFactor["AsylumSeekerRefugee"] = "employee-social-procurement-factor_asylum-seeker-refugee";
    EmployeeSocialFactor["DisengagedYouth"] = "employee-social-procurement-factor_disengaged-youth";
    EmployeeSocialFactor["LongTermUnemployed"] = "employee-social-procurement-factor_long-term-unemployed";
    EmployeeSocialFactor["PersonWithDisability"] = "employee-social-procurement-factor_person-with-disability-inclusive-of-mental-illness";
    EmployeeSocialFactor["Veteran"] = "employee-social-procurement-factor_verteran";
    EmployeeSocialFactor["PersonsAffectedByFamilyViolence"] = "employee-social-procurement-factor_person-affected-by-family-violence";
    EmployeeSocialFactor["ExOffender"] = "employee-social-procurement-factor_ex-offender";
    EmployeeSocialFactor["JobseekerWithAMentalIllness"] = "employee-social-procurement-factor_jobseeker-with-a-mental-illness";
    EmployeeSocialFactor["LivingInRegionsExperienceingEntrenchedDisadvantage"] = "employee-social-procurement-factor_living-in-regions-experiencing-entrenched-disadvantage";
    EmployeeSocialFactor["MatureAgedJobSeeker"] = "employee-social-procurement-factor_mature-aged-job-seeker";
    EmployeeSocialFactor["SingleParent"] = "employee-social-procurement-factor_single-parents";
    EmployeeSocialFactor["SocialHousingTenant"] = "employee-social-procurement-factor_social-housing-tenant";
    EmployeeSocialFactor["WorkersInTransitionRetrenchedWorked"] = "employee-social-procurement-factor_workers-in-transition-retrenched-workers";
    EmployeeSocialFactor["YoungPeopleInOutOfHomeCare"] = "employee-social-procurement-factor_young-people-in-out-of-home-care";
    EmployeeSocialFactor["WomenOver45"] = "employee-social-procurement-factor_women-over-45";
    EmployeeSocialFactor["CulturallyDiverseMigrant"] = "employee-social-procurement-factor_culturally-diverse-migrant";
    EmployeeSocialFactor["NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds"] = "employee-social-procurement-factor_newly-arrived-migrants-from-non-english-speaking-backgrounds";
    EmployeeSocialFactor["JobSeekersRegisteredWithJobsVictoriaMentorService"] = "employee-social-procurement-factor_job-seekers-registered-with-jobs-victoria-mentor-service";
})(EmployeeSocialFactor || (exports.EmployeeSocialFactor = EmployeeSocialFactor = {}));
var ContractType;
(function (ContractType) {
    ContractType["PermanentFullTime"] = "contract-type_permanent-full-time";
    ContractType["FixedTerm"] = "contract-type_fixed-term";
    ContractType["PartTime"] = "contract-type_part-time";
    ContractType["Casual"] = "contract-type_casual";
})(ContractType || (exports.ContractType = ContractType = {}));
var AgeGroup;
(function (AgeGroup) {
    AgeGroup["FifteenToTwentyFour"] = "age-group_15-24";
    AgeGroup["TwentyFiveToFourtyFour"] = "age-group_25-44";
    AgeGroup["FortyFiveToFourtyNine"] = "age-group_45-69";
    AgeGroup["FiftyPlus"] = "age-group_50+";
})(AgeGroup || (exports.AgeGroup = AgeGroup = {}));
