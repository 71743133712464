import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useRef } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { readXlsxFileToLidp } from "@utils/xlsx/localContent";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import CloseIcon from "@mui/icons-material/Close";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { catchSentryError } from "@utils/sentry";

export enum ImportStep {
  Init = "Init",
  Parse = "Parse",
  Upload = "Upload",
}

interface LidpImportProps {
  open: boolean;
  step: ImportStep;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  setIsOpen: (open: boolean) => void;
  setStep: (step: ImportStep) => void;
  upsertLidp: (labourHours: PackageLocalContentLineItem[]) => void;
}

export default function LidpImport({
  contractorPackage,
  open,
  project,
  setIsOpen,
  setStep,
  step,
  upsertLidp,
}: LidpImportProps) {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      if (project) {
        setIsOpen(true);
        setStep(ImportStep.Parse);
        const file = e.currentTarget.files[0];
        try {
          const xlsLabourHoursReponse = await readXlsxFileToLidp(
            file,
            project.id,
            contractorPackage?.id,
          );

          if (xlsLabourHoursReponse.errorMessage) {
            alert(xlsLabourHoursReponse.errorMessage);
          } else {
            setStep(ImportStep.Upload);
            await upsertLidp(xlsLabourHoursReponse.localContent);
          }
        } catch (error: any) {
          alert(error);
          catchSentryError(error);
        }
        setIsOpen(false);
        setStep(ImportStep.Init);
      }
    }
  };
  return (
    <Dialog maxWidth="sm" fullWidth open={open} className="bgBlueOverlay">
      <DialogTitle>Import Local Content</DialogTitle>
      <IconButton
        className="closeBtn"
        aria-label="close"
        onClick={() => {
          setIsOpen(false);
        }}
        sx={{
          "&:hover": {
            background: "#143E7C",
          },
          background: "#000",
          color: "#fff",
          position: "absolute",
          right: 14,
          top: 11,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            {step === ImportStep.Init ? (
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "center",
                  margin: "0",
                  padding: "50px 30px 50px",
                }}
              >
                <StyledButton
                  loading={false}
                  variant="contained"
                  className="blackBtn grey-outline-btn"
                  startIcon={<Iconify icon="mdi:microsoft-excel" />}
                >
                  <Link
                    href={
                      process.env.PUBLIC_URL + "/SocialProTemplateLidp.xlsx"
                    }
                    sx={{
                      color: "#000 !important",
                      margin: "auto",
                      textAlign: "center",
                      textDecoration: "none !important",
                    }}
                  >
                    Download Template
                  </Link>
                </StyledButton>
                <StyledButton
                  loading={false}
                  onClick={onFileInput}
                  variant="contained"
                  className="blackBtn"
                  startIcon={<CloudUploadIcon />}
                >
                  <input
                    hidden
                    ref={fileInput}
                    accept="*/xlsx"
                    type="file"
                    onChange={onUpload}
                  />
                  {"Upload"}
                </StyledButton>
              </Grid>
            ) : (
              <>
                <Box sx={{ margin: "auto !important", padding: "10%" }}>
                  <CircularProgress />
                </Box>
                <Typography align="center">
                  {step === ImportStep.Parse ? "Parsing..." : "Uploading...."}
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
