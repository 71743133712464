import {
  Box,
  Button,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { EstimateType } from "social-pro-common/entities/financial";

interface ProjectEstimateToggleProps {
  isLoading: boolean;
  disabled: boolean;
  selectedMethod: EstimateType;
  setSelectedMethod: (method: EstimateType) => void;
}

export const ProjectEstimateToggle = ({
  disabled,
  isLoading,
  selectedMethod,
  setSelectedMethod,
}: ProjectEstimateToggleProps): JSX.Element => {
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: EstimateType,
  ) => {
    if (newValue) {
      setSelectedMethod(newValue);
    }
  };
  return (
    <Box>
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Deemed Hours</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Estiamted Hours</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={selectedMethod}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton
            data-test-id={"deemed-hours-toggle-button"}
            disabled={disabled}
            value={EstimateType.DeemedHours}
            size="small"
          >
            Deemed Hours
          </ToggleButton>
          <ToggleButton
            data-test-id={"estimated-hours-toggle-button"}
            disabled={disabled}
            value={EstimateType.EstimatedHours}
            size="small"
          >
            Estimated Hours
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
