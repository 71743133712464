import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";

export const PolicyTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell align="left" sx={{ minWidth: "10vw" }}>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" variant="circular">
              <InfoIcon color={"info"} />
            </Skeleton>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Skeleton animation="wave">
                <Typography>Policy Name</Typography>
              </Skeleton>
            </Box>
          </Stack>
        </TableCell>

        <TableCell
          align="right"
          sx={{ minWidth: "10vw", textAlign: "right !important" }}
        >
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Skeleton>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
