import React from "react";

// material-ui
import {
  Skeleton,
  SxProps,
  TableCell,
  TableSortLabel,
  Theme,
  Typography,
} from "@mui/material";

interface TableHeaderProps {
  loading: boolean;
  title: string;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  sx?: SxProps<Theme>;
  sortInfo?: SortInfo;
}

export interface SortInfo {
  order: Order;
  index: number;
  orderBy: number;
  handleRequestSort: (property: number) => void;
}

export type Order = "asc" | "desc";

export const TableHeader = ({
  align = "center",
  loading,
  sortInfo,
  sx,
  title,
}: TableHeaderProps) => {
  const content = (
    <Typography
      align={align}
      sx={{
        color: "#333333",
        fontSize: "0.875rem",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  );
  return (
    <TableCell align={align} sx={{ ...sx }}>
      {loading ? (
        <Skeleton
          animation="wave"
          sx={{
            margin: align == "center" ? "auto" : undefined,
            marginLeft: align == "right" ? "auto" : undefined,
          }}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "0.875rem",
              fontWeight: "500",
            }}
          >
            {title.length > 0 ? title : ""}
          </Typography>
        </Skeleton>
      ) : sortInfo ? (
        <TableSortLabel
          active={sortInfo.index === sortInfo.orderBy}
          direction={
            sortInfo.index === sortInfo.orderBy ? sortInfo.order : "asc"
          }
          onClick={() => {
            sortInfo.handleRequestSort(sortInfo.index);
          }}
        >
          {content}
        </TableSortLabel>
      ) : (
        content
      )}
    </TableCell>
  );
};
