import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import {
  StyledButton,
  StyledLoadingButton,
} from "@stories/atoms/StyledButton/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { GreenMetricTableRow } from "../GreenMetricTableRow/GreenMetricTableRow";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import GreenMetricModal from "@stories/organisms/GreenMetricModal/GreenMetricModal";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "../ListTable/NoRows";
import { GreenMetricTableRowSkeleton } from "../GreenMetricTableRow/GreenMetricTableRowSkeleton";

interface GreenMetricRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  greenMetrics: GreenMetricLineItem[];
  onSubmit: (greenMetrics: GreenMetricLineItem[]) => void;
  handleBack: () => void;
  setProject: (project: ProjectLineItem) => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const GreenMetricRequirementForm = ({
  greenMetrics,
  handleBack,
  loading,
  onSubmit,
  project,
  setDirtyOnChange,
  setProject,
}: GreenMetricRequirementFormProps) => {
  const [metrics, setMetrics] = useState<GreenMetricLineItem[]>(greenMetrics);
  const [selectedGreenMetric, setSelectedGreenMetric] =
    useState<GreenMetricLineItem>();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const createMetric = (metric: GreenMetricLineItem) => {
    setMetrics([...metrics, metric]);
    setDirtyOnChange(true);
  };

  const goBack = () => {
    setProject({
      ...project,
      greenMetrics: metrics,
    });
    handleBack();
  };

  const updateMetric = (metric: GreenMetricLineItem) => {
    setMetrics(
      metrics.map((m) => {
        if (m.id === metric.id) {
          return metric;
        }
        return m;
      }),
    );
    setDirtyOnChange(true);
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Target Name"}
          align="left"
          sx={{ width: "80%" }}
        />
        <TableHeader
          loading={loading}
          title={"Action"}
          align="right"
          sx={{ width: "20%" }}
        />
      </TableRow>
    </TableHead>
  );

  return (
    <Box>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <SubTitle loading={loading} title={"Sustainability Reporting"} />
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelectedGreenMetric(undefined);
                setOpen(true);
              }}
            >
              Add Custom Target
            </StyledButton>
          </Stack>
        </Box>
        <Grid item xs={12} md={12}>
          {loading ? (
            <Table>
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <GreenMetricTableRowSkeleton key={index} />
                ))}
              </TableBody>
            </Table>
          ) : null}
          {!loading ? (
            <Table data-test-id="sustainability-table">
              {tableHeader}
              <TableBody>
                {metrics.map((metric, index) => {
                  return (
                    <GreenMetricTableRow
                      key={`green-metric-table-row-${index}`}
                      metric={metric}
                      handleEdit={(greenMetric: GreenMetricLineItem) => {
                        setSelectedGreenMetric(greenMetric);
                        setOpen(true);
                      }}
                      onDelete={() => {
                        setMetrics(metrics.filter((f) => f.id !== metric.id));
                      }}
                    />
                  );
                })}
                {!metrics.length ? (
                  <NoRows title="Sustainability Metrics" colSpan={5} />
                ) : null}
              </TableBody>
            </Table>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          marginTop: "0px",
          padding: "20px 40px 30px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={goBack}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledLoadingButton
            color="primary"
            type="submit"
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              onSubmit(metrics);
            }}
          >
            <span>Save</span>
          </StyledLoadingButton>
        </Stack>
      </Grid>
      {open ? (
        <GreenMetricModal
          open={open}
          loading={loading}
          projectId={project.id}
          existingMetric={selectedGreenMetric}
          createMetric={createMetric}
          updateMetric={updateMetric}
          handleClose={handleClose}
        />
      ) : null}
    </Box>
  );
};
