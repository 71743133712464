"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultContractorPackage = exports.PackageType = void 0;
var uuid_1 = require("uuid");
var packageLabourHourCommitment_1 = require("../../interfaces/packageLabourHourCommitment");
var packageSocialSpendCommitment_1 = require("../../interfaces/packageSocialSpendCommitment");
var PackageType;
(function (PackageType) {
    PackageType["OneTime"] = "package-type_one-time";
    PackageType["PrimaryPackage"] = "package-type_primary-package";
    PackageType["BalustradesAndHandrail"] = "package-type_balustrades-and-handrail";
    PackageType["Carpentry"] = "package-type_carpentry";
    PackageType["Civil"] = "package-type_civil";
    PackageType["ConcretingAndFormwork"] = "package-type_concreting-and-Formwork";
    PackageType["Consultants"] = "package-type_consultants";
    PackageType["CurtainsAndBlinds"] = "package-type_curtains-and-Blinds";
    PackageType["Demolition"] = "package-type_demolition";
    PackageType["DryFire"] = "package-type_dry_fire";
    PackageType["Design"] = "package-type_design";
    PackageType["Electrical"] = "package-type_electrical";
    PackageType["Excavating"] = "package-type_excavating";
    PackageType["Facade"] = "package-type_facade";
    PackageType["FacadeAndCladding"] = "package-type_facade-and-cladding";
    PackageType["Fencing"] = "package-type_fencing";
    PackageType["Flooring"] = "package-type_flooring";
    PackageType["Hydraulics"] = "package-type_hydraulics";
    PackageType["KitchensAndBars"] = "package-type_kitchens-and-bars";
    PackageType["LabourHire"] = "package-type_labour_hire";
    PackageType["Landscaping"] = "package-type_landscaping";
    PackageType["Masonry"] = "package-type_masonry";
    PackageType["Mechanical"] = "package-type_mechanical";
    PackageType["MechanicalGas"] = "package-type_mechanical-gas";
    PackageType["Miscellaneous"] = "package-type_miscellaneous";
    PackageType["Other"] = "package-type_other";
    PackageType["Painting"] = "package-type_painting";
    PackageType["Plastering"] = "package-type_plastering";
    PackageType["Plumbing"] = "package-type_plumbing";
    PackageType["RoofSafetySystems"] = "package-type_roof-safety-systems";
    PackageType["Roofing"] = "package-type_roofing";
    PackageType["Scaffolding"] = "package-type_scaffolding";
    PackageType["Security"] = "package-type_security";
    PackageType["Signage"] = "package-type_signage";
    PackageType["Structure"] = "package-type_structure";
    PackageType["StructuralAndFraming"] = "package-type_structural-and-framing";
    PackageType["StructuralSteel"] = "package-type_structural-steel";
    PackageType["Tiling"] = "package-type_tiling";
    PackageType["WetFire"] = "package-type_wet-fire";
})(PackageType || (exports.PackageType = PackageType = {}));
var createDefaultContractorPackage = function (project, packageType, packageNumber, financial) {
    var packageFinancial = __assign(__assign({}, financial), { financial_id: (0, uuid_1.v4)() });
    return {
        abn: "",
        commitments_hours: project.commitments_hours.map(function (p) {
            return (0, packageLabourHourCommitment_1.projectToPackageLabourHourCommitment)(project.project_id, p);
        }),
        commitments_spend: project.commitments_spend.map(function (p) {
            return (0, packageSocialSpendCommitment_1.projectToPackageSocialSpendCommitment)(project.project_id, p);
        }),
        contractor_id: project.organisation_id,
        contractor_package_id: project.project_id,
        created: new Date(),
        estimated_progress: 0,
        financial: packageFinancial,
        financial_id: packageFinancial.financial_id,
        green_metric_submissions: [],
        is_accepted: false,
        local_content: [],
        organisation_logo: project.organisation_logo,
        organisation_name: project.organisation_name,
        package_end_date: project.project_end_date,
        package_number: packageNumber,
        package_start_date: project.project_start_date,
        package_type: packageType,
        policies: [],
        project_id: project.project_id,
        representative_email: project.representative_email,
        use_labour_hour: true,
        use_local_content: true,
        use_policy: true,
        use_social_spend: true,
        use_sustainability: true,
    };
};
exports.createDefaultContractorPackage = createDefaultContractorPackage;
