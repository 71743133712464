import { Skeleton } from "@mui/material";
import { Steps } from "@stories/atoms/Steps/Steps";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

interface SustainabilityStepsProps {
  loading: boolean;
  activeStep: number;
  greenMetrics: GreenMetricLineItem[];
  readOnly?: boolean;
}

export const SustainabilitySteps = ({
  activeStep,
  greenMetrics,
  loading,
  readOnly,
}: SustainabilityStepsProps) => {
  if (loading) {
    const steps = [
      { label: "Target 1" },
      { label: "Target 2" },
      { label: "Target 3" },
    ];
    if (!readOnly) {
      steps.push({ label: "Submit" });
    }
    return (
      <Skeleton animation="wave" sx={{ margin: "auto" }}>
        <Steps activeStep={activeStep} steps={steps} />
      </Skeleton>
    );
  }

  const steps = greenMetrics.map((greenMetric) => {
    return {
      label: greenMetric.reportTargetName,
    };
  });
  if (!readOnly) {
    steps.push({ label: "Submit" });
  }
  return <Steps activeStep={activeStep} steps={steps} />;
};
