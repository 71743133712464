import { isEqual } from "lodash";
import { useEffect } from "react";
import { useCallback } from "react";

const useIsDirty = <T extends object>(
  initialValues: T,
  values: T,
  setIsDirty: (isDirty: boolean) => void,
) => {
  const checkIsDirty = useCallback(() => {
    const isDirty = !isEqual(initialValues, values);
    setIsDirty(isDirty);
  }, [initialValues, values, setIsDirty]);

  useEffect(() => {
    checkIsDirty();
  }, [checkIsDirty, initialValues, values, setIsDirty]);

  return checkIsDirty;
};

export default useIsDirty;