import { Typography, Skeleton, Grid, Chip, Stack, Box } from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";
import { useNavigate } from "react-router-dom";
import { RecommendedSupplierModelLineItem } from "social-pro-common/interfaces/dashboard";

interface RecommendedSupplierStackProps {
  loading: boolean;
  suppliers: RecommendedSupplierModelLineItem[];
  title: string;
  firstColumnTitle: string;
  url?: string;
  dataTestId?: string;
}

export const RecommendedSupplierStack = ({
  dataTestId,
  firstColumnTitle,
  loading,
  suppliers,
  title,
  url,
}: RecommendedSupplierStackProps) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <Grid item md={3} sx={{ width: "100%" }}>
        <Box
          border={1}
          padding={2}
          sx={{
            border: "1px solid #E3E8EA",
            borderRadius: "25px",
            cursor: "pointer",
            height: "100%",
            marginLeft: "5px",
            padding: "20px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Skeleton animation="wave">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {"Title"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"First Column"}
                </Typography>
              </Skeleton>
            </Grid>
            {Array.from({ length: 6 }, () => (
              <>
                <Grid item md={12}>
                  <Stack direction="row" spacing={2}>
                    <Skeleton animation={"wave"} variant={"circular"}>
                      <Avatar />
                    </Skeleton>

                    <Skeleton animation="wave" sx={{ width: "80%" }} />
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  }
  return (
    <Grid item md={3}>
      <Box
        border={1}
        padding={2}
        sx={{
          "&:hover ": {
            border: "1px solid #495a61",
          },
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          cursor: "pointer",
          height: "100%",
          marginLeft: "5px",
          padding: "20px",
        }}
        onClick={() => {
          if (url) {
            navigate(url);
          }
        }}
        data-test-id={`${dataTestId}-landmark`}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {firstColumnTitle}
            </Typography>
          </Grid>
          <Grid item md={6} />
          {suppliers.map((supplier) => (
            <>
              <Grid item md={12}>
                <div
                  style={{ alignItems: "center", display: "flex", gap: "25px" }}
                >
                  <Avatar
                    size="badge"
                    alt={supplier.supplierName}
                    src={supplier.supplierLogo}
                    sx={{ backgroundColor: "#ffffff" }}
                  />
                  <Typography
                    color="black"
                    sx={{
                      display: "inline-block",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    className={`${dataTestId}-title`}
                  >
                    {supplier.supplierName}
                  </Typography>
                </div>
              </Grid>
            </>
          ))}
          {!suppliers.length ? (
            <Grid item md={12}>
              <Chip size="small" label={"No data"} sx={{ width: "100%" }} />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};
