import { Grid, Stack } from "@mui/material";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";
import { OrganisationProgressModelLineItem } from "social-pro-common/interfaces/organisationDashboard";
import { LoadingTitle } from "../Dashboard/ProjectProgress/LoadingTitle";
import { TitleStack } from "../Dashboard/ProjectProgress/TitleStack";

interface OrganisationProgressProps {
  loading: boolean;
  organisationProgress?: OrganisationProgressModelLineItem;
  title?: string;
}

export const OrganisationProgress = ({
  loading,
  organisationProgress,
  title,
}: OrganisationProgressProps) => {
  return (
    <Grid item md={12} sx={{ marginTop: "16px" }}>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} justifyContent={"space-between"}>
          <LoadingTitle loading={loading} title={title || ""} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2}>
            <TitleStack
              loading={loading}
              header="Company Dashboard"
              subTitle="Overall Performance"
            />
          </Stack>
          <Stack spacing={3} sx={{ paddingLeft: "5vw", width: "100%" }}>
            <Grid container justifyContent={"space-between"}>
              <Grid item md={4}>
                <TitleStack
                  loading={loading}
                  header={`${formatDecimalPlaces(organisationProgress?.activeProjects || 0, 0)}`}
                  subTitle="Active Projects"
                  dataTestId="active-project-count"
                />
              </Grid>
              <Grid item md={4}>
                <TitleStack
                  loading={loading}
                  header={`${organisationProgress?.activeEmployees || 0}`}
                  subTitle="Active Employees"
                  dataTestId="active-employees-count"
                />
              </Grid>
              <Grid item md={4}>
                <TitleStack
                  loading={loading}
                  header={`$${nFormatter(organisationProgress?.totalValue || 0, 2)}`}
                  subTitle="Total Project Value"
                  dataTestId="total-project-value"
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
};
