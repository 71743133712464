import * as React from "react";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { decodeLabourHourTargetModel } from "social-pro-common/decoders/labourHour";
import { getApiData } from "@hooks/utils/api";
import { LabourHourTargetModel } from "social-pro-common/entities/labourHour";

export const useLabourHoursForTarget = (
  projectId?: string,
  targetId?: string,
  reportId?: string,
  contractorPackageId?: string,
  page = 0,
  sortColumn = 0,
  sortDirection: "asc" | "desc" = "asc",
) => {
  const [labourHourCount, setLabourHourCount] = React.useState<number>(0);
  const [labourHours, setLabourHours] = React.useState<
    LabourHourForTargetLineItem[]
  >([]);
  const [isLabourHourLoading, setIsLabourHourLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listLabourHoursForTarget = React.useCallback(
    async (
      projectId: string,
      targetId: string,
      reportId: string,
      contractorPackageId?: string,
      page = 0,
      sortColumn = 0,
      sortDirection: "asc" | "desc" = "asc",
    ): Promise<void> => {
      try {
        setIsLabourHourLoading(true);
        const labourHourResult = await getApiData(
          "listLabourHoursForTarget",
          "labourHour",
          projectId,
          {
            contractorPackageId,
            page: page.toString(),
            reportId,
            sortColumn,
            sortDirection,
            targetId,
          },
        );
        const labourHourModel = decodeLabourHourTargetModel(
          labourHourResult.data as LabourHourTargetModel,
        );

        setLabourHourCount(labourHourModel.totalLabourHours);
        setLabourHours(labourHourModel.labourHours);
      } catch (error) {
        setError("Could not list labourHours");
      } finally {
        setIsLabourHourLoading(false);
      }
    },
    [getApiData, decodeLabourHourTargetModel],
  );

  React.useEffect(() => {
    if (projectId && targetId && reportId) {
      listLabourHoursForTarget(
        projectId,
        targetId,
        reportId,
        contractorPackageId,
        page,
        sortColumn,
        sortDirection,
      );
    } else {
      setIsLabourHourLoading(false);
    }
  }, [
    listLabourHoursForTarget,
    projectId,
    targetId,
    reportId,
    contractorPackageId,
    page,
    sortColumn,
    sortDirection,
  ]);

  return {
    error,
    isLabourHourLoading,
    labourHourCount,
    labourHours,
    listLabourHoursForTarget,
  };
};
