import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ContractorPackageTableRow } from "./ContractorPackageTableRow";
import { ContractorPackageTableRowSkeleton } from "./ContractorPackageTableRowSkeleton";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { PackageType } from "social-pro-common/entities/contractorPackage";

interface ContractorPackageTableProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  project?: ProjectLineItem;
  contractorPackages: ContractorPackageLineItem[];
  isPrimaryPackage: boolean;
  searchValue: string;
  handleOpen: () => void;
  handleEditPackage: (contractorPackageId: string) => void;
  handleDeletePackage: (contractorPackage: ContractorPackageLineItem) => void;
  resendInvite: (contractorPackageId: string) => void;
}

export const ContractorPackageTable = ({
  contractorPackages,
  handleDeletePackage,
  handleEditPackage,
  isPrimaryPackage,
  loading,
  project,
  resendInvite,
  searchValue,
  userProfile,
}: ContractorPackageTableProps) => {
  let filteredPackages = contractorPackages
    .filter((p) => p.packageType !== PackageType.PrimaryPackage)
    .filter((p) =>
      p.contractorName.toLowerCase().includes(searchValue.toLowerCase()),
    );

  filteredPackages = filteredPackages.sort((a, b) => {
    if (a.packageNumber > b.packageNumber) {
      return 1;
    }
    return -1;
  });

  const tableHeader = (
    <TableHead>
      <TableRow sx={{ marginTop: "0px !important" }}>
        <TableHeader
          loading={loading}
          title={"No."}
          sx={{ width: "5%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Subcontractor"}
          sx={{ width: "40%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Work Package"}
          sx={{ width: "15%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Value ($)"}
          sx={{ width: "12%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Start Date"}
          sx={{ width: "12%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Target Date"}
          sx={{ width: "12%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Progress"}
          sx={{ width: "10%" }}
          align="center"
        />
        <TableHeader align="center" loading={loading} title={"Action"} sx={{ width: "10%" }} />
      </TableRow>
    </TableHead>
  );
  return (
    <TableContainer>
      {loading ? (
        <Table stickyHeader aria-label="sticky table">
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorPackageTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          data-test-id="contractor-package-table"
          stickyHeader
          aria-label="sticky table"
        >
          {tableHeader}
          <TableBody>
            {project &&
              filteredPackages &&
              filteredPackages.map(
                (row: ContractorPackageLineItem, index: number) => (
                  <ContractorPackageTableRow
                    key={`contractor-package-row-${index}`}
                    project={project}
                    userProfile={userProfile}
                    contractorPackage={row}
                    handleDeletePackage={handleDeletePackage}
                    handleEditPackage={handleEditPackage}
                    resendInvite={resendInvite}
                  />
                ),
              )}
            {filteredPackages.length === 0 ? (
              <NoRows colSpan={isPrimaryPackage ? 10 : 8} title={"Packages"} />
            ) : null}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
