import { TableCell, TableRow, Typography } from "@mui/material";

interface NoResultsFoundRowProps {
  colSpan: number;
  filterName: string;
}
export const NoResultsFoundRow = ({
  colSpan,
  filterName,
}: NoResultsFoundRowProps) => {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={colSpan - 1}
        sx={{ borderRadius:'12px' ,textAlign: "center !important"}}
      >
        <div>
          <Typography variant="h6" paragraph>
            Not found
          </Typography>

          <Typography variant="body2">
            No results found for &nbsp;
            <strong>&quot;{filterName}&quot;</strong>.
            <br /> Try checking for typos or using complete words.
          </Typography>
        </div>
      </TableCell>
      
    </TableRow>
  );
};
