import {
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import * as yup from "yup";
import { useFormik } from "formik";
import { SocialSpendTableRow } from "../SocialSpendTableRow/SocialSpendTableRow";
import { useEffect, useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { NoRows } from "../ListTable/NoRows";
import SocialSpendTargetModal from "@stories/organisms/SocialSpendTargetModal/SocialSpendTargetModal";
import {
  socialSpendCommitmentTypeToDescription,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import {
  ProjectSocialSpendCommitmentLineItem,
  createDefaultSocialSpendCommitment,
  getBaseMultiplierForSpend,
} from "social-pro-common/interfaces/projectSocialSpendCommitment";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { SocialSpendTableRowSkeleton } from "../SocialSpendTableRow/SocialSpendTableRowSkeleton";

interface SocialRequirementFormSpendProps {
  loading: boolean;
  title: string;
  project: ProjectLineItem;
  commitmentLineItems: ProjectSocialSpendCommitmentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

interface FormikField {
  name: string;
  title: string;
  description: string;
  label: string;
  outcomeMultiplier: number;
  initialValue: number;
  type: any;
  deleted?: boolean;
}

export const SocialRequirementFormSpend = ({
  commitmentLineItems,
  handleBack,
  handleNext,
  loading,
  project,
  setDirtyOnChange,
  setProject,
  title,
}: SocialRequirementFormSpendProps) => {
  const [commitments, setCommitments] =
    useState<ProjectSocialSpendCommitmentLineItem[]>(commitmentLineItems);
  const [selectedCommitment, setSelectedCommitment] = useState<
    ProjectSocialSpendCommitmentLineItem | undefined
  >();

  const [fields, setFields] = useState<FormikField[]>([]);
  const [initialValues, setInitialValues] = useState<{ [k: string]: number }>(
    {},
  );
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<
      {
        [x: string]: any;
      },
      yup.AnyObject,
      {
        [x: string]: any;
      },
      ""
    >
  >(yup.object());

  useEffect(() => {
    const fieldsFromCommitments: FormikField[] = commitments.map((c) => {
      return {
        deleted: c.deleted,
        description:
          c.targetDescription ||
          socialSpendCommitmentTypeToDescription(c.targetName),
        initialValue: c.targetValue,
        label: socialSpendCommitmentTypeToString(c.targetName),
        name: c.id,
        outcomeMultiplier: getBaseMultiplierForSpend(project.financial),
        title:
          c.targetDescription ||
          socialSpendCommitmentTypeToString(c.targetName),
        type: yup.number().required().min(0),
      } as FormikField;
    }) as FormikField[];

    const initialValues = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.initialValue]),
    );

    const SchemaObject = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.type]),
    );
    const newValidationSchema = yup.object().shape(SchemaObject);

    setFields(fieldsFromCommitments);
    setInitialValues(initialValues);
    setValidationSchema(newValidationSchema);
  }, [commitments]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const updatedCommitments = commitments
        .map((c) => {
          const value = parseFloat(values[c.id] as unknown as string);
          return {
            ...c,
            targetValue: value,
            targetValueRealised: Math.round(
              getBaseMultiplierForSpend(project.financial) * (value / 100),
            ),
          } as ProjectSocialSpendCommitmentLineItem;
        })
        .filter((c) => !c.deleted) as ProjectSocialSpendCommitmentLineItem[];

      setProject({
        ...project,
        commitmentsSpend: updatedCommitments,
      });
      handleNext();
    },
    validationSchema,
  });

  const goBack = () => {
    setProject({
      ...project,
      commitmentsSpend: commitments,
    });
    handleBack();
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setSelectedCommitment(undefined);
    setOpen(false);
  };

  const createSocialCommitment = (
    socialCommitment: ProjectSocialSpendCommitmentLineItem,
  ) => {
    const matchingCommitment = commitments.find(
      (c) => c.id === socialCommitment.id,
    );
    if (matchingCommitment) {
      const updatedCommitments = commitments.reduce(
        (
          acc: ProjectSocialSpendCommitmentLineItem[],
          c: ProjectSocialSpendCommitmentLineItem,
        ) => {
          if (c.id === socialCommitment.id) {
            return [...acc, socialCommitment];
          }
          return [...acc, c];
        },
        [],
      );
      setCommitments(updatedCommitments);
    } else {
      setCommitments([...commitments, socialCommitment]);
    }

    setDirtyOnChange(true);
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          title="Commitment"
          sx={{ width: "40%" }}
          loading={loading}
          align="left"
        />
        <TableHeader
          title="Target %"
          sx={{ width: "20%" }}
          loading={loading}
          align="center"
        />
        <TableHeader
          title="Target Outcome"
          sx={{ width: "20%" }}
          loading={loading}
          align="center"
        />
        <TableHeader
          title="Action"
          sx={{ width: "20%" }}
          loading={loading}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
  const filteredFields = fields.filter((f) => !f.deleted);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            padding: "25px 40px 0px 40px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "25px",
              width: "100%",
              // margin: "",
            }}
          >
            {loading ? (
              <Skeleton animation="wave">
                <SubTitle title={`Target Spend`} />
              </Skeleton>
            ) : (
              <SubTitle title={`Target ${title}`} />
            )}

            <StyledButton
              data-test-id="add-spend-target-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelectedCommitment(
                  createDefaultSocialSpendCommitment(project.id),
                );
                setOpen(true);
              }}
            >
              {`Add ${title} Target`}
            </StyledButton>
          </Box>

          <Grid item md={12}>
            <Box>
              {loading ? (
                <Table data-test-id="spend-commitment-table">
                  {tableHeader}
                  <TableBody>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <SocialSpendTableRowSkeleton key={index} />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table data-test-id="spend-commitment-table">
                  {tableHeader}
                  <TableBody>
                    {filteredFields.map((field) => {
                      const { description, name, outcomeMultiplier, title } =
                        field;
                      const handleEdit = () => {
                        setSelectedCommitment(
                          commitments.find((c) => c.id === name),
                        );
                        setOpen(true);
                      };
                      return (
                        <SocialSpendTableRow
                          key={`social-procurement-table-row-${field.title}`}
                          title={title}
                          loading={loading}
                          description={description}
                          value={formik.values[name]}
                          outcomeMultiplier={outcomeMultiplier}
                          handleEdit={handleEdit}
                          onDelete={() => {
                            const updatedCommitments = commitments.reduce(
                              (acc, c) => {
                                if (c.id === name) {
                                  return [...acc, { ...c, deleted: true }];
                                }
                                return [...acc, c];
                              },
                              [] as ProjectSocialSpendCommitmentLineItem[],
                            );
                            setCommitments(updatedCommitments);
                            setDirtyOnChange(true);
                          }}
                        />
                      );
                    })}
                    {filteredFields.length === 0 ? (
                      <NoRows title={"Spend Commitments"} colSpan={4} />
                    ) : null}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            padding: "20px 40px 30px",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton loading={loading} onClick={goBack} variant="outlined">
              Back
            </StyledButton>
            <StyledButton
              loading={loading}
              disabled={loading || formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Next
            </StyledButton>
          </Stack>
        </Grid>
      </form>
      {open && selectedCommitment ? (
        <SocialSpendTargetModal
          open={open}
          loading={loading}
          project={project}
          socialSpendCommitment={selectedCommitment}
          createSocialCommitment={createSocialCommitment}
          handleClose={handleClose}
        />
      ) : null}
    </Box>
  );
};
