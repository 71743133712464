"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectSocialSpendCommitment = exports.decodeProjectSocialSpendCommitment = void 0;
var string_1 = require("../../utils/string");
var decodeProjectSocialSpendCommitment = function (socialCommitment) {
    return {
        abns: socialCommitment.abns,
        achievedValue: socialCommitment.achieved_value,
        certifications: socialCommitment.certifications || [],
        colour: socialCommitment.colour,
        created: new Date(socialCommitment.created),
        entityTypes: socialCommitment.entity_types || [],
        id: socialCommitment.project_social_spend_commitment_id,
        postCodes: socialCommitment.post_codes,
        projectId: socialCommitment.project_id,
        targetDescription: socialCommitment.target_description
            ? socialCommitment.target_description
            : undefined,
        targetName: socialCommitment.target_type,
        targetValue: socialCommitment.target_value,
        targetValueRealised: socialCommitment.target_value_realised,
    };
};
exports.decodeProjectSocialSpendCommitment = decodeProjectSocialSpendCommitment;
var encodeProjectSocialSpendCommitment = function (socialCommitment) {
    var safeSocialCommitment = (0, string_1.makePostgresSafe)(socialCommitment);
    return {
        abns: socialCommitment.abns,
        achieved_value: safeSocialCommitment.achievedValue,
        certifications: socialCommitment.certifications || [],
        colour: safeSocialCommitment.colour,
        created: safeSocialCommitment.created,
        entity_types: socialCommitment.entityTypes || [],
        post_codes: socialCommitment.postCodes,
        project_id: safeSocialCommitment.projectId,
        project_social_spend_commitment_id: safeSocialCommitment.id,
        target_description: safeSocialCommitment.targetDescription,
        target_type: safeSocialCommitment.targetName,
        target_value: safeSocialCommitment.targetValue,
        target_value_realised: safeSocialCommitment.targetValueRealised,
    };
};
exports.encodeProjectSocialSpendCommitment = encodeProjectSocialSpendCommitment;
