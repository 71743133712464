import {
  Chip,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
  Button,
} from "@mui/material";
import {
  EmployeeLineItem,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import { ChangeEvent } from "react";
import {
  labourHourCommitmentTypeToAbbreviatedString,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { wordsToThreeLetterAcronym } from "@utils/string";

interface ProjectEmployeeTableRowProps {
  employee: EmployeeLineItem;
  project?: ProjectLineItem;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEmployeeAssignment: (
    employee: EmployeeLineItem,
    toggle: boolean,
  ) => void;
}

export const ProjectEmployeeTableRow = ({
  employee,
  handleEmployeeAssignment,
  handleOpenListModal,
  project,
}: ProjectEmployeeTableRowProps) => {
  const handleAssignment = (e: ChangeEvent<HTMLInputElement>) => {
    handleEmployeeAssignment(employee, e.target.checked);
  };

  const matchingLabourHourCommitments = getEmployeeFactors(employee, project);

  return (
    <>
      <TableRow key={employee.id}>
        <TableCell
          align="left"
          scope="row"
          onClick={() => {
            handleOpenListModal(employee);
          }}
          sx={{ width: "15%" }}
        >
          {`${employee.employeeName}${
            employee.employeeIdentifier
              ? ` (${employee.employeeIdentifier})`
              : ""
          }`}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
          sx={{ width: "15%" }}
        >
          {employee.occupation}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
          sx={{ width: "35%" }}
        >
          {occupationTypeOptionToString(employee.occupationType)}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
          sx={{ width: "15%" }}
        >
          {matchingLabourHourCommitments.map((sf, i) => {
            return (
              <Tooltip
                TransitionComponent={Zoom}
                key={`tool-tip-employee-social-factor-${i}`}
                title={
                  sf.targetDescription ||
                  labourHourCommitmentTypeToString(sf.targetName)
                }
              >
                <Chip
                  size="small"
                  label={
                    sf.targetDescription
                      ? wordsToThreeLetterAcronym(sf.targetDescription)
                      : labourHourCommitmentTypeToAbbreviatedString(
                          sf.targetName,
                        )
                  }
                  onMouseDown={(event) => event.stopPropagation()}
                  style={{
                    backgroundColor: sf.colour,
                    color: "#fff",
                  }}
                />
              </Tooltip>
            );
          })}
        </TableCell>
        <TableCell align="center" className="center" sx={{ width: "10%" }}>
          <Switch
            color="success"
            onChange={handleAssignment}
            checked={employee.assigned}
          />
        </TableCell>
        <TableCell
          align="right"
          sx={{ textAlign: "right !important", width: "10%" }}
        >
          <Button
            color="primary"
            size="large"
            onClick={() => {
              handleOpenListModal(employee);
            }}
            className="blackBtn"
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            View
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
