import { ProjectStatus } from "social-pro-common/interfaces/project";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";
interface ListProjectsTabsProps {
  isLoading: boolean;
  value: ProjectStatus;
  handleChange: (event: React.SyntheticEvent, newValue?: ProjectStatus) => void;
}

export const ListProjectsTabs = ({
  handleChange,
  isLoading,
  value,
}: ListProjectsTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings

  return (
    <Box>
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Active</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Active</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value={ProjectStatus.Active}
            size="small"
            data-test-id="active-projects-btn"
          >
            Active
          </ToggleButton>
          <ToggleButton
            value={ProjectStatus.Archived}
            size="small"
            data-test-id="archived-projects-btn"
          >
            Archived
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
