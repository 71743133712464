import { ProfileForm } from "@stories/molecules/ProfileForm/ProfileForm";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import {
  ProfileLineItem,
  ProfileStatus,
} from "social-pro-common/interfaces/profile";
import { Grid } from "@mui/material";
import { ToastOptions, toast } from "react-toastify";
import ChangePasswordForm from "@stories/molecules/ChangePasswordForm/ChangePasswordForm";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { catchSentryError } from "@utils/sentry";

export const EditProfile = () => {
  const { changePassword, isAuthLoading, user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    updateUserProfile,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmit = async (profile: ProfileLineItem) => {
    try {
      if (userProfile && userOrganisation) {
        await updateUserProfile({ ...profile, status: ProfileStatus.Active });
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save account, please contact support.", {
        type: "error",
      } as ToastOptions);
    }
  };
  const loading =
    isAuthProfileLoading || isAuthLoading || !userProfile || !userOrganisation;
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0",
        }}
      >
        <PageHeader
          loading={loading}
          title="Profile"
          subTitle={userProfile?.name}
        />
        {/* <Grid item md={3} />
        <Grid item md={6}>
          <Divider sx={{ marginTop: "20px" }}>
            {loading ? (
              <Skeleton variant="rounded" animation="wave">
                <Chip label={"Profile Details"} variant="outlined" />
              </Skeleton>
            ) : (
              <Chip label={"Profile Details"} variant="outlined" />
            )}
          </Divider>
        </Grid>
        <Grid item md={3} /> */}
        <Grid item sx={{ marginTop: "20px" }} md={12}>
          <ProfileForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmit}
          />
        </Grid>
        {/* <Grid item md={3} />
        <Grid item md={6}>
          <Divider>
            {loading ? (
              <Skeleton variant="rounded" animation="wave">
                <Chip label={"Change Password"} variant="outlined" />
              </Skeleton>
            ) : (
              <Chip label={"Change Password"} variant="outlined" />
            )}
          </Divider>
        </Grid>
        <Grid item md={3} /> */}
        <Grid sx={{ marginTop: "20px" }} item md={12}>
          <ChangePasswordForm
            loading={loading}
            changePassword={changePassword}
          />
        </Grid>
      </Grid>
    </>
  );
};
