"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodePostCodeModel = exports.decodeSubcontractorActivity = exports.decodeSustainabilitySubDataModel = exports.decodeLocalContentSubDataModel = exports.decodeLocalContentRowDataModel = exports.decodeSocialSpendSubDataModel = exports.decodeLabourHourSubDataModel = exports.deocodeRecommendedSupplierModel = exports.decodeForecastDataModel = exports.decodeContractorSubDataModel = exports.decodeSustainabilityProgressModel = exports.decodeLocalContentProgressModel = exports.decodeSpendProgressModel = exports.decodeLabourHourProgressModel = exports.decodeWidgetModel = exports.decodeProjectProgress = exports.decodeDashboardModel = void 0;
var socialBusiness_1 = require("../socialBusiness");
var decodeDashboardModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var subcontractorActivity;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(data.subcontractor_activity.map(function (sa) {
                    return (0, exports.decodeSubcontractorActivity)(sa, getImageUrl);
                }))];
            case 1:
                subcontractorActivity = _a.sent();
                return [2 /*return*/, {
                        labourHourProgress: (data === null || data === void 0 ? void 0 : data.labour_hour_progress)
                            ? (0, exports.decodeLabourHourProgressModel)(data.labour_hour_progress) || undefined
                            : undefined,
                        localContentProgress: (data === null || data === void 0 ? void 0 : data.local_content_progress)
                            ? (0, exports.decodeLocalContentProgressModel)(data.local_content_progress) ||
                                undefined
                            : undefined,
                        projectProgress: (0, exports.decodeProjectProgress)(data.project_progress),
                        spendProgress: (data === null || data === void 0 ? void 0 : data.spend_progress)
                            ? (0, exports.decodeSpendProgressModel)(data.spend_progress) || undefined
                            : undefined,
                        subcontractorActivity: subcontractorActivity,
                        sustainabilityProgress: (data === null || data === void 0 ? void 0 : data.sustainability_progress)
                            ? (0, exports.decodeSustainabilityProgressModel)(data.sustainability_progress) ||
                                undefined
                            : undefined,
                        widgets: (data === null || data === void 0 ? void 0 : data.widgets.map(function (widget) { return (0, exports.decodeWidgetModel)(widget); })) || [],
                    }];
        }
    });
}); };
exports.decodeDashboardModel = decodeDashboardModel;
var decodeProjectProgress = function (data) {
    return {
        activeSubcontractors: data.active_subcontractors,
        completedWorks: data.completed_works,
        timeProgress: data.time_progress,
    };
};
exports.decodeProjectProgress = decodeProjectProgress;
var decodeWidgetModel = function (data) {
    return {
        colour: data.colour,
        pcChange: data.pc_change,
        title: data.title,
        tooltip: data.tooltip,
        units: data.units,
        value: data.value,
    };
};
exports.decodeWidgetModel = decodeWidgetModel;
var decodeLabourHourProgressModel = function (data) {
    return {
        pcChange: data.pc_change,
        pcComplete: data.pc_complete,
        targetHours: data.target_hours,
        totalHours: data.total_hours,
    };
};
exports.decodeLabourHourProgressModel = decodeLabourHourProgressModel;
var decodeSpendProgressModel = function (data) {
    return {
        achievedSpend: data.achieved_spend,
        pcChange: data.pc_change,
        pcComplete: data.pc_complete,
        targetSpend: data.target_spend,
    };
};
exports.decodeSpendProgressModel = decodeSpendProgressModel;
var decodeLocalContentProgressModel = function (data) {
    return {
        anzValueAdd: data.anz_value_add,
        pcChange: data.pc_change,
        targetAnzValue: data.target_anz_value,
        valueReportedToDate: data.value_reported_to_date,
    };
};
exports.decodeLocalContentProgressModel = decodeLocalContentProgressModel;
var decodeSustainabilityProgressModel = function (data) {
    return {
        fuelsTotal: data.fuels_total,
        wasteTotal: data.waste_total,
        waterTotal: data.water_total,
    };
};
exports.decodeSustainabilityProgressModel = decodeSustainabilityProgressModel;
var decodeContractorSubDataModel = function (data) {
    return {
        pcChange: data.pc_change,
        title: data.title,
        units: data.units,
        value: data.value,
    };
};
exports.decodeContractorSubDataModel = decodeContractorSubDataModel;
var decodeForecastDataModel = function (data) {
    return {
        forecastData: data.forecast_data.map(function (item) {
            return (0, exports.decodeContractorSubDataModel)(item);
        }),
        forecastTotal: data.forecast_total,
        pcAchieved: data.pc_achieved,
    };
};
exports.decodeForecastDataModel = decodeForecastDataModel;
var deocodeRecommendedSupplierModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var supplierLogo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, socialBusiness_1.getSocialBusinessLogo)(data.supplier_logo, getImageUrl)];
            case 1:
                supplierLogo = _a.sent();
                return [2 /*return*/, {
                        supplierLogo: supplierLogo,
                        supplierName: data.supplier_name,
                        supplierTargetType: data.supplier_target_type,
                    }];
        }
    });
}); };
exports.deocodeRecommendedSupplierModel = deocodeRecommendedSupplierModel;
var decodeLabourHourSubDataModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var recommendedSuppliers, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!data.recommendedSuppliers) return [3 /*break*/, 2];
                return [4 /*yield*/, Promise.all(data.recommendedSuppliers.map(function (item) {
                        return (0, exports.deocodeRecommendedSupplierModel)(item, getImageUrl);
                    }))];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = undefined;
                _b.label = 3;
            case 3:
                recommendedSuppliers = _a;
                return [2 /*return*/, {
                        forecast: (0, exports.decodeForecastDataModel)(data.forecast),
                        recommendedSuppliers: recommendedSuppliers,
                        subcontractors: data.subcontractors.map(function (item) {
                            return (0, exports.decodeContractorSubDataModel)(item);
                        }),
                        targets: data.targets.map(function (item) { return (0, exports.decodeContractorSubDataModel)(item); }),
                    }];
        }
    });
}); };
exports.decodeLabourHourSubDataModel = decodeLabourHourSubDataModel;
var decodeSocialSpendSubDataModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var recommendedSuppliers, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!data.recommendedSuppliers) return [3 /*break*/, 2];
                return [4 /*yield*/, Promise.all(data.recommendedSuppliers.map(function (item) {
                        return (0, exports.deocodeRecommendedSupplierModel)(item, getImageUrl);
                    }))];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = undefined;
                _b.label = 3;
            case 3:
                recommendedSuppliers = _a;
                return [2 /*return*/, {
                        forecast: (0, exports.decodeForecastDataModel)(data.forecast),
                        recommendedSuppliers: recommendedSuppliers,
                        socialSpendData: data.socialSpendData.map(function (item) {
                            return (0, exports.decodeContractorSubDataModel)(item);
                        }),
                        targets: data.targets.map(function (item) { return (0, exports.decodeContractorSubDataModel)(item); }),
                    }];
        }
    });
}); };
exports.decodeSocialSpendSubDataModel = decodeSocialSpendSubDataModel;
var decodeLocalContentRowDataModel = function (data) {
    return {
        anzValueAdd: data.anz_value_add,
        target: data.target,
        title: data.title,
        value: data.value,
    };
};
exports.decodeLocalContentRowDataModel = decodeLocalContentRowDataModel;
var decodeLocalContentSubDataModel = function (data) {
    return {
        localContentTargets: data.local_content_targets.map(function (item) {
            return (0, exports.decodeLocalContentRowDataModel)(item);
        }),
        subcontractors: data.subcontractors.map(function (item) {
            return (0, exports.decodeContractorSubDataModel)(item);
        }),
    };
};
exports.decodeLocalContentSubDataModel = decodeLocalContentSubDataModel;
var decodeSustainabilitySubDataModel = function (data) {
    return {
        fuels: data.fuels.map(function (item) { return (0, exports.decodeContractorSubDataModel)(item); }),
        waste: data.waste.map(function (item) { return (0, exports.decodeContractorSubDataModel)(item); }),
        water: data.water.map(function (item) { return (0, exports.decodeContractorSubDataModel)(item); }),
    };
};
exports.decodeSustainabilitySubDataModel = decodeSustainabilitySubDataModel;
var decodeSubcontractorActivity = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(data.organisation_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(data.organisation_logo)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        created: new Date(data.created),
                        organisationLogo: presignedUrl,
                        organisationName: data.organisation_name,
                        reportId: data.report_id,
                    }];
        }
    });
}); };
exports.decodeSubcontractorActivity = decodeSubcontractorActivity;
var decodePostCodeModel = function (data) {
    return {
        postCode: data.post_code,
        value: data.value,
    };
};
exports.decodePostCodeModel = decodePostCodeModel;
