import { DatePickerProps } from "@mui/lab";
import { Skeleton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const StyledDatePicker = (props: DatePickerProps<Date>) => {
  if (props.loading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width={"100%"}
        sx={{ ...props.sx, maxHeight: "51px" }}
      >
        <TextField
          className={props.className}
          label="DEFAULT TEXT FIELD CONTENT - LOADING"
          placeholder={props.placeholder}
          margin="dense"
          fullWidth
        />
      </Skeleton>
    );
  }
  return (
    <DatePicker
      data-test-id={props["data-test-id"]}
      label={props.label}
      onChange={props.onChange}
      format={props.format || "MMMM YYYY"}
      disabled={props.disabled}
      value={props.value}
      maxDate={props.maxDate}
      minDate={props.minDate}
      views={props.views}
      openTo={props.openTo}
      slotProps={{
        ...props.slotProps,
      }}
    />
  );
};
