"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialSpendServiceCategory = exports.SocialSpendCertificate = exports.SocialSpendEntityType = exports.PurchaserType = exports.DEFAULT_SOCIAL_SPEND_LIMIT = void 0;
exports.DEFAULT_SOCIAL_SPEND_LIMIT = 10;
var PurchaserType;
(function (PurchaserType) {
    PurchaserType["Direct"] = "purchaser-type_direct";
    PurchaserType["Indirect"] = "purchaser-type_indirect";
})(PurchaserType || (exports.PurchaserType = PurchaserType = {}));
var SocialSpendEntityType;
(function (SocialSpendEntityType) {
    SocialSpendEntityType["SocialEnterprise"] = "social-spend-entity-type_social-enterprise";
    SocialSpendEntityType["DisabilityEnterprise"] = "social-spend-entity-type_disability-enterprise";
    SocialSpendEntityType["IndigenousEnterprise"] = "social-spend-entity-type_indigenous-enterprise";
})(SocialSpendEntityType || (exports.SocialSpendEntityType = SocialSpendEntityType = {}));
var SocialSpendCertificate;
(function (SocialSpendCertificate) {
    SocialSpendCertificate["AustralianCharitiesAndNotForProfitsCommision"] = "social-procurement-certificate_australian-charities-and-not-for-profits-commision";
    SocialSpendCertificate["AustralianNetworkOnDisability"] = "social-procurement-certificate_australian-network-on-disability";
    SocialSpendCertificate["DepartmentOfEducation"] = "social-procurement-certificate_department-of-education";
    SocialSpendCertificate["Kinaway"] = "social-procurement-certificate_kinaway";
    SocialSpendCertificate["OfficeOfRegistrarOfIndigenousCorporations"] = "social-procurement-certificate_office-of-registrar-of-indigenous-corporations";
    SocialSpendCertificate["SocialTraders"] = "social-procurement-certificate_social-traders";
    SocialSpendCertificate["SupplyNationCertified"] = "social-procurement-certificate_supply-nation-certified";
    SocialSpendCertificate["SupplyNationRegistered"] = "social-procurement-certificate_supply-nation-registered";
})(SocialSpendCertificate || (exports.SocialSpendCertificate = SocialSpendCertificate = {}));
var SocialSpendServiceCategory;
(function (SocialSpendServiceCategory) {
    SocialSpendServiceCategory["AdvertisingAndMedia"] = "social-procurement-service-category_advertising-and-media";
    SocialSpendServiceCategory["ArchitecturePlanningAndDesign"] = "social-procurement-service-category_architecture-planning-and-design";
    SocialSpendServiceCategory["BuildingProductsAndmaterials"] = "social-procurement-service-category_building-products-and-materials";
    SocialSpendServiceCategory["BuildingTradeRepairsAndMaintenance"] = "social-procurement-service-category_building-trade-repairs-and-maintenance";
    SocialSpendServiceCategory["BusinessAndAdministrativeServices"] = "social-procurement-service-category_business-and-administrative-services";
    SocialSpendServiceCategory["CateringAndHospitality"] = "social-procurement-service-category_catering-and-hospitality";
    SocialSpendServiceCategory["CivilConstruction"] = "social-procurement-service-category_civil-construction";
    SocialSpendServiceCategory["CleaningServicesAndEquipmentAndSupplies"] = "social-procurement-service-category_cleaning-services-and-equipment-and-supplies";
    SocialSpendServiceCategory["ClothingAndSafetyEquipment"] = "social-procurement-service-category_clothing-and-safety-equipment";
    SocialSpendServiceCategory["CommunicationsPromotionalMaterials"] = "social-procurement-service-category_communications-promotional-materials";
    SocialSpendServiceCategory["ConstructionPlantWetAndDryAndToolHire"] = "social-procurement-service-category_construction-plant-wet-and-dry-and-tool-hire";
    SocialSpendServiceCategory["CraneHire"] = "social-procurement-service-category_crane-hire";
    SocialSpendServiceCategory["CulturalHeritageManagement"] = "social-procurement-service-category_cultural-heritage-management";
    SocialSpendServiceCategory["ElectricalContractors"] = "social-procurement-service-category_electrical-contractors";
    SocialSpendServiceCategory["EmploymentDirect"] = "social-procurement-service-category_employment-direct";
    SocialSpendServiceCategory["EmploymentLabourHire"] = "social-procurement-service-category_employment-labour-hire";
    SocialSpendServiceCategory["EngineerResearchTechServices"] = "social-procurement-service-category_engineer-research-tech-services";
    SocialSpendServiceCategory["EventsVenueHire"] = "social-procurement-service-category_events-venue-hire";
    SocialSpendServiceCategory["FinancialAndInsuranceServices"] = "social-procurement-service-category_financial-and-insurance-services";
    SocialSpendServiceCategory["FleetHireAndCleaning"] = "social-procurement-service-category_fleet-hire-and-cleaning";
    SocialSpendServiceCategory["FleetServicingAndaccessories"] = "social-procurement-service-category_fleet-servicing-and-accessories";
    SocialSpendServiceCategory["FoodAndBeverageProducts"] = "social-procurement-service-category_food-and-beverage-products";
    SocialSpendServiceCategory["FurnitureAndFurnishings"] = "social-procurement-service-category_furniture-and-furnishings";
    SocialSpendServiceCategory["GiftsOrrewards"] = "social-procurement-service-category_gifts-or-rewards";
    SocialSpendServiceCategory["HealthcareServices"] = "social-procurement-service-category_healthcare-services";
    SocialSpendServiceCategory["HorticultureAndarboriculture"] = "social-procurement-service-category_horticulture-and-arboriculture";
    SocialSpendServiceCategory["HumanResourceServicesAndrecruitment"] = "social-procurement-service-category_human-resource-services-and-recruitment";
    SocialSpendServiceCategory["IndustrialProductionAndmanufacture"] = "social-procurement-service-category_industrial-production-and-manufacture";
    SocialSpendServiceCategory["ITAndTelecomms"] = "social-procurement-service-category_it-and-telecomms";
    SocialSpendServiceCategory["LandscapingAndArchitecturalSupplyAndConstruction"] = "social-procurement-service-category_landscaping-and-architectural-supply-and-construction";
    SocialSpendServiceCategory["LegalServices"] = "social-procurement-service-category_legal-services";
    SocialSpendServiceCategory["LibraryServices"] = "social-procurement-service-category_library-services";
    SocialSpendServiceCategory["MarketingAndCommunications"] = "social-procurement-service-category_marketing-and-communications";
    SocialSpendServiceCategory["MechanicalServices"] = "social-procurement-service-category_mechanical-services";
    SocialSpendServiceCategory["MerchandiseAndPersonalProducts"] = "social-procurement-service-category_merchandise-and-personal-products";
    SocialSpendServiceCategory["MiningOilAndGas"] = "social-procurement-service-category_mining-oil-and-gas";
    SocialSpendServiceCategory["MusicAndGamesAndToysAndArtsAndCraftsAndEducational"] = "social-procurement-service-category_music-and-games-and-toys-and-arts-and-crafts-and-educational";
    SocialSpendServiceCategory["OfficeConsumables"] = "social-procurement-service-category_office-consumables";
    SocialSpendServiceCategory["OfficeSuppliesAndservices"] = "social-procurement-service-category_office-supplies-and-services";
    SocialSpendServiceCategory["ParkingAndTrafficServicesAndEquipment"] = "social-procurement-service-category_parking-and-traffic-services-and-equipment";
    SocialSpendServiceCategory["PavementConstruction"] = "social-procurement-service-category_pavement-construction";
    SocialSpendServiceCategory["PersonalAndDomesticServices"] = "social-procurement-service-category_personal-and-domestic-services";
    SocialSpendServiceCategory["PersonalProtectiveEquipmentPPE"] = "social-procurement-service-category_personal-protective-equipment-ppe";
    SocialSpendServiceCategory["PrecastConcrete"] = "social-procurement-service-category_precast-concrete";
    SocialSpendServiceCategory["PrintingAndDistributionServices"] = "social-procurement-service-category_printing-and-distribution-services";
    SocialSpendServiceCategory["ProfessionalConsultancyServices"] = "social-procurement-service-category_professional-consultancy-services";
    SocialSpendServiceCategory["RealEstateAndpropertyServices"] = "social-procurement-service-category_real-estate-and-property-services";
    SocialSpendServiceCategory["RenewableEnergy"] = "social-procurement-service-category_renewable-energy";
    SocialSpendServiceCategory["SafetyBarriersAndBollards"] = "social-procurement-service-category_safety-barriers-and-bollards";
    SocialSpendServiceCategory["ScaffoldingAndaccess"] = "social-procurement-service-category_scaffolding-and-access";
    SocialSpendServiceCategory["SecurityServicesAndEquipment"] = "social-procurement-service-category_security-services-and-equipment";
    SocialSpendServiceCategory["SignsAndBarriers"] = "social-procurement-service-category_signs-and-barriers";
    SocialSpendServiceCategory["SignsAndpavementMarkings"] = "social-procurement-service-category_signs-and-pavement-markings";
    SocialSpendServiceCategory["SiteFacilities"] = "social-procurement-service-category_site-facilities";
    SocialSpendServiceCategory["SportAndRecEquipmentAndServices"] = "social-procurement-service-category_sport-and-rec-equipment-and-services";
    SocialSpendServiceCategory["StormwaterAndsubsurfaceDrainageSupplyOrinstallation"] = "social-procurement-service-category_stormwater-and-subsurface-drainage-supply-or-installation";
    SocialSpendServiceCategory["StructuralSteelFabrication"] = "social-procurement-service-category_structural-steel-fabrication";
    SocialSpendServiceCategory["Surveying"] = "social-procurement-service-category_surveying";
    SocialSpendServiceCategory["TrafficManagement"] = "social-procurement-service-category_traffic-management";
    SocialSpendServiceCategory["TrafficServicesAndEquipment"] = "social-procurement-service-category_traffic-services-and-equipment";
    SocialSpendServiceCategory["TrainingAndEducationServices"] = "social-procurement-service-category_training-and-education-services";
    SocialSpendServiceCategory["TransportFreightAndLogistics"] = "social-procurement-service-category_transport-freight-and-logistics";
    SocialSpendServiceCategory["TravelAndTourism"] = "social-procurement-service-category_travel-and-tourism";
    SocialSpendServiceCategory["UtilityServicesWorks"] = "social-procurement-service-category_utility-services-works";
    SocialSpendServiceCategory["VenueHire"] = "social-procurement-service-category_venue-hire";
    SocialSpendServiceCategory["WasteManagement"] = "social-procurement-service-category_waste-management";
    SocialSpendServiceCategory["WasteManagementAndLandfill"] = "social-procurement-service-category_waste-management-and-landfill";
    SocialSpendServiceCategory["None"] = "social-procurement-service-category_none";
})(SocialSpendServiceCategory || (exports.SocialSpendServiceCategory = SocialSpendServiceCategory = {}));
