import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { Boxes, FileBox, Box } from "lucide-react";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";

export const getSocialReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();

  const children = [];
  children.push({
    counter: sideBarMenuModel?.projectReportSocialCount,
    icon: <FileBox />,
    id: "report-project",
    title: "Project",
    type: "item",
    url: project ? RouteHelper.contractorProjectReports(project.id) : "",
  });

  children.push({
    counter: sideBarMenuModel?.packageReportSocialCount,
    icon: <Boxes />,
    id: "report-packages",
    title: "Package",
    type: "item",
    url: project ? RouteHelper.contractorPackageReports(project.id) : "",
  });

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "social-reporting",
    title: "Social Reporting",
    type: "group",
  };
};

export const getSustainabilityReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();
  const children = [];
  children.push({
    counter: sideBarMenuModel?.projectReportSustainabilityCount,
    icon: <FileBox />,
    id: "project-sustainability",
    title: "Project",
    type: "item",
    url: project ? RouteHelper.projectSustainability(project.id) : "",
  });

  children.push({
    counter: sideBarMenuModel?.packageReportSustainabilityCount,
    icon: <Box />,
    id: "sustainability",
    title: "Package",
    type: "item",
    url: project ? RouteHelper.sustainability(project.id) : "",
  });

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "sustainability-reporting",
    title: "Sustainability Reporting", //TODO: change class to reporting
    type: "group",
  };
};

export const getPolicyReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();

  const children = [];

  children.push({
    counter: sideBarMenuModel?.policyReportCount,
    icon: <FileBox />,
    id: "project-policies",
    title: "Policy Upload",
    type: "item",
    url: project ? RouteHelper.projectPolicies(project.id) : "",
  });

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "policy-reporting",
    title: "Governance Reporting", //TODO: change class to reporting
    type: "group",
  };
};

export const getReducedSocialReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();
  const children = [];
  children.push({
    counter: sideBarMenuModel?.packageReportSocialCount,
    icon: <Boxes />,
    id: "report-packages",
    title: "Package",
    type: "item",
    url: project ? RouteHelper.contractorPackageReports(project.id) : "",
  });

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "social-reporting",
    title: "Social Reporting", //TODO: change class to reporting
    type: "group",
  };
};

export const getReducedSustainabilityReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();
  const children = [];

  children.push({
    counter: sideBarMenuModel?.packageReportSustainabilityCount,
    icon: <Box />,
    id: "sustainability",
    title: "Package",
    type: "item",
    url: project ? RouteHelper.sustainability(project.id) : "",
  });

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "sustainability-reporting",
    title: "Sustainability Reporting", //TODO: change class to reporting
    type: "group",
  };
};

export const getReducedPolicyReportingNav = (
  project?: ProjectLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();
  const children = [];

  children.push({
    counter: sideBarMenuModel?.policyReportCount,
    icon: <FileBox />,
    id: "project-policies",
    title: "Policy Upload",
    type: "item",
    url: project ? RouteHelper.projectPolicies(project.id) : "",
  });

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "policy-reporting",
    title: "Governance Reporting",
    type: "group",
  };
};
