import {
  Grid,
  Box,
  FormControl,
  FormLabel,
  Stack,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Zoom,
  FormControlLabel,
  useTheme,
  FormGroup,
  Switch,
  Skeleton,
  Autocomplete,
  TextField,
  Divider,
  Chip,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Country, ICountry, State } from "country-state-city";
import { phone } from "phone";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { validateABN } from "social-pro-common/utils/abn";
import { useCallback, useRef, useState } from "react";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { uploadImage } from "@hooks/utils/useUpload";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { validatePostCode } from "@utils/location";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import { ToastOptions, toast } from "react-toastify";
import { ContactInfoLineItem } from "social-pro-common/interfaces/contactInfo";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { PageHeader } from "../PageHeader/PageHeader";
import { catchSentryError } from "@utils/sentry";
import { cleanString } from "social-pro-common/utils/string";
import { SearchIcon } from "lucide-react";
import { useOrganisationByABN } from "@hooks/crud/organisation/useOrganisationByABN";
import { debounce } from "lodash";

interface CreateAccountFormProps {
  loading: boolean;
  organisation: OrganisationLineItem;
  userProfile: ProfileLineItem;
  finalizeAccount: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem,
  ) => void;
}

export const CreateAccountForm = ({
  finalizeAccount,
  loading,
  organisation,
  userProfile,
}: CreateAccountFormProps) => {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [tempLogoUrl, setTempLogoUrl] = useState(organisation.organisationLogo);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { isOrganisationLoading, searchOrganisationExistsByABN } =
    useOrganisationByABN();

  const validationSchema = yup.object({
    abn: yup.string().required("ABN is required"),
    addressLine1: yup.string().required("Address Line 1 is required"),
    addressLine2: yup.string(),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    emailNotifications: yup.boolean().required("Email notifications required"),
    organisationLogoSrc: yup.string().required("Organisation logo is required"),
    organisationName: yup.string().required("Organisation name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .test("Valid Phone", "Phone not valid for country", function (item) {
        const res = phone(item, {
          country: this.parent.country,
          validateMobilePrefix: false,
        });
        return res.isValid;
      }),
    postCode: yup
      .string()
      .required("Postcode is required")
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item) {
          return validatePostCode(this.parent.country, item);
        },
      ),
    representativeEmail: yup
      .string()
      .email()
      .required("Representative email is required"),
    representativeName: yup
      .string()
      .required("Representative name is required"),
    state: yup.string().required("State is required"),
    tradingName: yup.string().required("Trading name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      abn: organisation.abn,
      addressLine1: organisation.contactInfo.addressLine1,
      addressLine2: organisation.contactInfo.addressLine2,
      city: organisation.contactInfo.city,
      country: organisation.contactInfo.country,
      emailNotifications: userProfile.emailNotifications,
      id: organisation.id,
      organisationLogoSrc: organisation.organisationLogoSrc,
      organisationName: organisation.organisationName,
      phoneNumber: organisation.contactInfo.phoneNumber,
      photoUrl: organisation.organisationLogo,
      postCode: organisation.contactInfo.postCode,
      representativeEmail: organisation.representativeEmail,
      representativeName: organisation.representativeName,
      state: organisation.contactInfo.state,
      tradingName: organisation.tradingName,
    },
    onSubmit: (values) => {
      finalizeAccount(
        {
          ...organisation,
          abn: cleanString(values.abn),
          contactInfo: {
            ...organisation.contactInfo,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            country: values.country,
            phoneNumber: values.phoneNumber,
            postCode: values.postCode,
            state: values.state,
          } as ContactInfoLineItem,
          organisationLogo: tempLogoUrl,
          organisationLogoSrc: values.organisationLogoSrc,
          organisationName: values.organisationName,
          representativeEmail: values.representativeEmail,
          representativeName: values.representativeName,
          tradingName: values.tradingName,
        } as OrganisationLineItem,
        {
          ...userProfile,
          email: values.representativeEmail,
          emailNotifications: values.emailNotifications,
          invited: false,
          isAdmin: true,
          name: values.representativeName,
        } as ProfileLineItem,
      );
    },
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);

        const fileKey = `logos/${organisation.id}/${file.name}`;
        await uploadImage(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          (error: any) => {
            setUploadProgress(0);
            catchSentryError(error);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );

        formik.setFieldValue("organisationLogoSrc", fileKey);

        const urlToImage = URL.createObjectURL(file);
        formik.setFieldValue("organisationLogo", urlToImage);
        setTempLogoUrl(urlToImage);
        setIsUploading(false);
      }
    }
  };

  const checkAbnValidity = async (abn: string) => {
    try {
      const cleanAbn = cleanString(abn);
      if (!validateABN(cleanAbn)) {
        formik.setFieldError("abn", "Not a valid ABN - i.e. 43 665 205 185");
      }
      const org = await searchOrganisationExistsByABN(cleanAbn);
      if (!org || !org.isSetup) {
        formik.validateField("abn");
      }
      formik.setFieldError("abn", "ABN Exists");
    } catch (error) {
      formik.setFieldError("abn", "ABN Validation error");
    }
  };

  const debouncedValidateABN = useCallback(debounce(checkAbnValidity, 300), [
    formik,
  ]);

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  const isDisabled = isOrganisationLoading;
  return (
    <Grid container spacing={2}>
      <PageHeader loading={loading} title="Create Account" />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "20%",
          marginRight: "20%",
          padding: "0px 0px 0px 0px !important",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ margin: "0", padding: "0px" }}>
            <Grid item md={12}>
              <Divider>
                {loading ? (
                  <Skeleton variant="rounded" animation="wave">
                    <Chip label={"Company Details"} variant="outlined" />
                  </Skeleton>
                ) : (
                  <Chip label={"Company Details"} variant="outlined" />
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="abn"
                name="abn"
                label="ABN"
                data-test-id="abn"
                fullWidth
                onChange={async (e) => {
                  const { value } = e.target;
                  formik.setFieldValue("abn", value);
                  if (value && value.length > 0) {
                    debouncedValidateABN(value);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.abn}
                error={formik.touched.abn && Boolean(formik.errors.abn)}
                helperText={formik.touched.abn ? formik.errors.abn : ""}
                InputProps={{
                  endAdornment: isOrganisationLoading ? (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: isOrganisationLoading
                          ? "rgba(0, 0, 0, 0.26)"
                          : undefined,
                      }}
                    >
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ) : undefined,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: isOrganisationLoading
                          ? "rgba(0, 0, 0, 0.26)"
                          : undefined,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="organisationName"
                name="organisationName"
                label="Organisation Name"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organisationName}
                error={
                  formik.touched.organisationName &&
                  Boolean(formik.errors.organisationName)
                }
                helperText={
                  formik.touched.organisationName
                    ? formik.errors.organisationName
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="tradingName"
                name="tradingName"
                label="Trading Name"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tradingName}
                error={
                  formik.touched.tradingName &&
                  Boolean(formik.errors.tradingName)
                }
                helperText={
                  formik.touched.tradingName ? formik.errors.tradingName : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="representativeName"
                name="representativeName"
                label="Representative Name"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.representativeName}
                error={
                  formik.touched.representativeName &&
                  Boolean(formik.errors.representativeName)
                }
                helperText={
                  formik.touched.representativeName
                    ? formik.errors.representativeName
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                id="representativeEmail"
                name="representativeEmail"
                label="Representative Email"
                fullWidth
                disabled={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.representativeEmail}
                error={
                  formik.touched.representativeEmail &&
                  Boolean(formik.errors.representativeEmail)
                }
                helperText={
                  formik.touched.representativeEmail
                    ? formik.errors.representativeEmail
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <Divider>
                {loading ? (
                  <Skeleton variant="rounded" animation="wave">
                    <Chip label={"Contact Info"} variant="outlined" />
                  </Skeleton>
                ) : (
                  <Chip label={"Contact Info"} variant="outlined" />
                )}
              </Divider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="addressLine1"
                name="addressLine1"
                label="Address Line 1"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine1}
                error={
                  formik.touched.addressLine1 &&
                  Boolean(formik.errors.addressLine1)
                }
                helperText={
                  formik.touched.addressLine1 ? formik.errors.addressLine1 : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="addressLine2"
                name="addressLine2"
                label="Address Line 2"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine2}
                error={
                  formik.touched.addressLine2 &&
                  Boolean(formik.errors.addressLine2)
                }
                helperText={
                  formik.touched.addressLine2 ? formik.errors.addressLine2 : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {loading ? (
                  <Skeleton animation="wave" variant="rounded" width={"100%"}>
                    <TextField
                      label="DEFAULT TEXT FIELD CONTENT - LOADING"
                      fullWidth
                    />
                  </Skeleton>
                ) : (
                  <Autocomplete
                    fullWidth
                    id="country"
                    disabled={isDisabled}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    value={Country.getCountryByCode(formik.values.country)}
                    onChange={(e: any, value: ICountry | null) => {
                      formik.setFieldValue("country", value?.isoCode);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        data-test-id="country"
                        {...params}
                        name="country"
                        label="Country"
                        fullWidth
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country ? formik.errors.country : ""
                        }
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                {loading ? (
                  <Skeleton animation="wave" variant="rounded" width={"100%"}>
                    <TextField
                      label="DEFAULT TEXT FIELD CONTENT - LOADING"
                      fullWidth
                    />
                  </Skeleton>
                ) : (
                  <Autocomplete
                    fullWidth
                    id="state"
                    disabled={isDisabled}
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={State.getStateByCodeAndCountry(
                      formik.values.state,
                      formik.values.country,
                    )}
                    onChange={(event) => {
                      const state = states.find(
                        (s) => s.name === event.currentTarget.textContent,
                      );
                      formik.setFieldValue("state", state?.isoCode || "");
                      // formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        data-test-id="state"
                        {...params}
                        name="state"
                        label="State"
                        fullWidth
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={
                          formik.touched.state ? formik.errors.state : ""
                        }
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledTextField
                  loading={loading}
                  disabled={isDisabled}
                  id="city"
                  name="city"
                  fullWidth
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city ? formik.errors.city : ""}
                  label="City"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="postCode"
                name="postCode"
                label="Postcode"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
                error={
                  formik.touched.postCode && Boolean(formik.errors.postCode)
                }
                helperText={
                  formik.touched.postCode ? formik.errors.postCode : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                loading={loading}
                disabled={isDisabled}
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <Divider>
                {loading ? (
                  <Skeleton variant="rounded" animation="wave">
                    <Chip label={"Settings"} variant="outlined" />
                  </Skeleton>
                ) : (
                  <Chip label={"Settings"} variant="outlined" />
                )}
              </Divider>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex !important",
                justifyContent: "left !important;",
              }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  display: "flex !important",
                  justifyContent: "left !important;",
                }}
              >
                <FormLabel component="legend">Upload logo</FormLabel>
                <Box sx={{ flexGrow: 1 }}>
                  <Stack spacing={2}>
                    {isUploading ? (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        {tempLogoUrl ? (
                          <Avatar
                            alt="User 3"
                            src={tempLogoUrl}
                            sx={{ height: 64, width: 64 }}
                          />
                        ) : null}
                        <CircularProgressWithLabel value={uploadProgress} />
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        {tempLogoUrl ? (
                          loading ? (
                            <Skeleton animation="wave">
                              <Avatar
                                alt="User 3"
                                sx={{ height: 64, width: 64 }}
                              />
                            </Skeleton>
                          ) : (
                            <Avatar
                              alt="User 3"
                              src={tempLogoUrl}
                              data-test-id="logo-image"
                              sx={{ height: 64, width: 64 }}
                            />
                          )
                        ) : null}
                        <input
                          ref={fileInput}
                          hidden
                          id="fileUploadInput"
                          accept="image/png, image/jpeg"
                          type="file"
                          style={{
                            cursor: "pointer",
                            height: "100px",
                            opacity: 0,
                            padding: 0.5,
                            position: "fixed",
                            width: "100px",
                            zIndex: 1,
                          }}
                          onChange={onUpload}
                        />
                        {loading ? (
                          <Skeleton animation="wave" variant="circular">
                            <IconButton
                              color="error"
                              sx={{
                                "&:hover ": {
                                  background: theme.palette.primary.dark,
                                  color: theme.palette.primary.light,
                                },
                                background: theme.palette.primary.light,
                                borderColor: "black",
                                color: theme.palette.primary.dark,
                              }}
                              size="large"
                            >
                              <UploadTwoToneIcon
                                sx={{
                                  fontSize: "1.1rem",
                                }}
                              />
                            </IconButton>
                          </Skeleton>
                        ) : (
                          <Tooltip
                            TransitionComponent={Zoom}
                            placement="top"
                            title="Upload"
                          >
                            <IconButton
                              disabled={isDisabled || isUploading}
                              color="error"
                              sx={{
                                "&:hover ": {
                                  background: theme.palette.primary.dark,
                                  color: theme.palette.primary.light,
                                },
                                background: theme.palette.primary.light,
                                borderColor: "black",
                                color: theme.palette.primary.dark,
                              }}
                              size="large"
                              onClick={onFileInput}
                            >
                              <UploadTwoToneIcon
                                sx={{
                                  fontSize: "1.1rem",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                    {formik.touched.organisationLogoSrc &&
                    formik.errors.organisationLogoSrc ? (
                      !loading ? (
                        <Typography color="error">Upload an image</Typography>
                      ) : null
                    ) : !loading ? (
                      <Typography variant="caption">
                        Image size should not exceed 10Mb Max.
                      </Typography>
                    ) : null}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      disabled={loading || formik.isSubmitting}
                      value={formik.values.emailNotifications}
                      onChange={(event, checked) => {
                        formik.setFieldValue("emailNotifications", checked);
                      }}
                    />
                  }
                  label="Email Notifications"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ padding: "12px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex !important",
                justifyContent: "right !important;",
              }}
            >
              <StyledButton
                id="submit_button"
                loading={loading}
                variant="contained"
                type="submit"
              >
                Save
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
