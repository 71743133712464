import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { usePolicySubmission } from "@hooks/crud/policySubmission/usePolicySubmission";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { PackageAnalyticsPolicyTable } from "./PackageAnalyticsPolicyTable";

interface PackageAnalyticsPolicyProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
}
export const PackageAnalyticsPolicy = ({
  contractorPackage,
  isLoading,
  project,
}: PackageAnalyticsPolicyProps) => {
  const { isPolicyLoading, policySubmissions } = usePolicySubmission(
    project?.id,
    contractorPackage?.id,
  );

  const loading = isLoading || isPolicyLoading;
  return (
    <>
      <PackageAnalyticsPolicyTable
        loading={loading}
        project={project}
        contractorPackage={contractorPackage}
        policySubmissions={policySubmissions}
      />
    </>
  );
};
