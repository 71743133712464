"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentOrigin = void 0;
var ContentOrigin;
(function (ContentOrigin) {
    ContentOrigin["Victoria"] = "content-origin_victoria";
    ContentOrigin["NewSouthWales"] = "content-origin_new-south-wales";
    ContentOrigin["Queensland"] = "content-origin_queensland";
    ContentOrigin["SouthAustralia"] = "content-origin_south-australia";
    ContentOrigin["WesternAustralia"] = "content-origin_western-australia";
    ContentOrigin["Tasmania"] = "content-origin_tasmania";
    ContentOrigin["NorthernTerritory"] = "content-origin_northern-territory";
    ContentOrigin["AustralianCapitalTerritory"] = "content-origin_australian-capital-territory";
    ContentOrigin["AllAustralia"] = "content-origin_all-australia";
    ContentOrigin["NewZealand"] = "content-origin_new-zealand";
    ContentOrigin["International"] = "content-origin_international";
})(ContentOrigin || (exports.ContentOrigin = ContentOrigin = {}));
