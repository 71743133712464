import { useCallback, useState, useEffect } from "react";
import { getApiData } from "@hooks/utils/api";
import { decodeSocialSpendAnalyticsModel } from "social-pro-common/decoders/analytics";
import { SocialSpendAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";
import { SocialSpendAnalyticsModel } from "social-pro-common/entities/analytics";
import { catchSentryError } from "@utils/sentry";

export const useSocialSpendAnalytics = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [socialSpendAnalytics, setSocialSpendAnalytics] =
    useState<SocialSpendAnalyticsModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getSocialSpendAnalyticsModel = useCallback(
    async (
      reportId: string,
      projectId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSocialSpendAnalytics",
          "analytics",
          projectId,
          { contractorPackageId, reportId },
        );

        const res = decodeSocialSpendAnalyticsModel(
          analysisResult.data as SocialSpendAnalyticsModel,
        );
        setSocialSpendAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodeSocialSpendAnalyticsModel, catchSentryError],
  );

  useEffect(() => {
    if (reportId && projectId && contractorPackageId) {
      getSocialSpendAnalyticsModel(
        reportId,
        projectId,
        projectId !== contractorPackageId ? contractorPackageId : undefined,
      );
    }
  }, [reportId, projectId, contractorPackageId, getSocialSpendAnalyticsModel]);

  return {
    error,
    isAnalysisLoading,
    socialSpendAnalytics,
  };
};