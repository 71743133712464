"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeGreenMetricSubmission = exports.decodeGreenMetricSubmission = void 0;
var string_1 = require("../../utils/string");
var decodeGreenMetricSubmission = function (greenMetric) {
    return {
        contractorPackageId: greenMetric.contractor_package_id,
        created: new Date(greenMetric.created),
        greenMetricId: greenMetric.green_metric_id,
        id: greenMetric.green_metric_submission_id,
        projectId: greenMetric.project_id,
        reportId: greenMetric.report_id,
    };
};
exports.decodeGreenMetricSubmission = decodeGreenMetricSubmission;
var encodeGreenMetricSubmission = function (greenMetric) {
    var safeGreenMetric = (0, string_1.makePostgresSafe)(greenMetric);
    return {
        contractor_package_id: safeGreenMetric.contractorPackageId,
        created: safeGreenMetric.created,
        green_metric_id: safeGreenMetric.greenMetricId,
        green_metric_submission_id: safeGreenMetric.id,
        project_id: safeGreenMetric.projectId,
        report_id: safeGreenMetric.reportId,
    };
};
exports.encodeGreenMetricSubmission = encodeGreenMetricSubmission;
