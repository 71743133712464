import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { TitleStack } from "../ProjectProgress/TitleStack";
import { DashboardProgressbar } from "../ProjectProgress/DashboardProgressBar";
import { ChipProgress } from "../ProjectProgress/ChipProgress";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { SustainabilityProgressModelLineItem } from "social-pro-common/interfaces/dashboard";
import { LocalContentProgressSubData } from "./SustainabilityProgressSubData";
import { useState } from "react";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";

interface SustainabilityProgressProps {
  loading: boolean;
  projectId?: string;
  packageId?: string;
  reportId?: string;
  sustainabilityProgress?: SustainabilityProgressModelLineItem;
}

export const SustainabilityProgress = ({
  loading,
  packageId,
  projectId,
  reportId,
  sustainabilityProgress,
}: SustainabilityProgressProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid
      item
      md={12}
      sx={{
        marginTop: "16px",
      }}
    >
      <Accordion
        square={true}
        sx={{
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          padding: "5px",
        }}
        disableGutters={true}
        expanded={open}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          setOpen(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={{ color: "#C4D5E7" }} />}
          aria-controls="sustainability-content"
          id="sustainability-panel"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item md={3}>
              <Stack spacing={2}>
                <TitleStack
                  loading={loading}
                  header="Sustainability"
                  subTitle="Overall Performance"
                />
                <ChipProgress
                  loading={loading}
                  value={`+${0}%`}
                  title="previous month"
                  primaryFontColour="#2C9F45"
                  secondaryFontColour="#4F8349"
                  chipColour="#D1F4D9"
                  dataTestId="sustainability-pc-change"
                />
              </Stack>
            </Grid>
            <Grid item md={9}>
              <Stack
                spacing={3}
                sx={{ paddingLeft: "5vw", paddingRight: "1vw", width: "100%" }}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${nFormatter(sustainabilityProgress?.fuelsTotal || 0, 2)} L`}
                      subTitle="Fuels (Litres)"
                      dataTestId="sustainability-total-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${formatDecimalPlaces(
                        sustainabilityProgress?.waterTotal || 0,
                        2,
                      )}%`}
                      subTitle="Water Usage"
                      dataTestId="water-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${formatDecimalPlaces(
                        sustainabilityProgress?.wasteTotal || 0,
                        2,
                      )}%`}
                      subTitle="Waste (Landfil, Reused, Soil)"
                      dataTestId="waste-value"
                    />
                  </Grid>
                </Grid>
                <DashboardProgressbar
                  loading={loading}
                  progress={0}
                  startDate={"0%"}
                  endDate={"100%"}
                  progressTestId="sustainability-progress-bar"
                  prefixTestId="sustainability-start"
                  suffixTestId="sustainability-end"
                />
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {open ? (
            <LocalContentProgressSubData
              loading={loading}
              projectId={projectId}
              packageId={packageId}
              reportId={reportId}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
