import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ViewTrainingContractForm } from "@stories/molecules/ViewTrainingContractDetail/ViewTrainingContractDetail";

interface ViewTrainingContractModalProps {
  open: boolean;
  uniqueStudentId?: string;
  traineeNumberValue?: string;
  qualificationValue?: string;
  educationalInstituteValue?: string;
  traineeContractEndDate?: Date;
  setOpen: (value: boolean) => void;
}

export default function ViewTrainingContractModal({
  educationalInstituteValue,
  open,
  qualificationValue,
  setOpen,
  traineeContractEndDate,
  traineeNumberValue,
  uniqueStudentId,
}: ViewTrainingContractModalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {"Training Contract Details"}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <ViewTrainingContractForm
          uniqueStudentId={uniqueStudentId}
          traineeNumberValue={traineeNumberValue}
          qualificationValue={qualificationValue}
          educationalInstituteValue={educationalInstituteValue}
          traineeContractEndDate={traineeContractEndDate}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
