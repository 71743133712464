import LAYOUT_CONST from "constant";
// types
import { ConfigProps } from "types/config";

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

export const DASHBOARD_PATH = "/";
export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
  borderRadius: 12,
  container: false,
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: "'Roboto', serif",
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  navType: "light", // light, dark
  outlinedFilled: true,
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  rtlLayout: false,
};

export default config;