import * as RouteHelper from "@utils/routes";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack } from "@mui/material";
import { WizardSteps } from "@stories/molecules/WizardSteps/WizardSteps";
import { useEffect, useState } from "react";
import { LabourHours } from "../../organisms/LabourHours/LabourHours";
import { useNavigate, useParams } from "react-router-dom";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { SocialSpends } from "@stories/organisms/SocialSpend/SocialSpend";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import WizardCompleteModal from "./WizardCompleteModal";
import { ReportConfirmation } from "@stories/organisms/ReportConfirmation/ReportConfirmation";
import { usePackageComments } from "@hooks/crud/packageComments/usePackageComments";
import { createDefaultReportSubmission } from "social-pro-common/interfaces/reportSubmission";
import {
  PackageCommentLineItem,
  createDefaultPackageComment,
} from "social-pro-common/interfaces/packageComment";
import { PackageLidpSubmissionForm } from "@stories/molecules/PackageLidpSubmissionForm/PackageLidpSubmissionForm";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { useReportDocument } from "@hooks/crud/reportDocument/useReportDocument";
import { useReportSocialSubmission } from "@hooks/crud/reportSocialSubmission/useReportSocialSubmission";

export const Wizard = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  if (!reportId) {
    throw Error("Not report number");
  }

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { comments, upsertPackageComments } = usePackageComments(
    selectedProject?.id,
    reportId,
    contractorPackage?.id,
  );

  const {
    createReportDocument,
    isReportDocumentsLoading,
    reportDocuments,
    updateReportDocument,
  } = useReportDocument(contractorPackage?.id, reportId);

  const { createReportSubmission, isReportLoading } =
    useReportSocialSubmission();

  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commentMap, setCommentMap] = useState(
    new Map<PackageCommentType, string>(),
  );

  //TODO: Fix this a bit better
  const handleUpdateComment = async (newComment: PackageCommentLineItem) => {
    setIsSubmitting(() => true);
    await upsertPackageComments([newComment]);
    setIsSubmitting(() => false);
  };

  useEffect(() => {
    if (comments) {
      const commentMap = new Map<PackageCommentType, string>();
      comments.forEach((comment) => {
        commentMap.set(comment.commentType, comment.comment);
      });
      setCommentMap(commentMap);
    }
  }, [comments]);

  const updateComment = async (
    commentType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => {
    if (selectedProject && contractorPackage && reportId) {
      if (save) {
        const matchingPackageComment = comments.find(
          (c) => c.commentType === commentType,
        );
        if (matchingPackageComment) {
          handleUpdateComment({
            ...matchingPackageComment,
            comment: comment,
          });
        } else {
          handleUpdateComment(
            createDefaultPackageComment(
              selectedProject?.id,
              contractorPackage?.id,
              reportId,
              commentType,
              comment,
            ),
          );
        }
      }
      setCommentMap((currentValue) => {
        return new Map(currentValue.set(commentType, comment));
      });
    }
  };

  const loading =
    (isAuthProfileLoading ||
      isProjectLoading ||
      isReportDocumentsLoading ||
      isReportLoading) &&
    !isSubmitting;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async () => {
    if (contractorPackage) {
      const reportSubmission = createDefaultReportSubmission(
        contractorPackage.projectId,
        contractorPackage.id,
        reportId,
      );
      reportSubmission.reportStatus = ReportStatus.Complete;
      await createReportSubmission(reportSubmission);
      setShowSuccess(true);
    }
  };

  const wizardComponents = [];
  if (contractorPackage) {
    if (contractorPackage?.commitmentsHours?.length) {
      wizardComponents.push(
        <LabourHours
          isLoading={loading}
          project={selectedProject}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          userOrganisation={userOrganisation}
          contractorPackageId={contractorPackage?.id}
          commentMap={commentMap}
          reportId={reportId}
          reportDocuments={reportDocuments}
          createReportDocument={createReportDocument}
          updateReportDocument={updateReportDocument}
          updateComment={updateComment}
        />,
      );
    }
    if (contractorPackage?.commitmentsSpend?.length) {
      wizardComponents.push(
        <SocialSpends
          isLoading={loading}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          selectedProject={selectedProject}
          contractorPackageId={contractorPackage?.id}
          reportId={reportId}
          commentMap={commentMap}
          userOrganisation={userOrganisation}
          updateComment={updateComment}
        />,
      );
    }
    if (selectedProject?.localContents?.length) {
      wizardComponents.push(
        <PackageLidpSubmissionForm
          isLoading={loading}
          isSubmitting={isSubmitting}
          project={selectedProject}
          contractorPackageId={contractorPackage?.id}
          isPrimaryPackage={isPrimaryPackage}
          reportId={reportId}
          updateComment={updateComment}
          commentMap={commentMap}
          packageValue={
            isPrimaryPackage
              ? selectedProject.financial.totalValue || 0
              : contractorPackage.financial.totalValue || 0
          }
          packageType={contractorPackage.packageType}
        />,
      );
    }
  }

  wizardComponents.push(
    <ReportConfirmation
      loading={loading}
      project={selectedProject}
      reportId={reportId}
    />,
  );

  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className="steps"
          sx={{ margin: "0", padding: "30px 20px" }}
        >
          <WizardSteps
            activeStep={activeStep}
            loading={loading}
            project={selectedProject}
            contractorPackage={contractorPackage}
          />
        </Grid>

        {selectedProject && contractorPackage ? selectedWizardStep : undefined}
        {!selectedProject || !contractorPackage ? (
          <LabourHours
            isLoading={true}
            project={selectedProject}
            isSubmitting={isSubmitting}
            contractorPackageId={contractorPackage?.id}
            setIsSubmitting={setIsSubmitting}
            commentMap={commentMap}
            reportId={reportId}
            updateComment={updateComment}
            reportDocuments={reportDocuments}
            createReportDocument={createReportDocument}
            updateReportDocument={updateReportDocument}
          />
        ) : undefined}
        <Grid
          container
          sx={{
            display: "flex !important",
            justifyContent: "space-between",
            padding: "0 40px 80px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack direction="row" spacing={1}>
              {activeStep > 0 ? (
                <StyledButton
                  loading={loading}
                  onClick={handleBack}
                  variant="outlined"
                >
                  Back
                </StyledButton>
              ) : null}

              {activeStep < wizardComponents.length - 1 ? (
                <StyledButton loading={loading} onClick={handleNext}>
                  Next
                </StyledButton>
              ) : (
                <StyledButton
                  loading={loading}
                  disabled={loading}
                  variant="contained"
                  onClick={onSubmit}
                >
                  Submit
                </StyledButton>
              )}
            </Stack>
          </Grid>
        </Grid>
        {showSuccess ? (
          <WizardCompleteModal
            handleClose={() => {
              setShowSuccess(false);
              if (selectedProject) {
                navigate(
                  RouteHelper.contractorPackageReports(selectedProject?.id),
                );
              }
            }}
          />
        ) : null}
      </Grid>
    </>
  );
};
