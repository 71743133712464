import dayjs from "dayjs";
import { StyledDatePicker } from "../StyledDatePicker/StyledDatePicker";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { Skeleton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
interface MonthSelectProps {
  loading: boolean;
  isPrimaryPackage: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  date?: Date;
  disabled?: boolean;
  setDate: (date: Date) => void;
}

export const MonthSelect = ({
  contractorPackage,
  date,
  disabled,
  isPrimaryPackage,
  loading,
  project,
  setDate,
}: MonthSelectProps) => {
  const startDate = isPrimaryPackage
    ? project?.projectStartDate
    : contractorPackage?.packageStartDate;
  const endDate = isPrimaryPackage
    ? project?.projectEndDate
    : contractorPackage?.packageEndDate;

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" height={40}>
        <DatePicker
          label={"Reporting Month"}
          loading={loading}
          disabled={disabled}
          slotProps={{
            textField: {
              error: false,
              margin: "dense",
              size: "small",
            },
          }}
        />
      </Skeleton>
    );
  }
  return (
    <StyledDatePicker
      loading={loading}
      disabled={disabled}
      label={"Reporting Month"}
      openTo="month"
      views={["year", "month"]}
      value={dayjs(date)}
      minDate={dayjs(startDate)}
      maxDate={dayjs(endDate)}
      onChange={(newValue: dayjs.Dayjs) => {
        if (newValue) {
          setDate(newValue?.toDate());
        }
      }}
      slotProps={{
        textField: {
          "data-test-id": "primary-month-select",
          error: false,
          margin: "dense",
          size: "small",
        },
      }}
    />
  );
};
