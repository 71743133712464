import { ProjectPolicyLineItem } from "social-pro-common/interfaces/projectPolicy";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { PolicyForm } from "@stories/molecules/PolicyForm/PolicyForm";

interface PolicyModalProps {
  open: boolean;
  loading: boolean;
  projectPolicy: ProjectPolicyLineItem;
  createPolicy: (policy: ProjectPolicyLineItem) => void;
  handleClose: () => void;
}

export default function PolicyModal({
  createPolicy,
  handleClose,
  loading,
  open,
  projectPolicy,
}: PolicyModalProps) {
  const onSubmit = async (policy: ProjectPolicyLineItem) => {
    if (policy) {
      await createPolicy(policy);
      handleClose();
    }
  };

  const title = "Create New Policy";

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <PolicyForm
            policy={projectPolicy}
            handleSubmit={onSubmit}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
