import { downloadFile } from "@hooks/utils/useUpload";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import {
  Grid,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { getDocumentName } from "social-pro-common/utils/string";
import { useState } from "react";
import { SortInfo, TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { DEFAULT_SOCIAL_SPEND_LIMIT } from "social-pro-common/entities/socialSpend";

interface ToolsSocialSpendTableProps {
  loading: boolean;
  socialSpends: SocialSpendLineItem[];
  socialSpendCount: number;
  sortInfo: SortInfo;
  setPage: (page: number) => void;
}

export const ToolsSpendTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center" className="center">
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <StyledButton className="blackBtn" loading={true}>
            Download
          </StyledButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};

export const ToolsSocialSpendTable = ({
  loading,
  setPage,
  socialSpendCount,
  socialSpends,
  sortInfo,
}: ToolsSocialSpendTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const onDownload = async (uri: string, spendName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, spendName);
    setIsDownloading(false);
  };

  // const TABLE_HEAD: TableHeaderCell[] = [
  //   {
  //     id: "transaction-date",
  //     label: "Transaction Date",
  //     align: "left",
  //     width: "15%",
  //   },
  //   {
  //     id: "subcontractor",
  //     label: "Subcontractor",
  //     align: "left",
  //     width: "25%",
  //   },
  //   { id: "supplier", label: "Supplier", align: "left", width: "25%" },
  //   {
  //     id: "amount",
  //     label: "Amount",
  //     align: "center",
  //     width: "20%",
  //   },
  //   { id: "", width: "15%" },
  // ];

  const tableHeader = (
    <TableHead>
      <TableHeader
        loading={loading}
        title={"Transaction Date"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 0,
        }}
        sx={{ width: "15%" }}
      />
      <TableHeader
        loading={loading}
        title={"Subcontractor"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 1,
        }}
        sx={{ width: "25%" }}
      />
      <TableHeader
        loading={loading}
        title={"Supplier"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 2,
        }}
        sx={{ width: "25%" }}
      />
      <TableHeader
        loading={loading}
        title={"Amount"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 3,
        }}
        sx={{ width: "20%" }}
      />
      <TableHeader loading={loading} title={""} sx={{ width: "15%" }} />
    </TableHead>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        {loading ? (
          <Table>
            {tableHeader}
            <TableBody>
              {loading
                ? Array.from({ length: 10 }, (_, index) => (
                    <ToolsSpendTableRowSkeleton key={index} />
                  ))
                : null}
            </TableBody>
          </Table>
        ) : (
          <Table data-test-id="spend-table">
            {tableHeader}
            <TableBody>
              {socialSpends.map((sp, index) => (
                <TableRow key={`${index}-social-spend-table-row`}>
                  <TableCell>{`${sp.transactionDate.toLocaleDateString(
                    "en-GB",
                  )}`}</TableCell>
                  <TableCell>{`${
                    sp.organisationName || sp.contractorPackageId
                  }`}</TableCell>
                  <TableCell>{`${sp.supplier}`}</TableCell>
                  <TableCell>{`$${sp.invoiceValue}`}</TableCell>
                  <TableCell align="right" className="right">
                    <StyledButton
                      loading={false}
                      className="blackBtn"
                      disabled={isDownloading || !sp.uri}
                      onClick={() =>
                        onDownload(
                          sp.uri,
                          getDocumentName(
                            `${
                              sp.supplier
                            }-${sp.transactionDate.toLocaleDateString(
                              "en-GB",
                            )}`,
                            sp.uri,
                          ),
                        )
                      }
                    >
                      Download
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))}
              {socialSpends.length === 0 ? (
                <NoRows title={"Spend"} colSpan={5} />
              ) : null}
            </TableBody>
          </Table>
        )}
      </Grid>
      {socialSpendCount > DEFAULT_SOCIAL_SPEND_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(socialSpendCount / DEFAULT_SOCIAL_SPEND_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </>
  );
};
