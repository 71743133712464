import { Grid } from "@mui/material";
import { useOrganisationLabourHourSubData } from "@hooks/crud/organisationDashboard/useOrganisationLabourHourSubData";
import { TripleTargetStack } from "../../Dashboard/ProjectProgress/TripleTargetStack";

interface OrganisationLabourHourProgressSubDataProps {
  loading: boolean;
}

export const OrganisationLabourHourProgressSubData = ({
  loading,
}: OrganisationLabourHourProgressSubDataProps) => {
  const { isAnalysisLoading, labourHourSubData } =
    useOrganisationLabourHourSubData();
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <TripleTargetStack
        loading={isLoading}
        targets={labourHourSubData?.targets || []}
        title={"Targets"}
        firstColumnTitle={"Category"}
        thirdColumnTitle={"Head Count"}
        secondColumnTitle={"Hours"}
        decimalPlaces={0}
        rightMargin
        dataTestId={"labour-hour-targets"}
      />
      <TripleTargetStack
        loading={isLoading}
        targets={labourHourSubData?.projects || []}
        title={"Projects"}
        firstColumnTitle={"Name"}
        secondColumnTitle={"Hours"}
        secondaryUnits="%"
        thirdColumnTitle={"%"}
        leftMargin
        dataTestId={"labour-hour-projects"}
      />
    </Grid>
  );
};
