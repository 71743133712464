"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultReportDocument = void 0;
var uuid_1 = require("uuid");
var createDefaultReportDocument = function (contractorPackageId, reportId, uri, reportDocumentType) {
    return {
        contractorPackageId: contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        reportDocumentType: reportDocumentType,
        reportId: reportId,
        uri: uri,
    };
};
exports.createDefaultReportDocument = createDefaultReportDocument;
