import * as ExcelJs from "exceljs";
import { SocialSpendTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import {
  SocialCommitmentTypeSpend,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getSocialSpendFactors } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import {
  createDefaultSocialSpend,
  socialSpendCertificateToString,
  socialSpendEntityTypeToString,
  SocialSpendLineItem,
  socialSpendPurchaserTypeToString,
  socialSpendServiceCategoryToString,
  stringImportNatureOfExpenditure,
  stringImportState,
  stringImportToCertification,
  stringImportToEntityType,
  stringImportToPurchaserType,
} from "social-pro-common/interfaces/socialSpend";
import { validateABN } from "social-pro-common/utils/abn";
import { isValidDate } from "social-pro-common/utils/date";
import {
  formatDecimalPlaces,
  isValidNumber,
} from "social-pro-common/utils/number";

interface XlsxSocialSpendResult {
  socialSpend: SocialSpendLineItem[];
  errorMessage?: string;
}

export const readXlsxFileToSocialSpend = async (
  userOrganisation: OrganisationLineItem,
  project: ProjectLineItem,
  contractorPackageId: string,
  reportId: string,
  file: File,
): Promise<XlsxSocialSpendResult> => {
  const DATA_START_ROW = 12;
  let errorMessage: string;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data).then((workbook: ExcelJs.Workbook) => {
          const socialSpend = [] as SocialSpendLineItem[];
          let count = 0;
          workbook.eachSheet((sheet: ExcelJs.Worksheet) => {
            if (count == 0) {
              sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                if (rowIndex >= DATA_START_ROW) {
                  const values = row.values as ExcelJs.CellValue[];
                  let supplierAbn = values[2] as string | number | undefined;
                  if (supplierAbn && typeof supplierAbn !== "string") {
                    supplierAbn = supplierAbn.toString();
                  }

                  if (
                    supplierAbn &&
                    (supplierAbn as string).length > 0 &&
                    supplierAbn !==
                      "Insert rows to enter more data as required" &&
                    validateABN(supplierAbn as string)
                  ) {
                    const newSocialSpend = createDefaultSocialSpend(
                      project.id,
                      contractorPackageId,
                      reportId,
                      userOrganisation,
                    );
                    newSocialSpend.abn = supplierAbn as string;
                    const supplier = values[3] as string;
                    if (!supplier) {
                      reject(`Error reading supplier on line ${rowIndex}`);
                    }
                    newSocialSpend.supplier = supplier;

                    newSocialSpend.supplierCountry =
                      project.projectAddress.country;

                    const supplierState = values[4] as string;
                    if (!supplierState) {
                      reject(
                        `Error reading supplier state on line ${rowIndex}`,
                      );
                    }
                    newSocialSpend.supplierState = stringImportState(
                      supplierState || "",
                    );

                    const postCode = values[5] as string;
                    if (!postCode) {
                      reject(`Error reading postcode on line ${rowIndex}`);
                    }
                    newSocialSpend.supplierPostCode = values[5] as string;

                    const vicPostCode = values[6] as string;
                    if (!vicPostCode) {
                      reject(
                        `Error reading Victorian postcode on line ${rowIndex}`,
                      );
                    }
                    newSocialSpend.victorianPostCode = vicPostCode;

                    const purchaseType = values[7] as string;
                    const parsedPurchaseType = stringImportToPurchaserType(
                      purchaseType || "",
                    );
                    if (!parsedPurchaseType) {
                      reject(
                        `Error reading purchaser type on line ${rowIndex}`,
                      );
                    } else {
                      newSocialSpend.purchaseType = parsedPurchaseType;
                    }
                    const purchaserOrganisationName = values[8] as string;
                    if (!purchaserOrganisationName) {
                      reject(
                        `Error reading purchaser organisation name on line ${rowIndex}`,
                      );
                    }
                    newSocialSpend.purchaserOrganisationName =
                      purchaserOrganisationName;

                    const purchaserOrganisationAbn = values[9] as string;
                    if (!purchaserOrganisationAbn) {
                      reject(
                        `Error reading purchaser organisation abn on line ${rowIndex}`,
                      );
                    }
                    newSocialSpend.purchaserOrganisationAbn =
                      purchaserOrganisationAbn;

                    const certifications = values[10] as string;
                    if (certifications) {
                      newSocialSpend.certifications = [];
                      const parsedCertificate = stringImportToCertification(
                        certifications || "",
                      );
                      if (parsedCertificate) {
                        newSocialSpend.certifications.push(parsedCertificate);
                      }
                    }
                    const entityType = values[11] as string;
                    if (entityType) {
                      const parsedEntityType = stringImportToEntityType(
                        entityType || "",
                      );
                      if (!parsedEntityType) {
                        reject(`Error reading entity type on line ${rowIndex}`);
                      } else {
                        newSocialSpend.entityType = parsedEntityType;
                      }
                    }
                    const transactionDate = values[12] as Date;
                    if (!transactionDate || !isValidDate(transactionDate)) {
                      reject(
                        `Error reading transaction date on line ${rowIndex}`,
                      );
                    }
                    newSocialSpend.transactionDate = transactionDate;
                    const invoiceValue = values[13] as any;
                    if (!isValidNumber(invoiceValue)) {
                      reject(`Error reading invoice value on line ${rowIndex}`);
                    }
                    newSocialSpend.invoiceValue = invoiceValue;
                    const natureOfExpenditure = values[14] as any;
                    if (natureOfExpenditure) {
                      const parsedNatureOfExpenditure =
                        stringImportNatureOfExpenditure(natureOfExpenditure);
                      if (!parsedNatureOfExpenditure) {
                        reject(
                          `Error reading nature of expenditure on line ${rowIndex}`,
                        );
                      } else {
                        newSocialSpend.natureOfExpenditure =
                          parsedNatureOfExpenditure;
                      }
                    }
                    newSocialSpend.socialFactors = getSocialSpendFactors(
                      newSocialSpend,
                      project,
                    ).map((sf) => sf.id);

                    socialSpend.push(newSocialSpend);
                  }
                }
              });
            }
            count += 1;
          });
          resolve({ errorMessage, socialSpend });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const writeXlsxSocialSpend = async (
  targetName: string,
  socialSpend: SocialSpendLineItem[],
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const workbook = new ExcelJs.Workbook();
        const workSheet = workbook.addWorksheet("Social Spend");
        workSheet.columns = [
          "Transaction Date",
          "Contractor",
          "Supplier",
          "Nature of Expenditure",
          "Invoice Value",
          "Country",
          "Postcode",
          "Purchaser Type",
          "Service Category",
          "Entity Type",
          "Certifications",
        ];
        workSheet.addRow([
          "Transaction Date",
          "Contractor",
          "Supplier",
          "Nature of Expenditure",
          "Invoice Value",
          "Country",
          "Postcode",
          "Purchaser Type",
          "Service Category",
          "Entity Type",
          "Certifications",
        ]);
        for (const ss of socialSpend) {
          workSheet.addRow([
            ss.transactionDate.toLocaleDateString(),
            ss.organisationName,
            ss.supplier,
            ss.supplierCountry,
            ss.supplierPostCode,
            socialSpendPurchaserTypeToString(ss.purchaseType),
            socialSpendServiceCategoryToString(ss.natureOfExpenditure),
            socialSpendEntityTypeToString(ss.entityType),
            `$${ss.invoiceValue}`,
            ss.certifications
              .map((c) => socialSpendCertificateToString(c))
              .join(", "),
          ]);
        }

        const data = await workbook.xlsx.writeBuffer();
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Social-Pro-Labour-Hour-${targetName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });
};

export const writeXlsxSocialSpendTargets = async (
  socialSpend: SocialSpendTargetAnalyticsLineItem[],
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const workbook = new ExcelJs.Workbook();
        const workSheet = workbook.addWorksheet("Social Spend");
        workSheet.columns = [
          "Type",
          "Target (Hours)",
          "To Date (Hours)",
          "Reported (Hours)",
          "Progress",
        ];
        workSheet.addRow([
          "Type",
          "Target (Hours)",
          "To Date (Hours)",
          "Reported (Hours)",
          "Progress",
        ]);
        for (const labourHour of socialSpend) {
          workSheet.addRow([
            labourHour.targetDescription ||
              socialSpendCommitmentTypeToString(
                labourHour.targetType as SocialCommitmentTypeSpend,
              ),
            formatDecimalPlaces(labourHour.targetValue),
            formatDecimalPlaces(labourHour.totalReportedValue),
            formatDecimalPlaces(labourHour.monthReportedValue),
            `${formatDecimalPlaces(labourHour.progress)}%`,
          ]);
        }

        const data = await workbook.xlsx.writeBuffer();
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Social-Pro-Social-Spend-Targets.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });
};
