"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricCategorySubmission = void 0;
var uuid_1 = require("uuid");
var createDefaultGreenMetricCategorySubmission = function (greenMetricCategoryId, projectId, contractorPackageId, reportId) {
    return {
        contractorPackageId: contractorPackageId,
        created: new Date(),
        greenMetricCategoryId: greenMetricCategoryId,
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId: reportId,
        reportValue: 0,
    };
};
exports.createDefaultGreenMetricCategorySubmission = createDefaultGreenMetricCategorySubmission;
