"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPoliciesForProject = exports.projectLevelPolicies = exports.createDefaultProjectPolicy = exports.policyTypeToStringShort = exports.policyTypeToDescription = exports.policyTypeToString = void 0;
var uuid_1 = require("uuid");
var projectPolicy_1 = require("../../entities/projectPolicy");
var policyTypeToString = function (socialCommitmentType) {
    switch (socialCommitmentType) {
        case projectPolicy_1.PolicyType.DefaultPolicy:
            return "Default Policy";
        case projectPolicy_1.PolicyType.FamilyViolencePolicy:
            return "Family Violence Leave Policy";
        case projectPolicy_1.PolicyType.EnvironmentalSustainabilityPolicy:
            return "Environmental Sustainability Policy";
        case projectPolicy_1.PolicyType.EntityLevelGenderEqualityActionPlan:
            return "Organisational Level Gender Equality Action Plan";
        case projectPolicy_1.PolicyType.ProjectLevelGenderEqualityActionPlan:
            return "Project Level Gender Equality Action Plan";
        case projectPolicy_1.PolicyType.FairJobsCode:
            return "Fair Jobs Code";
    }
};
exports.policyTypeToString = policyTypeToString;
var policyTypeToDescription = function (socialCommitmentType) {
    switch (socialCommitmentType) {
        case projectPolicy_1.PolicyType.DefaultPolicy:
            return "Default Policy";
        case projectPolicy_1.PolicyType.FamilyViolencePolicy:
            return "An entity\u2019s business-wide policy for entitlements offered to employees who are experiencing family and domestic violence to deal with the impacts of this violence where it is not practical to do so outside their work hours. \n\n      This might include making arrangements for their own or a family member\u2019s safety (including relocation), attending court or accessing police services, or attending counselling, or appointments with medical, financial or legal professionals. \n      \n      Family and domestic violence refers to violent, threatening or other abusive behaviour that seeks to coerce or control the employee and causes them harm or fear.";
        case projectPolicy_1.PolicyType.EnvironmentalSustainabilityPolicy:
            return "An entity\u2019s business-wide policy being a commitment to stakeholders around complying with legal obligations, minimising pollution, and applying a systematic environmental management approach that is consistent with the international standard ISO 14001. A robust policy will address the following environmental management issues:\n      Energy use\n      Greenhouse gas emissions and ozone depleting substances\n      Suppliers, produces and materials use\n      Office waste, building waste, and resource recovery\n      Potable water use and waste water";
        case projectPolicy_1.PolicyType.EntityLevelGenderEqualityActionPlan:
            return "An organisation’s business-wide policy to plan, implement and measure change in order to achieve gender equality in the workplace. The policy must include results of a workplace gender audit to assess the current state of gender equality in the workplace, and strategies and measures to improve gender equality in the workplace, based on the results of the audit.";
        case projectPolicy_1.PolicyType.ProjectLevelGenderEqualityActionPlan:
            return "A document outlining strategies to to support gender equality at the project level by:\n      Understanding the importance of gender equality\n      Identifying where change is needed\n      Planning and implementing actions to achieve progress\n      Measuring change over time.\n      \n      Different strategies apply at the project level compared to the organisational level, i.e. the gender pay gap of an organisation and a project will differ and will require different targets to eliminate or close the gap.";
        case projectPolicy_1.PolicyType.FairJobsCode:
            return "A code introduced by the Victorian Government designed to promote labour standards and encourage compliance with employment, industrial relations and workplace health and safety obligations.";
    }
};
exports.policyTypeToDescription = policyTypeToDescription;
var policyTypeToStringShort = function (socialCommitmentType) {
    switch (socialCommitmentType) {
        case projectPolicy_1.PolicyType.DefaultPolicy:
            return "Default Policy";
        case projectPolicy_1.PolicyType.FamilyViolencePolicy:
            return "FVP";
        case projectPolicy_1.PolicyType.EnvironmentalSustainabilityPolicy:
            return "ESP";
        case projectPolicy_1.PolicyType.EntityLevelGenderEqualityActionPlan:
            return "ELGEAP";
        case projectPolicy_1.PolicyType.ProjectLevelGenderEqualityActionPlan:
            return "PLGEAP";
        case projectPolicy_1.PolicyType.FairJobsCode:
            return "FJC";
    }
};
exports.policyTypeToStringShort = policyTypeToStringShort;
var createDefaultProjectPolicy = function (projectId) {
    return {
        created: new Date(),
        id: (0, uuid_1.v4)(),
        isProjectLevelPolicy: false,
        policyType: projectPolicy_1.PolicyType.DefaultPolicy,
        projectId: projectId,
    };
};
exports.createDefaultProjectPolicy = createDefaultProjectPolicy;
exports.projectLevelPolicies = [
    projectPolicy_1.PolicyType.FairJobsCode,
    projectPolicy_1.PolicyType.ProjectLevelGenderEqualityActionPlan,
    projectPolicy_1.PolicyType.EntityLevelGenderEqualityActionPlan,
];
var createDefaultPoliciesForProject = function (projectId) {
    var defaultPolicies = Object.values(projectPolicy_1.PolicyType).reduce(function (acc, p) {
        if (p !== projectPolicy_1.PolicyType.DefaultPolicy) {
            return __spreadArray(__spreadArray([], acc, true), [
                {
                    created: new Date(),
                    id: (0, uuid_1.v4)(),
                    isProjectLevelPolicy: exports.projectLevelPolicies.includes(p),
                    policyType: p,
                    projectId: projectId,
                },
            ], false);
        }
        return __spreadArray([], acc, true);
    }, []);
    return defaultPolicies;
};
exports.createDefaultPoliciesForProject = createDefaultPoliciesForProject;
