import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  EmployeeLineItem,
  genderToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";

interface EmployeeTableRowProps {
  employee: EmployeeLineItem;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEditEmployee: (employee: EmployeeLineItem) => void;
  handleArchiveEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeTableRow = ({
  employee,
  handleArchiveEmployee,
  handleEditEmployee,
  handleOpenListModal,
}: EmployeeTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow key={employee.id}>
        <TableCell
          align="left"
          scope="row"
          onClick={() => {
            handleOpenListModal(employee);
          }}
        >
          {`${employee.employeeName}${
            employee.employeeIdentifier
              ? ` (${employee.employeeIdentifier})`
              : ""
          }`}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
        >
          {employee.primaryEmployer}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
        >
          {employee.occupation}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
        >
          {occupationTypeOptionToString(employee.occupationType)}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => {
            handleOpenListModal(employee);
          }}
        >
          {genderToString(employee.gender)}
        </TableCell>
        <TableCell align="right" className="right">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Button
              color="primary"
              size="large"
              onClick={() => handleOpenListModal(employee)}
              className="blackBtn"
              sx={{ color: "white", fontSize: "12px" }}
            >
              View
            </Button>

            <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
              <IconButton
                color="primary"
                size="large"
                disabled={employee.archived}
                onClick={() => handleEditEmployee(employee)}
              >
                <EditIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>

            {!employee.archived ? (
              <Tooltip
                placement="top"
                title="Archive"
                TransitionComponent={Zoom}
              >
                <IconButton
                  color="error"
                  size="large"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  <DeleteIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title="Restore"
                TransitionComponent={Zoom}
              >
                <IconButton
                  color="error"
                  size="large"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  <RestoreIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={
          !employee.archived
            ? "Are you sure you want to archive this employee?"
            : "Are you sure you want to restore this employee?"
        }
        open={openConfirmDialog}
        title={!employee.archived ? "Archive Employee" : "Restore Employee"}
        buttonText={!employee.archived ? "Archive" : "Restore"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleArchiveEmployee(employee)}
      />
    </>
  );
};
