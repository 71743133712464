import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Skeleton, Typography } from "@mui/material";
import { ContractorAnalyticsLabourHours } from "@stories/molecules/ContractorAnalyticsLabourHours/ContractorAnalyticsLabourHours";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { useLabourHourAnalytics } from "@hooks/crud/analytics/useLabourHourAnalytics";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import DownloadIcon from "@mui/icons-material/Download";

interface PackageDetailsHoursProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  reportId: string;
  onLabourHourTargetChange: (targetId: string) => void;
}

export const PackageDetailsHours = ({
  contractorPackage,
  isLoading,
  onLabourHourTargetChange,
  reportId,
}: PackageDetailsHoursProps) => {
  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAnalysisLoading, labourHoursAnalytics } = useLabourHourAnalytics(
    reportId,
    selectedProject?.id,
    contractorPackage?.id,
  );

  const loading = isLoading || isProjectLoading || isAnalysisLoading;

  return (
    <>
      {/* <Grid
        container
        className="analytic-table"
        sx={{
          display: "flex",
          padding: "0 !important",
          margin: "0 0 15px",
          width: "100%",
        }}
      > */}
      {/* <Grid item xs={12} md={6}> */}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        {loading ? (
          <Skeleton animation="wave">
            <Typography variant="h2">Labour Hours</Typography>
          </Skeleton>
        ) : (
          <Typography variant="h2">Labour Hours</Typography>
        )}
        {/* </Grid> */}
        {/* <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}> */}
        <StyledButton
          data-test-id="export-targets-button"
          className="blackBtn grey-outline-btn"
          loading={loading}
          disabled={loading || !labourHoursAnalytics?.targets.length}
          variant="contained"
          onClick={() => {
            if (labourHoursAnalytics) {
              writeXlsxLabourHourTargets(labourHoursAnalytics.targets);
            }
          }}
          startIcon={<DownloadIcon />}
        >
          Export Targets
        </StyledButton>
      </div>
      {/* </Grid> */}
      {/* </Grid> */}
      <Grid item xs={12} md={12} className="analytic-table">
        <ContractorAnalyticsLabourHours
          loading={loading}
          labourHourAnalytics={labourHoursAnalytics}
          onLabourHourTargetChange={onLabourHourTargetChange}
        />
      </Grid>
    </>
  );
};
