import { Autocomplete, Grid, Skeleton, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { ProfileGroupTable } from "@stories/molecules/ProfileGroupTable/ProfileGroupTable";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";
import {
  ProfileProjectAccessLineItem,
  profileProjectAccessLevelToString,
} from "social-pro-common/interfaces/profileProjectAccess";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

interface ProfileGroupsProps {
  loading: boolean;
  searchQuery?: string;
  projects: ProjectDetailLineItem[];
  profiles: ProfileLineItem[];
  profileProjectAccessLevels: ProfileProjectAccessLineItem[];
  isProfileProjectAccessLoading: boolean;
  updateProfileProjectAccess: (
    accessItem: ProfileProjectAccessLineItem,
  ) => void;
}

export const ProfileGroups = ({
  loading,
  profileProjectAccessLevels,
  profiles,
  projects,
  searchQuery,
  updateProfileProjectAccess,
}: ProfileGroupsProps) => {
  const [selectedProject, setSelectedProject] =
    useState<ProjectDetailLineItem>();

  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<ProfileProjectAccessLevel>();

  const isLoading = loading;

  const filteredProfiles = profiles
    .filter((profile) => !profile.isAdmin)
    .filter((profile) => {
      if (searchQuery) {
        return profile.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return true;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0px 40px 0",
        }}
      >
        <Grid
          item
          xs={6}
          md={6}
          sx={{
            display: "flex",
            padding: "0 !important",
            textAlign: "left",
          }}
          className="newStyleField smartDirectWrap"
        >
          <Stack
            direction="row"
            mt={2}
            spacing={2}
            alignContent={"space-between"}
            justifyContent={"start"}
          >
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                height={40}
                width={"100%"}
              >
                <TextField sx={{ height: "40px" }} />
              </Skeleton>
            ) : (
              <>
                <Autocomplete
                  fullWidth
                  id="project-select"
                  className="select"
                  sx={{ width: "25%" }}
                  options={projects}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.projectName}
                  onChange={(
                    event: any,
                    newValue: ProjectDetailLineItem | null,
                  ) => {
                    setSelectedProject(newValue || undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="project-select"
                      {...params}
                      name="entitproject-select"
                      label="Project"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </>
            )}
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                height={40}
                width={"100%"}
              >
                <TextField sx={{ height: "40px" }} />
              </Skeleton>
            ) : (
              <>
                <Autocomplete
                  fullWidth
                  id="roles"
                  className="select"
                  options={Object.values(ProfileProjectAccessLevel)}
                  disabled={isLoading}
                  defaultValue={selectedAccessLevel}
                  getOptionLabel={(option) =>
                    profileProjectAccessLevelToString(option)
                  }
                  onChange={(
                    event: any,
                    newValue: ProfileProjectAccessLevel | null,
                  ) => {
                    setSelectedAccessLevel(newValue || undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="role-select"
                      {...params}
                      name="role-select"
                      label="Role"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <ProfileGroupTable
        loading={isLoading}
        profiles={filteredProfiles}
        projects={projects}
        selectedAccessLevel={selectedAccessLevel}
        selectedProject={selectedProject}
        profileProjectAccess={profileProjectAccessLevels}
        handleUpdateAccess={updateProfileProjectAccess}
      />
    </>
  );
};
