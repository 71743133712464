import {
  Box,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

export const PackageSocialSpendTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left" sx={{ minWidth: "10vw" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            textOverflow: "ellipsis",
          }}
        >
          <Skeleton animation="wave">
            <Typography>Title</Typography>
          </Skeleton>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <TextField />
        </Skeleton>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <TextField />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
