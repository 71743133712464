"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultIntegrationInfoLineItem = exports.IntegrationType = void 0;
var uuid_1 = require("uuid");
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["HammerTech"] = "integration_type-hammer_tech";
    IntegrationType["DamstraImport"] = "integration_type-damstra_import";
})(IntegrationType || (exports.IntegrationType = IntegrationType = {}));
var createDefaultIntegrationInfoLineItem = function (organisationId, integrationType) { return ({
    config: {},
    created: new Date(),
    enabled: false,
    id: (0, uuid_1.v4)(),
    integrationType: integrationType,
    organisationId: organisationId,
}); };
exports.createDefaultIntegrationInfoLineItem = createDefaultIntegrationInfoLineItem;
