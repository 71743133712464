"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProfileProjectAccessLineItem = exports.profileProjectAccessLevelToString = void 0;
var profileProjectAccess_1 = require("../../entities/profileProjectAccess");
var profileProjectAccessLevelToString = function (type) {
    switch (type) {
        case profileProjectAccess_1.ProfileProjectAccessLevel.Admin:
            return "Admin";
        case profileProjectAccess_1.ProfileProjectAccessLevel.Write:
            return "Reporter";
        case profileProjectAccess_1.ProfileProjectAccessLevel.View:
            return "Viewer";
        case profileProjectAccess_1.ProfileProjectAccessLevel.NoAccess:
            return "No Access";
    }
};
exports.profileProjectAccessLevelToString = profileProjectAccessLevelToString;
var createDefaultProfileProjectAccessLineItem = function (projectId, organisationId, profileId) {
    return {
        accessLevel: profileProjectAccess_1.ProfileProjectAccessLevel.NoAccess,
        created: new Date(),
        id: "".concat(profileId, "-").concat(projectId),
        organisationId: organisationId,
        profileId: profileId,
        projectId: projectId,
    };
};
exports.createDefaultProfileProjectAccessLineItem = createDefaultProfileProjectAccessLineItem;
