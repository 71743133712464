"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseMultiplierForSpend = exports.getSocialSpendFactors = exports.isValidABN = exports.isValidPostCode = exports.isValidCertifcation = exports.isValidEntityType = exports.createDefaultSocialSpendCommitment = exports.createDefaultSocialSpendCommitmentsForProject = exports.defaultSocialSpendCommmimentCriteria = void 0;
var uuid_1 = require("uuid");
var packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
var color_1 = require("../../utils/color");
var calc_1 = require("../../utils/calc");
var string_1 = require("../../utils/string");
var financial_1 = require("../../entities/financial");
var socialSpend_1 = require("../../entities/socialSpend");
exports.defaultSocialSpendCommmimentCriteria = (_a = {},
    _a[packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend] = {
        certifications: [],
        colour: "#A5D6A7",
        entityTypes: [socialSpend_1.SocialSpendEntityType.IndigenousEnterprise],
        postCodes: [],
        targetValue: 1.5,
    },
    _a[packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend] = {
        certifications: [],
        colour: "#FFE57F",
        entityTypes: [
            socialSpend_1.SocialSpendEntityType.DisabilityEnterprise,
            socialSpend_1.SocialSpendEntityType.SocialEnterprise,
        ],
        postCodes: [],
        targetValue: 5,
    },
    _a);
var createDefaultSocialSpendCommitmentsForProject = function (projectId) {
    return Object.values(packageSocialSpendCommitment_1.SocialCommitmentTypeSpend).reduce(function (acc, sc) {
        if (sc !== packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.DefaultSpend) {
            var commitmentConfiguration = exports.defaultSocialSpendCommmimentCriteria[sc];
            if (commitmentConfiguration) {
                return __spreadArray(__spreadArray([], acc, true), [
                    __assign(__assign({}, commitmentConfiguration), { achievedValue: 0, colour: (0, color_1.getRandomColour)(), created: new Date(), id: (0, uuid_1.v4)(), projectId: projectId, targetName: sc, targetValueRealised: 0 }),
                ], false);
            }
        }
        return __spreadArray([], acc, true);
    }, []);
};
exports.createDefaultSocialSpendCommitmentsForProject = createDefaultSocialSpendCommitmentsForProject;
var createDefaultSocialSpendCommitment = function (projectId) {
    return {
        achievedValue: 0,
        colour: "#000000",
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        projectSocialSpendCommitmentId: (0, uuid_1.v4)(),
        targetName: packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.DefaultSpend,
        targetValue: 0,
        targetValueRealised: 0,
    };
};
exports.createDefaultSocialSpendCommitment = createDefaultSocialSpendCommitment;
var isValidEntityType = function (socialSpendEntity, entities) {
    if (entities && entities.length > 0) {
        return entities.includes(socialSpendEntity);
    }
    return true;
};
exports.isValidEntityType = isValidEntityType;
var isValidCertifcation = function (socialSpendCertificates, certifications) {
    if (certifications && certifications.length > 0) {
        return (socialSpendCertificates.filter(function (x) { return certifications.includes(x); }).length >
            0);
    }
    return true;
};
exports.isValidCertifcation = isValidCertifcation;
var isValidPostCode = function (socialSpendPostCode, postCodes) {
    if (socialSpendPostCode && postCodes && postCodes.length > 0) {
        return postCodes.includes(socialSpendPostCode);
    }
    return true;
};
exports.isValidPostCode = isValidPostCode;
var isValidABN = function (socialSpendABN, abns) {
    if (abns && abns.length > 0) {
        return abns
            .map(function (abn) { return (0, string_1.cleanString)(abn); })
            .includes((0, string_1.cleanString)(socialSpendABN));
    }
    return true;
};
exports.isValidABN = isValidABN;
var getSocialSpendFactors = function (socialSpend, project) {
    if (project) {
        return project.commitmentsSpend.filter(function (c) {
            return ((0, exports.isValidEntityType)(socialSpend.entityType, c.entityTypes) &&
                (0, exports.isValidCertifcation)(socialSpend.certifications, c.certifications) &&
                (0, exports.isValidPostCode)(socialSpend.supplierPostCode, c.postCodes) &&
                (0, exports.isValidABN)(socialSpend.abn, c.abns));
        });
    }
    return [];
};
exports.getSocialSpendFactors = getSocialSpendFactors;
var getBaseMultiplierForSpend = function (financial) {
    return financial.projectEstimateMethod === financial_1.EstimateType.DeemedHours
        ? (0, calc_1.calculatedAvailableSpend)(financial.totalValue, financial.margin, financial.variations, financial.specialisedEquipmentValue)
        : financial.estimatedContractSpend;
};
exports.getBaseMultiplierForSpend = getBaseMultiplierForSpend;
