"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeGreenMetricComment = exports.decodeGreenMetricComment = void 0;
var string_1 = require("../../utils/string");
var decodeGreenMetricComment = function (greenMetric) {
    return {
        comment: greenMetric.comment,
        contractorPackageId: greenMetric.contractor_package_id,
        created: new Date(greenMetric.created),
        greenMetricId: greenMetric.green_metric_id,
        id: greenMetric.green_metric_comment_id,
        reportId: greenMetric.report_id,
    };
};
exports.decodeGreenMetricComment = decodeGreenMetricComment;
var encodeGreenMetricComment = function (greenMetricComment) {
    var safeGreenMetric = (0, string_1.makePostgresSafe)(greenMetricComment);
    return {
        comment: safeGreenMetric.comment,
        contractor_package_id: safeGreenMetric.contractorPackageId,
        created: safeGreenMetric.created,
        green_metric_comment_id: safeGreenMetric.id,
        green_metric_id: safeGreenMetric.greenMetricId,
        report_id: safeGreenMetric.reportId,
    };
};
exports.encodeGreenMetricComment = encodeGreenMetricComment;
