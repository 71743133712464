import { Grid } from "@mui/material";
import { useOrganisationSocialSpendSubData } from "@hooks/crud/organisationDashboard/useOrganisationSocialSpendSubData";
import { TripleTargetStack } from "../../Dashboard/ProjectProgress/TripleTargetStack";

interface OrganisationSocialSpendProgressSubDataProps {
  loading: boolean;
}

export const OrganisationSocialSpendProgressSubData = ({
  loading,
}: OrganisationSocialSpendProgressSubDataProps) => {
  const { isAnalysisLoading, socialSpendSubData } =
    useOrganisationSocialSpendSubData();
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <TripleTargetStack
        loading={isLoading}
        targets={socialSpendSubData?.targets || []}
        title={"Targets"}
        firstColumnTitle={"Category"}
        thirdColumnTitle={"Count"}
        decimalPlaces={0}
        primaryUnits="$"
        secondColumnTitle={"Spend"}
        rightMargin
        dataTestId={"social-spend-targets"}
      />

      <TripleTargetStack
        loading={isLoading}
        targets={socialSpendSubData?.projects || []}
        title={"Projects"}
        firstColumnTitle={"Name"}
        secondColumnTitle={"Spend"}
        primaryUnits="$"
        secondaryUnits="%"
        thirdColumnTitle={"%"}
        leftMargin
        dataTestId={"social-spend-projects"}
      />
    </Grid>
  );
};
