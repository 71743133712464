import { useState } from "react";
import Block from "@uiw/react-color-block";
import { Box, Chip, Menu } from "@mui/material";

interface ColourPickerProps {
  hex: string;
  setHex: (newHex: string) => void;
}
export default function ColourPicker({ hex, setHex }: ColourPickerProps) {
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <Box sx={{ margin: "auto", position: "relative", top: "25%" }}>
        <Chip
          sx={{
            backgroundColor: hex,
            colour: "#fff !important",
            margin: "auto !important",
            top: "25%",
            width: "100%",
          }}
          color="primary"
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget);
            setShowColourPicker(true);
          }}
          label="Colour"
        />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={showColourPicker}
        onClose={() => {
          setShowColourPicker(!showColourPicker);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box>
          <Block
            color={hex}
            onChange={(color) => setHex(color.hex)}
            colors={[
              "#000000",
              "#404040",
              "#848484",
              "#535a62",
              "#0f3051",
              "#751c10",
              "#a53121",
              "#a56e25",
              "#ec6d00",
              "#f7ac00",
              "#1163c2",
              "#175117",
              "#1a991a",
              "#7346e3",
              "#a740c0",
            ]}
          />
        </Box>
      </Menu>
    </>
  );
}
