"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.newProjectToProjectDetail = exports.ProjectStatus = exports.createDefaultProject = void 0;
var uuid_1 = require("uuid");
var projectPolicy_1 = require("../projectPolicy");
var contactInfo_1 = require("../contactInfo");
var financial_1 = require("../financial");
var greenMetric_1 = require("../greenMetric");
var date_1 = require("../../utils/date");
var projectSocialSpendCommitment_1 = require("../projectSocialSpendCommitment");
var projectLabourHourCommitment_1 = require("../projectLabourHourCommitment");
var project_1 = require("../../entities/project");
var contractorPackage_1 = require("../../entities/contractorPackage");
var createDefaultProject = function (userProfile, organisation) {
    var projectId = (0, uuid_1.v4)();
    return {
        archived: false,
        collaborators: [],
        commitmentsHours: (0, projectLabourHourCommitment_1.createDefaultLabourHourCommitmentsForProject)(projectId),
        commitmentsSpend: (0, projectSocialSpendCommitment_1.createDefaultSocialSpendCommitmentsForProject)(projectId),
        created: new Date(),
        estimatedProgress: 0,
        financial: (0, financial_1.createDefaultFinancial)(),
        greenMetrics: (0, greenMetric_1.createDefaultGreenMetricsForProject)(projectId),
        id: projectId,
        localContents: [],
        organisationId: (organisation === null || organisation === void 0 ? void 0 : organisation.id) || "",
        organisationLogo: (organisation === null || organisation === void 0 ? void 0 : organisation.organisationLogo) || "",
        organisationName: (organisation === null || organisation === void 0 ? void 0 : organisation.organisationName) || "",
        policies: (0, projectPolicy_1.createDefaultPoliciesForProject)(projectId),
        projectAccessLevel: project_1.ProjectAccessLevel.Admin,
        projectAddress: (0, contactInfo_1.createDefaultContactInfo)(),
        projectCode: "",
        projectEndDate: (0, date_1.dateToString)(new Date()),
        projectName: "",
        projectOwner: userProfile ? userProfile.id : "",
        projectReportMonths: [],
        projectStartDate: (0, date_1.dateToString)(new Date()),
        reportingDueDate: 14,
        representativeEmail: (organisation === null || organisation === void 0 ? void 0 : organisation.representativeEmail) || "",
        representativeName: (organisation === null || organisation === void 0 ? void 0 : organisation.representativeName) || "",
    };
};
exports.createDefaultProject = createDefaultProject;
var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Active"] = "ACTIVE";
    ProjectStatus["Archived"] = "ARCHIVED";
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}));
var newProjectToProjectDetail = function (project) {
    var startDate = new Date(project.projectStartDate);
    var getNextReportingDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    return __assign(__assign({}, project), { contractorPackageId: project.id, isAccepted: true, nextReportId: (0, date_1.dateToString)(getNextReportingDate), packageType: contractorPackage_1.PackageType.Other, projectContactInfoId: project.projectAddress.id });
};
exports.newProjectToProjectDetail = newProjectToProjectDetail;
