"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeContractorEmployeeProject = exports.decodeContractorEmployeeProject = void 0;
var string_1 = require("../../utils/string");
var decodeContractorEmployeeProject = function (contractorEmployeeProject) {
    return {
        assigned: contractorEmployeeProject.assigned,
        contractorEmployeeId: contractorEmployeeProject.contractor_employee_id,
        id: contractorEmployeeProject.contractor_employee_project_id,
        projectId: contractorEmployeeProject.project_id,
    };
};
exports.decodeContractorEmployeeProject = decodeContractorEmployeeProject;
var encodeContractorEmployeeProject = function (contractorEmployeeProject) {
    var safeContractorEmployeeProject = (0, string_1.makePostgresSafe)(contractorEmployeeProject);
    return {
        assigned: safeContractorEmployeeProject.assigned,
        contractor_employee_id: safeContractorEmployeeProject.contractorEmployeeId,
        contractor_employee_project_id: safeContractorEmployeeProject.id,
        project_id: safeContractorEmployeeProject.projectId,
    };
};
exports.encodeContractorEmployeeProject = encodeContractorEmployeeProject;
