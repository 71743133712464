import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

interface OrganisationTableRowProps {
  organisation: OrganisationLineItem;
  userOrganisation?: OrganisationLineItem;
  handleJoinOrganisation: (organisation: OrganisationLineItem) => void;
}

export const OrganisationTableRow = ({
  handleJoinOrganisation,
  organisation,
  userOrganisation,
}: OrganisationTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow hover style={{ cursor: "pointer", padding: "10px 0 0" }}>
        <TableCell
          align="center"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          <div style={{ alignItems: "center", display: "flex", gap: "25px" }}>
            <Avatar
              size="badge"
              alt={organisation.organisationName}
              src={organisation.organisationLogo}
              sx={{ backgroundColor: "#ffffff" }}
            />
            <Typography
              variant="caption"
              color="black"
              sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
            >
              {organisation.organisationName}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {organisation.maxActiveProjects}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {organisation.maxActiveProjects}
        </TableCell>
        <TableCell align="right" className="right">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Button
              color="primary"
              size="large"
              disabled={
                organisation.id === userOrganisation?.id ||
                !organisation.isSetup
              }
              onClick={() => setOpenConfirmDialog(true)}
              className="blackBtn"
              sx={{ color: "white", fontSize: "12px" }}
            >
              Join
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Do you wish to join this organisation?"}
        open={openConfirmDialog}
        title={"Join Organisation"}
        intent={"error"}
        buttonText={"Join"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleJoinOrganisation(organisation)}
      />
    </>
  );
};
