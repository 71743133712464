"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hammerTechEmployeeToEmployeeLineItem = exports.EployeeTabStatus = exports.isInvalidMPSG = exports.ageGroupToString = exports.createDefaultEmployee = exports.employeeLongtermUnemployedCategoryToString = exports.LongtermUnemployedCategory = exports.employeeDisabilityCategoryToString = exports.DisabilityCategory = exports.employeeAuxillaryInfomrationToDocumentDescription = exports.employeeSocialSpendFactorToDocumentDescription = exports.employeeSocialSpendFactorToDescription = exports.employeeSocialSpendFactorToColour = exports.employeeSocialSpendFactorToAbbreviatedString = exports.employeeSocialSpendFactorToSocialTarget = exports.employeeAuxillaryInfomrationToString = exports.employeeSocialFactorToString = exports.personWithDisabilityByState = exports.EmployeeAuxillaryInformation = exports.employmentLevelToDocumentDescription = exports.employmentLevelIsMpsg = exports.employmentLevelIsFemaleTraineeApprentice = exports.isDisadvantagedFactor = exports.isAboriginalOrTorresStraitIslander = exports.isDisabilityFactor = exports.isLongTermUnemployed = exports.traineeContractRequired = exports.employeeIsFemaleParticipation = exports.employmentTypeToString = exports.stringImportToEmploymentType = exports.contractTypeToString = exports.stringImportToContractType = exports.employmentLevelToString = exports.stringImportToEmploymentLevel = exports.occupationTypeOptionToString = exports.stringImportToOccupationType = exports.existingJobTypeToVMCString = exports.existingJobTypeToString = exports.stringImportToNewExistingJobType = exports.genderToString = exports.stringImportToGender = void 0;
var uuid_1 = require("uuid");
var packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
var contractorEmployee_1 = require("../../entities/contractorEmployee");
var stringImportToGender = function (gender) {
    switch (gender.toLowerCase().trim()) {
        default:
            return undefined;
        case "male":
        case "man":
            return contractorEmployee_1.Gender.Male;
        case "female":
        case "woman":
            return contractorEmployee_1.Gender.Female;
        case "prefer not to disclose":
            return contractorEmployee_1.Gender.Other;
    }
};
exports.stringImportToGender = stringImportToGender;
var genderToString = function (gender) {
    switch (gender) {
        case contractorEmployee_1.Gender.Male:
            return "Man";
        case contractorEmployee_1.Gender.Female:
            return "Woman";
        case contractorEmployee_1.Gender.Other:
            return "Prefer not to disclose";
    }
};
exports.genderToString = genderToString;
var stringImportToNewExistingJobType = function (newExistingJobType) {
    switch (newExistingJobType === null || newExistingJobType === void 0 ? void 0 : newExistingJobType.toLowerCase().trim()) {
        case "new":
            return contractorEmployee_1.NewExistingJobType.New;
        default:
        case "existing":
            return contractorEmployee_1.NewExistingJobType.Existing;
    }
};
exports.stringImportToNewExistingJobType = stringImportToNewExistingJobType;
var existingJobTypeToString = function (newExistingJobType) {
    switch (newExistingJobType) {
        case contractorEmployee_1.NewExistingJobType.New:
            return "New";
        case contractorEmployee_1.NewExistingJobType.Existing:
            return "Existing";
    }
};
exports.existingJobTypeToString = existingJobTypeToString;
var existingJobTypeToVMCString = function (newExistingJobType) {
    switch (newExistingJobType) {
        case contractorEmployee_1.NewExistingJobType.New:
            return "Created";
        case contractorEmployee_1.NewExistingJobType.Existing:
            return "Retained";
    }
};
exports.existingJobTypeToVMCString = existingJobTypeToVMCString;
var stringImportToOccupationType = function (occupationTypeOption) {
    switch (occupationTypeOption.toLowerCase().trim()) {
        default:
            return undefined;
        case "trade role":
            return contractorEmployee_1.OccupationType.TradeRole;
        case "non-trade position":
            return contractorEmployee_1.OccupationType.NonTradePosition;
        case "management, supervisor & specialist labour roles":
            return contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles;
        case "other":
            return contractorEmployee_1.OccupationType.Other;
    }
};
exports.stringImportToOccupationType = stringImportToOccupationType;
var occupationTypeOptionToString = function (occupationTypeOption) {
    switch (occupationTypeOption) {
        default:
            throw new Error("Unknown occupation type: ".concat(occupationTypeOption));
        case contractorEmployee_1.OccupationType.TradeRole:
            return "Trade role";
        case contractorEmployee_1.OccupationType.NonTradePosition:
            return "Non-trade position";
        case contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles:
            return "Management, supervisor & specialist labour roles";
        case contractorEmployee_1.OccupationType.Other:
            return "Other";
    }
};
exports.occupationTypeOptionToString = occupationTypeOptionToString;
var stringImportToEmploymentLevel = function (employmentLevel) {
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "full employment":
        case "standard":
        case "standard employment":
            return contractorEmployee_1.EmploymentLevel.FullEmployment;
        case "apprentice":
            return contractorEmployee_1.EmploymentLevel.Apprentice;
        case "trainee":
            return contractorEmployee_1.EmploymentLevel.Trainee;
        case "cadet":
            return contractorEmployee_1.EmploymentLevel.Cadet;
    }
};
exports.stringImportToEmploymentLevel = stringImportToEmploymentLevel;
var employmentLevelToString = function (employmentLevel) {
    switch (employmentLevel) {
        default:
            throw new Error("Unknown employment level: ".concat(employmentLevel));
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return "Standard";
        case contractorEmployee_1.EmploymentLevel.Apprentice:
            return "Apprentice";
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return "Trainee";
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return "Cadet";
    }
};
exports.employmentLevelToString = employmentLevelToString;
var stringImportToContractType = function (employmentLevel) {
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "full time":
        case "permanent/full time":
            return contractorEmployee_1.ContractType.PermanentFullTime;
        case "fixed term":
            return contractorEmployee_1.ContractType.FixedTerm;
        case "part time":
            return contractorEmployee_1.ContractType.PartTime;
        case "casual":
            return contractorEmployee_1.ContractType.Casual;
    }
};
exports.stringImportToContractType = stringImportToContractType;
var contractTypeToString = function (contractType) {
    switch (contractType) {
        default:
            throw new Error("Unknown contract type: ".concat(contractType));
        case contractorEmployee_1.ContractType.PermanentFullTime:
            return "Permanent/Full Time";
        case contractorEmployee_1.ContractType.FixedTerm:
            return "Fixed Term";
        case contractorEmployee_1.ContractType.PartTime:
            return "Part Time";
        case contractorEmployee_1.ContractType.Casual:
            return "Casual";
    }
};
exports.contractTypeToString = contractTypeToString;
var stringImportToEmploymentType = function (employmentLevel) {
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "direct":
            return contractorEmployee_1.EmploymentType.Direct;
        case "labour hire":
            return contractorEmployee_1.EmploymentType.Indirect;
    }
};
exports.stringImportToEmploymentType = stringImportToEmploymentType;
var employmentTypeToString = function (employmentType) {
    switch (employmentType) {
        case contractorEmployee_1.EmploymentType.Direct:
            return "Direct";
        case contractorEmployee_1.EmploymentType.Indirect:
            return "Labour Hire";
    }
};
exports.employmentTypeToString = employmentTypeToString;
var employeeIsFemaleParticipation = function (gender) {
    switch (gender) {
        case contractorEmployee_1.Gender.Female:
            return true;
        case contractorEmployee_1.Gender.Other:
        case contractorEmployee_1.Gender.Male:
            return false;
    }
};
exports.employeeIsFemaleParticipation = employeeIsFemaleParticipation;
var traineeContractRequired = function (employmentLevel) {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return false;
        case contractorEmployee_1.EmploymentLevel.Cadet:
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return true;
    }
};
exports.traineeContractRequired = traineeContractRequired;
var isLongTermUnemployed = function (socialFactors) {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed);
};
exports.isLongTermUnemployed = isLongTermUnemployed;
var isDisabilityFactor = function (socialFactors) {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability);
};
exports.isDisabilityFactor = isDisabilityFactor;
var isAboriginalOrTorresStraitIslander = function (socialFactors) {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander);
};
exports.isAboriginalOrTorresStraitIslander = isAboriginalOrTorresStraitIslander;
var isDisadvantagedFactor = function (socialFactors) {
    return socialFactors.length > 0;
};
exports.isDisadvantagedFactor = isDisadvantagedFactor;
var employmentLevelIsFemaleTraineeApprentice = function (employmentLevel) {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return false;
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return true;
    }
};
exports.employmentLevelIsFemaleTraineeApprentice = employmentLevelIsFemaleTraineeApprentice;
var employmentLevelIsMpsg = function (employmentLevel) {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return false;
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return true;
    }
};
exports.employmentLevelIsMpsg = employmentLevelIsMpsg;
var employmentLevelToDocumentDescription = function (employmentLevel) {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return [];
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return ["Registered Training Contract with an approved body"];
    }
};
exports.employmentLevelToDocumentDescription = employmentLevelToDocumentDescription;
var EmployeeAuxillaryInformation;
(function (EmployeeAuxillaryInformation) {
    EmployeeAuxillaryInformation["IdentityDocument"] = "employee-social-procurement-factor_identity-document";
})(EmployeeAuxillaryInformation || (exports.EmployeeAuxillaryInformation = EmployeeAuxillaryInformation = {}));
var personWithDisabilityByState = function (state) {
    switch (state) {
        default:
            return "Person with disability (inclusive of mental illness)";
        case "VIC":
            return "Victorians with a disability";
    }
};
exports.personWithDisabilityByState = personWithDisabilityByState;
var employeeSocialFactorToString = function (employeeSocialSpendFactor, state) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seekers (50+)";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parent";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "Jobseeker with a mental illness";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal and / or Torres-Strait Islander People";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Refugees and Asylum Seekers";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "Disengaged youth - aged between 15-24";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return (0, exports.personWithDisabilityByState)(state);
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "Veterans and their families";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "Jobseeker with a mental illness";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seekers (50+)";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parent";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "Workers in transition (retrenched workers)";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "Young people in out-of-home care";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "Migrants from culturally diverse communities";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "Women Over 45";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "Newly arrived migrants from non-English speaking backgrounds";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "Job seekers registered with Jobs Victoria Mentor Service";
    }
};
exports.employeeSocialFactorToString = employeeSocialFactorToString;
var employeeAuxillaryInfomrationToString = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown supporting document type: ".concat(employeeSocialSpendFactor));
        case EmployeeAuxillaryInformation.IdentityDocument:
            return "Identity Document";
    }
};
exports.employeeAuxillaryInfomrationToString = employeeAuxillaryInfomrationToString;
var employeeSocialSpendFactorToSocialTarget = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation;
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce;
    }
};
exports.employeeSocialSpendFactorToSocialTarget = employeeSocialSpendFactorToSocialTarget;
var employeeSocialSpendFactorToAbbreviatedString = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "IP";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "ASR";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "DY";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "LTE";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "PDMI";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "ADFV";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "PAFV";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "EO";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "JWAMI";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "LIRE";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "MAJS";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "SP";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "SHT";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "WITRW";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "YPOHC";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "CDM";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "WO45";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "NAMFNESB";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "JSRWJVMS";
    }
};
exports.employeeSocialSpendFactorToAbbreviatedString = employeeSocialSpendFactorToAbbreviatedString;
var employeeSocialSpendFactorToColour = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "#F44336";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "#1565C0";
        // case EmployeeSocialFactor.FemaleParticipation:
        //   return "#15cf74";
        // case EmployeeSocialFactor.MPSGEmploymentLevel:
        //   return "#3f99fa";
        // case EmployeeSocialFactor.LocalJobsFirst:
        //   return "#90CAF9";
        // case EmployeeSocialFactor.FemaleTraineeApprentice:
        //   return "#28A745";
    }
};
exports.employeeSocialSpendFactorToColour = employeeSocialSpendFactorToColour;
var employeeSocialSpendFactorToDescription = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal or Torres-Strait Islander";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Asylum Seeker/Refugee";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "Disengaged youth - aged between 15-24";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "Person with disability (inclusive of mental illness)";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "Veteran (ADF or Allied Forces)";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "A person who has previously been convicted of, or plead guilty to, a criminal offence who is not currently incarcerated.";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "Jobseeker with a mental illness";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seeker";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parent";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "Workers in transition (retrenched workers)";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "Young people in out-of-home care";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "Migrants from culturally diverse communities";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "Women Over 45";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "Newly arrived migrants from non-English speaking backgrounds";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "Job seekers registered with Jobs Victoria Mentor Service";
    }
};
exports.employeeSocialSpendFactorToDescription = employeeSocialSpendFactorToDescription;
var employeeSocialSpendFactorToDocumentDescription = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown employee social factor type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return ["Documentation from Employment Program Provider"];
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return ["Certificate of Aboriginality"];
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return [
                "Permanent Protection Visa (PPV)",
                "Temporary Protection Visa (TPV)",
                "Safe Haven Enterprise Visa (SHEV)",
            ];
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return [
                "Copy of Disengaged Youth and Long-term Welfare payments made to the individual",
            ];
        // case EmployeeSocialFactor.LongTermUnemployed:
        //   return ["Documentation from Employment Program Provider"];
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return ["National Assistance Card"];
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return [
                "Certificate of Service",
                "Certificate of Discharge",
                "Extracts of Service Records",
            ];
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return ["Valid documentation"];
    }
};
exports.employeeSocialSpendFactorToDocumentDescription = employeeSocialSpendFactorToDocumentDescription;
var employeeAuxillaryInfomrationToDocumentDescription = function (employeeAusillaryInforation) {
    switch (employeeAusillaryInforation) {
        default:
            throw new Error("Unknown supporting document type: ".concat(employeeAusillaryInforation));
        case EmployeeAuxillaryInformation.IdentityDocument:
            return ["Proof of address"];
    }
};
exports.employeeAuxillaryInfomrationToDocumentDescription = employeeAuxillaryInfomrationToDocumentDescription;
//Disability Program Provider
var DisabilityCategory;
(function (DisabilityCategory) {
    DisabilityCategory["DisabilityEnterpriseServices"] = "disability-category_disability-enterprise-sevices";
    DisabilityCategory["Other"] = "disability-category_other";
})(DisabilityCategory || (exports.DisabilityCategory = DisabilityCategory = {}));
var employeeDisabilityCategoryToString = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            return "";
        case DisabilityCategory.DisabilityEnterpriseServices:
            return "Disability Enterprise Services";
        case DisabilityCategory.Other:
            return "Other";
    }
};
exports.employeeDisabilityCategoryToString = employeeDisabilityCategoryToString;
//Employment Program Provider
var LongtermUnemployedCategory;
(function (LongtermUnemployedCategory) {
    LongtermUnemployedCategory["WorkforceAustralia"] = "long-term-unemployed-category_workforce-austrailia";
    LongtermUnemployedCategory["Other"] = "long-term-unemployed-category_other";
})(LongtermUnemployedCategory || (exports.LongtermUnemployedCategory = LongtermUnemployedCategory = {}));
var employeeLongtermUnemployedCategoryToString = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            return "";
        case LongtermUnemployedCategory.WorkforceAustralia:
            return "Workforce Australia";
        case LongtermUnemployedCategory.Other:
            return "Other";
    }
};
exports.employeeLongtermUnemployedCategoryToString = employeeLongtermUnemployedCategoryToString;
var createDefaultEmployee = function (contractor) {
    return {
        archived: false,
        contractorId: contractor.id,
        contractType: contractorEmployee_1.ContractType.PermanentFullTime,
        country: "AU",
        created: new Date(),
        employeeName: "",
        employerAbn: contractor.abn,
        employmentLevel: contractorEmployee_1.EmploymentLevel.FullEmployment,
        employmentType: contractorEmployee_1.EmploymentType.Direct,
        gender: contractorEmployee_1.Gender.Male,
        geoLocationLat: -1,
        geoLocationLong: -1,
        id: (0, uuid_1.v4)(),
        newExistingJob: contractorEmployee_1.NewExistingJobType.Existing,
        occupation: "",
        occupationType: contractorEmployee_1.OccupationType.TradeRole,
        postCode: "",
        primaryEmployer: contractor.organisationName,
        socialFactors: [],
        supportingDocuments: [],
    };
};
exports.createDefaultEmployee = createDefaultEmployee;
var ageGroupToString = function (ageGroup) {
    switch (ageGroup) {
        default:
            throw new Error("Unknown age group: ".concat(ageGroup));
        case contractorEmployee_1.AgeGroup.FifteenToTwentyFour:
            return "15-24";
        case contractorEmployee_1.AgeGroup.TwentyFiveToFourtyFour:
            return "25-44";
        case contractorEmployee_1.AgeGroup.FortyFiveToFourtyNine:
            return "45-49";
        case contractorEmployee_1.AgeGroup.FiftyPlus:
            return "50+";
    }
};
exports.ageGroupToString = ageGroupToString;
var isInvalidMPSG = function (employee) {
    return (!employee.trainingContractNumber &&
        !employee.qualification &&
        !employee.traineeContractEndDate &&
        !employee.educationalInstitute);
};
exports.isInvalidMPSG = isInvalidMPSG;
var EployeeTabStatus;
(function (EployeeTabStatus) {
    EployeeTabStatus["Active"] = "ACTIVE";
    EployeeTabStatus["Archived"] = "ARCHIVED";
})(EployeeTabStatus || (exports.EployeeTabStatus = EployeeTabStatus = {}));
var hammerTechEmployeeToEmployeeLineItem = function (htEmployee, organisation) {
    return {
        archived: false,
        contractorId: organisation.id,
        contractType: contractorEmployee_1.ContractType.PermanentFullTime,
        country: htEmployee.country,
        created: new Date(),
        dob: new Date(),
        educationalInstitute: "",
        employeeIdentifier: htEmployee.internalIdentifier,
        employeeName: "".concat(htEmployee.firstName, " ").concat(htEmployee.lastName),
        employerAbn: organisation.abn,
        employmentLevel: contractorEmployee_1.EmploymentLevel.FullEmployment,
        employmentType: contractorEmployee_1.EmploymentType.Direct,
        gender: contractorEmployee_1.Gender.Male,
        geoLocationLat: -1,
        geoLocationLong: -1,
        id: htEmployee.uniqueCode,
        newExistingJob: contractorEmployee_1.NewExistingJobType.Existing,
        occupation: htEmployee.jobTitle,
        occupationType: contractorEmployee_1.OccupationType.TradeRole,
        postCode: htEmployee.postcode,
        primaryEmployer: organisation.organisationName,
        qualification: "",
        socialFactors: [],
        supportingDocuments: [],
        traineeContractEndDate: new Date(),
        trainingContractNumber: "",
        uniqueStudentId: "",
    };
};
exports.hammerTechEmployeeToEmployeeLineItem = hammerTechEmployeeToEmployeeLineItem;
