"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePackageLabourHourCommitment = exports.decodePackageLabourHourCommitment = void 0;
var string_1 = require("../../utils/string");
var projectLabourHourCommitment_1 = require("../projectLabourHourCommitment");
var decodePackageLabourHourCommitment = function (socialCommitment) {
    return {
        achievedValue: socialCommitment.achieved_value,
        created: new Date(socialCommitment.created),
        id: socialCommitment.package_labour_hour_commitment_id,
        packageId: socialCommitment.contractor_package_id,
        projectId: socialCommitment.project_id,
        projectLabourHourCommitmentId: socialCommitment.project_labour_hour_commitment_id,
        targetDescription: socialCommitment.target_description,
        targetName: (0, projectLabourHourCommitment_1.decodeLabourHourTargetName)(socialCommitment.target_type),
        targetValue: socialCommitment.target_value,
        targetValueRealised: socialCommitment.target_value_realised,
    };
};
exports.decodePackageLabourHourCommitment = decodePackageLabourHourCommitment;
var encodePackageLabourHourCommitment = function (socialCommitment) {
    if (!socialCommitment.id) {
        throw new Error("No id supplied");
    }
    var safeSocialCommitment = (0, string_1.makePostgresSafe)(socialCommitment);
    return {
        achieved_value: safeSocialCommitment.achievedValue,
        contractor_package_id: safeSocialCommitment.packageId,
        created: safeSocialCommitment.created,
        package_labour_hour_commitment_id: safeSocialCommitment.id,
        project_id: safeSocialCommitment.projectId,
        project_labour_hour_commitment_id: safeSocialCommitment.projectLabourHourCommitmentId,
        target_description: safeSocialCommitment.targetDescription,
        target_type: safeSocialCommitment.targetName,
        target_value: safeSocialCommitment.targetValue,
        target_value_realised: safeSocialCommitment.targetValueRealised,
    };
};
exports.encodePackageLabourHourCommitment = encodePackageLabourHourCommitment;
