"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPolicySubmission = void 0;
var uuid_1 = require("uuid");
var createDefaultPolicySubmission = function (projectId, contractorPackageId, projectPolicyId) {
    return {
        contractorPackageId: contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        projectPolicyId: projectPolicyId,
        uri: "",
    };
};
exports.createDefaultPolicySubmission = createDefaultPolicySubmission;
