"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportingDocumentTypeToString = exports.createDefaultSupportingDocument = void 0;
var uuid_1 = require("uuid");
var contractorEmployee_1 = require("../contractorEmployee");
var contractorEmployee_2 = require("../../entities/contractorEmployee");
var createDefaultSupportingDocument = function (contractorEmployeeId, employeeSocialFactor, fileKey) {
    return {
        contractorEmployeeId: contractorEmployeeId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        supportingDocumentType: employeeSocialFactor,
        uri: fileKey,
    };
};
exports.createDefaultSupportingDocument = createDefaultSupportingDocument;
var supportingDocumentTypeToString = function (employeeSocialSpendFactor) {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error("Unknown supporting document type: ".concat(employeeSocialSpendFactor));
        case contractorEmployee_2.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal or Torres-Strait Islander";
        case contractorEmployee_2.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Asylum Seeker/Refugee";
        case contractorEmployee_2.EmployeeSocialFactor.DisengagedYouth:
            return "Disengaged youth - aged between 15-24";
        case contractorEmployee_2.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed";
        case contractorEmployee_2.EmployeeSocialFactor.PersonWithDisability:
            return "Person with disability (inclusive of mental illness)";
        case contractorEmployee_2.EmployeeSocialFactor.Veteran:
            return "Veteran (ADF or Allied Forces)";
        case contractorEmployee_2.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_2.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_1.EmployeeAuxillaryInformation.IdentityDocument:
            return "Identity Document";
    }
};
exports.supportingDocumentTypeToString = supportingDocumentTypeToString;
