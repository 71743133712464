import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Skeleton,
  Button,
} from "@mui/material";
interface LidpTabsProps {
  isLoading: boolean;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const LidpTabs = ({
  handleChange,
  isLoading,
  value,
}: LidpTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings

  return (
    <Box marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Actual</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Expected</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton data-test-id="actual-button" value={0} size="small">
            Actual
          </ToggleButton>
          <ToggleButton data-test-id="expected-button" value={1} size="small">
            Expected
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
