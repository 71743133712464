import { useCallback, useState, useEffect } from "react";
import { getApiData } from "@hooks/utils/api";
import { SocialSpendSubDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { SocialSpendSubDataModel } from "social-pro-common/entities/dashboard";
import { decodeSocialSpendSubDataModel } from "social-pro-common/decoders/dashboard";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";

/**
 * Custom hook to fetch social spend sub data
 * @param projectId - The project ID
 * @param packageId - The package ID
 * @param reportId - The report ID
 * @returns Object containing error, loading state, and social spend sub data
 */
export const useSocialSpendSubData = (
  projectId?: string,
  packageId?: string,
  reportId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [socialSpendSubData, setSocialSpendSubData] =
    useState<SocialSpendSubDataModelLineItem>();

  const getSocialSpendSubData = useCallback(
    async (
      projectId: string,
      packageId: string,
      reportId: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSocialSpendSubData",
          "dashboard-v2",
          projectId,
          { packageId, reportId },
        );
        const socialSpendSubData = await decodeSocialSpendSubDataModel(
          analysisResult.data as SocialSpendSubDataModel,
          getImageUrl,
        );
        setSocialSpendSubData(socialSpendSubData);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getImageUrl, decodeSocialSpendSubDataModel],
  );

  useEffect(() => {
    if (projectId && packageId && reportId) {
      getSocialSpendSubData(projectId, packageId, reportId);
    }
  }, [projectId, packageId, reportId, getSocialSpendSubData]);

  return {
    error,
    isAnalysisLoading,
    socialSpendSubData,
  };
};