import * as React from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import {
  decodeContractorEmployeeListModel,
  decodeEmployee,
  encodeEmployee,
} from "social-pro-common/decoders/contractorEmployee";
import {
  deleteBatchApiData,
  getApiData,
  postApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import {
  ContractorEmployee,
  ContractorEmployeeListModel,
} from "social-pro-common/entities/contractorEmployee";
import { catchSentryError } from "@utils/sentry";

export const useEmployee = (
  organisationId?: string,
  query?: string,
  page = 0,
  archived?: boolean,
) => {
  const [employeeCount, setEmployeeCount] = React.useState<number>(0);
  const [employees, setEmployees] = React.useState<EmployeeLineItem[]>([]);
  const [isEmployeeLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getEmployee = React.useCallback(
    async (id: string): Promise<EmployeeLineItem | undefined> => {
      try {
        setIsLoading(true);
        const employeeResult = await getApiData(
          "getContractorEmployee",
          "contractorEmployee",
          id,
        );
        if (
          !employeeResult ||
          !employeeResult.data ||
          Object.keys(employeeResult.data).length === 0
        ) {
          return undefined;
        }
        return decodeEmployee(employeeResult.data as ContractorEmployee);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch employee");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeEmployee, catchSentryError],
  );

  const listEmployees = React.useCallback(
    async (
      organisationId: string,
      query?: string,
      page = 0,
      archived?: boolean,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const employeeResult = await getApiData(
          "listContractorEmployees",
          "contractorEmployee",
          organisationId,
          {
            archived: archived ? "true" : undefined,
            page: page.toString(),
            query,
          },
        );
        const decodedEmployees = decodeContractorEmployeeListModel(
          employeeResult.data as ContractorEmployeeListModel,
        );

        setEmployeeCount(decodedEmployees.totalEmployees);
        setEmployees(decodedEmployees.employees);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list employees");
      } finally {
        setIsLoading(false);
      }
    },
    [
      getApiData,
      decodeContractorEmployeeListModel,
      setEmployeeCount,
      setEmployees,
      catchSentryError,
    ],
  );

  const createEmployee = React.useCallback(
    async (employee: EmployeeLineItem): Promise<EmployeeLineItem> => {
      try {
        setIsLoading(true);
        const encodedEmployee = encodeEmployee(employee);
        const upsertedEmployee = await postApiData(
          "createContractorEmployee",
          "contractorEmployee",
          encodedEmployee,
        );
        const decodedEmployee = await decodeEmployee(
          upsertedEmployee.data as ContractorEmployee,
        );

        setEmployees((prevEmployees) => [...prevEmployees, decodedEmployee]);
        return decodedEmployee;
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create employee");
      } finally {
        setIsLoading(false);
      }
      return employee;
    },
    [
      encodeEmployee,
      postApiData,
      decodeEmployee,
      setEmployees,
      catchSentryError,
    ],
  );

  const updateEmployee = React.useCallback(
    async (employee: EmployeeLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedEmployee = encodeEmployee(employee);
        await postApiData(
          "updateContractorEmployee",
          "contractorEmployee",
          encodedEmployee,
        );
        setEmployees((prevEmployees) =>
          prevEmployees
            .map((c) => (c.id === employee.id ? employee : c))
            .filter((e) => e.archived === archived),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update employee");
      } finally {
        setIsLoading(false);
      }
    },
    [archived, encodeEmployee, postApiData, setEmployees, catchSentryError],
  );

  const upsertEmployees = React.useCallback(
    async (employeesToUpsert: EmployeeLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedEmployees = employeesToUpsert.map((e) =>
          encodeEmployee(e),
        );
        await postBatchApiData(
          "upsertContractorEmployees",
          "contractorEmployee",
          encodedEmployees,
        );

        const updatedEmployees = employees.reduce((acc, employee) => {
          const updatedEmployee = employeesToUpsert.find(
            (e) => e.id === employee.id,
          );
          if (updatedEmployee) {
            return [...acc, updatedEmployee];
          }
          return [...acc, employee];
        }, [] as EmployeeLineItem[]);

        const createdEmployees = employeesToUpsert
          .filter(
            (employee) => !updatedEmployees.find((e) => e.id === employee.id),
          )
          .filter((e) => e.archived === archived);

        setEmployees([...updatedEmployees, ...createdEmployees]);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not upsert employees");
      } finally {
        setIsLoading(false);
      }
    },
    [
      archived,
      employees,
      encodeEmployee,
      postBatchApiData,
      setEmployees,
      catchSentryError,
    ],
  );

  const deleteEmployees = React.useCallback(
    async (employeesToDelete: EmployeeLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedEmployee = employeesToDelete.map((e) => encodeEmployee(e));
        await deleteBatchApiData(
          "deleteContractorEmployees",
          "contractorEmployee",
          encodedEmployee,
        );
        if (organisationId) {
          await listEmployees(organisationId, query, page);
        }
        setEmployees((prevEmployees) =>
          prevEmployees.filter(
            (employee) => !employeesToDelete.find((e) => e.id === employee.id),
          ),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete employees");
      } finally {
        setIsLoading(false);
      }
    },
    [
      organisationId,
      query,
      page,
      encodeEmployee,
      deleteBatchApiData,
      listEmployees,
      catchSentryError,
    ],
  );

  React.useEffect(() => {
    if (organisationId) {
      listEmployees(organisationId, query, page, archived);
    }
  }, [organisationId, query, page, archived, listEmployees]);

  return {
    createEmployee,
    deleteEmployees,
    employeeCount,
    employees,
    error,
    getEmployee,
    isEmployeeLoading,
    listEmployees,
    updateEmployee,
    upsertEmployees,
  };
};
