import { Button, Skeleton, Stack, TableCell, TableRow } from "@mui/material";

export const ContractorAnalyticsMainRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Stack
          direction="row"
          spacing={2}
          alignContent={"end"}
          justifyContent={"end"}
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ marginLeft: "auto" }}
          >
            <Button
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              Details
            </Button>
          </Skeleton>
          <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
            <Button
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              Details
            </Button>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
