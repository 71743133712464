import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import {
  EmployeeLineItem,
  createDefaultEmployee,
} from "social-pro-common/interfaces/contractorEmployee";
import { EmployeeForm } from "@stories/molecules/EmployeeForm/EmployeeForm";
import { Steps } from "@stories/atoms/Steps/Steps";
import { EmployeeSocialFactorForm } from "@stories/molecules/EmployeeSocialFactorForm/EmployeeSocialFactorForm";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ToastOptions, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { getLongitudeAndLatitude } from "@hooks/utils/useLocation";
import { catchSentryError } from "@utils/sentry";

interface EmployeeEditModalProps {
  open: boolean;
  loading: boolean;
  contractorOrganisation: OrganisationLineItem;
  existingEmployee?: EmployeeLineItem;
  assignEmployee?: boolean;
  createEmployee: (
    employee: EmployeeLineItem,
    assignEmployee?: boolean,
  ) => void;
  updateEmployee: (employee: EmployeeLineItem) => void;
  handleClose: () => void;
  handleReloadEmployees: () => void;
}

export default function EmployeeEditModal({
  assignEmployee,
  contractorOrganisation,
  createEmployee,
  existingEmployee,
  handleClose,
  handleReloadEmployees,
  loading,
  open,
  updateEmployee,
}: EmployeeEditModalProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeLineItem>(
      existingEmployee || createDefaultEmployee(contractorOrganisation),
    );

  const onSubmit = async () => {
    try {
      if (selectedEmployee) {
        if (existingEmployee) {
          await updateEmployee({ ...selectedEmployee });
        } else {
          await createEmployee({ ...selectedEmployee }, assignEmployee);
        }
        setActiveStep(0);
        handleClose();
        handleReloadEmployees();
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save employee data!", {
        type: "error",
      } as ToastOptions);
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const selectedEmployeeStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <EmployeeForm
            employee={selectedEmployee}
            handleSubmit={async (employee: EmployeeLineItem) => {
              if (employee.country && employee.postCode) {
                const postCodeLatLng = await getLongitudeAndLatitude(
                  employee.country,
                  employee.postCode,
                  "",
                  "",
                );
                employee.geoLocationLat = postCodeLatLng.lat;
                employee.geoLocationLong = postCodeLatLng.lng;
              }

              setSelectedEmployee({
                ...employee,
              });
              handleNext();
            }}
            loading={loading}
          />
        );
      case 1:
        return (
          <EmployeeSocialFactorForm
            loading={loading}
            employee={selectedEmployee}
            handleNext={onSubmit}
            handleBack={handleBack}
            setEmployee={setSelectedEmployee}
          />
        );
    }
  })();

  const steps = [{ label: "Employee Details" }, { label: "Social Factors" }];
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className=" bgBlueOverlay custom-scrollbar"
    >
      <DialogTitle>{"Employee Details"}</DialogTitle>{" "}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          "&:hover": {
            background: "#E23E31",
          },
          background: "#000 !important",
          borderRadius: "50%",
          color: "#fff",
          padding: "2px",
          position: "absolute",
          right: 24,
          top: 30,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        className="custom-scrollbar"
        style={{ paddingTop: "12px" }}
      >
        <Stack spacing={2}>
          <Steps activeStep={activeStep} steps={steps} />
          {selectedEmployeeStep}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
