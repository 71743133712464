import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useAuthContext } from "./useAuthContext";
import { useOrganisation } from "@hooks/crud/organisation/useOrganisation";
import { useProfile } from "@hooks/crud/profile/useProfile";
import * as Sentry from "@sentry/react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

type ProfileContextType = {
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  error?: string;
  isAuthProfileLoading: boolean;
  setUserOrganisation: (organisation: OrganisationLineItem) => void;
  createUserProfile: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem,
  ) => Promise<void>;
  setUpUserProfileAndOrg: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem,
  ) => Promise<void>;
  updateUserProfile: (profile: ProfileLineItem) => Promise<void>;
  updateUserOrganisation: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem,
  ) => Promise<void>;
};

export const ProfileContext = createContext<ProfileContextType>({
  createUserProfile: () => Promise.resolve(),
  error: "Context not initialised correctly.",
  isAuthProfileLoading: false,
  setUpUserProfileAndOrg: () => Promise.resolve(),
  setUserOrganisation: () => Promise.resolve(),
  updateUserOrganisation: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
});

type ProfileProviderProps = {
  children: React.ReactNode;
};

const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const { isAuthLoading, setUserProfile, userProfile } = useAuthContext();
  const { createProfile, updateProfile } = useProfile();
  const {
    createOrganisation,
    getProfileOrganisation,
    isOrganisationLoading,
    setUserOrganisation,
    updateOrganisation,
    userOrganisation,
  } = useOrganisation();

  const createUserProfile = useCallback(
    async (organisation: OrganisationLineItem, profile: ProfileLineItem) => {
      await createOrganisation(organisation);
      await createProfile(profile);
      setUserProfile(profile);
    },
    [createOrganisation, createProfile, setUserProfile],
  );

  const setUpUserProfileAndOrg = useCallback(
    async (organisation: OrganisationLineItem, profile: ProfileLineItem) => {
      await updateOrganisation(organisation);
      await updateProfile(profile);
      setUserProfile(profile);
    },
    [createOrganisation, createProfile, setUserProfile],
  );

  const updateUserProfile = useCallback(
    async (profile: ProfileLineItem) => {
      await updateProfile(profile);
      setUserProfile(profile);
    },
    [updateProfile, setUserProfile],
  );

  const updateUserOrganisation = useCallback(
    async (organisation: OrganisationLineItem, profile: ProfileLineItem) => {
      if (profile.isAdmin) {
        await updateOrganisation(organisation);
      }
    },
    [updateOrganisation],
  );

  useEffect(() => {
    if (userProfile) {
      getProfileOrganisation(userProfile.organisationId);
    }
  }, [userProfile, getProfileOrganisation]);

  useEffect(() => {
    if (userProfile && userOrganisation) {
      Sentry.setUser({
        email: userProfile.email,
        id: userProfile.id,
        username: `${userOrganisation.organisationName} - ${userProfile.name}`,
        // Add any additional user information
      });
    }
  }, [userOrganisation, userProfile]);

  return (
    <ProfileContext.Provider
      value={{
        createUserProfile,
        isAuthProfileLoading: isAuthLoading || isOrganisationLoading,
        setUpUserProfileAndOrg,
        setUserOrganisation,
        updateUserOrganisation,
        updateUserProfile,
        userOrganisation,
        userProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => useContext(ProfileContext);

export { ProfileProvider, useProfileContext };
