"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectCommentTypeToString = exports.ProjectCommentType = exports.createDefaultProjectComment = void 0;
var uuid_1 = require("uuid");
var createDefaultProjectComment = function (projectId, reportId, commentType, comment) {
    return {
        comment: comment,
        commentType: commentType,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId: reportId,
    };
};
exports.createDefaultProjectComment = createDefaultProjectComment;
var ProjectCommentType;
(function (ProjectCommentType) {
    ProjectCommentType["ProjectSummary"] = "project-comment-type_project-summary";
    ProjectCommentType["ProjectSustainabilitySummary"] = "project-comment-type_project-sustainability-summary";
})(ProjectCommentType || (exports.ProjectCommentType = ProjectCommentType = {}));
var projectCommentTypeToString = function (projectCommentType) {
    switch (projectCommentType) {
        case ProjectCommentType.ProjectSummary:
            return "Project Summary";
        default:
            throw new Error("Invalid ProjectCommentType");
    }
};
exports.projectCommentTypeToString = projectCommentTypeToString;
