import { Button, Grid, TableCell, TableRow, Typography } from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { stringToDate } from "social-pro-common/utils/date";

interface ContractorPackageAnalyticsTableRowProps {
  contractorPackage: ContractorPackageLineItem;
  viewPackageDetails: () => void;
}

export const ContractorPackageAnalyticsTableRow = ({
  contractorPackage,
  viewPackageDetails,
}: ContractorPackageAnalyticsTableRowProps) => {
  return (
    <>
      <TableRow hover style={{ cursor: "pointer", padding: "10px 0 0" }}>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
        >{`${contractorPackage.packageNumber}`}</TableCell>
        <TableCell align="center" onClick={viewPackageDetails}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Avatar
                size="badge"
                alt={contractorPackage.contractorName}
                src={contractorPackage.contractorLogo}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography align="left" variant="subtitle1" component="div">
                {contractorPackage.contractorName}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" onClick={viewPackageDetails}>
          {`${
            contractorPackage.packageDescription
              ? contractorPackage.packageDescription
              : packageTypeToString(contractorPackage.packageType)
          }`}
        </TableCell>
        <TableCell align="center" onClick={viewPackageDetails}>
          {stringToDate(contractorPackage.packageStartDate).toLocaleDateString(
            "en-GB",
          )}
        </TableCell>
        <TableCell align="center" onClick={viewPackageDetails}>
          {stringToDate(contractorPackage.packageEndDate).toLocaleDateString(
            "en-GB",
          )}
        </TableCell>
        <TableCell
          align="right"
          className="right"
          sx={{ paddingRight: "20px !important" }}
        >
          <Button
            color="primary"
            size="large"
            onClick={viewPackageDetails}
            className="blackBtn grey-outline-btn"
            sx={{ color: "white", fontSize: "12px" }}
          >
            Details
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
