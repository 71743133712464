import { Avatar, CardHeader, Skeleton, Typography } from "@mui/material";

interface SubTitleProps {
  title: string;
  loading?: boolean;
}

export const SubTitle = ({ loading = false, title }: SubTitleProps) => {
  if (loading) {
    return (
      <Skeleton animation={"wave"}>
        <Typography variant="h1">{title}</Typography>
      </Skeleton>
    );
  }
  return (
    <Typography variant="h1" style={{ fontSize: "25px" }}>
      {title}
    </Typography>
  );
};

interface SubTitleWithImageProps {
  loading: boolean;
  url: string;
  title: string;
}

export const SubTitleWithImage = ({
  loading,
  title,
  url,
}: SubTitleWithImageProps) => {
  if (loading) {
    return <SubTitleWithImageSkeleton />;
  }
  return (
    <CardHeader
      sx={{
        padding: "6px",
      }}
      titleTypographyProps={{
        color: "rgb(18, 25, 38)",
        fontSize: "20px !important",
        fontWeight: "600",
        lineheight: "24px",
        margin: "0",
      }}
      avatar={
        <Avatar
          src={url}
          alt={title.toUpperCase()}
          sx={{ backgroundColor: "#ffffff" }}
        />
      }
      title={title}
    />
  );
};

export const SubTitleWithImageSkeleton = () => {
  return (
    <CardHeader
      sx={{ padding: "6px" }}
      titleTypographyProps={{
        color: "rgb(18, 25, 38)",
        fontSize: "20px !important",
        fontWeight: "600",
        lineheight: "24px",
        margin: "0",
      }}
      avatar={
        <Skeleton animation={"wave"} variant="circular">
          <Avatar src={""} alt={""} sx={{ backgroundColor: "#ffffff" }} />
        </Skeleton>
      }
      title={
        <Skeleton animation={"wave"}>
          <Typography component={"h1"}>
            This is a long title Page Title
          </Typography>
        </Skeleton>
      }
    />
  );
};
