import { Box, Stack } from "@mui/material";
import HeroBg from "assets/images/home/1.png";
import Logo from "assets/images/home/SocialPro_Blue.png";
import ForgotPasswordForm from "@stories/organisms/ForgotPasswordForm/ForgotPasswordForm";
import ForgotPasswordSubmitForm from "@stories/organisms/ForgotPasswordSubmitForm/ForgotPasswordSubmitForm";
import { useState } from "react";

const ForgotPassword = () => {
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  return (
    <Box className="loginPage">
      <img src={HeroBg} alt="HeroBg" className="loginBackground" />
      <Stack spacing={2} className="loginCard">
        <a href="/" style={{ marginBottom: "20px", textAlign: "center" }}>
          <img src={Logo} alt="" width="200px" />
        </a>
        {showEnterCode && emailAddress ? (
          <ForgotPasswordSubmitForm emailAddress={emailAddress} />
        ) : (
          <ForgotPasswordForm
            setShowEnterCode={setShowEnterCode}
            setEmailAddress={setEmailAddress}
          />
        )}
      </Stack>
    </Box>
  );
};

export default ForgotPassword;
