import { ProjectAccessLevel } from "social-pro-common/entities/project";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  ProjectDetailLineItem,
  ProjectLineItem,
} from "social-pro-common/interfaces/project";

export const userIsAdmin = (profile?: ProfileLineItem) => {
  return profile?.isAdmin || false;
};

export const userHasAdminAccess = (
  project?: ProjectDetailLineItem | ProjectLineItem,
) => {
  if (!project) return false;
  return project.projectAccessLevel === ProjectAccessLevel.Admin;
};

export const userHasManagerAccess = (
  project?: ProjectDetailLineItem | ProjectLineItem,
) => {
  if (!project) return false;
  return (
    project.projectAccessLevel === ProjectAccessLevel.Admin ||
    project.projectAccessLevel === ProjectAccessLevel.Manager
  );
};

export const userHasEditorAccess = (
  project?: ProjectDetailLineItem | ProjectLineItem,
) => {
  if (!project) return false;
  return (
    project.projectAccessLevel === ProjectAccessLevel.Admin ||
    project.projectAccessLevel === ProjectAccessLevel.Manager ||
    project.projectAccessLevel === ProjectAccessLevel.Write
  );
};
