import { useCallback, useState, useEffect } from "react";
import { getApiData } from "@hooks/utils/api";
import { SustainabilitySubDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { SustainabilitySubDataModel } from "social-pro-common/entities/dashboard";
import { decodeSustainabilitySubDataModel } from "social-pro-common/decoders/dashboard";
import { catchSentryError } from "@utils/sentry";

export const useSustainabilitySubData = (
  projectId?: string,
  packageId?: string,
  reportId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sustainabilitySubData, setSustainabilitySubData] =
    useState<SustainabilitySubDataModelLineItem>();

  const getSustainabilitySubData = useCallback(
    async (projectId: string, packageId: string, reportId: string) => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSustainabilitySubData",
          "dashboard-v2",
          projectId,
          { packageId, reportId },
        );
        const sustainabilitySubData = decodeSustainabilitySubDataModel(
          analysisResult.data as SustainabilitySubDataModel,
        );
        setSustainabilitySubData(sustainabilitySubData);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodeSustainabilitySubDataModel, catchSentryError],
  );

  useEffect(() => {
    if (projectId && packageId && reportId) {
      getSustainabilitySubData(projectId, packageId, reportId);
    }
  }, [projectId, packageId, reportId, getSustainabilitySubData]);

  return {
    error,
    isAnalysisLoading,
    sustainabilitySubData,
  };
};