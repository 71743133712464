import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack } from "@mui/material";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { useState } from "react";
import { dateToString, stringToDate } from "social-pro-common/utils/date";
import { useNavigate, useParams } from "react-router-dom";
import { LabourHoursTable } from "@stories/organisms/PackageTools/ToolsLabourHoursTable";
import * as RouteHelper from "@utils/routes";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useLabourHoursForTarget } from "@hooks/crud/labourHours/useLabourHoursForTarget";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { useLabourHourExport } from "@hooks/crud/downloadFiles/useLabourHourExport";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const AnalyticsHoursTarget = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [sortInfo, setSortInfo] = useState<SortInfo>({
    handleRequestSort: (property: number) => {
      setSortInfo((prev: SortInfo) => {
        const isAsc = prev.orderBy === property && prev.order === "asc";
        return {
          ...sortInfo,
          order: isAsc ? "desc" : "asc",
          orderBy: property,
        };
      });
    },
    index: 0,
    order: "asc",
    orderBy: 0,
  });

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };
  const { projectId, reportId, targetId } = useParams();
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getLabourHourExcel } =
    useLabourHourExport();

  const [selectedReportId, setSelectedReportId] = useState<string>(
    reportId || dateToString(new Date()),
  );

  const { isLabourHourLoading, labourHourCount, labourHours } =
    useLabourHoursForTarget(
      selectedProject?.id,
      targetId,
      selectedReportId,
      isPrimaryPackage ? undefined : contractorPackage?.id,
      page,
      sortInfo.orderBy,
      sortInfo.order,
    );

  const loading = isProjectLoading || isLabourHourLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const handlOnBack = () => {
    if (projectId && reportId) {
      navigate(RouteHelper.analyticsLabourHours(projectId, reportId));
    }
  };

  const filteredLabourHours = searchValue
    ? labourHours.filter((p) =>
        p.organisationName.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : labourHours;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Labour Hours - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                labourHourCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
          handlOnBack={handlOnBack}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <MonthSelect
              data-test-id="month-select"
              loading={loading}
              project={selectedProject}
              contractorPackage={contractorPackage}
              isPrimaryPackage={isPrimaryPackage}
              setDate={(date: Date) => {
                setSelectedReportId(dateToString(date));
              }}
              date={stringToDate(selectedReportId)}
            />
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
              <StyledButton
                className="blackBtn grey-outline-btn topBarBtn"
                loading={loading}
                disabled={loading || !labourHours || !labourHours.length}
                variant="contained"
                onClick={() => {
                  if (projectId && reportId && targetId) {
                    getLabourHourExcel(
                      projectId,
                      reportId,
                      targetId,
                      isPrimaryPackage ? undefined : contractorPackage?.id,
                    );
                  }
                }}
                startIcon={<DownloadIcon />}
              >
                Export Targets
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "30px 40px !important" }}
        >
          <LabourHoursTable
            loading={loading}
            labourHours={filteredLabourHours}
            labourHourCount={labourHourCount}
            setPage={setPage}
            sortInfo={sortInfo}
          />
        </Grid>
        <PdfDownlodDialog
          downloadStep={downloadStep}
          total={downloadProgress}
          title={"Labour Hour Export"}
        />
      </Grid>
    </>
  );
};
