"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageCommentType = void 0;
var PackageCommentType;
(function (PackageCommentType) {
    PackageCommentType["Hour"] = "package-comment-type_hour";
    PackageCommentType["Spend"] = "package-comment-type_spend";
    PackageCommentType["Policy"] = "package-comment-type_policy";
    PackageCommentType["LocalContent"] = "package-comment-type_local-content";
    PackageCommentType["GreenMetric"] = "package-comment-type_green-metric";
})(PackageCommentType || (exports.PackageCommentType = PackageCommentType = {}));
