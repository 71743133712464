import {
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { GreenMetricCategoryLineItem } from "social-pro-common/interfaces/greenMetricCategory";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ToastOptions, toast } from "react-toastify";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import {
  GreenMetricCategorySubmissionLineItem,
  createDefaultGreenMetricCategorySubmission,
} from "social-pro-common/interfaces/greenMetricCategorySubmission";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { getDocumentName } from "social-pro-common/utils/string";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { catchSentryError } from "@utils/sentry";

interface SustainabilityRowProps {
  project?: ProjectLineItem;
  reportId: string;
  contractorPackage?: ContractorPackageLineItem;
  greenMetricCategory: GreenMetricCategoryLineItem;
  greenMetricCategorySubmission?: GreenMetricCategorySubmissionLineItem;
  readOnly?: boolean;
  createGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
  updateGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
}

export const SustainabilityRow = ({
  contractorPackage,
  createGreenMetricCategorySubmission,
  greenMetricCategory,
  greenMetricCategorySubmission,
  project,
  readOnly,
  reportId,
  updateGreenMetricCategorySubmission,
}: SustainabilityRowProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTargetId, setSelectedTargetId] = useState<string>();

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const handleValueChange = async (e: React.FocusEvent<HTMLInputElement>) => {
    setIsSubmitting(true);
    if (project && contractorPackage) {
      const newValue = parseFloat(e.target.value);
      const matchingProjectGreenMetric = project.greenMetrics.find(
        (p) => p.id === greenMetricCategory.greenMetricId,
      );

      if (matchingProjectGreenMetric) {
        if (greenMetricCategorySubmission) {
          await updateGreenMetricCategorySubmission({
            ...greenMetricCategorySubmission,
          });
        } else {
          const newGreenMetricCategorySubmission =
            createDefaultGreenMetricCategorySubmission(
              greenMetricCategory.id,
              project.id,
              contractorPackage.id,
              reportId,
            );
          await createGreenMetricCategorySubmission({
            ...newGreenMetricCategorySubmission,
            reportValue: newValue,
          });
        }
      } else {
        toast("Could not find metric.", {
          type: "error",
        } as ToastOptions);
      }
    }
    setIsSubmitting(false);
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmitting(true);
    setUploadProgress(0);
    if (
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0 &&
      project &&
      contractorPackage
    ) {
      const file = e.currentTarget?.files[0] || null;
      if (file && selectedTargetId) {
        setIsUploading(true);

        const fileKey = `sustainability/${contractorPackage.id}/${selectedTargetId}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          (error: any) => {
            setUploadProgress(0);
            catchSentryError(error);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );

        const matchingProjectGreenMetric = project.greenMetrics.find(
          (p) => p.id === greenMetricCategory.greenMetricId,
        );

        if (matchingProjectGreenMetric) {
          if (greenMetricCategorySubmission) {
            await updateGreenMetricCategorySubmission({
              ...greenMetricCategorySubmission,
              reportUri: fileKey,
            });
          } else {
            const newGreenMetricCategorySubmission =
              createDefaultGreenMetricCategorySubmission(
                greenMetricCategory.id,
                project.id,
                contractorPackage.id,
                reportId,
              );
            await createGreenMetricCategorySubmission({
              ...newGreenMetricCategorySubmission,
              reportUri: fileKey,
            });
          }
        } else {
          toast("Could not find metric.", {
            type: "error",
          } as ToastOptions);
        }
        setIsUploading(false);
        setIsSubmitting(false);
      }
    }
  };

  const fileInput = useRef<HTMLInputElement | null>(null);
  const onFileInput = (id: string) => {
    if (fileInput.current) {
      fileInput.current.click();
      setSelectedTargetId(id);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography align="left">
            {greenMetricCategory.targetCategory}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{greenMetricCategory.metricType}</Typography>
        </TableCell>
        <TableCell align="left">
          <StyledTextField
            loading={false}
            disabled={isSubmitting || readOnly}
            className="search tableInput"
            id={`${greenMetricCategory.id}-category-row`}
            name="reportedValue"
            margin="dense"
            fullWidth
            sx={{ width: "75%" }}
            value={
              greenMetricCategorySubmission?.reportValue
                ? formatDecimalPlaces(greenMetricCategorySubmission.reportValue)
                : ""
            }
            onBlur={handleValueChange}
            InputProps={{
              endAdornment: !greenMetricCategory.targetType.includes("$") ? (
                <InputAdornment
                  position="end"
                  variant="standard"
                  sx={{
                    color: isSubmitting ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  {greenMetricCategory.targetType}
                </InputAdornment>
              ) : undefined,
              inputComponent: NumericFormatCustom as any,
              startAdornment: greenMetricCategory.targetType.includes("$") ? (
                <InputAdornment
                  position="end"
                  variant="standard"
                  sx={{
                    color: isSubmitting ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  {greenMetricCategory.targetType}
                </InputAdornment>
              ) : undefined,
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            {greenMetricCategorySubmission?.reportUri ? (
              isDownloading ? (
                <CircularProgress />
              ) : (
                <Button
                  color="primary"
                  size="large"
                  disabled={isUploading || isDownloading}
                  onClick={() =>
                    greenMetricCategorySubmission?.reportUri
                      ? onDownload(
                          greenMetricCategorySubmission?.reportUri,
                          getDocumentName(
                            `${greenMetricCategory.targetCategory}-${greenMetricCategorySubmission.reportId}`,
                            greenMetricCategorySubmission?.reportUri,
                          ),
                        )
                      : undefined
                  }
                  className="blackBtn grey-outline-btn"
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    padding: "10px 15px",
                  }}
                >
                  Download
                </Button>
              )
            ) : null}
            {!readOnly ? (
              isUploading ? (
                <CircularProgressWithLabel value={uploadProgress} />
              ) : (
                <label htmlFor="containedUploadButtonFile">
                  <input
                    ref={fileInput}
                    hidden
                    type="file"
                    style={{
                      cursor: "pointer",
                      opacity: 0,
                      padding: 0.5,
                      position: "fixed",
                      zIndex: 1,
                    }}
                    onChange={onUpload}
                  />
                  <Button
                    color="primary"
                    size="large"
                    data-test-id="policy-details-button"
                    disabled={isUploading || isDownloading || isSubmitting}
                    onClick={() => {
                      onFileInput(greenMetricCategory.id);
                    }}
                    className={"blackBtn"}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      lineHeight: "16px",
                      padding: "10px 15px",
                    }}
                  >
                    {greenMetricCategorySubmission?.reportUri
                      ? "Reupload"
                      : "Upload"}
                  </Button>
                </label>
              )
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
