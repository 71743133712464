import { Typography, Skeleton, Stack } from "@mui/material";

interface TitleStackProps {
  dataTestId?: string;
  loading: boolean;
  header: string;
  subTitle: string;
}

export const TitleStack = ({
  dataTestId,
  header,
  loading,
  subTitle,
}: TitleStackProps) => {
  if (loading) {
    return (
      <Stack spacing={0}>
        <Skeleton animation="wave">
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            {header}
          </Typography>
        </Skeleton>
        <Skeleton animation="wave">
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {subTitle}
          </Typography>
        </Skeleton>
      </Stack>
    );
  }
  return (
    <Stack spacing={0}>
      <Typography
        variant="h3"
        sx={{ fontWeight: "bold" }}
        data-test-id={dataTestId}
      >
        {header}
      </Typography>
      <Typography variant="body1">{subTitle}</Typography>
    </Stack>
  );
};
