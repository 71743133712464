import { Box, Button } from "@mui/material";

import LogoSection2 from "../../MenuShared/LogoSection2";
import { useAuthContext } from "@hooks/context/useAuthContext";

const Header2 = () => {
  const { signOut } = useAuthContext();
  return (
    <>
      <div className="Projectheader">
        <LogoSection2 />
        <Box sx={{ alignItems: "center", display: "flex", gap: "10px" }}></Box>
        <div className="header-btn">
          <Button size="large" variant="outlined" onClick={signOut}>
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export default Header2;
