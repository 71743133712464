import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Typography, useTheme } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WizardCompleteModalProps {
  handleClose: () => void;
}

export default function WizardCompleteModal({
  handleClose,
}: WizardCompleteModalProps) {
  const theme = useTheme();
  return (
    <div>
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        sx={{
          "& .MuiDialog-paper": { maxHeight: 435, width: "80%", zIndex: 5000 },
        }}
        maxWidth="xs"
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle sx={{ color: theme.palette.success.main }}>
          {"Report Submitted Successfully!"}
        </DialogTitle>
        <DialogContent>
          <Typography>You have completed this month's report.</Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "10px 20px" }}>
          <StyledButton
            data-test-id="confirm-dialog-button-ok"
            loading={false}
            disabled={false}
            variant="contained"
            onClick={handleClose}
          >
            Done
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
