import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack, debounce } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useProjectComments } from "@hooks/crud/projectComments/useProjectComments";
import { ReportSubmissions } from "@stories/organisms/ReportSubmissions/ReportSubmissions";
import { useProjectReportSocialSubmission } from "@hooks/crud/projectReportSocialSubmission/useProjectReportSocialSubmission";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { createDefaultProjectReportSubmission } from "social-pro-common/interfaces/projectReportSubmission";
import { ProjectWizardSteps } from "@stories/molecules/ProjectWizardSteps/ProjectWizardSteps";
import { ProjectReportConfirmation } from "@stories/organisms/ProjectReportConfirmation/ProjectReportConfirmation";
import {
  ProjectCommentLineItem,
  ProjectCommentType,
  createDefaultProjectComment,
} from "social-pro-common/interfaces/projectComment";
import { stringToDate } from "social-pro-common/utils/date";
import WizardCompleteModal from "../Wizard/WizardCompleteModal";
import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { getZipTitle } from "social-pro-common/utils/string";
import { useReportSubmissionSustainabilityForProject } from "@hooks/crud/reportSubmissionSustainabilityForProject/useReportSubmissionSustainabilityForProject";

export const ProjectSustainabilityWizard = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  if (!reportId) {
    throw Error("Not report number");
  }
  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const downloadPdf = async (
    projectId: string,
    contractorPackageId: string,
  ) => {
    const reportTitle = getZipTitle(stringToDate(reportId));
    getPdfInfo(projectId, reportId, reportTitle, contractorPackageId, true);
  };
  const [firstLoad, setFirstLoad] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const { comments, isCommentsLoading, upsertProjectComments } =
    useProjectComments(selectedProject?.id, reportId, contractorPackage?.id);

  const {
    deleteReportSubmission,
    isReportSubmissionsForProjectLoading,
    reportSubmissions,
    updateReportSubmission,
  } = useReportSubmissionSustainabilityForProject(
    selectedProject?.id,
    reportId,
  );

  const { createProjectReportSubmission, isProjectReportLoading } =
    useProjectReportSocialSubmission(selectedProject?.id);

  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const viewReportSubmission = (reportMonthId: string, rowId: string) => {
    if (selectedProject) {
      navigate({
        pathname: RouteHelper.readOnlyReportSustainability(
          selectedProject.id,
          rowId,
          reportMonthId,
        ),
        search: createSearchParams({
          redirect: window.location.pathname,
        }).toString(),
      });
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (contractorPackage) {
      const reportSubmission = createDefaultProjectReportSubmission(
        contractorPackage?.id,
        reportId,
      );
      await createProjectReportSubmission(reportSubmission);
      setShowSuccess(true);
    }
    setIsSubmitting(false);
  };

  const [commentMap, setCommentMap] = useState(
    new Map<ProjectCommentType, string>(),
  );

  //TODO: Fix this a bit better
  const handleUpdateComment = async (newComment: ProjectCommentLineItem) => {
    await upsertProjectComments([newComment]);
  };

  const onUpdateComment = useRef(debounce(handleUpdateComment, 1000));

  useEffect(() => {
    if (comments) {
      const commentMap = new Map<ProjectCommentType, string>();
      comments.forEach((comment) => {
        commentMap.set(comment.commentType, comment.comment);
      });
      setCommentMap(commentMap);
      onUpdateComment.current = debounce(handleUpdateComment, 1000);
    }
  }, [comments]);

  const updateComment = async (
    commentType: ProjectCommentType,
    comment: string,
    save: boolean,
  ) => {
    if (save) {
      const matchingPackageComment = comments.find(
        (c) => c.commentType === commentType,
      );
      if (matchingPackageComment) {
        onUpdateComment.current({
          ...matchingPackageComment,
          comment: comment,
        });
      } else {
        if (selectedProject && reportId) {
          onUpdateComment.current(
            createDefaultProjectComment(
              selectedProject?.id,
              reportId,
              commentType,
              comment,
            ),
          );
        }
      }
    }
    setCommentMap((currentValue) => {
      return new Map(currentValue.set(commentType, comment));
    });
  };

  const loading =
    firstLoad &&
    (isProjectLoading ||
      isCommentsLoading ||
      isReportSubmissionsForProjectLoading ||
      isContractorPackageLoading ||
      isProjectReportLoading);

  useEffect(() => {
    if (!loading) {
      setFirstLoad(false);
    }
  }, [loading]);

  const reportDate = stringToDate(reportId);

  //Only show packages that are for this month
  const filteredPackages = contractorPackages.filter(
    (pkg) =>
      stringToDate(pkg.packageStartDate).getTime() <= reportDate.getTime() &&
      stringToDate(pkg.packageEndDate).getTime() >= reportDate.getTime(),
  );

  const wizardComponents = [
    <ReportSubmissions
      loading={loading}
      deleteReportSubmission={deleteReportSubmission}
      updateReportSubmission={updateReportSubmission}
      selectedProject={selectedProject}
      contractorPackages={filteredPackages}
      reportSubmissions={reportSubmissions}
      reportId={reportId}
      viewReportSubmission={viewReportSubmission}
      downloadStep={downloadStep}
      downloadProgress={downloadProgress}
      downloadPdf={downloadPdf}
    />,
  ];

  wizardComponents.push(
    <ProjectReportConfirmation
      loading={loading}
      project={selectedProject}
      reportId={reportId}
      reportCommentType={ProjectCommentType.ProjectSustainabilitySummary}
      updateComment={updateComment}
      commentMap={commentMap}
    />,
  );

  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <>
      {showSuccess ? (
        <WizardCompleteModal
          handleClose={() => {
            setShowSuccess(false);
            if (selectedProject) {
              navigate(
                RouteHelper.contractorProjectReports(selectedProject?.id),
              );
            }
          }}
        />
      ) : null}
      <Grid
        container
        spacing={2}
        sx={{ margin: "0", padding: "30px 20px 10px" }}
      >
        <Grid item xs={12} md={12} className="steps">
          <ProjectWizardSteps
            activeStep={activeStep}
            loading={loading}
            project={selectedProject}
          />
        </Grid>
      </Grid>
      {selectedWizardStep}

      <Grid
        container
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "0 40px 80px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <Stack direction="row" spacing={1}>
            {activeStep > 0 ? (
              <StyledButton
                loading={loading}
                disabled={loading || isSubmitting}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </StyledButton>
            ) : null}

            {activeStep < 1 ? (
              <StyledButton
                loading={loading}
                disabled={loading || isSubmitting}
                onClick={handleNext}
              >
                Next
              </StyledButton>
            ) : (
              <StyledButton
                disabled={loading || isSubmitting}
                loading={loading}
                variant="contained"
                onClick={onSubmit}
              >
                Submit
              </StyledButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
