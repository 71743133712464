"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultLabourHour = void 0;
var uuid_1 = require("uuid");
var contractorEmployee_1 = require("../../entities/contractorEmployee");
var createDefaultLabourHour = function (projectId, reportId, contractorPackageId, userOrganisation, employee) {
    return {
        amountOfHoursStudied: 0,
        amountOfHoursWorked: 0,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        employeeId: (employee === null || employee === void 0 ? void 0 : employee.id) || "",
        employeeName: (employee === null || employee === void 0 ? void 0 : employee.employeeName) || "",
        employmentLevel: (employee === null || employee === void 0 ? void 0 : employee.employmentLevel) || contractorEmployee_1.EmploymentLevel.FullEmployment,
        gender: (employee === null || employee === void 0 ? void 0 : employee.gender) || contractorEmployee_1.Gender.Male,
        id: (0, uuid_1.v4)(),
        organisationName: userOrganisation.organisationName,
        projectId: projectId,
        reportId: reportId,
        socialFactors: [],
        supportingDocuments: [],
    };
};
exports.createDefaultLabourHour = createDefaultLabourHour;
