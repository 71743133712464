import { useCallback, useEffect, useState } from "react";
import { listApiData, postBatchApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import {
  decodeForecastModel,
  encodeForecastModel,
} from "social-pro-common/decoders/forecastData";
import {
  ForecastModel,
  ForecastUnitType,
} from "social-pro-common/entities/forecastData";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  createDefaultForecastData,
  ForecastModelLineItem,
} from "social-pro-common/interfaces/forecastData";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  dateToString,
  getReportMonths,
  stringToDate,
} from "social-pro-common/utils/date";

export const useForecastModel = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
) => {
  const [forecastModels, setForecastModels] = useState<ForecastModelLineItem[]>(
    [],
  );
  const [isForecastLoading, setIsForecastLoading] = useState<boolean>(true);
  const [isSubmittingForecast, setIsSubmittingForecast] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const createForecastModel = useCallback(
    async (newForecastModels: ForecastModelLineItem[]) => {
      try {
        setIsForecastLoading(true);
        const encodedForecastModel = newForecastModels.map((f) =>
          encodeForecastModel(f),
        );
        await postBatchApiData(
          "createForecastModel",
          "forecastData",
          encodedForecastModel,
        );
        setForecastModels((prevModels) => [
          ...prevModels,
          ...newForecastModels,
        ]);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create forecast data");
      } finally {
        setIsForecastLoading(false);
      }
    },
    [],
  );

  const listForecastModel = useCallback(
    async (projectId: string, contractorPackageId: string) => {
      if (project && contractorPackage) {
        try {
          setIsForecastLoading(true);
          const forecastResult = await listApiData(
            "listForecastModel",
            "forecastData",
            projectId,
            { contractorPackageId },
          );

          if (forecastResult.data.length === 0) {
            const reportMonths = getReportMonths(
              stringToDate(contractorPackage.packageStartDate),
              stringToDate(contractorPackage.packageEndDate),
            );
            const reportIds = reportMonths?.map((rm) => dateToString(rm));

            const hourForecastModel =
              contractorPackage?.commitmentsHours.map((c) => ({
                active: true,
                colour: "#0081E2",
                contractorPackageId,
                created: new Date(),
                data: reportIds.map((rm) =>
                  createDefaultForecastData(c.id, rm),
                ),
                id: c.id,
                modelName:
                  c.targetDescription ||
                  labourHourCommitmentTypeToString(c.targetName),
                projectId: project.id,
                units: ForecastUnitType.Hour,
              })) || [];

            const spendForecastModel =
              contractorPackage?.commitmentsSpend.map((c) => ({
                active: true,
                colour: "#0081E2",
                contractorPackageId,
                created: new Date(),
                data: reportIds.map((rm) =>
                  createDefaultForecastData(c.id, rm),
                ),
                id: c.id,
                modelName:
                  c.targetDescription ||
                  socialSpendCommitmentTypeToString(c.targetName),
                projectId: project.id,
                units: ForecastUnitType.Spend,
              })) || [];

            const allModels = [...hourForecastModel, ...spendForecastModel];
            await createForecastModel(allModels);
            setForecastModels(allModels);
          } else {
            setForecastModels(
              forecastResult.data.map((p) =>
                decodeForecastModel(p as ForecastModel),
              ),
            );
          }
        } catch (e: any) {
          catchSentryError(e);
          setError("Could not list forecast data");
        } finally {
          setIsForecastLoading(false);
        }
      }
    },
    [project, contractorPackage, createForecastModel],
  );

  const updateForecastModel = useCallback(
    async (updatedForecastModels: ForecastModelLineItem[]) => {
      try {
        setIsSubmittingForecast(true);
        const encodedForecastModels = updatedForecastModels.map((f) =>
          encodeForecastModel(f),
        );
        await postBatchApiData(
          "updateForecastModel",
          "forecastData",
          encodedForecastModels,
        );
        setForecastModels((prevModels) => {
          const updatedModels = prevModels.map(
            (f) => updatedForecastModels.find((fm) => fm.id === f.id) || f,
          );
          return updatedModels;
        });
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update forecast data");
      } finally {
        setIsSubmittingForecast(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (project && contractorPackage) {
      listForecastModel(project.id, contractorPackage.id);
    }
  }, [project, contractorPackage, listForecastModel]);

  return {
    createForecastModel,
    error,
    forecastModels,
    isForecastLoading,
    isSubmittingForecast,
    updateForecastModel,
  };
};
