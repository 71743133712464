export const sleep = (ms: number) => {
  return new Promise((resolve) => setInterval(resolve, ms));
};

export const randomEnumValue = (enumeration: any) => {
  const values = Object.keys(enumeration);
  const enumKey = values[Math.floor(Math.random() * values.length)];
  return enumeration[enumKey];
};

export const stringToSearchQuery = (queryString?: string) =>
  queryString && queryString.length > 0 ? queryString : undefined;
