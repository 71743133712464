import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";

export interface FAQ {
  question: string;
  answer: string;
}

interface FAQAccordionProps {
  faq: FAQ;
  index: number;
}

export default function FAQAccordion({ faq, index }: FAQAccordionProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <Grid
      item
      md={12}
      sx={{
        borderRadius: "12px",
        marginTop: "10px",
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        disableGutters
        elevation={0}
        sx={{
          "&::before": {
            display: "none",
          },
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          borderRadius: "12px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "#e9f0f5",
            borderRadius: expanded
              ? "12px 12px 0px 0px"
              : "12px 12px 12px 12px",
          }}
        >
          {`${index + 1} ${faq.question}`}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#f8f8f8",
            borderRadius: "0px 0px 12px 12px",
            padding: "16px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
