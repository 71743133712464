"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeGreenMetric = exports.decodeGreenMetric = void 0;
var string_1 = require("../../utils/string");
var greenMetricCategory_1 = require("../greenMetricCategory");
var decodeGreenMetric = function (greenMetric) {
    return {
        created: new Date(greenMetric.created),
        id: greenMetric.green_metric_id,
        projectId: greenMetric.project_id,
        reportCategories: greenMetric.report_categories.map(function (r) {
            return (0, greenMetricCategory_1.decodeGreenMetricCategory)(r);
        }),
        reportTargetName: greenMetric.report_target_name,
    };
};
exports.decodeGreenMetric = decodeGreenMetric;
var encodeGreenMetric = function (greenMetric) {
    var safeGreenMetric = (0, string_1.makePostgresSafe)(greenMetric);
    return {
        created: safeGreenMetric.created,
        green_metric_id: safeGreenMetric.id,
        project_id: safeGreenMetric.projectId,
        report_categories: greenMetric.reportCategories.map(function (r) {
            return (0, greenMetricCategory_1.encodeGreenMetricCategory)(r);
        }),
        report_target_name: safeGreenMetric.reportTargetName,
    };
};
exports.encodeGreenMetric = encodeGreenMetric;
