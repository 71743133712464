import { Grid, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import {
  PackageLocalContentLineItem,
  aggregateLocalContentItems,
} from "social-pro-common/interfaces/packageLocalContent";
import { LidpTableSumRow } from "../LidpTableSumRow/LidpTableSumRow";
import { ContractorAnalyticsLocalContentRow } from "./ContractorAnalyticsLocalContentRow";
import { useState } from "react";
import { LidpTabs } from "../LidpTabs/LidpTabs";
import { NoRows } from "../ListTable/NoRows";
import { LidpTableSumRowSkeleton } from "../LidpTableSumRow/LidpTableSumRowSkeleton";
import { ContractorAnalyticsLocalContentRowSkeleton } from "./ContractorAnalyticsLocalContentRowSkeleton";
import { writeXlsxLidp } from "@utils/xlsx/localContent";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PackageType } from "social-pro-common/entities/contractorPackage";

interface ContractorAnalyticsLocalContentProps {
  loading: boolean;
  isPrimaryPackage: boolean;
  contractorPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
}

export const ContractorAnalyticsLocalContent = ({
  contractorPackage,
  isPrimaryPackage,
  loading,
  localContents,
}: ContractorAnalyticsLocalContentProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const showActual = value === 1;

  const itemsToDisplay = showActual
    ? localContents
    : aggregateLocalContentItems(contractorPackage?.localContent || []);

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Item Description"}
          align="left"
        />
        <TableHeader loading={loading} title={"Manufacturer"} align="left" />
        <TableHeader loading={loading} title={"Supplier"} align="left" />
        <TableHeader loading={loading} title={"Value ($)"} align="center" />
        <TableHeader
          loading={loading}
          title={"Steel Mass (T)"}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Local Value Add"}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Import Value Add"}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Contract Content"}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"ANZ value-add activity (%)"}
          align="center"
        />
        <TableHeader loading={loading} title={"SME Count"} align="center" />
        <TableHeader
          loading={loading}
          title={"Supplier Count"}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginTop: "0", padding: "0 !important" }}
        >
          {isPrimaryPackage &&
          contractorPackage &&
          contractorPackage.packageType == PackageType.PrimaryPackage ? (
            <LidpTabs
              isLoading={loading}
              value={value}
              handleChange={handleChange}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0",
            padding: "0 !important",
          }}
        >
          <StyledButton
            data-test-id="export-targets-button"
            className="blackBtn grey-outline-btn topBarBtn"
            loading={loading}
            disabled={loading || !itemsToDisplay || !itemsToDisplay.length}
            variant="contained"
            onClick={() => {
              if (itemsToDisplay) {
                writeXlsxLidp(itemsToDisplay);
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "30px 40px !important" }}
      >
        {loading ? (
          <Table>
            {tableHeader}
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <ContractorAnalyticsLocalContentRowSkeleton key={index} />
              ))}
              <LidpTableSumRowSkeleton />
            </TableBody>
          </Table>
        ) : (
          <Table data-test-id="localContent-commitment-table">
            {tableHeader}
            <TableBody>
              {itemsToDisplay.map((lc) => {
                return (
                  <ContractorAnalyticsLocalContentRow
                    key={`local-content-table-row-${lc.id}`}
                    localContent={lc}
                  />
                );
              })}
              {itemsToDisplay.length > 0 ? (
                <LidpTableSumRow
                  localContents={itemsToDisplay}
                  hasButtonColumn={false}
                />
              ) : (
                <NoRows title={"Local Content"} colSpan={11} />
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
    </>
  );
};
