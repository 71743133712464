import { Grid, Typography, Skeleton, Chip, Stack, Box } from "@mui/material";

interface WidgetSkeletonProps {
  colour: string;
}

const hexToRgba = (hex: string, opacity: number) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const WidgetSkeleton = ({ colour }: WidgetSkeletonProps) => {
  const backgroundColor = hexToRgba(colour, 0.05);
  return (
    <Grid
      item
      md={2}
      sx={{
        backgroundColor: backgroundColor,
        border: `1px solid ${colour}`,
        borderRadius: "25px",
        objectFit: "cover",
      }}
    >
      <Stack spacing={2} sx={{ padding: "20px" }}>
        <Stack spacing={1}>
          <Box
            sx={{
              display: "flex",
              height: "40px", // Fixed height for title
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Skeleton animation="wave">
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {"Widget Title"}
              </Typography>
            </Skeleton>
          </Box>
          <Skeleton animation="wave">
            <Typography variant="h2" sx={{ fontWeight: "bold" }}>
              {"0.00"}
            </Typography>
          </Skeleton>
        </Stack>
        <Chip
          label={
            <Skeleton animation="wave">
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {"0.00"}
              </Typography>
            </Skeleton>
          }
          size="small"
          sx={{ backgroundColor: colour, color: "#fff" }}
        />
      </Stack>
    </Grid>
  );
};
