import { Skeleton, TableCell, TableRow } from "@mui/material";
export const ViewEmployeeTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
