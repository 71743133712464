import {
  Box,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

interface PackageSustainabilityTableRowProps {
  greenMetric: GreenMetricLineItem;
}

export const PackageSustainabilityTableRow = ({
  greenMetric,
}: PackageSustainabilityTableRowProps) => {
  return (
    <>
      <TableRow key={greenMetric.id}>
        <TableCell align="left" sx={{ minWidth: "10vw" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip
              title={greenMetric.reportTargetName}
              TransitionComponent={Zoom}
            >
              <Typography>{greenMetric.reportTargetName}</Typography>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
