import { Typography, Skeleton } from "@mui/material";

interface LoadingTitleProps {
  loading: boolean;
  title: string;
}

export const LoadingTitle = ({ loading, title }: LoadingTitleProps) => {
  if (loading) {
    return (
      <Skeleton animation="wave">
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          {"Page Title"}
        </Typography>
      </Skeleton>
    );
  }
  return (
    <Typography variant="h2" sx={{ fontWeight: "bold" }}>
      {title}
    </Typography>
  );
};
