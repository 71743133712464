import { TableCell, TableRow, Typography } from "@mui/material";

interface NoRowsProps {
  title: string;
  colSpan: number;
}
export const NoRows = ({ colSpan, title }: NoRowsProps) => {
  return (
    <TableRow sx={{ marginTop: "0px !important" }}>
      <TableCell
        align="center"
        colSpan={colSpan + 1}
        sx={{
          borderRadius: "12px",
          height: "49px !important",
          padding: "0px !important",
        }}
        className="fullBorderRad"
      >
        <Typography
          align="center"
          sx={{
            fontWeight: "bold",
          }}
        >
          No {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
