"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeCommitmentAnalysis = exports.decodePackageReport = exports.decodePackageCommitmentReport = exports.decodeMainChartData = exports.decodeChartData = exports.decodeSubcontractorActivity = exports.decodeProgressChartModel = exports.decodePolicyProgress = exports.decodeDashboardTargetModel = exports.decodeDashboardSubcontractorProgress = exports.decodeDashboardSupplierModel = exports.decodeDashboardModel = void 0;
var number_1 = require("../../utils/number");
var forecastData_1 = require("../forecastData");
var socialBusiness_1 = require("../socialBusiness");
var decodeDashboardModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var top3Subcontractors, bottom3Subcontractors, recommendedSuppliers, subcontractorActivity;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(data.top3_subcontractors.map(function (d) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/, (0, exports.decodeDashboardSubcontractorProgress)(d, getImageUrl)];
                }); }); }))];
            case 1:
                top3Subcontractors = _a.sent();
                return [4 /*yield*/, Promise.all(data.bottom3_subcontractors.map(function (d) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/, (0, exports.decodeDashboardSubcontractorProgress)(d, getImageUrl)];
                    }); }); }))];
            case 2:
                bottom3Subcontractors = _a.sent();
                return [4 /*yield*/, Promise.all(data.recommended_suppliers.map(function (rs) {
                        return (0, exports.decodeDashboardSupplierModel)(rs, getImageUrl);
                    }))];
            case 3:
                recommendedSuppliers = _a.sent();
                return [4 /*yield*/, Promise.all(data.subcontractor_activity.map(function (sa) {
                        return (0, exports.decodeSubcontractorActivity)(sa, getImageUrl);
                    }))];
            case 4:
                subcontractorActivity = _a.sent();
                return [2 /*return*/, {
                        bottom3Subcontractors: bottom3Subcontractors,
                        bottom3Targets: data.bottom3_targets.map(function (d) {
                            return (0, exports.decodeDashboardTargetModel)(d);
                        }),
                        chartData: (0, exports.decodeMainChartData)(data.chart_data),
                        hourProgress: (0, exports.decodeProgressChartModel)(data.hour_progress),
                        policyProgress: (0, exports.decodePolicyProgress)(data.policy_progress),
                        projectPCChange: (0, number_1.formatDecimalPlaces)(data.project_pc_change, 2),
                        projectTargetProgress: (0, number_1.formatDecimalPlaces)(data.project_target_progress, 2),
                        projectTimeProgress: (0, number_1.formatDecimalPlaces)(data.project_time_progress, 2),
                        recommendedSuppliers: recommendedSuppliers,
                        spendProgress: (0, exports.decodeProgressChartModel)(data.spend_progress),
                        subcontractorActivity: subcontractorActivity,
                        top3Subcontractors: top3Subcontractors,
                        top3Targets: data.top3_targets.map(function (d) { return (0, exports.decodeDashboardTargetModel)(d); }),
                    }];
        }
    });
}); };
exports.decodeDashboardModel = decodeDashboardModel;
var decodeDashboardSupplierModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(data.supplier_logo && data.supplier_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl("".concat(socialBusiness_1.SOCIAL_BUSINESS_LOGO_PREFIX, "/").concat(data.supplier_logo))];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        supplierLogo: presignedUrl,
                        supplierName: data.supplier_name,
                        supplierTargetType: data.supplier_target_type,
                    }];
        }
    });
}); };
exports.decodeDashboardSupplierModel = decodeDashboardSupplierModel;
var decodeDashboardSubcontractorProgress = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(data.supplier_logo && data.supplier_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(data.supplier_logo)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        pcChange: (0, number_1.formatDecimalPlaces)(data.pc_change, 2),
                        supplierLogo: presignedUrl,
                        supplierName: data.supplier_name,
                    }];
        }
    });
}); };
exports.decodeDashboardSubcontractorProgress = decodeDashboardSubcontractorProgress;
var decodeDashboardTargetModel = function (data) {
    return {
        achievedValue: data.achieved_value,
        isSpend: data.is_spend,
        progress: data.progress,
        targetDescription: data.target_description,
        targetDisplayValue: data.target_display_value,
        targetType: data.target_type,
    };
};
exports.decodeDashboardTargetModel = decodeDashboardTargetModel;
var decodePolicyProgress = function (data) {
    return {
        expected: data.expected,
        submitted: data.submitted,
    };
};
exports.decodePolicyProgress = decodePolicyProgress;
var decodeProgressChartModel = function (data) {
    return {
        achieved: data.achieved,
        pcChange: data.pc_change,
        target: data.target,
    };
};
exports.decodeProgressChartModel = decodeProgressChartModel;
var decodeSubcontractorActivity = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(data.organisation_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(data.organisation_logo)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        created: new Date(data.created),
                        organisationLogo: presignedUrl,
                        organisationName: data.organisation_name,
                        reportId: data.report_id,
                    }];
        }
    });
}); };
exports.decodeSubcontractorActivity = decodeSubcontractorActivity;
var decodeChartData = function (data) {
    return {
        achievedData: data.achieved_data,
        id: data.id,
    };
};
exports.decodeChartData = decodeChartData;
var decodeMainChartData = function (data) {
    return {
        forecast: data.forecasts.map(function (d) { return (0, forecastData_1.decodeForecastModel)(d); }),
        greenMetrics: (0, exports.decodeChartData)(data.green_metrics),
        labourHours: (0, exports.decodeChartData)(data.labour_hours),
        socialSpend: (0, exports.decodeChartData)(data.social_spend),
    };
};
exports.decodeMainChartData = decodeMainChartData;
var decodePackageCommitmentReport = function (data) {
    return {
        contractorPackageId: data.contractor_package_id,
        packageCommitmentId: data.package_commitment_id,
        projectCommitmentId: data.project_commitment_id,
        projectId: data.project_id,
        reportId: data.report_id,
        targetToDate: data.target_to_date,
        valueAchieved: data.value_achieved,
        valueToDate: data.value_to_date,
    };
};
exports.decodePackageCommitmentReport = decodePackageCommitmentReport;
var decodePackageReport = function (data) {
    return {
        contractorPackageId: data.contractor_package_id,
        currentMonthTarget: data.current_month_target,
        currentMonthTotal: data.current_month_total,
        currentTarget: data.current_target,
        currentTotal: data.current_total,
        packageCommitmentId: data.package_commitment_id,
        packageCommitmentTotal: data.package_commitment_total,
        packageCommitmentValue: data.package_commitment_value,
        projectCommitmentId: data.project_commitment_id,
        projectId: data.project_id,
    };
};
exports.decodePackageReport = decodePackageReport;
var decodeCommitmentAnalysis = function (commitmentAnalysis) {
    return {
        id: commitmentAnalysis.project_commitment_id,
        reported: commitmentAnalysis.reported,
        target: commitmentAnalysis.target,
    };
};
exports.decodeCommitmentAnalysis = decodeCommitmentAnalysis;
