import { getDistance } from "geolib";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

export interface PlaceLatLng {
  lat: number;
  lng: number;
}
export const getGeoDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  return (
    getDistance(
      { latitude: lat1, longitude: lon1 },
      { latitude: lat2, longitude: lon2 },
    ) / 1000
  );
};

export const validatePostCode = (country: string, postCode: string) => {
  if (postcodeValidatorExistsForCountry(country)) {
    const valid = postcodeValidator(postCode, country);
    return valid;
  }
};

export const getIsWithinRadiusFirst = async (
  employee: EmployeeLineItem,
  project: ProjectLineItem,
  maxDistance: number,
) => {
  if (
    project.projectAddress.geoLocationLat &&
    project.projectAddress.geoLocationLong
  ) {
    const distance = getGeoDistance(
      employee.geoLocationLat,
      employee.geoLocationLong,
      project.projectAddress.geoLocationLat,
      project.projectAddress.geoLocationLong,
    );

    return {
      distance: distance,
      withInRadius: distance < maxDistance,
    };
  }
  return { distance: 100000, withInRadius: false };
};
