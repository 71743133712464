import { Box } from "@mui/material";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

import { EmployeeSupportingDocumentSubTable } from "./EmployeeSupportingDocumentSubTable";
import { EmployeeLabourHoursSubTable } from "./EmployeeLabourHoursSubTable";
import { useState } from "react";
import { EmployeeTableTabs } from "./EmployeeTableTabs";

interface EmployeeTableSubRowProps {
  employee: EmployeeLineItem;
}

export const EmployeeTableSubRow = ({ employee }: EmployeeTableSubRowProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Box sx={{ margin: 0 }}>
      <EmployeeTableTabs setTabIndex={setTabIndex} tabIndex={tabIndex} />
      {tabIndex === 0 ? (
        <EmployeeLabourHoursSubTable employee={employee} />
      ) : (
        <EmployeeSupportingDocumentSubTable employee={employee} />
      )}
    </Box>
  );
};
