// material-ui
import {
  Box,
  Button,
  Grid,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

// project imports
import Avatar from "@stories/ui-component/extended/Avatar";

export const ContractorPackageAnalyticsTableRowSkeleton = () => {
  return (
    <TableRow key={`sub-contractor-row-${0}`}>
      <TableCell align="center">
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton animation={"wave"} variant={"circular"}>
              <Avatar />
            </Skeleton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Skeleton animation={"wave"}>
              <Typography align="left" variant="subtitle1" component="div">
                Contractor Name
              </Typography>
            </Skeleton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center">
        <Box display="flex" gap="10px">
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ marginLeft: "auto" }}
          >
            <Button
              color="primary"
              sx={{
                "&:hover ": {
                  background: "#e3f2fd",
                },
                borderColor: "#0063c6",
                color: "#0063c6",
              }}
              size="large"
            ></Button>
          </Skeleton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
