// material-ui
import { styled } from "@mui/material/styles";

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const LayoutWrapper = styled("div")(({ theme }) => ({
  background: "#EEF2F6",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.grey[100],
  boxShadow: "0 10px 80px rgba(0,0,0,0.1)",
  margin: 0,
  minHeight: "100vh",
}));

export default LayoutWrapper;
