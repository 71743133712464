import { Typography, Skeleton, Stack, Chip } from "@mui/material";

interface ChipProgressProps {
  loading: boolean;
  value: string;
  title: string;
  primaryFontColour: string;
  secondaryFontColour: string;
  chipColour: string;
  dataTestId?: string;
}

export const ChipProgress = ({
  chipColour,
  dataTestId,
  loading,
  primaryFontColour,
  secondaryFontColour,
  title,
  value,
}: ChipProgressProps) => {
  if (loading) {
    return (
      <Chip
        label={
          <Stack spacing={1} direction="row">
            <Skeleton animation="wave">
              <Typography
                variant="body1"
                sx={{ color: primaryFontColour, fontWeight: "bold" }}
              >
                0
              </Typography>
            </Skeleton>
            <Skeleton animation="wave">
              <Typography
                variant="caption"
                sx={{
                  color: secondaryFontColour,
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                Active Subcontractors
              </Typography>
            </Skeleton>
          </Stack>
        }
        sx={{ backgroundColor: "#E3E8EA" }}
      />
    );
  }
  return (
    <Chip
      label={
        <Stack spacing={1} direction="row">
          <Typography
            variant="body1"
            sx={{ color: primaryFontColour, fontWeight: "bold" }}
            data-test-id={dataTestId}
          >
            {value}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: secondaryFontColour,
              display: "inline-block",
              fontSize: "12px",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            {title}
          </Typography>
        </Stack>
      }
      sx={{ backgroundColor: chipColour, width: "100%" }}
    />
  );
};
