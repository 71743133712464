import { TableHeaderCell } from "social-pro-common/interfaces/tables";
import { Grid, Table, TableBody, TableContainer } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "../ListTable/NoRows";
import { ProfileGroupTableRowSkeleton } from "./ProfileGroupTableRowSkeleton";
import { ProfileGroupTableRow } from "./ProjectGroupTableRow";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";
import { ProfileProjectAccessLineItem } from "social-pro-common/interfaces/profileProjectAccess";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";

interface ProfileGroupTableProps {
  loading: boolean;
  projects: ProjectDetailLineItem[];
  profiles: ProfileLineItem[];
  selectedAccessLevel?: ProfileProjectAccessLevel;
  selectedProject?: ProjectDetailLineItem;
  profileProjectAccess: ProfileProjectAccessLineItem[];
  handleUpdateAccess: (
    profileProjectAccess: ProfileProjectAccessLineItem,
  ) => void;
}

export const ProfileGroupTable = ({
  handleUpdateAccess,
  loading,
  profileProjectAccess,
  profiles,
  projects,
  selectedAccessLevel,
  selectedProject,
}: ProfileGroupTableProps) => {
  const projectHeadings = selectedProject
    ? [
        {
          align: "left",
          id: selectedProject.id,
          label: selectedProject.projectName,
        } as TableHeaderCell,
      ]
    : projects.map((project) => {
        return {
          align: "left",
          id: project.id,
          label: project.projectName,
        } as TableHeaderCell;
      });

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "name",
      label: "Name",
      minWidth: "10vw",
      width: "10%",
    },
    ...projectHeadings,
  ];

  const profileRows =
    profiles.length > 0
      ? profiles.map((row, index) => {
          const ppa = profileProjectAccess.filter((p) => p.profileId == row.id);
          return (
            <ProfileGroupTableRow
              key={`${row.id}-${index}`}
              profile={row}
              projects={projects}
              selectedAccessLevel={selectedAccessLevel}
              profileProjectAccess={ppa}
              handleUpdateAccess={handleUpdateAccess}
            />
          );
        })
      : null;

  return (
    <>
      {loading ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            padding: "20px 0 !important",
          }}
        >
          <Table>
            <ListTableHead loading={true} headLabel={TABLE_HEAD} />
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <ProfileGroupTableRowSkeleton key={index} projects={projects} />
              ))}
            </TableBody>
          </Table>
        </Grid>
      ) : null}
      {!loading ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            padding: "20px 0 !important",
          }}
        >
          <TableContainer style={{ overflowX: "auto" }}>
            <Table stickyHeader data-test-id="teams-table">
              <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
              <TableBody>
                {profiles.length ? (
                  profileRows
                ) : (
                  <NoRows title={"Matches"} colSpan={1 + projects.length} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
    </>
  );
};
