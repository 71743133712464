import { useProjectContext } from "@hooks/context/useProjectContext";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { ToolsSocialSpendTable } from "@stories/organisms/PackageTools/ToolsSocialSpendTable";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useSocialSpendForTargets } from "@hooks/crud/socialSpend/useSocialSpendForTargets";
import { useState } from "react";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { useSocialSpendExport } from "@hooks/crud/downloadFiles/useSocialSpendExport";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

interface PackageDetailsSpendTargetProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  targetId?: string;
  reportId?: string;
  handleOnBack: () => void;
}

export const PackageDetailsSpendTarget = ({
  contractorPackage,
  handleOnBack,
  isLoading,
  reportId,
  targetId,
}: PackageDetailsSpendTargetProps) => {
  const { downloadProgress, downloadStep, getSocialSpendExcel } =
    useSocialSpendExport();

  const [page, setPage] = useState(0);
  const [sortInfo, setSortInfo] = useState<SortInfo>({
    handleRequestSort: (property: number) => {
      setSortInfo((prev: SortInfo) => {
        const isAsc = prev.orderBy === property && prev.order === "asc";
        return {
          ...sortInfo,
          order: isAsc ? "desc" : "asc",
          orderBy: property,
        };
      });
    },
    index: 0,
    order: "asc",
    orderBy: 0,
  });

  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isSocialSpendLoading, socialSpendCount, socialSpends } =
    useSocialSpendForTargets(
      selectedProject?.id,
      targetId,
      reportId,
      contractorPackage?.id,
      page,
      sortInfo.orderBy,
      sortInfo.order,
    );

  const loading = (isLoading && isProjectLoading) || isSocialSpendLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );

  return (
    <>
      <Grid container>
        <Grid item md={6} sx={{ paddingLeft: "0px !important" }}>
          {loading ? (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="primary" size="large">
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
              </Skeleton>
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {`Spend - ${
                      selectedTarget
                        ? selectedTarget.targetDescription ||
                          socialSpendCommitmentTypeToString(
                            selectedTarget.targetName,
                          )
                        : ""
                    }`}
                  </Typography>
                </Skeleton>
              </Stack>
            </Grid>
          ) : (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
                <IconButton
                  data-test-id="back-button"
                  size="large"
                  onClick={handleOnBack}
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>

              <Stack>
                <Typography variant="h2">
                  {`Spend - ${
                    selectedTarget
                      ? selectedTarget.targetDescription ||
                        socialSpendCommitmentTypeToString(
                          selectedTarget.targetName,
                        )
                      : ""
                  }`}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            className="blackBtn grey-outline-btn"
            loading={loading}
            disabled={loading || !socialSpends || !socialSpends.length}
            variant="contained"
            onClick={() => {
              if (contractorPackage && reportId && targetId) {
                getSocialSpendExcel(
                  contractorPackage.projectId,
                  reportId,
                  targetId,
                  contractorPackage?.id,
                );
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-tb"
          sx={{ margin: "20px 0 0", padding: "0 !important", width: "100%" }}
        >
          <ToolsSocialSpendTable
            loading={loading}
            socialSpends={socialSpends}
            socialSpendCount={socialSpendCount}
            sortInfo={sortInfo}
            setPage={setPage}
          />
        </Grid>
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Labour Hour Export"}
      />
    </>
  );
};
