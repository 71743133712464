import { Skeleton, TableCell, TableRow, TextField } from "@mui/material";

export const ForecastTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center" sx={{ width: "30%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "15%" }}>
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <TextField className="search tableInput" />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
