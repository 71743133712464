import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  Switch,
} from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ChangeEvent, useState } from "react";
import {
  StyledButton,
  StyledLoadingButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface PackageLidpRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  isFinalStep: boolean;
  handleNext: (contractorPackage: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
}

export const PackageLidpRequirementForm = ({
  contractorPackageSetup,
  handleBack,
  handleNext,
  isFinalStep,
  loading,
  project,
}: PackageLidpRequirementFormProps) => {
  const [localContentEnabled, setLidpEnabled] = useState(
    contractorPackageSetup.contractorPackage.financial.lidpPc !== -1,
  );
  const [localContentPcValue, setLidpPcValue] = useState(
    contractorPackageSetup.contractorPackage.financial.lidpPc == -1
      ? project.financial.lidpPc
      : contractorPackageSetup.contractorPackage.financial.lidpPc,
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = () => {
    setErrorMessage(null);
    handleNext({
      ...contractorPackageSetup,
      contractorPackage: {
        ...contractorPackageSetup.contractorPackage,
        financial: {
          ...contractorPackageSetup.contractorPackage.financial,
          lidpPc: localContentEnabled ? localContentPcValue : -1,
        },
      },
    });
  };

  const handleLidpPcChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLidpPcValue(Number(e.target.value));
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",

          justifyContent: "flex-start",
          margin: "0px !important",
          padding: "25px 40px 0px !important",
          width: "100%",
        }}
      >
        <Grid
          item
          md={7}
          sx={{
            alignContent: "left",
            display: "flex",
            justifyContent: "left",
          }}
        >
          <SubTitle title={"Agreed Local Content"} loading={loading} />
        </Grid>
        <Grid
          md={4}
          sx={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "flex-start",
          }}
        >
          {loading ? (
            <Skeleton animation="wave">
              <Switch />
            </Skeleton>
          ) : (
            <>
              <FormControlLabel
                // sx={{ marginLeft: "0px" }}
                labelPlacement="start"
                data-test-id="localContentEnabledForPackage"
                name={"localContentEnabledForPackage"}
                control={
                  <Switch
                    color="success"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setLidpEnabled(e.target.checked);
                    }}
                    checked={localContentEnabled}
                  />
                }
                label="Enable Local Content for package?"
              />
              <StyledTextField
                loading={loading}
                id="localContentPcValue"
                name="localContentPcValue"
                label="Local Content Percentage (%)"
                fullWidth
                disabled={!localContentEnabled}
                onChange={handleLidpPcChange}
                value={formatDecimalPlaces(localContentPcValue)}
                error={Boolean(errorMessage)}
                helperText={errorMessage ? errorMessage : ""}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "auto",
                  },
                  fieldset: {
                    backgroundColor: !localContentEnabled
                      ? "rgba(239, 239, 239, 0.3) !important;"
                      : undefined,
                  },
                  height: "auto",
                  marginLeft: "14px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        backgroundColor: !localContentEnabled
                          ? "rgba(239, 239, 239, 0.3) !important;"
                          : undefined,
                        height: "100%",
                        m: 0,
                        maxHeight: "100%",
                        p: 0,
                      }}
                      position="end"
                    >
                      %
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                }}
              />
            </>
          )}
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
            padding: "0px 0px 40px 0px",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={loading}
              onClick={handleBack}
              disabled={loading}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledLoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {isFinalStep ? "Save" : "Next"}
            </StyledLoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
