"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.packageTypeToString = exports.createDefaultContractorPackage = void 0;
var uuid_1 = require("uuid");
var financial_1 = require("../financial");
var packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
var packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
var contractorPackage_1 = require("../../entities/contractorPackage");
var createDefaultContractorPackage = function (project, packageType, packageCount, contractor) {
    var packageId = (0, uuid_1.v4)();
    var packageCountString = packageCount.toString().trim();
    return {
        commitmentsHours: (0, packageLabourHourCommitment_1.cloneProjectLabourHourCommitmentsForPackage)(packageId, project.commitmentsHours),
        commitmentsSpend: (0, packageSocialSpendCommitment_1.cloneProjectSpendCommitmentsForPackage)(packageId, project.commitmentsSpend),
        contractorAbn: (contractor === null || contractor === void 0 ? void 0 : contractor.abn) || "",
        contractorId: (contractor === null || contractor === void 0 ? void 0 : contractor.id) || "",
        contractorLogo: (contractor === null || contractor === void 0 ? void 0 : contractor.organisationLogo) || "",
        contractorName: (contractor === null || contractor === void 0 ? void 0 : contractor.organisationName) || "",
        created: new Date(),
        estimatedProgress: 0,
        financial: (0, financial_1.createDefaultFinancial)(),
        greenMetricSubmissions: [],
        id: packageId,
        isAccepted: false,
        localContent: [],
        packageEndDate: project.projectEndDate,
        packageNumber: "#".concat(packageCountString.padStart(3, "0")),
        packageStartDate: project.projectStartDate,
        packageType: packageType,
        policies: [],
        projectId: project.id,
        representativeEmail: (contractor === null || contractor === void 0 ? void 0 : contractor.representativeEmail) || "",
        useLabourHour: true,
        useLocalContent: true,
        usePolicies: true,
        useSocialSpend: true,
        useSustainability: true,
    };
};
exports.createDefaultContractorPackage = createDefaultContractorPackage;
var packageTypeToString = function (packageType) {
    switch (packageType) {
        default:
            throw new Error("Unknown package type: ".concat(packageType));
        case contractorPackage_1.PackageType.OneTime:
            return "One Time";
        case contractorPackage_1.PackageType.PrimaryPackage:
            return "Primary Package";
        case contractorPackage_1.PackageType.BalustradesAndHandrail:
            return "Balustrades & Handrail";
        case contractorPackage_1.PackageType.Carpentry:
            return "Carpentry";
        case contractorPackage_1.PackageType.ConcretingAndFormwork:
            return "Concreting & Formwork";
        case contractorPackage_1.PackageType.Consultants:
            return "Consultants";
        case contractorPackage_1.PackageType.CurtainsAndBlinds:
            return "Curtains & Blinds";
        case contractorPackage_1.PackageType.Civil:
            return "Civil";
        case contractorPackage_1.PackageType.Demolition:
            return "Demolition";
        case contractorPackage_1.PackageType.Design:
            return "Design";
        case contractorPackage_1.PackageType.DryFire:
            return "Dry Fire";
        case contractorPackage_1.PackageType.Electrical:
            return "Electrical";
        case contractorPackage_1.PackageType.Excavating:
            return "Excavating";
        case contractorPackage_1.PackageType.Facade:
            return "Facade";
        case contractorPackage_1.PackageType.FacadeAndCladding:
            return "Facade & Cladding";
        case contractorPackage_1.PackageType.Fencing:
            return "Fencing";
        case contractorPackage_1.PackageType.Flooring:
            return "Flooring";
        case contractorPackage_1.PackageType.Hydraulics:
            return "Hydraulics";
        case contractorPackage_1.PackageType.KitchensAndBars:
            return "Kitchens & Bars";
        case contractorPackage_1.PackageType.LabourHire:
            return "Labour Hire";
        case contractorPackage_1.PackageType.Landscaping:
            return "Landscaping";
        case contractorPackage_1.PackageType.Masonry:
            return "Masonry";
        case contractorPackage_1.PackageType.Mechanical:
            return "Mechanical";
        case contractorPackage_1.PackageType.MechanicalGas:
            return "Mechanical Gas";
        case contractorPackage_1.PackageType.Miscellaneous:
            return "Miscellaneous";
        case contractorPackage_1.PackageType.Other:
            return "Other";
        case contractorPackage_1.PackageType.Painting:
            return "Painting";
        case contractorPackage_1.PackageType.Plumbing:
            return "Plumbing";
        case contractorPackage_1.PackageType.Plastering:
            return "Plastering";
        case contractorPackage_1.PackageType.RoofSafetySystems:
            return "Roof Safety Systems";
        case contractorPackage_1.PackageType.Roofing:
            return "Roofing";
        case contractorPackage_1.PackageType.Scaffolding:
            return "Scaffolding";
        case contractorPackage_1.PackageType.Security:
            return "Security";
        case contractorPackage_1.PackageType.Signage:
            return "Signage";
        case contractorPackage_1.PackageType.Structure:
            return "Structure";
        case contractorPackage_1.PackageType.StructuralAndFraming:
            return "Structural & Framing";
        case contractorPackage_1.PackageType.StructuralSteel:
            return "Structural Steel";
        case contractorPackage_1.PackageType.Tiling:
            return "Tiling";
        case contractorPackage_1.PackageType.WetFire:
            return "Wet Fire";
    }
};
exports.packageTypeToString = packageTypeToString;
