//Public routes
export const login = "/login";
export const forgotPassword = "/forgot-password";

//Private routes
export const organisation = "/organisation";
export const profile = "/profile";

export const createProfile = "/create-profile";

export const createAccount = "/create-account";
export const listProjects = "/list-projects";

export const employees = "/employees";
export const profileGroups = "/teams";
export const integrations = "/integrations";
export const organisationDashboard = "/organisation-dashboard";

export const platformSupport = "/platform-support";

export const users = "/users";

export const socialProAdmin = "/social-pro-admin";

export const forecast = (projectId: string) =>
  absolute(["forecast", projectId]);

export const profileGroup = (groupId?: string, panel?: string) =>
  absolute(["group", groupId, panel]);

export const project = (projectId?: string) => absolute(["project", projectId]);
export const userPackage = (projectId?: string) =>
  absolute(["my-package", projectId]);

export const blogDetail = (blogTitle?: string) => absolute(["blog", blogTitle]);

export const contractorDashboard = (projectId: string) =>
  absolute(["dashboard", projectId]);

export const contractorDashboardV2 = (projectId: string) =>
  absolute(["dashboard-v2", projectId]);

export const contractorDashboardPackage = (projectId: string) =>
  absolute(["dashboard", projectId, "package"]);

export const contractors = (projectId: string) =>
  absolute(["sub-contractors", projectId]);

export const packages = (projectId: string) =>
  absolute(["packages", projectId]);

export const projectEmployees = (projectId: string) =>
  absolute(["project-employees", projectId]);

export const projectPolicies = (projectId: string) =>
  absolute(["project-policies", projectId]);

export const projectSustainability = (projectId: string) =>
  absolute(["project-sustainability", projectId]);

export const sustainability = (projectId: string) =>
  absolute(["sustainability", projectId]);

export const contractorPackage = (projectId: string, packageId: string) =>
  absolute(["package-details", projectId, packageId]);

export const analyticsSpend = (projectId: string, reportId?: string) =>
  absolute(["analytics-spend", projectId, reportId]);

export const analyticsViewSpend = (
  projectId: string,
  reportId: string,
  targetId: string,
) => absolute(["view-analytics-spend", projectId, reportId, targetId]);

export const analyticsEnviroSustainability = (
  projectId: string,
  reportId: string,
) => absolute(["analytics-sustainability", projectId, reportId]);

export const analyticsEnviroSustainabilityDetails = (
  projectId: string,
  packageId: string,
  reportId: string,
) =>
  absolute([
    "package-analytics-sustainability",
    projectId,
    packageId,
    reportId,
  ]);

export const analyticsLabourHours = (projectId: string, reportId?: string) =>
  absolute(["analytics-labour-hours", projectId, reportId]);

export const analyticsViewLabourHours = (
  projectId: string,
  reportId: string,
  targetId: string,
) => absolute(["view-analytics-labour-hours", projectId, reportId, targetId]);

export const analyticsPolicies = (projectId: string) =>
  absolute(["analytics-policies", projectId]);

export const analyticsLocalContent = (projectId: string) =>
  absolute(["analytics-localContent", projectId]);

export const analyticsLocalContentDetails = (
  projectId: string,
  packageId: string,
) => absolute(["analytics-localContent", projectId, packageId]);

export const analyticsPackageLocalContentDetails = (
  projectId: string,
  packageId: string,
) => absolute(["package-analytics-local-content", projectId, packageId]);

export const contractorPackageReports = (projectId: string) =>
  absolute(["report-packages", projectId]);

export const contractorProjectReports = (projectId: string) =>
  absolute(["report-project", projectId]);

export const wizard = (
  projectId: string,
  reportId: string,
  contractorPackageId?: string,
) => absolute(["wizard", projectId, reportId, contractorPackageId]);

export const wizardOneTime = (projectId: string, contractorPackageId: string) =>
  absolute(["one-time-wizard", projectId, contractorPackageId]);

export const readOnlyReportSocial = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) => absolute(["view-social-report", projectId, contractorPackageId, reportId]);

export const readOnlyReportSustainability = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) =>
  absolute([
    "view-sustainability-report",
    projectId,
    contractorPackageId,
    reportId,
  ]);

export const sustainabilityWizard = (projectId: string, reportId: string) =>
  absolute(["sustainability-wizard", projectId, reportId]);

export const projectSocialWizard = (projectId: string, reportId: string) =>
  absolute(["project-social-wizard", projectId, reportId]);

export const projectSustainabilityWizard = (
  projectId: string,
  reportId: string,
) => absolute(["project-sustainability-wizard", projectId, reportId]);

export const directory = (projectId: string, tagetId?: string) =>
  absolute(["directory", projectId, tagetId]);

export const absolute = (params: (string | number | undefined)[]): string =>
  `/${params.filter((a) => Boolean(a)).join("/")}`;

export const faqQuestion = (faqId: string) => absolute(["faq-question", faqId]);
