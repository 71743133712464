// material-ui
import { Typography } from "@mui/material";

// project imports
import { getNavMenuItems } from "../menu-items";
import NavGroup from "./NavGroup";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { defaultMenuItem } from "../menu-items/projects";

// ==============================|| SIDEBAR MENU LIST ||============================== //

interface MenuListProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  isDrawOpen: boolean;
  selectedItem: string[];
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
}
const MenuList = ({
  isDrawOpen,
  loading,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
  userProfile,
}: MenuListProps) => {
  // const getMenu = Menu();
  const menuItem = getNavMenuItems(userProfile);

  const content = loading ? (
    <>
      {Array.from({ length: 3 }, (_, index) => (
        <NavGroup
          key={`nav-bar-item-${index}`}
          loading={true}
          item={{
            children: [
              defaultMenuItem(1),
              defaultMenuItem(2),
              defaultMenuItem(3),
            ],
            id: "loading",
            title: "loading",
            type: "group",
          }}
          isDrawOpen={isDrawOpen}
          setIsDrawOpen={setIsDrawOpen}
          selectedItem={[]}
          setSelectedItem={setSelectedItem}
        />
      ))}
    </>
  ) : (
    menuItem.items.map((item) => {
      switch (item.type) {
        case "group":
          return (
            <NavGroup
              loading={loading}
              key={`nav-bar-item-${item.id}`}
              item={item}
              isDrawOpen={isDrawOpen}
              setIsDrawOpen={setIsDrawOpen}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    })
  );
  return <>{content}</>;
};

export default MenuList;
