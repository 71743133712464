import { Skeleton, TableCell, TableRow } from "@mui/material";

export const EmployeeHoursSubTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center" width={"33%"}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" width={"33%"}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" width={"33%"}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" width={"33%"}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
