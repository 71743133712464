"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultContactInfo = void 0;
var uuid_1 = require("uuid");
var createDefaultContactInfo = function () {
    return {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "AU",
        id: (0, uuid_1.v4)(),
        postCode: "",
        state: "",
    };
};
exports.createDefaultContactInfo = createDefaultContactInfo;
