import { getPackageNav, getReducedPackageNav } from "./package";
import { NavItemType } from "types";
import {
  getPolicyReportingNav,
  getReducedPolicyReportingNav,
  getReducedSocialReportingNav,
  getReducedSustainabilityReportingNav,
  getSocialReportingNav,
  getSustainabilityReportingNav,
} from "./reporting";
import { getAnalysisNav } from "./analysis";
import { getDirectoryNav } from "./directory";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getProjectsNav } from "./projects";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";
import { userHasEditorAccess } from "@utils/projectAccess";
import { getOneTimeReportingNav } from "./one-time";
import { PackageType } from "social-pro-common/entities/contractorPackage";

export const getNavMenuItems = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
  isOwner = false,
): { items: NavItemType[] } => {
  if (contractorPackage?.packageType == PackageType.OneTime) {
    return getOneTimeNavMenu(project, contractorPackage);
  }
  const items = [getProjectsNav(project?.projectName || "")];

  if (isOwner) {
    items.push(getPackageNav(project));
  } else {
    items.push(getReducedPackageNav(project));
  }

  if (
    (project?.commitmentsHours.length ||
      project?.commitmentsSpend.length ||
      project?.localContents.length) &&
    userHasEditorAccess(project)
  ) {
    if (isOwner) {
      items.push(getSocialReportingNav(project, sideBarMenuModel));
    } else {
      items.push(getReducedSocialReportingNav(project, sideBarMenuModel));
    }
  }
  if (project?.greenMetrics?.length && userHasEditorAccess(project)) {
    if (isOwner) {
      items.push(getSustainabilityReportingNav(project, sideBarMenuModel));
    } else {
      items.push(
        getReducedSustainabilityReportingNav(project, sideBarMenuModel),
      );
    }
  }

  if (project?.policies?.length && userHasEditorAccess(project)) {
    if (isOwner) {
      items.push(getPolicyReportingNav(project, sideBarMenuModel));
    } else {
      items.push(getReducedPolicyReportingNav(project, sideBarMenuModel));
    }
  }

  items.push(getAnalysisNav(project, contractorPackage, isOwner));

  items.push(getDirectoryNav(project?.id));

  return { items };
};

export const getOneTimeNavMenu = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
): { items: NavItemType[] } => {
  const items = [
    getOneTimeReportingNav(project, contractorPackage),
    getDirectoryNav(project?.id),
  ];

  return { items };
};
