import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";

export enum EmploymentTypeTabOption {
  Standard = "Standard",
  Trainee = "Trainee",
}
interface EmployeeTypeTabsProps {
  isLoading: boolean;
  value: EmploymentTypeTabOption;
  setEmployeeType: (newValue: EmploymentTypeTabOption) => void;
}

export const EmployeeTypeTabs = ({
  isLoading,
  setEmployeeType,
  value,
}: EmployeeTypeTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: EmploymentTypeTabOption,
  ) => {
    if (newValue) {
      setEmployeeType(newValue);
    }
  };

  return (
    <Box marginTop="20px" marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Standard</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Trainees/Apprentices/Cadets</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            data-test-id="standard-button"
            value={EmploymentTypeTabOption.Standard}
            size="small"
          >
            Standard
          </ToggleButton>
          <ToggleButton
            data-test-id="trainee-button"
            value={EmploymentTypeTabOption.Trainee}
            size="small"
          >
            Trainees and Apprentices
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
