"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSocialBusiness = exports.getSocialBusinessLogo = exports.SOCIAL_BUSINESS_LOGO_PREFIX = void 0;
exports.SOCIAL_BUSINESS_LOGO_PREFIX = "companies";
var getSocialBusinessLogo = function (url, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!url.length) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl("".concat(exports.SOCIAL_BUSINESS_LOGO_PREFIX, "/").concat(url))];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, presignedUrl];
        }
    });
}); };
exports.getSocialBusinessLogo = getSocialBusinessLogo;
var decodeSocialBusiness = function (socialBusiness, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(socialBusiness.social_business_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, (0, exports.getSocialBusinessLogo)(socialBusiness.social_business_logo, getImageUrl)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        created: new Date(socialBusiness.created),
                        registered: socialBusiness.registered,
                        socialBusinessAbn: socialBusiness.social_business_abn,
                        socialBusinessBeneficiaries: socialBusiness.social_business_beneficiaries,
                        socialBusinessBlurb: socialBusiness.social_business_blurb,
                        socialBusinessCountry: socialBusiness.social_business_country,
                        socialBusinessEba: socialBusiness.social_business_eba,
                        socialBusinessId: socialBusiness.social_business_id,
                        socialBusinessLocations: socialBusiness.social_business_locations,
                        socialBusinessLogo: presignedUrl,
                        socialBusinessName: socialBusiness.social_business_name,
                        socialBusinessPostCode: socialBusiness.social_business_post_code,
                        socialBusinessServices: socialBusiness.social_business_services,
                        socialBusinessState: socialBusiness.social_business_state,
                        socialBusinessWebsite: socialBusiness.social_business_website,
                    }];
        }
    });
}); };
exports.decodeSocialBusiness = decodeSocialBusiness;
