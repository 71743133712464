import { forwardRef } from "react";
import { Icon, IconifyIcon } from "@iconify/react";
import { Box } from "@mui/material";

interface IconifyProps {
  icon: IconifyIcon | string;
  width?: number;
  sx?: any;
  other?: any;
}
const Iconify = forwardRef(
  ({ icon, sx, width = 20, ...other }: IconifyProps, ref) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      sx={{ height: width, width, ...sx }}
      {...other}
    />
  ),
);

export default Iconify;
