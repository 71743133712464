import { Skeleton, TableCell, TableRow } from "@mui/material";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

interface ProfileGroupTableRowSkeletonProps {
  projects: ProjectDetailLineItem[];
}
export const ProfileGroupTableRowSkeleton = ({
  projects,
}: ProfileGroupTableRowSkeletonProps) => {
  const projectCells = projects.map((project) => {
    return (
      <TableCell key={project.id} align="center">
        <Skeleton animation="wave" />
      </TableCell>
    );
  });

  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      {projectCells}
    </TableRow>
  );
};
