import {
  Box,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { ToastOptions, toast } from "react-toastify";
import YupPassword from "yup-password";

YupPassword(Yup);

interface ResetPasswordSubmitFormProps {
  loginProp?: number;
  emailAddress: string;
  password: string;
  showResetPassword: boolean;
  setShowResetPassword: (showResetPassword: boolean) => void;
}

const ResetPasswordSubmitForm = ({
  emailAddress,
  password,
  setShowResetPassword,
}: ResetPasswordSubmitFormProps) => {
  const { isAuthLoading, resetPassword } = useAuthContext();

  const handleResetPassword = async (
    email: string,
    password: string,
    newPassword: string,
  ) => {
    await resetPassword(email, password, newPassword);
  };

  return (
    <Formik
      initialValues={{
        code: "",
        confirmPassword: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Required"),
        password: Yup.string()
          .required("Password is required")
          .min(
            8,
            "Must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
          )
          .minLowercase(1, "Must contain at least 1 lower case letter")
          .minUppercase(1, "Must contain at least 1 upper case letter")
          .minNumbers(1, "Must contain at least 1 number")
          .minSymbols(1, "Must contain at least 1 special character"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await handleResetPassword(emailAddress, password, values.password);

          setStatus({ success: true });
          setSubmitting(false);

          toast(`Password reset!`, {
            type: "success",
          } as ToastOptions);
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong!", { type: "error" } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
              >
                <TextField
                  id="password"
                  type="password"
                  label="New password"
                  name="password"
                  value={values.password}
                  disabled={isAuthLoading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword,
                )}
              >
                <TextField
                  id="confirmPassword"
                  type="password"
                  label="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  disabled={isAuthLoading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error>
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto", mt: 3 }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography align="center">{errors.submit}</Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ fontFamily: "Roboto", textAlign: "center" }}>
                <Button
                  id="submit_button"
                  disabled={isAuthLoading || isSubmitting}
                  variant={"contained"}
                  fullWidth
                  type="submit"
                  size="large"
                  sx={{
                    background: "#054F81",
                    margin: "0 auto",
                    minWidth: "200px",
                    transition: "0.3s all",
                    width: "auto",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              onClick={() => {
                setShowResetPassword(false);
              }}
            >
              <a href="/login">Start again</a>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordSubmitForm;
