// material-ui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

interface HeaderProps {
  isDrawOpen: boolean;
  setIsDrawOpen: (open: boolean) => void;
}
const Header = ({ isDrawOpen }: HeaderProps) => {
  const theme = useTheme();
  return (
    <>
      {/* logo & toggler button */}
      <Box
        className={isDrawOpen === true ? "admin-header" : "admin-header active"}
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "flex-start",
          padding: "30px 30px 30px 30px",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          width: "100%",
        }}
      >
        <LogoSection />
      </Box>
    </>
  );
};

export default Header;
