import { ContractorAnalyticsPolicyTable } from "./ContractorAnalyticsPolicyTable";
import { PolicyAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";

interface ContractorAnalyticsPolicyProps {
  isLoading: boolean;
  policyAnalytics?: PolicyAnalyticsModelLineItem;
}
export const ContractorAnalyticsPolicy = ({
  isLoading,
  policyAnalytics,
}: ContractorAnalyticsPolicyProps) => {
  const loading = isLoading;
  return (
    <ContractorAnalyticsPolicyTable
      loading={loading}
      policyAnalytics={policyAnalytics}
    />
  );
};
