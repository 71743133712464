import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";

interface LabourHourTableRowProps {
  loading: boolean;
  title: string;
  description: string;
  value: number;
  outcomeMultiplier: number;
  handleEdit: () => void;
  onDelete: () => void;
  canDelete?: boolean;
}

export const LabourHourTableRow = ({
  description,
  handleEdit,
  loading,
  onDelete,
  outcomeMultiplier,
  title,
  value,
}: LabourHourTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow key={title}>
        <TableCell align="left" sx={{ width: "50%" }}>
          <Stack direction="row" spacing={2}>
            <Tooltip
              placement="top"
              title={description}
              TransitionComponent={Zoom}
            >
              <InfoIcon color={"info"} />
            </Tooltip>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Tooltip title={title} TransitionComponent={Zoom}>
                <Typography noWrap>{title}</Typography>
              </Tooltip>
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "30%" }}
          className="projectTextField"
        >
          {`${value ? formatDecimalPlaces(value) : 0}%`}
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {`${Math.round(
            (outcomeMultiplier * value) / 100,
          ).toLocaleString()} hours`}
        </TableCell>
        <TableCell
          align="right"
          sx={{ textAlign: "right !important", width: "10%" }}
        >
          <Box display="flex" gap="10px" justifyContent={"right"}>
            <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
              <IconButton
                color="primary"
                size="large"
                disabled={loading}
                onClick={() => handleEdit()}
              >
                <EditIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
              <IconButton
                color="error"
                size="large"
                disabled={loading}
                onClick={() => setOpenConfirmDialog(true)}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this commitment?"}
        open={openConfirmDialog}
        title={"Delete Commitment"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => onDelete()}
      />
    </>
  );
};
