import { useEffect, useState } from "react";
import * as RouteHelper from "@utils/routes";
import { matchPath, useLocation } from "react-router-dom";

// material-ui
import { styled, useTheme, Theme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "../MenuShared/Header";
import Sidebar from "./Sidebar";

import LAYOUT_CONST, { drawerWidth } from "constant";
import useConfig from "@hooks/utils/useConfig";

// assets
import { ProfileProvider } from "@hooks/context/useProfileContext";
import { ProjectProvider } from "@hooks/context/useProjectContext";
import "./dashboard.css";
function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
  layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ layout, open, theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.sharp,
      }),
    }),
    ...(open && {
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 20,
      },
      [theme.breakpoints.up("md")]: {},
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  }),
);

interface MainDashboardLayoutProps {
  children: React.ReactNode;
}
const MainDashboardLayout = ({ children }: MainDashboardLayoutProps) => {
  const theme = useTheme();
  const location = useLocation();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.forecast(":projectId"),
    RouteHelper.contractorDashboard(":projectId"),
    RouteHelper.packages(":projectId"),
    RouteHelper.contractorPackage(":projectId", ":packageId"),
    RouteHelper.userPackage(":projectId"),
    RouteHelper.analyticsViewLabourHours(
      ":projectId",
      ":reportId",
      ":targetId",
    ),
    RouteHelper.analyticsViewSpend(":projectId", ":reportId", ":targetId"),
    RouteHelper.analyticsSpend(":projectId", ":reportId"),
    RouteHelper.analyticsSpend(":projectId", ":reportId"),
    RouteHelper.analyticsLabourHours(":projectId", ":reportId"),
    RouteHelper.analyticsPolicies(":projectId"),
    RouteHelper.analyticsEnviroSustainability(":projectId", ":reportId"),
    RouteHelper.analyticsEnviroSustainabilityDetails(
      ":projectId",
      ":packageId",
      ":reportId",
    ),
    RouteHelper.analyticsLocalContent(":projectId"),
    RouteHelper.analyticsLocalContentDetails(":projectId", ":packageId"),
    RouteHelper.projectSustainability(":projectId"),
    RouteHelper.sustainability(":projectId"),
    RouteHelper.projectEmployees(":projectId"),
    RouteHelper.wizard(":projectId", ":packageId"),
    RouteHelper.wizardOneTime(":projectId", ":packageId"),
    RouteHelper.projectSocialWizard(":projectId", ":reportId"),
    RouteHelper.projectSustainabilityWizard(":projectId", ":reportId"),
    RouteHelper.contractorPackageReports(":projectId"),
    RouteHelper.contractorProjectReports(":projectId"),
    RouteHelper.projectPolicies(":projectId"),
    RouteHelper.directory(":projectId"),
    RouteHelper.readOnlyReportSocial(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.readOnlyReportSustainability(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
  ]);

  const [isDrawOpen, setIsDrawOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  useEffect(() => {
    let currentPathId =
      routeMatch?.pattern?.path.split("/")[1] || "NO_ID_SELECTED";

    if (currentPathId === "project-social-wizard") {
      currentPathId = "report-project";
    }

    if (currentPathId === "view-social-report") {
      currentPathId = "report-project";
    }

    if (currentPathId === "project-sustainability-wizard") {
      currentPathId = "project-sustainability";
    }

    if (currentPathId === "view-sustainability-report") {
      currentPathId = "project-sustainability";
    }

    if (currentPathId === "wizard") {
      currentPathId = "report-packages";
    } else if (currentPathId === "one-time-wizard") {
      currentPathId = "report-packages";
    }

    if (currentPathId === "package-details") {
      currentPathId = "packages";
    }

    if (currentPathId === "report") {
      const path = routeMatch?.pattern?.path.split("/");
      if (path) {
        if (path.length > 3) {
          currentPathId = "submit-report";
        } else {
          currentPathId = "view-report";
        }
      }
    }

    if (currentPathId === "analytics") {
      if (routeMatch?.pattern?.path.split("/")[2] === "labourHours") {
        currentPathId = "labour-hours";
      } else if (routeMatch?.pattern?.path.split("/")[2] === "spend") {
        currentPathId = "spend";
      }
    }
    setSelectedItem([currentPathId]);
  }, [location]);

  const { container, layout } = useConfig();

  const header = (
    <Toolbar
      sx={{
        padding: "0 !important",
        width: "260px",
      }}
    >
      <Header setIsDrawOpen={setIsDrawOpen} isDrawOpen={isDrawOpen} />
    </Toolbar>
  );

  return (
    <ProfileProvider>
      <ProjectProvider>
        <Box sx={{ display: "flex" }} className="dashboardPage">
          <CssBaseline />
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ background: "transparent" }}
          >
            {header}
          </AppBar>
          {/* drawer */}
          {!isDrawOpen ? (
            <div className="active">
              {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
                <Sidebar
                  isDrawOpen={isDrawOpen}
                  selectedItem={selectedItem}
                  setIsDrawOpen={setIsDrawOpen}
                  setSelectedItem={setSelectedItem}
                />
              )}
            </div>
          ) : (
            <div className="normal">
              {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
                <Sidebar
                  isDrawOpen={isDrawOpen}
                  selectedItem={selectedItem}
                  setIsDrawOpen={setIsDrawOpen}
                  setSelectedItem={setSelectedItem}
                />
              )}
            </div>
          )}

          {/* main content */}
          <Main theme={theme} open={isDrawOpen} layout={layout}>
            <Container
              maxWidth={container ? "lg" : false}
              {...(!container && { sx: { px: { xs: 0 } } })}
            >
              <Box
                sx={{ backgroundColor: "white", borderRadius: "30px 0 0 0" }}
              >
                {children}
              </Box>
            </Container>
          </Main>
        </Box>
      </ProjectProvider>
    </ProfileProvider>
  );
};

export default MainDashboardLayout;
