import { useCallback, useEffect, useState } from "react";
import { getApiData } from "@hooks/utils/api";
import { SustainabilitySubDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { decodeSustainabilitySubDataModel } from "social-pro-common/decoders/organisationDashboard";
import { SustainabilitySubDataModel } from "social-pro-common/entities/dashboard";
import { catchSentryError } from "@utils/sentry";

export const useOrganisationSustainabilitySubData = () => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sustainabilitySubData, setSustainabilitySubData] =
    useState<SustainabilitySubDataModelLineItem>();

  const getSustainabilitySubData = useCallback(async () => {
    try {
      setIsAnalysisLoading(true);
      const analysisResult = await getApiData(
        "getSustainabilitySubData",
        "organisationDashboard",
        "",
      );
      const sustainabilitySubData = decodeSustainabilitySubDataModel(
        analysisResult.data as SustainabilitySubDataModel,
      );
      setSustainabilitySubData(sustainabilitySubData);
    } catch (error) {
      catchSentryError(error);
      setError("Could not fetch chart data");
    } finally {
      setIsAnalysisLoading(false);
    }
  }, [getApiData, decodeSustainabilitySubDataModel, catchSentryError]);

  useEffect(() => {
    getSustainabilitySubData();
  }, [getSustainabilitySubData]);

  return {
    error,
    isAnalysisLoading,
    sustainabilitySubData,
  };
};