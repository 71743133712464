import { policyTypeToString } from "social-pro-common/interfaces/projectPolicy";
import {
  Button,
  Chip,
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { PolicyTargetAnalyticLineItem } from "social-pro-common/interfaces/analytics";
import { getDocumentName } from "social-pro-common/utils/string";

interface ContractorAnalyticsPolicyRowProps {
  policyAnalysis: PolicyTargetAnalyticLineItem;
  isDownloading: boolean;
  onDownload: (uri: string, policyName: string) => void;
}
export const ContractorAnalyticsPolicyRow = ({
  isDownloading,
  onDownload,
  policyAnalysis,
}: ContractorAnalyticsPolicyRowProps) => {
  return (
    <TableRow>
      <TableCell align="left" sx={{ width: "30%" }}>
        <Typography align="left">
          {policyAnalysis.policyDescription ||
            policyTypeToString(policyAnalysis.policyType)}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center", width: "10%" }}>
        {policyAnalysis.isProjectLevelPolicy ? (
          <Chip label="Project Level Policy" />
        ) : undefined}
      </TableCell>
      <TableCell sx={{ textAlign: "right", width: "20%" }}>
        <Typography align="center">
          {policyAnalysis.expectedSubmissions || 0}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "right", width: "20%" }}>
        <Typography align="center">
          {policyAnalysis.totalSubmissions || 0}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "right", width: "20%" }}>
        <Typography align="center">
          {formatDecimalPlaces(policyAnalysis.progress)}%
        </Typography>
      </TableCell>
      <TableCell align="center" className="right">
        {isDownloading ? (
          <CircularProgress />
        ) : (
          <Button
            data-test-id="policy-details-button"
            color="primary"
            size="large"
            disabled={isDownloading || !policyAnalysis.uri}
            onClick={() =>
              policyAnalysis.uri
                ? onDownload(
                    policyAnalysis.uri,
                    getDocumentName(
                      policyTypeToString(policyAnalysis.policyType),
                      policyAnalysis.uri,
                    ),
                  )
                : undefined
            }
            className="blackBtn grey-outline-btn"
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            Download
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
