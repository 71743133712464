import { useState } from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { ReportSubmissionTable } from "@stories/molecules/ReportSubmissionTable/ReportSubmissionTable";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { FileGenerationStatus } from "social-pro-common/entities/fileGeneration";

interface ReportSubmissionsProps {
  loading: boolean;
  selectedProject?: ProjectLineItem;
  contractorPackages: ContractorPackageLineItem[];
  reportSubmissions: ReportSubmissionLineItem[];
  reportId: string;
  downloadStep: FileGenerationStatus;
  downloadProgress: number;
  downloadPdf: (
    projectId: string,
    reportId: string,
    contractorPackageId?: string,
  ) => void;
  viewReportSubmission: (reportId: string, rowId: string) => void;
  deleteReportSubmission: (report: ReportSubmissionLineItem) => Promise<void>;
  updateReportSubmission: (report: ReportSubmissionLineItem) => Promise<void>;
}

export const ReportSubmissions = ({
  contractorPackages,
  deleteReportSubmission,
  downloadPdf,
  downloadProgress,
  downloadStep,
  loading,
  reportId,
  reportSubmissions,
  selectedProject,
  updateReportSubmission,
  viewReportSubmission,
}: ReportSubmissionsProps) => {
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          padding: "20px 40px 0px 40px",
        }}
        className="borderTop"
      >
        <Grid item md={10}>
          {loading ? (
            <Stack>
              <Skeleton animation="wave">
                <Typography variant="h2">{"Report Confirmation"}</Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="subtitle1">{"September 2000"}</Typography>
              </Skeleton>
            </Stack>
          ) : (
            <Stack>
              <Typography variant="h2">{"Report Confirmation"}</Typography>
              <Typography variant="subtitle1">
                {`${selectedProject?.projectName}: ${
                  reportId ? formatReportDate(stringToDate(reportId)) : ""
                }`}
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <SearchInput
            loading={loading}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSearchChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sx={{ padding: "30px 40px 30px" }}>
        <ReportSubmissionTable
          loading={loading}
          project={selectedProject}
          contractorPackages={contractorPackages}
          reportSubmissions={reportSubmissions}
          reportId={reportId}
          filterName={searchValue}
          deleteReportSubmission={deleteReportSubmission}
          updateReportSubmission={updateReportSubmission}
          viewReadOnlyReport={viewReportSubmission}
          downloadProgress={downloadProgress}
          downloadStep={downloadStep}
          downloadPdf={downloadPdf}
        />
      </Grid>
    </>
  );
};
