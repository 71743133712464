"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultUser = void 0;
var uuid_1 = require("uuid");
var createDefaultUser = function (email, isContractor, id) {
    return {
        email: email,
        id: id ? id : (0, uuid_1.v4)(),
        isContractor: isContractor,
        isSocialProAdmin: false,
    };
};
exports.createDefaultUser = createDefaultUser;
