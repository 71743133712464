import { useCallback, useState, useEffect } from "react";
import { listApiData, postBatchApiData } from "@hooks/utils/api";
import { PackageCommentLineItem } from "social-pro-common/interfaces/packageComment";
import { PackageComment } from "social-pro-common/entities/packageComment";
import {
  decodePackageComment,
  encodePackageComment,
} from "social-pro-common/decoders/packageComment";
import { catchSentryError } from "@utils/sentry";

export const usePackageComments = (
  projectId?: string,
  reportId?: string,
  contractorPackageId?: string,
) => {
  const [comments, setComments] = useState<PackageCommentLineItem[]>([]);
  const [isCommentsLoading, setIsCommentLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listPackageComments = useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      reportId: string,
    ) => {
      try {
        setIsCommentLoading(true);
        const commentResult = await listApiData(
          "listPackageComments",
          "packageComments",
          projectId,
          { contractorPackageId, reportId },
        );
        setComments(
          commentResult.data.map((c) =>
            decodePackageComment(c as PackageComment),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list comments");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [setComments, setIsCommentLoading, setError],
  );

  const upsertPackageComments = useCallback(
    async (commentsToUpsert: PackageCommentLineItem[]) => {
      try {
        setIsCommentLoading(true);
        const encodedComments = commentsToUpsert.map((e) =>
          encodePackageComment(e),
        );
        await postBatchApiData(
          "upsertPackageComments",
          "packageComments",
          encodedComments,
        );

        const updatedComments = comments.reduce((acc, comment) => {
          const updatedComment = commentsToUpsert.find(
            (e) => e.id === comment.id,
          );
          if (updatedComment) {
            return [...acc, updatedComment];
          }
          return [...acc, comment];
        }, [] as PackageCommentLineItem[]);

        const createdComments = commentsToUpsert.filter(
          (comment) => !updatedComments.find((e) => e.id === comment.id),
        );

        setComments([...updatedComments, ...createdComments]);
      } catch (e) {
        catchSentryError(e);
        setError("Could not update employee");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [comments, setComments, setIsCommentLoading, setError],
  );

  useEffect(() => {
    if (projectId && reportId && contractorPackageId) {
      listPackageComments(projectId, contractorPackageId, reportId);
    } else {
      setIsCommentLoading(false);
    }
  }, [projectId, reportId, contractorPackageId, listPackageComments]);

  return {
    comments,
    error,
    isCommentsLoading,
    upsertPackageComments,
  };
};