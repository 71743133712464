import { Skeleton, TableCell, TableRow, TextField } from "@mui/material";

interface LabourHourTableRowSkeletonProps {
  showStudiedHours: boolean;
}

export const LabourHourTableRowSkeleton = ({
  showStudiedHours,
}: LabourHourTableRowSkeletonProps) => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        {showStudiedHours ? (
          <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
            <TextField className="search tableInput" />
          </Skeleton>
        ) : undefined}
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <TextField className="search tableInput" />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
