import { Typography, Skeleton, Grid, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalContentRowDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { toTitleCase } from "social-pro-common/utils/string";

interface AnzStackProps {
  loading: boolean;
  targets: LocalContentRowDataModelLineItem[];
  url?: string;
  dataTestId?: string;
  compact?: boolean;
}

export const AnzStack = ({
  compact = false,
  dataTestId,
  loading,
  targets,
  url,
}: AnzStackProps) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <Grid item md={compact ? 6 : 8} sx={{ width: "100%" }}>
        <Box
          border={1}
          padding={2}
          sx={{
            border: "1px solid #E3E8EA",
            borderRadius: "25px",
            boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
            height: "100%",
            marginRight: "5px",
            padding: "20px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Skeleton animation="wave">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {"Summary"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={3}>
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"First Column"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={3}>
              <Skeleton animation="wave" sx={{ marginLeft: "auto" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"First Column"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={3}>
              <Skeleton animation="wave" sx={{ marginLeft: "auto" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  align="right"
                >
                  {"Second Column"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={3}>
              <Skeleton animation="wave" sx={{ marginLeft: "auto" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  align="right"
                >
                  {"Third Column"}
                </Typography>
              </Skeleton>
            </Grid>

            {Array.from({ length: 6 }, () => (
              <>
                <Grid item md={3}>
                  <Skeleton animation="wave">
                    <Typography variant="caption">{"Title"}</Typography>
                  </Skeleton>
                </Grid>
                <Grid
                  item
                  md={3}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Skeleton
                    animation="wave"
                    sx={{ marginLeft: "auto" }}
                    variant="rounded"
                  >
                    <Typography variant="body1" align="center">
                      {"Title"}
                    </Typography>
                  </Skeleton>
                </Grid>
                <Grid
                  item
                  md={3}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Skeleton
                    animation="wave"
                    sx={{ marginLeft: "auto" }}
                    variant="rounded"
                  >
                    <Typography variant="body1" align="center">
                      {"Title"}
                    </Typography>
                  </Skeleton>
                </Grid>

                <Grid
                  item
                  md={3}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Skeleton
                    animation="wave"
                    sx={{ marginLeft: "auto" }}
                    variant="rounded"
                  >
                    <Typography variant="body1" align="center">
                      {"Title"}
                    </Typography>
                  </Skeleton>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  }
  return (
    <Grid item md={compact ? 6 : 8} sx={{ width: "100%" }}>
      <Box
        border={1}
        padding={2}
        sx={{
          "&:hover ": {
            border: "1px solid #495a61",
          },
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
          cursor: "pointer",
          height: "100%",
          marginRight: "5px",
          padding: "20px",
        }}
        onClick={() => {
          if (url) {
            navigate(url);
          }
        }}
        data-test-id={`${dataTestId}-landmark`}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {"Summary"}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {"Item"}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {"Value Reported"}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {"ANZ Value Add %"}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {"Target Local Content %"}
            </Typography>
          </Grid>
          {targets.map((target) => (
            <>
              <Grid item md={3}>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                  className={`${dataTestId}-title`}
                >
                  {toTitleCase(target.title)}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Typography
                  variant="body2"
                  align="right"
                  className={`${dataTestId}-value`}
                >
                  ${formatDecimalPlaces(target.value || 0)}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Typography
                  align="right"
                  sx={{
                    display: "inline-block",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                  className={`${dataTestId}-value`}
                >
                  {`${formatDecimalPlaces(target.anzValueAdd || 0)}%`}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Typography
                  align="right"
                  sx={{
                    display: "inline-block",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                  className={`${dataTestId}-value`}
                >
                  {`${formatDecimalPlaces(target.target || 0)}%`}
                </Typography>
              </Grid>
            </>
          ))}
          {!targets.length ? (
            <Grid item md={12}>
              <Chip size="small" label={"No data"} sx={{ width: "100%" }} />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};
