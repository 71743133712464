import { Typography, Skeleton, Grid, Box, Tooltip, Zoom } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ForecastSubDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { formatChartDate, stringToDate } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { toTitleCase } from "social-pro-common/utils/string";

interface ForecastStackProps {
  loading: boolean;
  forecast?: ForecastSubDataModelLineItem;
  url?: string;
  dataTestId?: string;
}

export const ForecastStack = ({
  dataTestId,
  forecast,
  loading,
  url,
}: ForecastStackProps) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <Grid item md={4}>
        <Box
          border={1}
          padding={2}
          sx={{
            border: "1px solid #E3E8EA",
            borderRadius: "25px",
            boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
            height: "100%",
            marginLeft: "10px",
            padding: "20px",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={1}>
            <Grid item md={12}>
              <Skeleton animation="wave">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {"Forecast"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"3-Month Forecast"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid
              item
              md={6}
              justifyContent={"right"}
              alignItems={"right"}
              display={"flex"}
            >
              <Skeleton animation="wave">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  align="right"
                >
                  {"Hours"}
                </Typography>
              </Skeleton>
            </Grid>
            {Array.from({ length: 3 }, () => (
              <>
                <Grid item md={6}>
                  <Skeleton animation="wave">
                    <Typography variant="caption">{"Title"}</Typography>
                  </Skeleton>
                </Grid>
                <Grid
                  item
                  md={6}
                  justifyContent={"right"}
                  alignItems={"right"}
                  display={"flex"}
                >
                  <Skeleton animation="wave" variant="rounded">
                    <Typography variant="caption">{"Value"}</Typography>
                  </Skeleton>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container justifyContent={"space-between"} spacing={1}>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"To Date"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave" sx={{ margin: "auto" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  align="right"
                >
                  {"%"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="caption">{"Actual"}</Typography>
              </Skeleton>
            </Grid>
            <Grid
              item
              md={6}
              justifyContent={"right"}
              alignItems={"right"}
              display={"flex"}
            >
              <Skeleton animation="wave" variant="rounded">
                <Typography variant="caption">{"0.00%"}</Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="caption">{"Forecast"}</Typography>
              </Skeleton>
            </Grid>
            <Grid
              item
              md={6}
              justifyContent={"right"}
              alignItems={"right"}
              display={"flex"}
            >
              <Skeleton animation="wave" variant="rounded">
                <Typography variant="caption">{"0.00%"}</Typography>
              </Skeleton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
  return (
    <Grid item md={4}>
      <Box
        border={1}
        padding={2}
        sx={{
          "&:hover ": {
            border: "1px solid #495a61",
          },
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
          cursor: "pointer",
          height: "100%",
          marginLeft: "10px",
          padding: "20px",
        }}
        onClick={() => {
          if (url) {
            navigate(url);
          }
        }}
        data-test-id={`${dataTestId}-landmark`}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {"Forecast"}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {"3-Month Forecast"}
            </Typography>
          </Grid>
          <Grid item md={6} alignItems={"center"}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {"Hours"}
            </Typography>
          </Grid>
          {forecast?.forecastData.map((fc) => (
            <>
              <Grid item md={6}>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                  className={`${dataTestId}-title`}
                >
                  {toTitleCase(formatChartDate(stringToDate(fc.title)))}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title={formatDecimalPlaces(fc.value)}
                >
                  <Typography
                    variant="caption"
                    className={`${dataTestId}-value`}
                  >
                    {formatDecimalPlaces(fc.value)}
                  </Typography>
                </Tooltip>
              </Grid>
            </>
          ))}
        </Grid>
        <br />
        <Grid container justifyContent={"space-between"} spacing={1}>
          <Grid item md={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {"To Date"}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {"%"}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                display: "inline-block",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {"Forecast"}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            justifyContent={"right"}
            alignItems={"right"}
            display={"flex"}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={
                forecast?.forecastTotal
                  ? `${formatDecimalPlaces(forecast?.forecastTotal)}%`
                  : "0.00%"
              }
            >
              <Typography
                align="right"
                sx={{
                  display: "inline-block",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
                data-test-id={`${dataTestId}-total`}
              >
                {forecast?.forecastTotal
                  ? `${formatDecimalPlaces(forecast?.forecastTotal)}%`
                  : "0.00%"}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                display: "inline-block",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {"Actual"}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            justifyContent={"right"}
            alignItems={"right"}
            display={"flex"}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={
                forecast?.pcAchieved
                  ? `${formatDecimalPlaces(forecast?.pcAchieved)}%`
                  : "0.00%"
              }
            >
              <Typography
                align="right"
                sx={{
                  display: "inline-block",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
                data-test-id={`${dataTestId}-actual`}
              >
                {forecast?.pcAchieved
                  ? `${formatDecimalPlaces(forecast?.pcAchieved)}%`
                  : "0.00%"}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
