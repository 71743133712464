import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  InputAdornment,
  FormControlLabel,
  Switch,
  Typography,
  CircularProgress,
  Stack,
  FormControl,
} from "@mui/material";
import {
  ContractorPackageLineItem,
  createDefaultContractorPackage,
  ContractorPackageSetupLineItem,
} from "social-pro-common/interfaces/contractorPackage";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { useEffect, useState } from "react";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { validateABN } from "social-pro-common/utils/abn";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import { useOrganisationByABN } from "@hooks/crud/organisation/useOrganisationByABN";
import {
  createDefaultProfile,
  ProfileLineItem,
} from "social-pro-common/interfaces/profile";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { createDefaultUser } from "social-pro-common/interfaces/user";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import dayjs from "dayjs";
import { stringToDate } from "social-pro-common/utils/date";
import { cleanString } from "social-pro-common/utils/string";

interface OneTimePackageDetailsFormProps {
  loading: boolean;
  project: ProjectLineItem;
  packageCount: number;
  selectedOneTimePackage?: ContractorPackageSetupLineItem;
  handleSubmit: (
    contractor: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ) => void;
}

export default function OneTimePackageDetailsForm({
  handleSubmit,
  loading,
  packageCount,
  project,
  selectedOneTimePackage,
}: OneTimePackageDetailsFormProps) {
  const [contractor, setContractor] = useState<
    OrganisationLineItem | undefined
  >(selectedOneTimePackage?.organisation);

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const { isOrganisationLoading, searchOrganisationsByAbn } =
    useOrganisationByABN();

  const validationSchema = yup.object({
    abn: yup
      .string()
      .required("ABN is required")
      .test(
        "Valid ABN",
        "Not a valid ABN - i.e. 43 665 205 185",
        function (item) {
          return validateABN(item);
        },
      ),
    description: yup.string().required("Description is required"),
    email: yup.string().email().required("Contractor email is required"),
    labourHours: yup.boolean(),
    localContent: yup.boolean(),
    name: yup.string().required("Contact name is required"),
    policies: yup.boolean(),
    reportDate: yup.date().required("Report date is required"),
    socialSpend: yup.boolean(),
    sustainability: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      abn: "",
      description:
        selectedOneTimePackage?.contractorPackage?.packageDescription || "",
      email: contractor?.representativeEmail || "",
      labourHours: false,
      localContent: false,
      name: contractor?.representativeName || "",
      policies: false,
      reportDate: selectedOneTimePackage?.contractorPackage
        ? dayjs(
            stringToDate(
              selectedOneTimePackage?.contractorPackage.packageStartDate,
            ),
          )
        : dayjs(stringToDate(project.projectStartDate)),
      socialSpend: false,
      sustainability: false,
    },
    onSubmit: async (values) => {
      const {
        labourHours,
        localContent,
        policies,
        socialSpend,
        sustainability,
      } = values;
      if (
        !labourHours &&
        !socialSpend &&
        !localContent &&
        !policies &&
        !sustainability
      ) {
        setShowErrorMessage(true);
        return;
      }
      if (contractor) {
        const organisation = contractor;
        if (organisation.transient) {
          organisation.representativeEmail = values.email;
          organisation.representativeName = values.name;

          const user = createDefaultUser(values.email, false);
          const profile = createDefaultProfile(organisation, user);
          profile.name = values.name;
          const contractorPackage = createDefaultContractorPackage(
            project,
            PackageType.OneTime,
            packageCount,
            organisation,
          );

          if (!values.labourHours) {
            contractorPackage.commitmentsHours = [];
          } else {
            contractorPackage.commitmentsHours =
              selectedOneTimePackage?.contractorPackage?.commitmentsHours.map(
                (commitment) => ({
                  ...commitment,
                  targetValue: 0,
                  targetValueRealised: 0,
                }),
              ) || [];
          }
          if (!values.socialSpend) {
            contractorPackage.commitmentsSpend =
              selectedOneTimePackage?.contractorPackage?.commitmentsSpend.map(
                (commitment) => ({
                  ...commitment,
                  targetValue: 0,
                  targetValueRealised: 0,
                }),
              ) || [];
          }
          contractorPackage.useLabourHour = values.labourHours;
          contractorPackage.useSocialSpend = values.socialSpend;
          contractorPackage.usePolicies = values.policies;
          contractorPackage.useLocalContent = values.localContent;
          contractorPackage.useSustainability = values.sustainability;

          await handleSubmit(
            organisation,
            contractorPackage,
            organisation.transient ? profile : undefined,
          );
        } else {
          const user = createDefaultUser(values.email, false);
          const profile = createDefaultProfile(organisation, user);
          const contractorPackage = createDefaultContractorPackage(
            project,
            PackageType.OneTime,
            packageCount,
            organisation,
          );
          await handleSubmit(
            organisation,
            contractorPackage,
            organisation.transient ? profile : undefined,
          );
        }
      }
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    const searchAbn = async () => {
      if (formik.values.abn && formik.touched.abn && !formik.errors.abn) {
        const isValid = validateABN(formik.values.abn);
        if (isValid) {
          const query = cleanString(formik.values.abn);
          const organisation = await searchOrganisationsByAbn(query);

          if (organisation) {
            await formik.setFieldValue(
              "email",
              organisation?.representativeEmail,
              false,
            );
            await formik.setFieldValue(
              "name",
              organisation?.representativeName,
              false,
            );
            setContractor(organisation);
          }
        } else {
          await formik.setFieldValue("email", "", false);
          await formik.setFieldValue("name", "", false);
          setContractor(undefined);
        }
      } else {
        await formik.setFieldValue("email", "", false);
        await formik.setFieldValue("name", "", false);
        setContractor(undefined);
      }
    };
    searchAbn();
  }, [formik.values.abn, formik.errors.abn, formik.touched.abn]);

  const isDisabled = isOrganisationLoading || formik.isSubmitting;
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          direction={"row"}
          sx={{
            margin: "0",
            padding: "0px 20px 0px 0px",
            width: "100%",
          }}
        >
          <Grid item md={12} mb={2}>
            <SubTitle loading={loading} title={"Supplier Details"} />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="abn"
              name="abn"
              label="Supplier ABN"
              data-test-id="supplier-abn"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.abn}
              error={formik.touched.abn && Boolean(formik.errors.abn)}
              helperText={formik.touched.abn ? formik.errors.abn : ""}
              InputProps={{
                endAdornment: isOrganisationLoading ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                    }}
                  >
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : undefined,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FormControl fullWidth>
              <StyledDatePicker
                loading={loading}
                label="Report Date"
                onChange={(value: dayjs.Dayjs) => {
                  if (value) {
                    formik.setFieldValue("reportDate", value, true);
                  }
                }}
                openTo="month"
                views={["year", "month"]}
                value={formik.values.reportDate}
                slotProps={{
                  popper: {
                    popperOptions: {
                      modifiers: [
                        {
                          enabled: true,
                          fn: ({ state }: { state: Partial<any> }) => {
                            state.styles.popper.height = "358px";
                            if (state.placement.includes("top-start")) {
                              state.styles.popper = {
                                ...state.styles.popper,
                                alignItems: "flex-end",
                                display: "flex",
                              };
                            }
                            if (state.placement.includes("bottom")) {
                              state.styles.popper = {
                                ...state.styles.popper,
                                display: "block",
                              };
                            }
                          },
                          name: "applyCustom",
                          phase: "beforeWrite",
                          requires: ["computeStyles"],
                        },
                      ],
                    },
                  },
                  textField: {
                    "data-test-id": "package-start-date",
                    error:
                      formik.touched.reportDate &&
                      Boolean(formik.errors.reportDate),
                    helperText:
                      formik.touched.reportDate &&
                      Boolean(formik.errors.reportDate)
                        ? "Invalid date"
                        : formik.errors.reportDate,
                  },
                }}
                minDate={dayjs(project.projectStartDate)}
                maxDate={dayjs(project.projectEndDate)}
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="email"
              name="email"
              label="Email"
              data-test-id="email"
              fullWidth
              disabled={isDisabled || !contractor?.transient}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="name"
              name="name"
              label="Name"
              data-test-id="name"
              fullWidth
              disabled={isDisabled || !contractor?.transient}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
          </Grid>
          <Grid item md={12}>
            <StyledTextField
              loading={loading}
              id="description"
              name="description"
              label="Description"
              data-test-id="description"
              fullWidth
              disabled={isDisabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description ? formik.errors.description : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid item md={12}>
              <Typography variant="h5">Supplier</Typography>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">Company Name:</Typography>
                <Typography data-test-id="one-time-org-name">
                  {contractor?.organisationName || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">Address Line 1:</Typography>
                <Typography data-test-id="one-time-address-line1">
                  {contractor?.contactInfo.addressLine1 || "-"}
                </Typography>
              </Stack>
            </Grid>

            {contractor?.contactInfo.addressLine2 && (
              <Grid item md={12}>
                <Stack spacing={1} direction="row">
                  <Typography variant="body1">Address Line 2:</Typography>
                  <Typography data-test-id="one-time-address-line2">
                    {contractor?.contactInfo.addressLine2 || "-"}
                  </Typography>
                </Stack>
              </Grid>
            )}
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">City:</Typography>
                <Typography data-test-id="one-time-city">
                  {contractor?.contactInfo.city || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">State:</Typography>
                <Typography data-test-id="one-time-state">
                  {contractor?.contactInfo.state || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">Country:</Typography>
                <Typography data-test-id="one-time-country">
                  {contractor?.contactInfo.country || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1} direction="row">
                <Typography variant="body1">Postcode:</Typography>
                <Typography data-test-id="one-time-post-code">
                  {contractor?.contactInfo.postCode || "-"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid container>
              <Grid item md={12}>
                <Typography variant="h5">Targets</Typography>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        data-test-id="labour-hour-toggle"
                        disabled={isDisabled}
                        checked={formik.values.labourHours}
                        onChange={(event, checked) => {
                          setShowErrorMessage(false);
                          formik.setFieldValue("labourHours", checked);
                        }}
                      />
                    }
                    label={!loading ? "Labour Hours" : undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        data-test-id="spend-toggle"
                        disabled={isDisabled}
                        checked={formik.values.socialSpend}
                        onChange={(event, checked) => {
                          setShowErrorMessage(false);
                          formik.setFieldValue("socialSpend", checked);
                        }}
                      />
                    }
                    label={!loading ? "Social Spend" : undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        data-test-id="local-content-toggle"
                        disabled={isDisabled}
                        checked={formik.values.localContent}
                        onChange={(event, checked) => {
                          setShowErrorMessage(false);
                          formik.setFieldValue("localContent", checked);
                        }}
                      />
                    }
                    label={!loading ? "Local Content" : undefined}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            {showErrorMessage && (
              <Typography variant="body2" color="error">
                Choose at least one target
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            margin: "0",
            padding: "0px 20px 20px 0px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              alignItems: "flex-end",
              display: "flex !important",
              justifyContent: "end",
            }}
          />
          <StyledButton
            loading={loading}
            variant="contained"
            type="submit"
            disabled={
              !contractor || isDisabled || !formik.isValid || showErrorMessage
            }
          >
            Save
          </StyledButton>
        </Grid>
      </form>
    </Box>
  );
}
