"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeReportSubmission = exports.decodeReportSubmission = void 0;
var string_1 = require("../../utils/string");
var decodeReportSubmission = function (reportSubmission) {
    return {
        accepted: reportSubmission.accepted,
        contractorPackageId: reportSubmission.contractor_package_id,
        created: new Date(reportSubmission.created),
        id: reportSubmission.report_submission_id,
        projectId: reportSubmission.project_id,
        rejectMessage: reportSubmission.reject_message,
        reportId: reportSubmission.report_id,
        reportStatus: reportSubmission.report_status,
    };
};
exports.decodeReportSubmission = decodeReportSubmission;
var encodeReportSubmission = function (reportSubmission) {
    var safeReportSubmission = (0, string_1.makePostgresSafe)(reportSubmission);
    return {
        accepted: reportSubmission.accepted,
        contractor_package_id: safeReportSubmission.contractorPackageId,
        created: safeReportSubmission.created,
        project_id: safeReportSubmission.projectId,
        reject_message: safeReportSubmission.rejectMessage,
        report_id: safeReportSubmission.reportId,
        report_status: safeReportSubmission.reportStatus,
        report_submission_id: safeReportSubmission.id,
    };
};
exports.encodeReportSubmission = encodeReportSubmission;
