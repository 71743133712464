import * as React from "react";
import { Grid, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import SendIcon from "@mui/icons-material/Send";
import { API_END_POINT_AUTH } from "init";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { ToastOptions, toast } from "react-toastify";

interface ContactSectionProps {
  loading: boolean;
}

export const sendSupportEmail = async (
  email: string,
  company: string,
  enquiryType: string,
  message: string,
) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({
      company,
      email,
      enquiryType,
      message,
      method: "support",
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

export default function ContactSection({ loading }: ContactSectionProps) {
  const { userOrganisation, userProfile } = useProfileContext();
  const [subject, setSubject] = React.useState("");

  const [text, setText] = React.useState("");

  const handleChange = (event: any) => {
    setText(event.target.value);
  };

  const sendMail = () => {
    if (userProfile && userOrganisation) {
      try {
        if (subject.length && text.length) {
          sendSupportEmail(
            userProfile.email,
            userOrganisation.organisationName,
            subject,
            text,
          );
          toast("Message sent - we will respond shortly!", {
            type: "success",
          } as ToastOptions);
        } else {
          toast("Failed to send - subject or message empty!", {
            type: "error",
          } as ToastOptions);
        }
      } catch (e) {
        toast("Failed to send - something went wrong!", {
          type: "error",
        } as ToastOptions);
      }
    }
  };

  return (
    <>
      <Grid item md={12}>
        {loading ? (
          <Stack direction="column">
            <Skeleton animation="wave">
              <Typography
                variant="h2"
                component="div"
                fontWeight="bold"
                fontSize="28px"
              >
                Contact Form
              </Typography>
            </Skeleton>

            <Skeleton animation="wave">
              <Typography>
                Submit an equiry to SocialPro or email support@socialproapp.com
              </Typography>
            </Skeleton>
          </Stack>
        ) : (
          <Stack direction="column">
            <Typography
              variant="h2"
              component="div"
              fontWeight="bold"
              fontSize="28px"
            >
              Contact Form
            </Typography>
            <Typography sx={{ mt: 1.5 }} variant="h4" fontWeight="normal">
              Submit an equiry to SocialPro or email support@socialproapp.com
            </Typography>
          </Stack>
        )}
      </Grid>
      <Grid item md={10} sx={{ marginTop: "40px" }}>
        <StyledTextField
          loading={loading}
          id="enquiryType"
          name="enquiryType"
          label="Enquiry Type"
          value={subject}
          fullWidth
          margin="dense"
          onChange={(event) => setSubject(event.target.value)}
        />
      </Grid>

      <Grid item sm={10} sx={{ marginTop: "20px" }}>
        <TextField
          fullWidth
          multiline
          rows={10}
          label="Message"
          InputProps={{
            minRows: 5,
          }}
          value={text}
          onChange={handleChange}
        />
      </Grid>

      <Grid
        item
        sm={8}
        sx={{ display: "flex", margin: "auto", marginTop: "20px" }}
        alignItems={"right"}
        justifyContent={"flex-end"}
      >
        <Stack direction="row" spacing={2}>
          <StyledButton
            className="blackBtn"
            loading={loading}
            variant="contained"
            startIcon={<SendIcon />}
            onClick={sendMail}
          >
            Send
          </StyledButton>
        </Stack>
      </Grid>
    </>
  );
}
