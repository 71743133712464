import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface PolicyTableRowProps {
  loading: boolean;
  title: string;
  description: string;
  onDelete: () => void;
}

export const PolicyTableRow = ({
  description,
  loading,
  onDelete,
  title,
}: PolicyTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow key={title}>
        <TableCell align="left" sx={{ minWidth: "10vw" }}>
          <Stack direction="row" spacing={2}>
            <Tooltip
              placement="top"
              title={description}
              TransitionComponent={Zoom}
            >
              <InfoIcon color={"info"} />
            </Tooltip>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography>{title}</Typography>
            </Box>
          </Stack>
        </TableCell>

        <TableCell
          align="right"
          sx={{ minWidth: "10vw", textAlign: "right !important" }}
        >
          <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
            <IconButton
              color="error"
              size="large"
              disabled={loading}
              data-test-id="delete-policy"
              onClick={() => setOpenConfirmDialog(true)}
            >
              <DeleteIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this policy?"}
        open={openConfirmDialog}
        title={"Delete Policy"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => onDelete()}
      />
    </>
  );
};
