import { Box, Skeleton, TableCell, TableRow, Typography } from "@mui/material";

export const PackageSustainabilityTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell align="left" sx={{ minWidth: "10vw" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              textOverflow: "ellipsis",
            }}
          >
            <Skeleton variant="text" animation="wave">
              <Typography>Target Name</Typography>
            </Skeleton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
