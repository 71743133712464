import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ContractorAnalyticsPolicySubRowSkeleton } from "./ContractorAnalyticsPolicyRowSkeleton";
import { ContractorAnalyticsPolicyRow } from "./ContractorAnalyticsPolicyRow";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { PolicyAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";
import { NoRows } from "../ListTable/NoRows";
import { downloadFile } from "@hooks/utils/useUpload";
import { useState } from "react";

interface ContractorAnalyticsPolicySubRowProps {
  loading: boolean;
  policyAnalytics?: PolicyAnalyticsModelLineItem;
}
export const ContractorAnalyticsPolicyTable = ({
  loading,
  policyAnalytics,
}: ContractorAnalyticsPolicySubRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Policy Name"}
          sx={{ width: "20%" }}
          align="left"
        />
        <TableHeader loading={loading} title={""} sx={{ width: "20%" }} />
        <TableHeader
          loading={loading}
          title={"Expected Submissions"}
          sx={{ width: "20%" }}
        />
        <TableHeader
          loading={loading}
          title={"Total Submitted"}
          sx={{ width: "20%" }}
        />
        <TableHeader
          loading={loading}
          title={"Progress"}
          sx={{ width: "10%" }}
        />
        <TableHeader
          loading={loading}
          title={"Download"}
          sx={{ width: "10%" }}
        />
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer>
      {loading ? (
        <Table size="small" aria-label="purchases">
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorAnalyticsPolicySubRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          data-test-id="policy-commitments-table"
          size="small"
          aria-label="purchases"
        >
          {tableHeader}
          <TableBody>
            {policyAnalytics
              ? policyAnalytics.targets.map((pa) => {
                  return (
                    <ContractorAnalyticsPolicyRow
                      key={pa.id}
                      policyAnalysis={pa}
                      isDownloading={isDownloading}
                      onDownload={onDownload}
                    />
                  );
                })
              : null}
            {policyAnalytics &&
            policyAnalytics.targets?.length === 0 &&
            !loading ? (
              <NoRows title={"policy analytics"} colSpan={5} />
            ) : null}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
