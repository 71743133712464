import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Skeleton,
  Button,
} from "@mui/material";
import { ChartTargetType } from "social-pro-common/entities/analysis";
interface LidpTabsProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  value: ChartTargetType;
  handleChange: (
    event: React.SyntheticEvent,
    newValue: ChartTargetType,
  ) => void;
}

export const DashboardChartTabs = ({
  handleChange,
  isLoading,
  project,
  value,
}: LidpTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings
  if (isLoading) {
    return (
      <Box marginBottom="20px">
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Actual</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Expected</Button>
          </Skeleton>
        </Stack>
      </Box>
    );
  }
  return (
    <Box marginBottom="20px">
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {project && project?.commitmentsHours.length > 0 ? (
          <ToggleButton
            value={ChartTargetType.Hour}
            size="small"
            data-test-id="labour-hour-toggle"
          >
            Labour Hours
          </ToggleButton>
        ) : null}
        {project && project?.commitmentsSpend.length > 0 ? (
          <ToggleButton
            value={ChartTargetType.Spend}
            size="small"
            data-test-id="spend-toggle"
          >
            Spend
          </ToggleButton>
        ) : null}
        {/* {project && project?.greenMetrics.length > 0 ? (
          <ToggleButton value={ChartTargetType.GreenMetric} size="small">
            Environmental Sustainability
          </ToggleButton>
        ) : null} */}
      </ToggleButtonGroup>
    </Box>
  );
};
