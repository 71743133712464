import { Chip } from "@mui/material";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
interface ReportStatusChipProps {
  reportStatus: ReportStatus;
}

export const ReportStatusChip = ({ reportStatus }: ReportStatusChipProps) => {
  switch (reportStatus) {
    default:
    case ReportStatus.ToSubmit:
    case ReportStatus.Upcoming:
      return (
        <Chip
          label="Upcoming"
          size="small"
          color="warning"
          sx={{ color: "#fff", magin: "auto" }}
        />
      );
    case ReportStatus.InProgress:
      return (
        <Chip
          label="In Progress"
          size="small"
          color="warning"
          sx={{ color: "#fff", magin: "auto" }}
        />
      );
    case ReportStatus.Complete:
      return (
        <Chip
          label="Complete"
          size="small"
          color="primary"
          sx={{ color: "#fff", magin: "auto" }}
        />
      );
    case ReportStatus.Overdue:
      return (
        <Chip
          label="Overdue"
          size="small"
          color="error"
          sx={{ color: "#fff", magin: "auto" }}
        />
      );
    case ReportStatus.Accepted:
      return (
        <Chip
          label="Accepted"
          size="small"
          color="success"
          sx={{ color: "#fff", magin: "auto" }}
        />
      );
  }
};
