import { Box, Skeleton, Table, TableBody, Typography } from "@mui/material";
import { AccordianPanel } from "@stories/atoms/AccordianPanel/AccordianPanel";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { DashboardSubcontractorActivityLineItem } from "social-pro-common/interfaces/analysis";
import {
  getTimeSinceCreation,
  monthNames,
  stringToDate,
} from "social-pro-common/utils/date";

interface SubcontractorActivityProps {
  loading: boolean;
  activity: DashboardSubcontractorActivityLineItem[];
}

export const SubcontractorActivity = ({
  activity,
  loading,
}: SubcontractorActivityProps) => {
  return (
    <Box display="flex" flexDirection="column">
      {loading ? (
        <Skeleton>
          <AccordianPanel label="Recent Activity">
            <div></div>
          </AccordianPanel>
        </Skeleton>
      ) : (
        <AccordianPanel label="Recent Activity">
          {!loading && activity.length == 0 ? (
            <Table>
              <TableBody>
                <NoRows title={"Recent Activity"} colSpan={6} />
              </TableBody>
            </Table>
          ) : undefined}
          {loading
            ? Array.from({ length: 3 }).map(() => {
                return (
                  <Box
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#f5fbff",
                      display: "flex",
                      gap: "7px",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        background: "#CCD9E2",
                        borderRadius: "5px",
                        display: "flex",
                        height: "40px",
                        justifyContent: "center",
                        padding: "5px",
                        width: "40px",
                      }}
                    >
                      <Skeleton variant="circular" animation="wave">
                        <img
                          style={{ backgroundColor: "#8FC9F9" }}
                          width={20}
                        />
                      </Skeleton>
                    </Box>
                    <Box>
                      <Skeleton>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "700",
                            lineHeight: "15px",
                          }}
                        >
                          {"Subcontractor name"}
                        </Typography>
                      </Skeleton>

                      <Skeleton>
                        <Typography
                          component="p"
                          sx={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {0} ago
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                );
              })
            : undefined}
          {!loading &&
            activity.map((act) => {
              const reportDate = stringToDate(act.reportId);
              return (
                <Box
                  sx={{
                    alignItems: "center",
                    backgroundColor: "#f5fbff",
                    borderRadius: "10px",
                    display: "flex",
                    gap: "7px",
                    padding: "8px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      background: "transparent",
                      borderRadius: "5px",
                      display: "flex",
                      height: "40px",
                      justifyContent: "center",
                      padding: "5px",
                      width: "40px",
                    }}
                  >
                    <img
                      style={{
                        backgroundColor: "#8FC9F9",
                        borderRadius: "5px",
                      }}
                      width={20}
                      alt={act.organisationName}
                      src={act.organisationLogo}
                    />
                  </Box>
                  <Box sx={{ width: "calc(100% - 40px)" }}>
                    <Typography
                      data-test-id="recent-activity"
                      variant="h6"
                      component="h6"
                      sx={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "700",
                        lineHeight: "15px",
                      }}
                    >
                      {`${act.organisationName} submitted ${
                        monthNames[reportDate.getMonth()]
                      } report`}
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        color: "gray",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {getTimeSinceCreation(act.created)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </AccordianPanel>
      )}
    </Box>
  );
};
