import { useCallback, useState, useEffect } from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import { ProjectReportSubmissionLineItem } from "social-pro-common/interfaces/projectReportSubmission";
import {
  decodeProjectReportSubmission,
  encodeProjectReportSubmission,
} from "social-pro-common/decoders/projectReportSubmission";
import { ProjectReportSubmission } from "social-pro-common/entities/projectReportSubmission";
import { catchSentryError } from "@utils/sentry";

export const useProjectReportSustainabilitySubmission = (
  projectId?: string,
) => {
  const [projectReportSubmissions, setProjectReportSubmissions] = useState<
    ProjectReportSubmissionLineItem[]
  >([]);
  const [isProjectReportLoading, setIsProjectReportLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listProjectReportSubmissions = useCallback(
    async (id: string): Promise<void> => {
      try {
        setIsProjectReportLoading(true);
        const reportResult = await listApiData(
          "listProjectReportSubmissions",
          "projectReportSustainabilitySubmission",
          id,
        );
        reportResult.data.reverse();
        setProjectReportSubmissions(
          reportResult.data.map((p) =>
            decodeProjectReportSubmission(p as ProjectReportSubmission),
          ),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list reports");
      } finally {
        setIsProjectReportLoading(false);
      }
    },
    [setIsProjectReportLoading, setProjectReportSubmissions],
  );

  const createProjectReportSubmission = useCallback(
    async (
      report: ProjectReportSubmissionLineItem,
    ): Promise<ProjectReportSubmissionLineItem> => {
      try {
        setIsProjectReportLoading(true);
        const encodedProjectReport = encodeProjectReportSubmission(report);
        const upsertedReport = await postApiData(
          "createProjectReportSubmission",
          "projectReportSustainabilitySubmission",
          encodedProjectReport,
        );
        const decodedProjectReport = decodeProjectReportSubmission(
          upsertedReport.data as ProjectReportSubmission,
        );
        setProjectReportSubmissions((prevSubmissions) => [
          ...prevSubmissions,
          decodedProjectReport,
        ]);
        return decodedProjectReport;
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create report");
      } finally {
        setIsProjectReportLoading(false);
      }
      return report;
    },
    [setIsProjectReportLoading, setProjectReportSubmissions],
  );

  const updateProjectReportSubmission = useCallback(
    async (report: ProjectReportSubmissionLineItem): Promise<void> => {
      try {
        setIsProjectReportLoading(true);
        const encodedProjectReport = encodeProjectReportSubmission(report);
        await postApiData(
          "updateProjectReportSubmission",
          "projectReportSustainabilitySubmission",
          encodedProjectReport,
        );
        setProjectReportSubmissions((prevSubmissions) =>
          prevSubmissions.map((p) => (p.id !== report.id ? p : report)),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update report");
      } finally {
        setIsProjectReportLoading(false);
      }
    },
    [setIsProjectReportLoading, setProjectReportSubmissions],
  );

  const deleteProjectReportSubmission = useCallback(
    async (report: ProjectReportSubmissionLineItem): Promise<void> => {
      try {
        setIsProjectReportLoading(true);
        const encodedProjectReport = encodeProjectReportSubmission(report);
        await postApiData(
          "deleteProjectReportSubmission",
          "projectReportSustainabilitySubmission",
          encodedProjectReport,
        );
        setProjectReportSubmissions((prevSubmissions) =>
          prevSubmissions.filter((p) => p.id !== report.id),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete report");
      } finally {
        setIsProjectReportLoading(false);
      }
    },
    [setIsProjectReportLoading, setProjectReportSubmissions],
  );

  useEffect(() => {
    if (projectId) {
      listProjectReportSubmissions(projectId);
    }
  }, [projectId, listProjectReportSubmissions]);

  return {
    createProjectReportSubmission,
    deleteProjectReportSubmission,
    error,
    isProjectReportLoading,
    listProjectReportSubmissions,
    projectReportSubmissions,
    updateProjectReportSubmission,
  };
};
