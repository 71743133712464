import { TableCell, TableRow, Typography } from "@mui/material";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface ContractorAnalyticsLocalContentRowProps {
  localContent: PackageLocalContentLineItem;
}

export const ContractorAnalyticsLocalContentRow = ({
  localContent,
}: ContractorAnalyticsLocalContentRowProps) => {
  return (
    <TableRow key={localContent.id}>
      <TableCell align="center">{localContent.itemDescription}</TableCell>
      <TableCell align="center">{localContent.manufacturer}</TableCell>
      <TableCell align="center">{localContent.supplier}</TableCell>
      <TableCell align="center">
        <Typography align="center">
          {formatDecimalPlaces(localContent.value)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {formatDecimalPlaces(localContent.steelMass)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {formatDecimalPlaces(localContent.localValueAdd)}%
        </Typography>
      </TableCell>
      <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
        <Typography align="center">
          {formatDecimalPlaces(localContent.importValueAdd)}%
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {formatDecimalPlaces(localContent.contractContent, 4)}%
        </Typography>
      </TableCell>
      <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
        <Typography align="center">
          {formatDecimalPlaces(localContent.anzValueAdd, 4)}%
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">{localContent.smeCount}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">{localContent.supplierCount}</Typography>
      </TableCell>
    </TableRow>
  );
};
