import { useCallback, useState, useEffect } from "react";
import { getApiData } from "@hooks/utils/api";
import { decodeOrganisationDashboardModel } from "social-pro-common/decoders/organisationDashboard";
import { OrganisationDashboardModel } from "social-pro-common/entities/organisationDashboard";
import { getImageUrl } from "@hooks/utils/useUpload";
import { OrganisationDashboardModelLineItem } from "social-pro-common/interfaces/organisationDashboard";
import { catchSentryError } from "@utils/sentry";

export const useOrganisationDashboard = () => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [dashboardData, setDashboardData] =
    useState<OrganisationDashboardModelLineItem>();

  const getOrganisationDashboardModel = useCallback(async () => {
    try {
      setIsAnalysisLoading(true);
      const analysisResult = await getApiData(
        "getDashboardModel",
        "organisationDashboard",
        "",
      );

      const decodedDashboardModel = await decodeOrganisationDashboardModel(
        analysisResult.data as OrganisationDashboardModel,
        getImageUrl,
      );
      setDashboardData(decodedDashboardModel);
    } catch (error) {
      catchSentryError(error);
      setError("Could not fetch chart data");
    } finally {
      setIsAnalysisLoading(false);
    }
  }, [getApiData, decodeOrganisationDashboardModel, getImageUrl, catchSentryError]);

  useEffect(() => {
    getOrganisationDashboardModel();
  }, [getOrganisationDashboardModel]);

  return {
    dashboardData,
    error,
    isAnalysisLoading,
  };
};