import { useCallback, useEffect, useState } from "react";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { decodeSocialSpendTargetModel } from "social-pro-common/decoders/socialSpend";
import { getApiData } from "@hooks/utils/api";
import { SocialSpendTargetModel } from "social-pro-common/entities/socialSpend";
import { catchSentryError } from "@utils/sentry";

export const useSocialSpendForTargets = (
  projectId?: string,
  targetId?: string,
  reportId?: string,
  contractorPackageId?: string,
  page = 0,
  sortColumn = 0,
  sortDirection?: "asc" | "desc",
) => {
  const [socialSpendCount, setSocialSpendCount] = useState<number>(0);
  const [socialSpends, setSocialSpends] = useState<SocialSpendLineItem[]>([]);
  const [isSocialSpendLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listSocialSpendForTarget = useCallback(
    async (
      projectId: string,
      targetId: string,
      reportId: string,
      contractorPackageId?: string,
      page = 0,
      sortColumn = 0,
      sortDirection?: "asc" | "desc",
    ) => {
      try {
        setIsLoading(true);
        const socialSpendResult = await getApiData(
          "listSocialSpendForTarget",
          "socialSpend",
          projectId,
          {
            contractorPackageId,
            page: page.toString(),
            reportId,
            sortColumn,
            sortDirection,
            targetId,
          },
        );
        const socialSpendModel = decodeSocialSpendTargetModel(
          socialSpendResult.data as SocialSpendTargetModel,
        );

        setSocialSpendCount(socialSpendModel.totalSocialSpend);
        setSocialSpends(socialSpendModel.socialSpend);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list social spends");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeSocialSpendTargetModel, catchSentryError],
  );

  useEffect(() => {
    if (projectId && targetId && reportId) {
      listSocialSpendForTarget(
        projectId,
        targetId,
        reportId,
        contractorPackageId,
        page,
        sortColumn,
        sortDirection,
      );
    } else {
      setIsLoading(false);
    }
  }, [
    projectId,
    targetId,
    reportId,
    contractorPackageId,
    page,
    sortColumn,
    sortDirection,
    listSocialSpendForTarget,
  ]);

  return {
    error,
    isSocialSpendLoading,
    listSocialSpendForTarget,
    socialSpendCount,
    socialSpends,
  };
};