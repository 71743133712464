import { Typography } from "@mui/material";
import { getNavMenuItems } from "../menu-items";
import NavGroup from "./NavGroup";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";
import { defaultMenuItem } from "@stories/layout/ProjectsLayout/menu-items/projects";

// ==============================|| SIDEBAR MENU LIST ||============================== //

interface MenuListProps {
  loading: boolean;
  selectedProject?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  sideBarMenuModel?: SideBarMenuModelLineItem;
  isOwner: boolean;
  isDrawOpen: boolean;
  selectedItem: string[];
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
}
const MenuList = ({
  contractorPackage,
  isDrawOpen,
  isOwner,
  loading,
  selectedItem,
  selectedProject,
  setIsDrawOpen,
  setSelectedItem,
  sideBarMenuModel,
}: MenuListProps) => {
  // const getMenu = Menu();
  const menuItem = getNavMenuItems(
    selectedProject,
    contractorPackage,
    sideBarMenuModel,
    isOwner,
  );

  const content = loading ? (
    <>
      {Array.from({ length: 3 }, (_, index) => (
        <NavGroup
          key={`nav-bar-item-${index}`}
          loading={true}
          item={{
            children: [
              defaultMenuItem(1),
              defaultMenuItem(2),
              defaultMenuItem(3),
            ],
            id: "loading",
            title: "loading",
            type: "group",
          }}
          isDrawOpen={isDrawOpen}
          setIsDrawOpen={setIsDrawOpen}
          selectedItem={[]}
          setSelectedItem={setSelectedItem}
        />
      ))}
    </>
  ) : (
    menuItem.items.map((item) => {
      switch (item.type) {
        case "group":
          return (
            <NavGroup
              loading={loading}
              key={`nav-bar-item-${item.id}`}
              item={item}
              isDrawOpen={isDrawOpen}
              setIsDrawOpen={setIsDrawOpen}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    })
  );
  return <>{content}</>;
};

export default MenuList;
