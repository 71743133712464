import { useEffect, useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import ProfileSection from "../../MenuShared/ProfileSection";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MenuList from "../MenuList";
import LogoSection from "../../MenuShared/LogoSection2";
import MiniDrawerStyled from "./MiniDrawerStyled";

import { drawerWidth } from "constant";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useSideBarInfo } from "@hooks/crud/sideBarInfo/useSideBarInfo";

interface SidebarProps {
  isDrawOpen: boolean;
  selectedItem: string[];
  setIsDrawOpen: (open: boolean) => void;
  setSelectedItem: (item: string[]) => void;
}

const Sidebar = ({
  isDrawOpen,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: SidebarProps) => {
  const theme = useTheme();
  const { isAuthProfileLoading } = useProfileContext();
  const { contractorPackage, isOwner, isProjectLoading, selectedProject } =
    useProjectContext();
  const { getSidebarMenuModel, sideBarMenuModel } = useSideBarInfo();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    [],
  );

  useEffect(() => {
    if (contractorPackage?.id) {
      getSidebarMenuModel(contractorPackage?.id);
    }
  }, [contractorPackage, selectedItem]);

  const loading = isAuthProfileLoading || isProjectLoading || !selectedProject;

  const drawerContent = (
    <MenuList
      loading={loading}
      selectedProject={selectedProject}
      contractorPackage={contractorPackage}
      sideBarMenuModel={sideBarMenuModel}
      isOwner={isOwner}
      isDrawOpen={isDrawOpen}
      setIsDrawOpen={setIsDrawOpen}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
    />
  );

  const drawerSX = {
    background: "transparent",
    marginTop: isDrawOpen ? 0 : "120px",
    paddingLeft: isDrawOpen ? "16px" : 0,
    paddingRight: isDrawOpen ? "16px" : 0,
  };

  const drawer = matchDownMd ? (
    <Box sx={drawerSX}>{drawerContent}</Box>
  ) : (
    <PerfectScrollbar
      component="div"
      style={{
        height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 89px)",
        ...drawerSX,
      }}
    >
      {drawerContent}
    </PerfectScrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
      className="sidebar sidebarmain"
    >
      {matchDownMd || isDrawOpen ? (
        <>
          <Drawer
            variant={matchUpMd ? "persistent" : "temporary"}
            anchor="left"
            open={isDrawOpen}
            onClose={() => setIsDrawOpen(!isDrawOpen)}
            sx={{
              "& .MuiDrawer-paper": {
                background: "transparent",
                borderRight: "none",
                color: "white",
                paddingBottom: "60px",
                width: drawerWidth,
                zIndex: 1099,
              },
            }}
            ModalProps={{ keepMounted: true }}
            color="white"
          >
            {matchDownMd && logo}
            {drawer}

            <div className="sidebar-prf">
              <ProfileSection />
            </div>
          </Drawer>
        </>
      ) : (
        <MiniDrawerStyled variant="permanent" open={isDrawOpen}>
          {/* {drawer} */}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;
