import React, { useEffect, useState } from "react";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import "./PlatforSupportStyles.css";
import {
  SupportServicesTabOptions,
  SupportServicesTabs,
} from "@stories/molecules/SupportServicesTabs/SupportServicesTabs";
import FAQSection from "@stories/molecules/FAQSection/FAQSection";
import { TutorialSection } from "@stories/molecules/TutorialSection/TutorialSection";
import ContactSection from "@stories/molecules/ContactSection/ContactSection";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const PlatformSupport = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<SupportServicesTabOptions>(
    SupportServicesTabOptions.Faqs,
  );

  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: SupportServicesTabOptions,
  ) => {
    if (newValue) {
      setSelectedTab(newValue);
    }
  };

  if (!userProfile) {
    throw new Error("No user profile to list projects");
  }

  const loading = isAuthProfileLoading;

  const pageContent = (() => {
    switch (selectedTab) {
      default:
      case SupportServicesTabOptions.Faqs:
        return <FAQSection loading={loading} />;
      case SupportServicesTabOptions.Tutorials:
        return <TutorialSection loading={loading} />;
      case SupportServicesTabOptions.ContactUs:
        return <ContactSection loading={loading} />;
    }
  })();

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab == "tutorials") {
      setSelectedTab(SupportServicesTabOptions.Tutorials);
    } else if (tab == "faqs") {
      setSelectedTab(SupportServicesTabOptions.Faqs);
    } else if (tab == "contact") {
      setSelectedTab(SupportServicesTabOptions.ContactUs);
    }
    setSearchParams();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0",
        }}
      >
        <PageHeader loading={loading} title="Help Center" />
        <Grid
          md={12}
          item
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <SupportServicesTabs
            isLoading={loading}
            value={selectedTab}
            handleChange={handleChange}
          />
        </Grid>

        <Grid
          md={12}
          item
          sx={{
            padding: "10px 40px 10px",
          }}
        >
          {pageContent}
        </Grid>
      </Grid>
    </>
  );
};
