import {
  Typography,
  Skeleton,
  Grid,
  Chip,
  Box,
  Zoom,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContractorSubDataModelLineItem } from "social-pro-common/interfaces/dashboard";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface SustainabilityTargetStackProps {
  loading: boolean;
  targets: ContractorSubDataModelLineItem[];
  title: string;
  firstColumnTitle: string;
  secondColumnTitle: string;
  thirdColumnTitle: string;
  units?: string;
  secondaryUnits?: string;
  leftMargin?: boolean;
  rightMargin?: boolean;
  url?: string;
  dataTestId?: string;
}

export const SustainabilityTargetStack = ({
  dataTestId,
  firstColumnTitle,
  leftMargin,
  loading,
  rightMargin,
  secondColumnTitle,
  secondaryUnits,
  targets,
  thirdColumnTitle,
  title,
  units,
  url,
}: SustainabilityTargetStackProps) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <Grid item md={4}>
        <Box
          border={1}
          padding={2}
          sx={{
            border: "1px solid #E3E8EA",
            borderRadius: "25px",
            boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
            height: "100%",
            marginLeft: leftMargin ? "10px" : "0px",
            marginRight: rightMargin ? "10px" : "0px",
            padding: "20px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Skeleton animation="wave">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {"Title"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid item md={6}>
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"First Column"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid
              item
              md={3}
              justifyContent={"right"}
              alignItems={"right"}
              display={"flex"}
            >
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"Second Column"}
                </Typography>
              </Skeleton>
            </Grid>
            <Grid
              item
              md={3}
              justifyContent={"right"}
              alignItems={"right"}
              display={"flex"}
            >
              <Skeleton animation="wave">
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {"Third Column"}
                </Typography>
              </Skeleton>
            </Grid>
            {Array.from({ length: 6 }, () => (
              <>
                <Grid item md={6}>
                  <Skeleton animation="wave">
                    <Typography variant="body1">{"Title"}</Typography>
                  </Skeleton>
                </Grid>
                <Grid
                  item
                  md={3}
                  justifyContent={"right"}
                  alignItems={"right"}
                  display={"flex"}
                >
                  <Skeleton animation="wave" variant="rounded">
                    <Typography variant="body1">{"Title"}</Typography>
                  </Skeleton>
                </Grid>
                <Grid
                  item
                  md={3}
                  justifyContent={"right"}
                  alignItems={"right"}
                  display={"flex"}
                >
                  <Skeleton animation="wave" variant="rounded">
                    <Typography variant="body1">{"Title"}</Typography>
                  </Skeleton>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  }
  return (
    <Grid item md={4}>
      <Box
        border={1}
        padding={2}
        sx={{
          "&:hover ": {
            border: "1px solid #495a61",
          },
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
          cursor: "pointer",
          height: "100%",
          marginLeft: leftMargin ? "10px" : "0px",
          marginRight: rightMargin ? "10px" : "0px",
          padding: "20px",
        }}
        onClick={() => {
          if (url) {
            navigate(url);
          }
        }}
        data-test-id={`${dataTestId}-landmark`}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {firstColumnTitle}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {secondColumnTitle}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              align="right"
            >
              {thirdColumnTitle}
            </Typography>
          </Grid>
          {targets.map((target) => (
            <>
              <Grid item md={6}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title={target.title}
                >
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    className={`${dataTestId}-title`}
                  >
                    {target.title}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={3}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title={`${units === "$" ? "$" : ""}${formatDecimalPlaces(target.value)}${units !== "$" ? units || "" : ""}`}
                >
                  <Typography
                    align="right"
                    sx={{
                      display: "inline-block",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    className={`${dataTestId}-value`}
                  >
                    {`${units === "$" ? "$" : ""}${formatDecimalPlaces(target.value)}${units !== "$" ? units || "" : ""}`}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={3}
                justifyContent={"right"}
                alignItems={"right"}
                display={"flex"}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title={`${secondaryUnits === "$" ? "$" : ""}${formatDecimalPlaces(target?.pcChange || 0)}${secondaryUnits !== "$" ? secondaryUnits || "" : ""}`}
                >
                  <Typography
                    align="right"
                    sx={{
                      display: "inline-block",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    className={`${dataTestId}-pc-change`}
                  >
                    {`${secondaryUnits === "$" ? "$" : ""}${formatDecimalPlaces(target?.pcChange || 0)}${secondaryUnits !== "$" ? secondaryUnits || "" : ""}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </>
          ))}
          {!targets.length ? (
            <Grid item md={12}>
              <Chip size="small" label={"No data"} sx={{ width: "100%" }} />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};
