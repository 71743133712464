import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { LabourHourProgressModelLineItem } from "social-pro-common/interfaces/dashboard";
import { useState } from "react";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";
import { TitleStack } from "../../Dashboard/ProjectProgress/TitleStack";
import { ChipProgress } from "../../Dashboard/ProjectProgress/ChipProgress";
import { DashboardProgressbar } from "../../Dashboard/ProjectProgress/DashboardProgressBar";
import { OrganisationLabourHourProgressSubData } from "./OrganisationLabourHourProgressSubData";

interface OrganisationLabourHourProgressProps {
  loading: boolean;
  labourHourProgress?: LabourHourProgressModelLineItem;
}

export const OrganisationLabourHourProgress = ({
  labourHourProgress,
  loading,
}: OrganisationLabourHourProgressProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid
      item
      md={12}
      sx={{
        marginTop: "16px",
      }}
    >
      <Accordion
        square={true}
        sx={{
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          padding: "5px",
        }}
        disableGutters={true}
        expanded={open}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          setOpen(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={{ color: "#C4D5E7" }} />}
          aria-controls="labour-hour-content"
          id="labour-hour-panel"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item md={3}>
              <Stack spacing={2}>
                <TitleStack
                  loading={loading}
                  header="Labour Hours"
                  subTitle="Overall Performance"
                />
                <ChipProgress
                  loading={loading}
                  value={`+${formatDecimalPlaces(labourHourProgress?.pcChange ? Math.max(labourHourProgress.pcChange, 0) : 0)}%`}
                  title="previous month"
                  primaryFontColour="#2C9F45"
                  secondaryFontColour="#4F8349"
                  chipColour="#D1F4D9"
                  dataTestId="labour-hour-pc-change"
                />
              </Stack>
            </Grid>
            <Grid item md={9}>
              <Stack
                spacing={3}
                sx={{ paddingLeft: "5vw", paddingRight: "1vw", width: "100%" }}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={nFormatter(
                        labourHourProgress?.targetHours || 0,
                        2,
                      )}
                      subTitle="Target (hours)"
                      dataTestId="labour-hour-target-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={nFormatter(
                        labourHourProgress?.totalHours || 0,
                        2,
                      )}
                      subTitle="Achieved (hours)"
                      dataTestId="labour-hour-total-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${nFormatter(labourHourProgress?.pcComplete || 0, 2)}%`}
                      subTitle="Complete"
                      dataTestId="labour-hour-pc-complete"
                    />
                  </Grid>
                </Grid>
                <DashboardProgressbar
                  loading={loading}
                  progress={labourHourProgress?.pcComplete || 0}
                  startDate={"0%"}
                  endDate={"100%"}
                  progressTestId="labour-hour-progress-bar"
                  prefixTestId="labour-hour-start"
                  suffixTestId="labour-hour-end"
                />
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ borderRadius: "50px" }}>
          {open ? (
            <OrganisationLabourHourProgressSubData loading={loading} />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
