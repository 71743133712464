import { TableCell, TableRow, TableHead } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";

interface ProjectTableHeaderProps {
  loading: boolean;
}

export const ProjectTableHeader = ({ loading }: ProjectTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow style={{ border: "none", padding: "10px 0 0" }}>
        <TableCell sx={{ width: "5%" }} />
        <TableHeader
          align={"left"}
          loading={loading}
          title="Project Name"
          sx={{ width: "20%" }}
        />
        <TableHeader
          align={"left"}
          loading={loading}
          title="Head Contractor"
          sx={{ width: "20%" }}
        />
        <TableHeader
          align={"left"}
          loading={loading}
          title="Location"
          sx={{ width: "25%" }}
        />
        <TableHeader
          align={"left"}
          loading={loading}
          title="Collaborators"
          sx={{ width: "10%" }}
        />
        <TableHeader
          align="center"
          loading={loading}
          title="Progress"
          sx={{ width: "5%" }}
        />
        <TableHeader
          align="center"
          loading={loading}
          title="Action"
          sx={{ width: "15%" }}
        />
      </TableRow>
    </TableHead>
  );
};
