import { useCallback, useEffect, useState } from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import {
  decodePolicySubmission,
  encodePolicySubmission,
} from "social-pro-common/decoders/policySubmission";
import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import { PolicySubmission } from "social-pro-common/entities/policySubmission";
import { catchSentryError } from "@utils/sentry";

export const usePolicySubmission = (
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [policySubmissions, setPolicySubmissions] = useState<
    PolicySubmissionLineItem[]
  >([]);
  const [isPolicyLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listPolicySubmissions = useCallback(
    async (projectId: string, contractorPackageId: string) => {
      try {
        setIsLoading(true);
        const policyResult = await listApiData(
          "listPolicySubmissions",
          "policySubmission",
          projectId,
          { contractorPackageId },
        );
        setPolicySubmissions(
          policyResult.data.map((p) =>
            decodePolicySubmission(p as PolicySubmission),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list policies");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodePolicySubmission, setPolicySubmissions, setIsLoading, setError],
  );

  const createPolicySubmission = useCallback(
    async (policy: PolicySubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedPolicy = encodePolicySubmission(policy);
        const upsertedPolicy = await postApiData(
          "createPolicySubmission",
          "policySubmission",
          encodedPolicy,
        );
        const decodedPolicy = decodePolicySubmission(
          upsertedPolicy.data as PolicySubmission,
        );
        setPolicySubmissions((prevPolicySubmissions) => [
          ...prevPolicySubmissions,
          decodedPolicy,
        ]);
        return decodedPolicy;
      } catch (e) {
        catchSentryError(e);
        setError("Could not create policy");
      } finally {
        setIsLoading(false);
      }
      return policy;
    },
    [encodePolicySubmission, postApiData, decodePolicySubmission, setPolicySubmissions, setIsLoading, setError],
  );

  const updatePolicySubmission = useCallback(
    async (policy: PolicySubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedPolicy = encodePolicySubmission(policy);
        await postApiData(
          "updatePolicySubmission",
          "policySubmission",
          encodedPolicy,
        );
        setPolicySubmissions((prevPolicySubmissions) =>
          prevPolicySubmissions.map((p) => (p.id === policy.id ? policy : p)),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update policy");
      } finally {
        setIsLoading(false);
      }
    },
    [encodePolicySubmission, postApiData, setPolicySubmissions, setIsLoading, setError],
  );

  const deletePolicySubmission = useCallback(
    async (policy: PolicySubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedPolicy = encodePolicySubmission(policy);
        await postApiData(
          "deletePolicySubmission",
          "policySubmission",
          encodedPolicy,
        );
        setPolicySubmissions((prevPolicySubmissions) =>
          prevPolicySubmissions.filter((p) => p.id !== policy.id),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not delete policy");
      } finally {
        setIsLoading(false);
      }
    },
    [encodePolicySubmission, postApiData, setPolicySubmissions, setIsLoading, setError],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      listPolicySubmissions(projectId, contractorPackageId);
    }
  }, [projectId, contractorPackageId, listPolicySubmissions]);

  return {
    createPolicySubmission,
    deletePolicySubmission,
    error,
    isPolicyLoading,
    listPolicySubmissions,
    policySubmissions,
    updatePolicySubmission,
  };
};