import { Typography, Box, Button, Skeleton } from "@mui/material";

export const ReportCardSkeleton = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        background: "#fff",
        border: "1px solid #C6D3DE",
        borderRadius: "5px",
        display: "flex",
        gap: "10px",
        marginBottom: "10px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flex: "2",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            border: "2px dotted #b9b9b9",
            borderRadius: "50%",
            height: "15px",
            marginTop: "5px",
            width: "15px",
          }}
        ></Box>
        <Box>
          <Skeleton animation="wave">
            <Typography
              sx={{
                color: "black",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "1.4",
              }}
            >
              {11 / 11 / 2024}
            </Typography>
          </Skeleton>
          <Skeleton animation="wave">
            <Typography
              sx={{
                color: "#b9b9b9",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "1.4",
              }}
            >
              Due 11/11/2024
            </Typography>
          </Skeleton>
        </Box>
      </Box>
      <Skeleton animation="wave" variant="rounded">
        <Button
          color="primary"
          size="small"
          className="blackBtn"
          sx={{
            color: "white",
            fontSize: "11px !important",
            fontWeight: "600 !important",
            lineHeight: "14px",
            minWidth: "auto !important",
            padding: "4px 10px!important",
          }}
        >
          Submit Report
        </Button>
      </Skeleton>
    </Box>
  );
};
