import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Skeleton, Typography } from "@mui/material";
import { ContractorAnalyticsSocialSpend } from "@stories/molecules/ContractorAnalyticsSocialSpend/ContractorAnalyticsSocialSpend";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { useSocialSpendAnalytics } from "@hooks/crud/analytics/useSocialSpendAnalytics";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { writeXlsxSocialSpendTargets } from "@utils/xlsx/spend";
import DownloadIcon from "@mui/icons-material/Download";

interface PackageDetailsSpendProps {
  isLoading: boolean;
  reportId: string;
  contractorPackage?: ContractorPackageLineItem;
  onSocialSpendTargetChange: (targetId: string) => void;
}

export const PackageDetailsSpend = ({
  contractorPackage,
  isLoading,
  onSocialSpendTargetChange,
  reportId,
}: PackageDetailsSpendProps) => {
  const { isProjectLoading, selectedProject } = useProjectContext();

  const { isAnalysisLoading, socialSpendAnalytics } = useSocialSpendAnalytics(
    reportId,
    selectedProject?.id,
    contractorPackage?.id,
  );

  const loading = isLoading || isProjectLoading || isAnalysisLoading;
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{
          display: "flex",
          margin: "0 0 20px",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <Grid item md={6}>
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">{"Spend"}</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">{"Spend"}</Typography>
          )}
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            data-test-id="export-targets-button"
            className="blackBtn grey-outline-btn"
            loading={loading}
            disabled={
              loading ||
              !socialSpendAnalytics ||
              !socialSpendAnalytics?.targets.length
            }
            variant="contained"
            onClick={() => {
              if (socialSpendAnalytics) {
                writeXlsxSocialSpendTargets(socialSpendAnalytics.targets);
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0", padding: "0 !important", width: "100%" }}
      >
        <ContractorAnalyticsSocialSpend
          loading={loading}
          socialSpendAnalysis={socialSpendAnalytics}
          onSocialSpendTargetChange={onSocialSpendTargetChange}
        />
      </Grid>
    </>
  );
};
