import { Button, Skeleton, Switch, TableCell, TableRow } from "@mui/material";

export const ProjectEmployeeTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "15%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "15%" }}>
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <Switch color="success" />
        </Skeleton>
      </TableCell>
      <TableCell
        align="right"
        sx={{ textAlign: "center !important", width: "15%" }}
      >
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <Button
            color="primary"
            size="large"
            className="blackBtn"
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            View
          </Button>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
