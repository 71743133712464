import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export const GreenMetricTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Skeleton animation="wave">
            <Typography>{"Green Steel"}</Typography>
          </Skeleton>
        </TableCell>
        <TableCell align="center" sx={{ width: "20%" }} className="center">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Skeleton variant="circular" animation="wave">
              <IconButton color="primary" size="large">
                <EditIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Skeleton>
            <Skeleton variant="circular" animation="wave">
              <IconButton color="error" size="large">
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Skeleton>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
