import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import EditIcon from "@mui/icons-material/Edit";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ProjectLabourHourCommitmentLineItem } from "social-pro-common/interfaces/projectLabourHourCommitment";
import { ProjectSocialSpendCommitmentLineItem } from "social-pro-common/interfaces/projectSocialSpendCommitment";

interface ForecastTableRowProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  forecastModel: ForecastModelLineItem;
  editForecastData: (forecastModel: ForecastModelLineItem) => Promise<void>;
  updateForecastModel: (forecastModel: ForecastModelLineItem) => void;
}

const getMatchingCommitmentsForUnit = (
  project: ProjectLineItem,
  unit: ForecastUnitType,
) => {
  return unit === ForecastUnitType.Hour
    ? project.commitmentsHours
    : project.commitmentsSpend;
};

export const ForecastTableRow = ({
  disabled,
  editForecastData,
  forecastModel,
  project,
  updateForecastModel,
}: ForecastTableRowProps) => {
  const units = forecastModel.units;

  if (!project) {
    return null;
  }
  const matchingCommitment =
    units === ForecastUnitType.Hour
      ? project.commitmentsHours.find((c) => c.id === forecastModel.id)
      : project.commitmentsSpend.find((c) => c.id === forecastModel.id);

  const matchingCommitments = matchingCommitment
    ? [matchingCommitment]
    : getMatchingCommitmentsForUnit(project, forecastModel.units);

  const targetTotal = (matchingCommitments as any[]).reduce(
    (acc: number, c: any) => acc + (c?.targetValueRealised || 0),
    0 as number,
  );

  const reportedTotal = (matchingCommitments as any[]).reduce(
    (
      acc: number,
      c:
        | ProjectLabourHourCommitmentLineItem
        | ProjectSocialSpendCommitmentLineItem,
    ) => acc + (c?.achievedValue || 0),
    0 as number,
  );
  const remainingTotal = Math.max(0, targetTotal - reportedTotal);

  const target = matchingCommitment?.targetValueRealised || targetTotal;

  const reported = forecastModel.data.reduce(
    (acc, d) => acc + (d.value || 0),
    0,
  );

  const remaining = Math.max(
    0,
    matchingCommitment ? target - reported : remainingTotal,
  );

  return (
    <>
      <TableRow key={forecastModel.id}>
        <TableCell>
          <span
            style={{
              background: forecastModel.colour,
              borderRadius: "50%",
              boxShadow: "none",
              display: "block",
              height: "12px",
              margin: "0 auto",
              width: "12px",
            }}
          />
        </TableCell>
        <TableCell align="left" scope="row" sx={{ width: "30%" }}>
          {forecastModel.modelName}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(target)}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(reported)}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(remaining)}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ width: "10%" }}>
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Switch
              disabled={disabled}
              color="success"
              onChange={() => {
                updateForecastModel({
                  ...forecastModel,
                  active: !forecastModel.active,
                });
              }}
              checked={forecastModel.active}
            />

            <Box display="flex" alignItems="center">
              <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
                <IconButton
                  color="primary"
                  size="large"
                  disabled={disabled}
                  onClick={() => editForecastData(forecastModel)}
                >
                  <EditIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
