import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";

import { Dispatch, useRef } from "react";
import {
  EmployeeLineItem,
  employeeSocialSpendFactorToDocumentDescription,
  employeeSocialFactorToString,
} from "social-pro-common/interfaces/contractorEmployee";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";
import { SupportingDocumentLineItem } from "social-pro-common/interfaces/supportingDocument";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDocumentName } from "social-pro-common/utils/string";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";

interface EmployeeSupportingDocsFormRowProps {
  employee: EmployeeLineItem;
  socialFactor: EmployeeSocialFactor;
  supportingDocuments: SupportingDocumentLineItem[];
  isUploading: boolean;
  isDownloading: boolean;
  uploadProgress: number;
  downloadProgress: number;
  selectedTargetId?: string;
  setSelectedTargetId: (id: EmployeeSocialFactor) => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDownload: (uri: string, policyName: string) => void;
  handleDeleteSupportingDocument: (
    supportingDocument: SupportingDocumentLineItem,
  ) => void;
  setEmployee: Dispatch<React.SetStateAction<EmployeeLineItem>>;
}

export const EmployeeSupportingDocsFormRow = ({
  downloadProgress,
  handleDeleteSupportingDocument,
  isDownloading,
  isUploading,
  onDownload,
  onUpload,
  selectedTargetId,
  setSelectedTargetId,
  socialFactor,
  supportingDocuments,
  uploadProgress,
}: EmployeeSupportingDocsFormRowProps) => {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = (id: EmployeeSocialFactor) => {
    if (fileInput.current) {
      fileInput.current.click();
    }
    setSelectedTargetId(id);
  };

  const supportingDocument = supportingDocuments.find(
    (s) => s.supportingDocumentType === socialFactor,
  );

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography>{employeeSocialFactorToString(socialFactor)}</Typography>
          <Stack>
            {employeeSocialSpendFactorToDocumentDescription(socialFactor).map(
              (s) => (
                <Typography variant="caption">{`* ${s}`}</Typography>
              ),
            )}
          </Stack>
        </TableCell>
        <TableCell align="center">
          {isUploading && selectedTargetId === socialFactor ? (
            <CircularProgressWithLabel value={uploadProgress} />
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <input
                ref={fileInput}
                hidden
                accept="*/*"
                type="file"
                style={{
                  cursor: "pointer",
                  height: "100px",
                  opacity: 0,
                  padding: 0.5,
                  position: "fixed",
                  width: "100px",
                  zIndex: 1,
                }}
                onChange={onUpload}
              />
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Upload"
              >
                <IconButton
                  color="error"
                  sx={{
                    "&:hover ": {
                      background: theme.palette.primary.dark,
                      color: theme.palette.primary.light,
                    },
                    background: theme.palette.primary.light,
                    borderColor: "black",
                    color: theme.palette.primary.dark,
                  }}
                  disabled={isUploading || isDownloading}
                  size="large"
                  onClick={() => onFileInput(socialFactor)}
                >
                  <UploadTwoToneIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCell>
        <TableCell align="center">
          {supportingDocument ? (
            isDownloading && selectedTargetId === socialFactor ? (
              <CircularProgressWithLabel value={downloadProgress} />
            ) : supportingDocument?.uri ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="View"
                >
                  <IconButton
                    color="error"
                    sx={{
                      "&:hover ": {
                        background: theme.palette.primary.dark,
                        color: theme.palette.primary.light,
                      },
                      background: theme.palette.primary.light,
                      borderColor: "black",
                      color: theme.palette.primary.dark,
                    }}
                    disabled={isUploading || isDownloading}
                    size="large"
                    onClick={() =>
                      supportingDocument
                        ? onDownload(
                            supportingDocument.uri,
                            getDocumentName(
                              `${employeeSocialFactorToString(socialFactor)}`,
                              supportingDocument.uri,
                            ),
                          )
                        : undefined
                    }
                  >
                    <RemoveRedEyeIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : null
          ) : null}
        </TableCell>
        <TableCell align="center">
          {supportingDocument ? (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title={"Delete"}
              >
                <IconButton
                  color="error"
                  sx={{
                    "&:hover ": {
                      background: theme.palette.primary.dark,
                      color: theme.palette.error.light,
                    },
                    background: theme.palette.primary.light,
                    borderColor: "black",
                    color: theme.palette.error.dark,
                  }}
                  disabled={isUploading || isDownloading}
                  size="large"
                  onClick={() =>
                    handleDeleteSupportingDocument(supportingDocument)
                  }
                >
                  <DeleteIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};
