"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPackageComment = exports.packageCommentTypeToString = exports.createDefaultCommitmentCommentLineItem = void 0;
var uuid_1 = require("uuid");
var packageComment_1 = require("../../entities/packageComment");
var createDefaultCommitmentCommentLineItem = function (projectId, contractorPackageId, reportId, commentType, comment) {
    return {
        comment: comment,
        commentType: commentType,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId: reportId,
    };
};
exports.createDefaultCommitmentCommentLineItem = createDefaultCommitmentCommentLineItem;
var packageCommentTypeToString = function (packageCommentType) {
    switch (packageCommentType) {
        case packageComment_1.PackageCommentType.Spend:
            return "Social Spend";
        case packageComment_1.PackageCommentType.Hour:
            return "Labour Hour";
        case packageComment_1.PackageCommentType.Policy:
            return "Policy";
        case packageComment_1.PackageCommentType.GreenMetric:
            return "Environmental Sustainability";
        case packageComment_1.PackageCommentType.LocalContent:
            return "Local Content";
        default:
            throw new Error("Invalid PackageCommentType");
    }
};
exports.packageCommentTypeToString = packageCommentTypeToString;
var createDefaultPackageComment = function (projectId, contractorPackageId, reportId, commentType, comment) {
    return {
        comment: comment,
        commentType: commentType,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId: reportId,
    };
};
exports.createDefaultPackageComment = createDefaultPackageComment;
