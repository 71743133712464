import { useCallback, useState, useEffect } from "react";
import { listApiData, postBatchApiData } from "@hooks/utils/api";
import { SubcontractorNoteLineItem } from "social-pro-common/interfaces/subcontractorNote";
import { SubcontractorNote } from "social-pro-common/entities/subcontractorNote";
import {
  decodeSubcontractorNote,
  encodeSubcontractorNote,
} from "social-pro-common/decoders/subcontractorNote";
import { catchSentryError } from "@utils/sentry";

export const useSubcontractorNotes = (
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [comments, setComments] = useState<SubcontractorNoteLineItem[]>([]);
  const [isCommentsLoading, setIsCommentLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listSubcontractorNotes = useCallback(
    async (projectId: string, contractorPackageId: string): Promise<void> => {
      try {
        setIsCommentLoading(true);
        const commentResult = await listApiData(
          "listSubcontractorNotes",
          "subcontractorNotes",
          projectId,
          {
            contractorPackageId,
          },
        );
        setComments(
          commentResult.data.map((c) =>
            decodeSubcontractorNote(c as SubcontractorNote),
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not list comments");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [setComments, setIsCommentLoading, setError],
  );

  const upsertSubcontractorNotes = useCallback(
    async (commentsToUpsert: SubcontractorNoteLineItem[]): Promise<void> => {
      try {
        setIsCommentLoading(true);
        const encodedComments = commentsToUpsert.map((e) =>
          encodeSubcontractorNote(e),
        );
        await postBatchApiData(
          "upsertSubcontractorNotes",
          "subcontractorNotes",
          encodedComments,
        );

        const updatedComments = comments.reduce((acc, comment) => {
          const updatedComment = commentsToUpsert.find(
            (e) => e.id === comment.id,
          );
          if (updatedComment) {
            return [...acc, updatedComment];
          }
          return [...acc, comment];
        }, [] as SubcontractorNoteLineItem[]);

        const createdComments = commentsToUpsert.filter((comment) => {
          return !updatedComments.find((e) => e.id === comment.id);
        });

        setComments([...updatedComments, ...createdComments]);
      } catch (error) {
        catchSentryError(error);
        setError("Could not update employee");
      } finally {
        setIsCommentLoading(false);
      }
    },
    [comments, setComments, setIsCommentLoading, setError],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      listSubcontractorNotes(projectId, contractorPackageId);
    }
  }, [projectId, contractorPackageId, listSubcontractorNotes]);

  return {
    comments,
    error,
    isCommentsLoading,
    upsertSubcontractorNotes,
  };
};