import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Grid,
  FormControl,
  Typography,
  InputAdornment,
  TextField,
  Autocomplete,
  Skeleton,
  Checkbox,
} from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  SocialCommitmentTypeHour,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLabourHourCommitmentLineItem } from "social-pro-common/interfaces/projectLabourHourCommitment";
import {
  employeeSocialFactorToString,
  employmentLevelToString,
  genderToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import { ChangeEvent, useState } from "react";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import ColourPicker from "../ColourPicker/ColourPicker";
import { validateABN } from "social-pro-common/utils/abn";
import { validatePostCode } from "@utils/location";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { cleanString } from "social-pro-common/utils/string";
import {
  EmployeeSocialFactor,
  EmploymentLevel,
  Gender,
  OccupationType,
} from "social-pro-common/entities/contractorEmployee";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface LabourHourTargetFormProps {
  loading: boolean;
  project: ProjectLineItem;
  baseMultiplier: number;
  socialCommitment: ProjectLabourHourCommitmentLineItem;
  handleSubmit: (socialCommitment: ProjectLabourHourCommitmentLineItem) => void;
}

export const LabourHourTargetForm = ({
  baseMultiplier,
  handleSubmit,
  loading,
  project,
  socialCommitment,
}: LabourHourTargetFormProps) => {
  const [hex, setHex] = useState(socialCommitment.colour || "#000000");
  const [abnString, setAbnString] = useState<string>(
    socialCommitment?.abns ? socialCommitment.abns.join(", ") : "",
  );
  const [postCodesString, setPostCodesString] = useState<string>(
    socialCommitment?.postCodes ? socialCommitment.postCodes.join(", ") : "",
  );
  const [selectedEmployeeSocialFactors, setSelectedEmployeeSocialFactor] =
    useState<EmployeeSocialFactor[]>(socialCommitment.socialFactors || []);
  const [selectedEmploymentLevel, setSelectedEmploymentLevel] = useState<
    EmploymentLevel[]
  >(socialCommitment.employmentLevel || []);
  const [selectedOccupationType, setSelectedOccupationType] = useState<
    OccupationType[]
  >(socialCommitment.occupationType || []);
  const [selectedGender, setSelectedGender] = useState<Gender[]>(
    socialCommitment.gender || [],
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const validationSchema = yup.object({
    abns: yup
      .array()
      .test("Valid ABN", "Invalid ABN - i.e. 43 665 205 185", function (items) {
        if (items && items.length > 0) {
          return items.filter((i) => validateABN(i)).length === items.length;
        }
        return true;
      }),
    colour: yup.string().required(),
    distanceFromProject: yup.number(),
    employeeSocialFactors: yup.array(),
    employmentLevel: yup.array(),
    gender: yup.array(),
    postCodes: yup
      .array()
      .test("Valid for country", "Postcode is invalid", function (items) {
        if (items && items.length > 0) {
          return (
            items.filter((i) =>
              validatePostCode(project.projectAddress.country, i),
            ).length === items.length
          );
        }
        return true;
      }),
    targetDescription: yup.string().required("Description required"),
    targetName: yup.string().required("Target name is required"),
    targetValue: yup
      .number()
      .required("Target value is required")
      .moreThan(0, "Target value must be greater than 0")
      .max(100, "Target value must be less than 100"),
    targetValueRealised: yup
      .number()
      .required("Target value is required")
      .moreThan(0, "Target value must be greater than 0"),
  });
  const formik = useFormik({
    initialValues: {
      abns: socialCommitment.abns || [],
      colour: hex,
      distanceFromProject: socialCommitment.maxDistance,
      employeeSocialFactors: selectedEmployeeSocialFactors,
      employmentLevel: selectedEmploymentLevel,
      gender: selectedGender,
      occupationType: selectedOccupationType,
      postCodes: socialCommitment.postCodes || [],
      targetDescription:
        socialCommitment.targetName == SocialCommitmentTypeHour.DefaultHour
          ? socialCommitment.targetDescription
          : labourHourCommitmentTypeToString(socialCommitment.targetName),
      targetName: socialCommitment.targetName,
      targetValue: socialCommitment.targetValue,
      targetValueRealised: socialCommitment.targetValueRealised,
    },
    onSubmit: (values) => {
      const validTarget =
        selectedEmployeeSocialFactors.length > 0 ||
        selectedGender.length > 0 ||
        selectedOccupationType.length > 0 ||
        selectedEmploymentLevel.length > 0 ||
        postCodesString.length > 0 ||
        abnString.length > 0 ||
        (values.distanceFromProject && values.distanceFromProject > 0);

      if (validTarget) {
        setErrorMessage(undefined);
        handleSubmit({
          ...socialCommitment,
          abns: values.abns.map((abn) => cleanString(abn)) || [],
          colour: values.colour,
          deleted: false,
          employmentLevel: selectedEmploymentLevel,
          gender: selectedGender,
          maxDistance: values.distanceFromProject,
          occupationType: selectedOccupationType,
          postCodes: values.postCodes || [],
          socialFactors: selectedEmployeeSocialFactors,
          targetDescription: values.targetDescription,
          targetName: values.targetName,
          targetValue: values.targetValue,
          targetValueRealised: values.targetValueRealised,
        });
      } else {
        formik.setSubmitting(false);
        setErrorMessage("Please select at least one employee profile factor");
      }
    },
    validationSchema: validationSchema,
  });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const disabled = formik.isSubmitting;
  return (
    <Box>
      <form onSubmit={formik.handleSubmit} style={{ paddingTop: "12px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetDescription"
                name="targetDescription"
                value={formik.values.targetDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetDescription &&
                  Boolean(formik.errors.targetDescription)
                }
                helperText={
                  formik.touched.targetDescription
                    ? formik.errors.targetDescription
                    : ""
                }
                label="Description"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ColourPicker hex={hex} setHex={setHex} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetValue"
                name="targetValue"
                value={formatDecimalPlaces(formik.values.targetValue)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numberValue = e.target.value as any as number;
                  const realisedValue = baseMultiplier * (numberValue / 100);
                  formik.setFieldValue("targetValueRealised", realisedValue);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetValue &&
                  Boolean(formik.errors.targetValue)
                }
                helperText={
                  formik.touched.targetValue ? formik.errors.targetValue : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      %
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Target Value %"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetValueRealised"
                name="targetValueRealised"
                value={formatDecimalPlaces(formik.values.targetValueRealised)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numberValue = e.target.value as any as number;
                  const targetValue = (numberValue / baseMultiplier) * 100;
                  formik.setFieldValue("targetValue", targetValue);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetValue &&
                  Boolean(formik.errors.targetValueRealised)
                }
                helperText={
                  formik.touched.targetValueRealised
                    ? formik.errors.targetValueRealised
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      h
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Total Hours (hours)"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4">
              Employee Profile Factors - Choose at least one
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="distanceFromProject"
                name="distanceFromProject"
                value={formik.values.distanceFromProject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.distanceFromProject &&
                  Boolean(formik.errors.distanceFromProject)
                }
                helperText={
                  formik.touched.distanceFromProject
                    ? formik.errors.distanceFromProject
                    : ""
                }
                label="Max distance from project (km)"
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="gender"
                  multiple
                  disableCloseOnSelect
                  options={Object.values(Gender)}
                  disabled={disabled}
                  value={selectedGender}
                  getOptionLabel={(option) => genderToString(option)}
                  onChange={(e: any, value: Gender[] | null) => {
                    setSelectedGender(value || []);
                    // formik.handleChange(e);
                  }}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value.map((v) => genderToString(v)).join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {genderToString(option)}
                    </li>
                  )}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="gender"
                      {...params}
                      name="gender"
                      label="Gender"
                      fullWidth
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={
                        formik.touched.gender ? formik.errors.gender : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="employeeSocialFactors"
                  multiple
                  disableCloseOnSelect
                  value={selectedEmployeeSocialFactors}
                  options={Object.values(EmployeeSocialFactor)}
                  disabled={disabled}
                  getOptionLabel={(option) =>
                    employeeSocialFactorToString(option)
                  }
                  onChange={(e: any, value: EmployeeSocialFactor[] | null) => {
                    setSelectedEmployeeSocialFactor(value || []);
                    // formik.handleChange(e);
                  }}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => employeeSocialFactorToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {employeeSocialFactorToString(option)}
                    </li>
                  )}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="employeeSocialFactors"
                      {...params}
                      name="employeeSocialFactors"
                      label="Social Factors"
                      fullWidth
                      error={
                        formik.touched.employeeSocialFactors &&
                        Boolean(formik.errors.employeeSocialFactors)
                      }
                      helperText={
                        formik.touched.employeeSocialFactors
                          ? formik.errors.employeeSocialFactors
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  id="employmentLevel"
                  value={selectedEmploymentLevel}
                  options={Object.values(EmploymentLevel)}
                  disabled={disabled}
                  getOptionLabel={(option) => employmentLevelToString(option)}
                  onChange={(
                    event: any,
                    newValue: EmploymentLevel[] | null,
                  ) => {
                    setSelectedEmploymentLevel(newValue || []);
                    // formik.handleChange(event);
                  }}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => employmentLevelToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {employmentLevelToString(option)}
                    </li>
                  )}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="employmentLevel"
                      {...params}
                      name="employmentLevel"
                      label="Employment Level"
                      fullWidth
                      error={
                        formik.touched.employmentLevel &&
                        Boolean(formik.errors.employmentLevel)
                      }
                      helperText={
                        formik.touched.employmentLevel
                          ? formik.errors.employmentLevel
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  id="occupationType"
                  value={selectedOccupationType}
                  options={Object.values(OccupationType)}
                  disabled={disabled}
                  getOptionLabel={(option) =>
                    occupationTypeOptionToString(option)
                  }
                  onChange={(event: any, newValue: OccupationType[] | null) => {
                    setSelectedOccupationType(newValue || []);
                    // formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => occupationTypeOptionToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {occupationTypeOptionToString(option)}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="occupationType"
                      {...params}
                      name="Occupation Type"
                      label="Occupation Type"
                      fullWidth
                      error={
                        formik.touched.occupationType &&
                        Boolean(formik.errors.occupationType)
                      }
                      helperText={
                        formik.touched.occupationType
                          ? formik.errors.occupationType
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <StyledTextField
                fullWidth
                loading={false}
                id="postCodes"
                name="postCodes"
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const postCodes = e.target.value;
                  setPostCodesString(postCodes);
                  const cleanPostCodes = postCodes
                    .split(",")
                    .map((a: string) => cleanString(a))
                    .filter((f) => !!f);
                  formik.setFieldValue("postCodes", cleanPostCodes);
                }}
                onBlur={formik.handleBlur}
                value={postCodesString}
                error={
                  formik.touched.postCodes && Boolean(formik.errors.postCodes)
                }
                helperText={
                  formik.touched.postCodes ? formik.errors.postCodes : ""
                }
                label={"Postcodes (Comma separated)"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <StyledTextField
                fullWidth
                loading={false}
                id="abns"
                name="abns"
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const abns = e.target.value;
                  setAbnString(abns);
                  const cleanAbns = abns
                    .split(",")
                    .map((a: string) => cleanString(a))
                    .filter((f) => !!f);
                  formik.setFieldValue("abns", cleanAbns);
                }}
                onBlur={formik.handleBlur}
                value={abnString}
                error={formik.touched.abns && Boolean(formik.errors.abns)}
                helperText={formik.touched.abns ? formik.errors.abns : ""}
                label={"ABNs (Comma separated)"}
              />
            </FormControl>
          </Grid>
          {errorMessage ? (
            <Grid item xs={12} sm={12}>
              <Typography paragraph color={"error"}>
                {errorMessage}
              </Typography>
            </Grid>
          ) : undefined}
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={disabled}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
