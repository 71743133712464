import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ContractorAnalyticsLocalContent } from "@stories/molecules/ContractorAnalyticsLocalContent/ContractorAnalyticsLocalContent";
import { useNavigate, useParams } from "react-router-dom";
import * as RouteHelper from "@utils/routes";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const AnalyticsLocalContentDetails = () => {
  const { packageId } = useParams();
  const navigate = useNavigate();

  const [contractorPackage, setContractorPackage] =
    useState<ContractorPackageLineItem>();

  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { getContractorPackage, isContractorPackageLoading } =
    useContractorPackage();
  const handlOnBack = () => {
    navigate(RouteHelper.analyticsLocalContent(selectedProject?.id || ""));
  };

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (packageId) {
        const contractorPackage = await getContractorPackage(packageId);
        if (contractorPackage) {
          setContractorPackage(contractorPackage);
        }
      }
    };
    fetchContractorPackage();
  }, [packageId]);
  const loading = isProjectLoading || isContractorPackageLoading;
  return (
    <Grid
      container
      md={12}
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title={`Local Content - ${
          contractorPackage ? contractorPackage.contractorName : ""
        }`}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        handlOnBack={handlOnBack}
      />

      <ContractorAnalyticsLocalContent
        loading={loading}
        isPrimaryPackage={isPrimaryPackage}
        contractorPackage={contractorPackage}
        localContents={getSortedLocalContent(selectedProject?.localContents)}
      />
    </Grid>
  );
};
