import { Storage as AWSStorage } from "aws-amplify";
import Compressor from "compressorjs";
import { API } from "aws-amplify";
import { BUCKET_NAME } from "init";
import { catchSentryError } from "@utils/sentry";

// Check if the file name is an image file
export const isImageFile = (fileName: string): boolean => {
  return (
    fileName.includes("jpg") ||
    fileName.includes("png") ||
    fileName.includes("jpeg")
  );
};

// Get the URL of an image stored in AWS S3
export const getImageUrl = async (s3Key: string): Promise<string> => {
  try {
    const presignedUrl = await AWSStorage.get(s3Key, { bucket: BUCKET_NAME });
    return presignedUrl;
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Upload a file to AWS S3
export const uploadFile = async (
  location: string,
  body: File,
  onProgress: (progress: any) => void,
  onError: (error: any) => void,
) => {
  try {
    await AWSStorage.put(location, body, {
      bucket: BUCKET_NAME,
      errorCallback: onError,
      progressCallback: onProgress,
    });
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Upload an image to AWS S3 after compressing
export const uploadImage = async (
  location: string,
  body: File,
  onProgress: (progress: any) => void,
  onError: (error: any) => void,
): Promise<void> => {
  try {
    await new Promise((resolve, reject) => {
      new Compressor(body, {
        error(err) {
          console.error("Error compressing image:", err);
          reject(err);
        },
        quality: 0.7,
        success(result) {
          uploadFile(location, result as File, onProgress, onError)
            .then(resolve)
            .catch(reject);
        },
      });
    });
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Download a file from AWS S3 and trigger download in the browser
export const downloadFile = async (
  location: string,
  downloadName: string,
  onProgress?: (progress: string) => void,
) => {
  try {
    const config = {
      cacheControl: "max-age=120",
      download: true,
    };
    const response = (await AWSStorage.get(location, {
      ...config,
      progressCallback: onProgress,
    })) as any;
    downloadBlob(response.Body, downloadName);
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Post a request to zip message data and get the presigned URL for download
export const postZipMessageData = async (keys: string[]): Promise<string> => {
  try {
    const zipRequest = {
      body: { documents: keys },
      response: true,
    };
    const response = (await API.post("api", "fileZipper", zipRequest)) as {
      data: { presignedUrl: string };
    };
    return response.data.presignedUrl;
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Bulk download files by zipping and downloading them
export const bulkDownload = async (
  keys: string[],
  onProgress?: (progress: string) => void,
) => {
  try {
    const presignedUrl = await postZipMessageData(keys);
    await downloadFile(presignedUrl, "archive.zip", onProgress);
  } catch (error) {
    catchSentryError(error);
    throw error;
  }
};

// Helper function to trigger download of a blob in the browser
const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download.txt";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};
