import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import {
  Grid,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import SupportingDocumentDialog from "@stories/molecules/SupportingDocumentDialog/SupportingDocumentDialog";
import { useState } from "react";
import { SortInfo, TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { DEFAULT_LABOUR_HOUR_LIMIT } from "social-pro-common/entities/labourHour";

interface LabourHoursTableProps {
  loading: boolean;
  labourHours: LabourHourForTargetLineItem[];
  labourHourCount: number;
  sortInfo: SortInfo;
  setPage: (page: number) => void;
}

export const LabourHourTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center" className="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" variant="rounded">
          <StyledButton className="blackBtn" loading={true}>
            Documents
          </StyledButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};

export const LabourHoursTable = ({
  labourHourCount,
  labourHours,
  loading,
  setPage,
  sortInfo,
}: LabourHoursTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const [selectedLabourHour, setSelectedLabourHour] =
    useState<LabourHourForTargetLineItem>();

  const handleClose = () => {
    setSelectedLabourHour(undefined);
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Name or Code"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 0,
          }}
          sx={{ width: "25%" }}
        />
        <TableHeader
          loading={loading}
          title={"Subcontractor Name"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 1,
          }}
          sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Previous Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 2,
          }}
          sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Current Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 3,
          }}
          sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Total Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 4,
          }}
          sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Hours Studied"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 4,
          }}
          sx={{ width: "15%" }}
        />
        <TableHeader loading={loading} title={""} />
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        <TableContainer
          sx={{
            overflow: "auto",
            position: "sticky",
          }}
        >
          {loading ? (
            <Table data-test-id="labour-hours-table">
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <LabourHourTableRowSkeleton key={index} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table data-test-id="labour-hours-table">
              {tableHeader}
              <TableBody>
                {labourHours.map((lh, index) => {
                  return (
                    <TableRow key={`${index}-labour-hours-table-row`}>
                      <TableCell>{`${lh.employeeName}`}</TableCell>
                      <TableCell>{`${lh.organisationName}`}</TableCell>
                      <TableCell>{`${lh.previousHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.currentHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.totalHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.hoursStudied} Hours`}</TableCell>
                      <TableCell>
                        <StyledButton
                          loading={false}
                          className="blackBtn"
                          onClick={() => {
                            setSelectedLabourHour(lh);
                          }}
                        >
                          Documents
                        </StyledButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {labourHours.length === 0 ? (
                  <NoRows title={"Labour Hours"} colSpan={7} />
                ) : null}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {labourHourCount > DEFAULT_LABOUR_HOUR_LIMIT ? (
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "0 40px 40px 40px",
            }}
          >
            <Pagination
              count={Math.ceil(labourHourCount / DEFAULT_LABOUR_HOUR_LIMIT)}
              sx={{ margin: "auto" }}
              onChange={handleChange}
              boundaryCount={2}
            />
          </Grid>
        ) : null}

        {selectedLabourHour ? (
          <SupportingDocumentDialog
            open
            handleClose={handleClose}
            labourHour={selectedLabourHour}
          />
        ) : null}
      </Grid>
    </>
  );
};
