import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { Typography } from "@mui/material";

export interface ConfirmationDialogProps {
  message: string;
  open: boolean;
  title: string;
  cancelButtonText?: string;
  buttonText: string;
  intent: "info" | "success" | "error";
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({
  buttonText,
  cancelButtonText,
  intent,
  message,
  onCancel,
  onConfirm,
  open,
  title,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <StyledButton
          id="confirm-dialog-button-cancel"
          loading={false}
          onClick={onCancel}
        >
          {cancelButtonText || "Cancel"}
        </StyledButton>
        <StyledButton
          data-test-id="confirm-dialog-button-ok"
          loading={false}
          onClick={onConfirm}
          color={intent}
        >
          {buttonText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
