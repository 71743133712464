import { BasicLayout } from "@stories/layout/BasicLayout";
import MainDashboardLayout from "@stories/layout/MainLayout";
import { NoMenuLayout } from "@stories/layout/NoMenuLayout";
import HomePageLayout from "@stories/layout/ProjectsLayout";
import { FAQInnerContainerAccordian } from "@stories/molecules/FAQSection/FAQInnerContainerAccordian";
import { MyPackage } from "@stories/pages/Contractor/MyPackage/MyPackage";
import * as RouteHelper from "@utils/routes";
import { AccountView } from "@views/Pages/Account/AccountView";
import { ForgotPasswordView } from "@views/Pages/Authenticate/ForgotPasswordView";
import { LoginView } from "@views/Pages/Authenticate/LoginView";
import { AnalyticsEnviroSustainabilityView } from "@views/Pages/Contractor/AnalyticsEnviroSustainabilityView/AnalyticsEnviroSustainabilityView";
import { AnalyticsEnviroSustainabilityViewDetailsView } from "@views/Pages/Contractor/AnalyticsEnviroSustainabilityViewDetails/AnalyticsEnviroSustainabilityViewDetails";
import { AnalyticsHoursTargetView } from "@views/Pages/Contractor/AnalyticsHoursTargetView/AnalyticsHoursTargetView";
import { AnalyticsHoursView } from "@views/Pages/Contractor/AnalyticsHoursView/AnalyticsHoursView";
import { AnalyticsLocalContentDetailsView } from "@views/Pages/Contractor/AnalyticsLocalContentDetailsView/AnalyticsLocalContentDetailsView";
import { AnalyticsLocalContentView } from "@views/Pages/Contractor/AnalyticsLocalContentView/AnalyticsLocalContentView";
import { AnalyticsPackageLocalContentView } from "@views/Pages/Contractor/AnalyticsPackageLocalContentView/AnalyticsLocalContentView";
import { AnalyticsPolicyView } from "@views/Pages/Contractor/AnalyticsPolicyView/AnalyticsPolicyView";
import { AnalyticsSpendTargetView } from "@views/Pages/Contractor/AnalyticsSpendTargetView/AnalyticsSpendTargetView";
import { AnalyticsSpendView } from "@views/Pages/Contractor/AnalyticsSpendView/AnalyticsSpendView";
import { DashboardV2View } from "@views/Pages/Contractor/DashboardV2View/DashboardV2View";
import { EmployeesView } from "@views/Pages/Contractor/EmployeesView/EmployeesView";
import { ForecastView } from "@views/Pages/Contractor/ForecastView/ForecastView";
import { IntegrationsView } from "@views/Pages/Contractor/IntegrationsView/IntegrationsView";
import { OrganisationDashboardView } from "@views/Pages/Contractor/OrganisationDashboardView/OrganisationDashboardView";
import { PackageDetailView } from "@views/Pages/Contractor/PackageDetailView/PackageDetailView";
import { PackageReportView } from "@views/Pages/Contractor/PackageReportView/PackageReportView";
import { PackageSustainabilityView } from "@views/Pages/Contractor/PackageSustainabilityView/PackageSustainabilityView";
import { PackagesView } from "@views/Pages/Contractor/PackagesView/PackagesView";
import { PlatformSupportView } from "@views/Pages/Contractor/PlatformSupport/PlatformSupport";
import { ProjectEmployeesView } from "@views/Pages/Contractor/ProjectEmployeesView/ProjectEmployeesView";
import { ProjectPoliciesView } from "@views/Pages/Contractor/ProjectPoliciesView/ProjectPoliciesView";
import { ProjectReportView } from "@views/Pages/Contractor/ProjectReportView/ProjectReportView";
import { ProjectSustainabilityReportView } from "@views/Pages/Contractor/ProjectSustainabilityReportView/ProjectSustainabilityReportView";
import { CreateAccountView } from "@views/Pages/CreateAccount/CreateAccountView";
import { CreateProfileView } from "@views/Pages/CreateProfileView/CreateProfileView";
import { DirectoryView } from "@views/Pages/DirectoryView/DirectoryView";
import { ListProjectsView } from "@views/Pages/ListProjectsView/ListProjectsView";
import { OneTimeWizardView } from "@views/Pages/OneTimeWizardView/OneTimeWizardView";
import { ProfileView } from "@views/Pages/Profile/ProfileView";
import { ProjectSocialWizardView } from "@views/Pages/ProjectSocialWizardView/ProjectSocialWizardView";
import { ProjectSustainabilityWizardView } from "@views/Pages/ProjectSustainabilityWizardView/ProjectSustainabilityWizardView";
import { ReadOnlyReportSocialView } from "@views/Pages/ReportView/ReadOnlyReportSocialView";
import { ReadOnlyReportSustainabilityView } from "@views/Pages/ReportView/ReadOnlyReportSustainabilityView";
import { SocialProAdminView } from "@views/Pages/SocialProAdminView/SocialProAdminView";
import { SustainabilityWizardView } from "@views/Pages/SustainabilityWizardView/SustainabilityWizardView";
import { UsersView } from "@views/Pages/UsersView/UsersView";
import { WizardView } from "@views/Pages/WizardView/WizardView";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export interface RouteInfo {
  index?: boolean;
  path: string;
  component: React.FC<any>;
  layout: React.FC<any>;
}

const routesUnauthenticated: RouteInfo[] = [
  {
    component: LoginView,
    index: true,
    layout: BasicLayout,
    path: RouteHelper.login,
  },
  {
    component: ForgotPasswordView,
    layout: BasicLayout,
    path: RouteHelper.forgotPassword,
  },
];

const routesAuthenticatedNoProfile: RouteInfo[] = [
  {
    component: CreateAccountView,
    index: true,
    layout: NoMenuLayout,
    path: RouteHelper.createAccount,
  },
];

const routesAuthenticated: RouteInfo[] = [
  {
    component: ListProjectsView,
    index: true,
    layout: HomePageLayout,
    path: RouteHelper.listProjects,
  },
  {
    component: SocialProAdminView,
    layout: HomePageLayout,
    path: RouteHelper.socialProAdmin,
  },
  {
    component: AccountView,
    layout: HomePageLayout,
    path: RouteHelper.organisation,
  },
  {
    component: ProfileView,
    layout: HomePageLayout,
    path: RouteHelper.profile,
  },
  {
    component: CreateProfileView,
    layout: NoMenuLayout,
    path: RouteHelper.createProfile,
  },
  {
    component: UsersView,
    layout: HomePageLayout,
    path: RouteHelper.users,
  },
  {
    component: EmployeesView,
    layout: HomePageLayout,
    path: RouteHelper.employees,
  },
  {
    component: IntegrationsView,
    layout: HomePageLayout,
    path: RouteHelper.integrations,
  },
  {
    component: OrganisationDashboardView,
    layout: HomePageLayout,
    path: RouteHelper.organisationDashboard,
  },
  {
    component: ForecastView,
    layout: MainDashboardLayout,
    path: RouteHelper.forecast(":projectId"),
  },
  {
    component: PlatformSupportView,
    layout: HomePageLayout,
    path: RouteHelper.platformSupport,
  },
  {
    component: DashboardV2View,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorDashboard(":projectId"),
  },
  {
    component: DashboardV2View,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorDashboardPackage(":projectId"),
  },
  {
    component: MyPackage,
    layout: MainDashboardLayout,
    path: RouteHelper.userPackage(":projectId"),
  },
  {
    component: PackagesView,
    layout: MainDashboardLayout,
    path: RouteHelper.packages(":projectId"),
  },
  {
    component: PackageDetailView,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorPackage(":projectId", ":packageId"),
  },
  {
    component: ProjectReportView,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorProjectReports(":projectId"),
  },
  {
    component: PackageReportView,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorPackageReports(":projectId"),
  },
  {
    component: AnalyticsHoursView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsLabourHours(":projectId", ":reportId"),
  },
  {
    component: AnalyticsHoursTargetView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsViewLabourHours(
      ":projectId",
      ":reportId",
      ":targetId",
    ),
  },
  {
    component: AnalyticsSpendView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsSpend(":projectId", ":reportId"),
  },
  {
    component: AnalyticsSpendTargetView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsViewSpend(
      ":projectId",
      ":reportId",
      ":targetId",
    ),
  },
  {
    component: AnalyticsEnviroSustainabilityView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsEnviroSustainability(":projectId", ":reportId"),
  },
  {
    component: AnalyticsEnviroSustainabilityViewDetailsView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsEnviroSustainabilityDetails(
      ":projectId",
      ":packageId",
      ":reportId",
    ),
  },
  {
    component: AnalyticsPolicyView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsPolicies(":projectId"),
  },
  {
    component: AnalyticsLocalContentDetailsView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsLocalContentDetails(":projectId", ":packageId"),
  },
  {
    component: AnalyticsPackageLocalContentView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsPackageLocalContentDetails(
      ":projectId",
      ":packageId",
    ),
  },
  {
    component: AnalyticsLocalContentView,
    layout: MainDashboardLayout,
    path: RouteHelper.analyticsLocalContent(":projectId"),
  },
  {
    component: ReadOnlyReportSocialView,
    layout: MainDashboardLayout,
    path: RouteHelper.readOnlyReportSocial(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
  },
  {
    component: ReadOnlyReportSustainabilityView,
    layout: MainDashboardLayout,
    path: RouteHelper.readOnlyReportSustainability(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
  },
  {
    component: WizardView,
    layout: MainDashboardLayout,
    path: RouteHelper.wizard(":projectId", ":reportId"),
  },
  {
    component: OneTimeWizardView,
    layout: MainDashboardLayout,
    path: RouteHelper.wizardOneTime(":projectId", ":reportId"),
  },
  {
    component: SustainabilityWizardView,
    layout: MainDashboardLayout,
    path: RouteHelper.sustainabilityWizard(":projectId", ":reportId"),
  },
  {
    component: ProjectSocialWizardView,
    layout: MainDashboardLayout,
    path: RouteHelper.projectSocialWizard(":projectId", ":reportId"),
  },
  {
    component: ProjectSustainabilityWizardView,
    layout: MainDashboardLayout,
    path: RouteHelper.projectSustainabilityWizard(":projectId", ":reportId"),
  },
  {
    component: FAQInnerContainerAccordian,
    layout: HomePageLayout,
    path: RouteHelper.faqQuestion(":faqId"),
  },
  {
    component: DirectoryView,
    layout: MainDashboardLayout,
    path: RouteHelper.directory(":projectId", ":targetId"),
  },
  {
    component: DirectoryView,
    layout: MainDashboardLayout,
    path: RouteHelper.directory(":projectId"),
  },
  {
    component: DashboardV2View,
    layout: MainDashboardLayout,
    path: RouteHelper.contractorDashboard(":projectId"),
  },
  {
    component: ProjectEmployeesView,
    layout: MainDashboardLayout,
    path: RouteHelper.projectEmployees(":projectId"),
  },
  {
    component: ProjectPoliciesView,
    layout: MainDashboardLayout,
    path: RouteHelper.projectPolicies(":projectId"),
  },
  {
    component: ProjectSustainabilityReportView,
    layout: MainDashboardLayout,
    path: RouteHelper.projectSustainability(":projectId"),
  },
  {
    component: PackageSustainabilityView,
    layout: MainDashboardLayout,
    path: RouteHelper.sustainability(":projectId"),
  },
];

export const getRoutes = (loggedIn: boolean, profile?: ProfileLineItem) => {
  if (loggedIn && (!profile || !profile.isSetup)) {
    return routesAuthenticatedNoProfile;
  }
  if (loggedIn) {
    return routesAuthenticated;
  }
  return routesUnauthenticated;
};
