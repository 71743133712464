"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProfile = exports.decodeProfile = void 0;
var profile_1 = require("../../interfaces/profile");
var string_1 = require("../../utils/string");
var decodeProfileStatus = function (profileStatus) {
    switch (profileStatus) {
        default:
            throw Error("Invalid profile status");
        case "profile-status_active":
            return profile_1.ProfileStatus.Active;
        case "profile-status_invited":
            return profile_1.ProfileStatus.Invited;
    }
};
var encodeProfileStatus = function (profileStatus) {
    switch (profileStatus) {
        default:
            throw Error("Invalid profile status");
        case profile_1.ProfileStatus.Active:
            return "profile-status_active";
        case profile_1.ProfileStatus.Invited:
            return "profile-status_invited";
    }
};
var decodeProfile = function (profile) {
    return {
        created: new Date(profile.created),
        email: profile.email,
        emailNotifications: profile.email_notifications,
        id: profile.profile_id,
        isAdmin: profile.is_admin,
        isSetup: profile.is_setup,
        name: profile.name,
        organisationId: profile.organisation_id,
        status: decodeProfileStatus(profile.status),
    };
};
exports.decodeProfile = decodeProfile;
var encodeProfile = function (profile) {
    var safeProfile = (0, string_1.makePostgresSafe)(profile);
    return {
        created: new Date(safeProfile.created),
        email: safeProfile.email,
        email_notifications: safeProfile.emailNotifications,
        is_admin: safeProfile.isAdmin,
        is_setup: safeProfile.isSetup,
        name: safeProfile.name,
        organisation_id: safeProfile.organisationId,
        profile_id: safeProfile.id,
        status: encodeProfileStatus(safeProfile.status),
    };
};
exports.encodeProfile = encodeProfile;
