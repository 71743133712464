import {
  GreenMetricLineItem,
  createDefaultGreenMetric,
} from "social-pro-common/interfaces/greenMetric";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { GreenMetricForm } from "@stories/molecules/GreenMetricForm/GreenMetricForm";

interface GreenMetricModalProps {
  open: boolean;
  loading: boolean;
  projectId: string;
  existingMetric?: GreenMetricLineItem;
  createMetric: (policy: GreenMetricLineItem) => void;
  updateMetric: (policy: GreenMetricLineItem) => void;
  handleClose: () => void;
}

export default function GreenMetricModal({
  createMetric,
  existingMetric,
  handleClose,
  loading,
  open,
  projectId,
  updateMetric,
}: GreenMetricModalProps) {
  const onSubmit = async (metric: GreenMetricLineItem) => {
    if (metric) {
      if (existingMetric) {
        await updateMetric(metric);
      } else {
        await createMetric(metric);
      }
      handleClose();
    }
  };

  const title = "Add Custom Target";

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      className="bgBlueOverlay"
    >
      <DialogTitle sx={{ padding: "13px 24px !important" }}>{title}</DialogTitle>
      <DialogContent>
        <GreenMetricForm
          greenMetric={existingMetric || createDefaultGreenMetric(projectId)}
          handleSubmit={onSubmit}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
