import { useCallback, useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { decodeEmployee } from "social-pro-common/decoders/contractorEmployee";
import { searchApiData } from "@hooks/utils/api";
import { ContractorEmployee } from "social-pro-common/entities/contractorEmployee";
import { catchSentryError } from "@utils/sentry";

export const useSearchEmployee = () => {
  const [isSearchEmployeeLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const searchEmployeesByNameOrCode = useCallback(
    async (employeeIds: string[]): Promise<EmployeeLineItem[]> => {
      try {
        setIsLoading(true);
        const employees = await searchApiData(
          "searchEmployeesByNameOrCode",
          "contractorEmployee",
          employeeIds,
        );

        return employees.data.map((e) =>
          decodeEmployee(e as ContractorEmployee),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not search employees");
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [catchSentryError, decodeEmployee, searchApiData],
  );

  return {
    error,
    isSearchEmployeeLoading,
    searchEmployeesByNameOrCode,
  };
};