"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePackageComment = exports.decodePackageComment = void 0;
var string_1 = require("../../utils/string");
var decodePackageComment = function (comment) {
    return {
        comment: comment.comment,
        commentType: comment.comment_type,
        contractorPackageId: comment.contractor_package_id,
        created: new Date(comment.created),
        id: comment.package_comment_id,
        projectId: comment.project_id,
        reportId: comment.report_id,
    };
};
exports.decodePackageComment = decodePackageComment;
var encodePackageComment = function (comment) {
    var safeComment = (0, string_1.makePostgresSafe)(comment);
    return {
        comment: safeComment.comment,
        comment_type: safeComment.commentType,
        contractor_package_id: safeComment.contractorPackageId,
        created: new Date(safeComment.created),
        package_comment_id: safeComment.id,
        project_id: safeComment.projectId,
        report_id: safeComment.reportId,
    };
};
exports.encodePackageComment = encodePackageComment;
