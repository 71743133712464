import * as RouteHelper from "@utils/routes";

// material-ui
import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

// project imports
import Avatar from "@stories/ui-component/extended/Avatar";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { getSeverityColour } from "social-pro-common/utils/color";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { stringToDate } from "social-pro-common/utils/date";
import { CoolDownButton } from "@stories/atoms/CooldownButton/CooldownButton";
import { userHasManagerAccess } from "@utils/projectAccess";

interface ContractorPackageTableRowProps {
  project: ProjectLineItem;
  userProfile?: ProfileLineItem;
  contractorPackage: ContractorPackageLineItem;
  handleEditPackage: (contractorPackageId: string) => void;
  handleDeletePackage: (contractorPackage: ContractorPackageLineItem) => void;
  resendInvite: (contractorPackageId: string) => void;
}

export const ContractorPackageTableRow = ({
  contractorPackage,
  handleDeletePackage,
  handleEditPackage,
  project,
  resendInvite,
  userProfile,
}: ContractorPackageTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();

  const viewPackageDetails = () => {
    navigate(RouteHelper.contractorPackage(project.id, contractorPackage.id));
  };

  return (
    <>
      <TableRow hover style={{ cursor: "pointer", padding: "10px 0 0" }}>
        <TableCell size="small" align="left" onClick={viewPackageDetails}>
          {`${contractorPackage.packageNumber}`}
        </TableCell>
        <TableCell
          align="center"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          <div style={{ alignItems: "center", display: "flex", gap: "25px" }}>
            <Avatar
              size="badge"
              alt={contractorPackage.contractorName}
              src={contractorPackage.contractorLogo}
              sx={{ backgroundColor: "#ffffff" }}
            />
            <Typography
              variant="caption"
              color="black"
              sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
            >
              {`${contractorPackage.contractorName}${contractorPackage.isAccepted ? "" : " (Pending)"}`}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {`${
            contractorPackage.packageDescription
              ? contractorPackage.packageDescription
              : packageTypeToString(contractorPackage.packageType)
          }`}
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {`$ ${formatDecimalPlaces(contractorPackage.financial.totalValue)}`}
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {stringToDate(contractorPackage.packageStartDate).toLocaleDateString(
            "en-GB",
          )}
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {stringToDate(contractorPackage.packageEndDate).toLocaleDateString(
            "en-GB",
          )}
        </TableCell>
        <TableCell
          align="center"
          className="center"
          onClick={viewPackageDetails}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgressWithLabel
              value={contractorPackage.estimatedProgress}
              color={getSeverityColour(contractorPackage.estimatedProgress)}
            />
          </div>
        </TableCell>

        <TableCell align="right" className="right">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            {contractorPackage.isAccepted ? (
              <Button
                color="primary"
                size="large"
                onClick={viewPackageDetails}
                className="blackBtn grey-outline-btn"
                sx={{ color: "white", fontSize: "12px" }}
              >
                Details
              </Button>
            ) : (
              <CoolDownButton
                cooldownDuration={5000}
                onResend={() => {
                  resendInvite(contractorPackage.id);
                }}
                title={"Resend"}
              />
            )}
            {userHasManagerAccess(project) ? (
              <>
                <Tooltip
                  placement="top"
                  title="Edit"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    disabled={!userProfile?.isAdmin}
                    onClick={() => handleEditPackage(contractorPackage.id)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="Delete"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="error"
                    size="large"
                    disabled={!userProfile?.isAdmin}
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    <DeleteIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this package?"}
        open={openConfirmDialog}
        title={"Delete Package"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDeletePackage(contractorPackage)}
      />
    </>
  );
};
