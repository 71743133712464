import * as React from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import {
  decodeOrganisation,
  encodeOrganisation,
} from "social-pro-common/decoders/organisation";
import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { Organisation } from "social-pro-common/entities/organisation";
import { catchSentryError } from "@utils/sentry";

export const useOrganisation = (profileOrganisation?: OrganisationLineItem) => {
  const [projectOrganisation, setProjectOrganisation] =
    React.useState<OrganisationLineItem>();
  const [userOrganisation, setUserOrganisation] = React.useState<
    OrganisationLineItem | undefined
  >(profileOrganisation);

  const [organisations, setOrganisations] = React.useState<
    OrganisationLineItem[]
  >([]);

  const [isOrganisationLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const getOrganisation = React.useCallback(
    async (id: string): Promise<OrganisationLineItem | null> => {
      try {
        setIsLoading(true);
        const organisationResult = await getApiData(
          "getOrganisation",
          "organisation",
          id,
        );
        if (
          !organisationResult ||
          !organisationResult.data ||
          Object.keys(organisationResult.data).length === 0
        ) {
          return null;
        }

        return decodeOrganisation(
          organisationResult.data as Organisation,
          getImageUrl,
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch organisation");
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [getApiData, decodeOrganisation, getImageUrl, catchSentryError],
  );

  const getProjectOrganisation = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        setIsLoading(true);
        const organisationResult = await getApiData(
          "getProjectOrganisation",
          "organisation",
          id,
        );
        if (
          !organisationResult ||
          !organisationResult.data ||
          Object.keys(organisationResult.data).length === 0
        ) {
          return undefined;
        }

        const organisationLineItem = await decodeOrganisation(
          organisationResult.data as Organisation,
          getImageUrl,
        );
        setProjectOrganisation(organisationLineItem);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch organisation");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeOrganisation, getImageUrl, catchSentryError],
  );

  const getProfileOrganisation = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        setIsLoading(true);
        const organisationResult = await getApiData(
          "getOrganisation",
          "organisation",
          id,
        );
        if (
          !organisationResult ||
          !organisationResult.data ||
          Object.keys(organisationResult.data).length === 0
        ) {
          return undefined;
        }
        const organisationLineItem = await decodeOrganisation(
          organisationResult.data as Organisation,
          getImageUrl,
        );
        setUserOrganisation(organisationLineItem);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch organisation");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeOrganisation, getImageUrl, catchSentryError],
  );

  const listOrganisations = React.useCallback(
    async (organisationId: string): Promise<void> => {
      try {
        setIsLoading(true);
        const organisationResult = await listApiData(
          "listOrganisations",
          "organisation",
          organisationId,
        );

        const organisationLineItems = await Promise.all(
          organisationResult.data.map((p) =>
            decodeOrganisation(p as Organisation, getImageUrl),
          ),
        );
        setOrganisations(organisationLineItems);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list organisations");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeOrganisation, getImageUrl, catchSentryError],
  );

  const searchOrganisations = React.useCallback(
    async (query: string): Promise<OrganisationLineItem[]> => {
      try {
        const organisationResult = await listApiData(
          "searchOrganisations",
          "organisation",
          query,
        );

        const decodedOrganisations = await Promise.all(
          organisationResult.data.map((p) =>
            decodeOrganisation(p as Organisation, getImageUrl),
          ),
        );
        return decodedOrganisations.filter(
          (o) => o.id !== userOrganisation?.id,
        );
      } catch (e: any) {
        setError("Could not search organisations");
        return [];
      }
    },
    [listApiData, decodeOrganisation, getImageUrl, userOrganisation],
  );

  const createOrganisation = React.useCallback(
    async (
      organisation: OrganisationLineItem,
    ): Promise<OrganisationLineItem> => {
      try {
        setIsLoading(true);
        const encodedOrganisation = encodeOrganisation(organisation);
        const upsertedOrganisation = await postApiData(
          "createOrganisation",
          "organisation",
          encodedOrganisation,
        );
        const decodedOrganisation = await decodeOrganisation(
          upsertedOrganisation.data as Organisation,
          getImageUrl,
        );
        setOrganisations([...organisations, decodedOrganisation]);
        return decodedOrganisation;
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list organisations");
      } finally {
        setIsLoading(false);
      }
      return organisation;
    },
    [
      postApiData,
      encodeOrganisation,
      decodeOrganisation,
      getImageUrl,
      organisations,
      catchSentryError,
    ],
  );

  const updateOrganisation = React.useCallback(
    async (organisation: OrganisationLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedOrganisation = encodeOrganisation(organisation);
        await postApiData(
          "updateOrganisation",
          "organisation",
          encodedOrganisation,
        );
        setOrganisations(
          organisations.map((c) => {
            if (c.id === organisation.id) {
              return organisation;
            }
            return c;
          }),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update organisation");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeOrganisation, organisations, catchSentryError],
  );

  const deleteOrganisation = React.useCallback(
    async (organisation: OrganisationLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedOrganisation = encodeOrganisation(organisation);
        await postApiData(
          "deleteOrganisation",
          "organisation",
          encodedOrganisation,
        );
        setOrganisations(organisations.filter((p) => p.id !== organisation.id));
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete organisation");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeOrganisation, organisations, catchSentryError],
  );

  return {
    createOrganisation,
    deleteOrganisation,
    error,
    getOrganisation,
    getProfileOrganisation,
    getProjectOrganisation,
    isOrganisationLoading,
    listOrganisations,
    organisations,
    projectOrganisation,
    searchOrganisations,
    setUserOrganisation,
    updateOrganisation,
    userOrganisation,
  };
};
