import { getApiData } from "@hooks/utils/api";
import { MainChartDataLineItem } from "social-pro-common/interfaces/analysis";
import { decodeMainChartData } from "social-pro-common/decoders/analysis";
import { MainChartData } from "social-pro-common/entities/analysis";
import { useEffect, useState, useCallback } from "react";
import { catchSentryError } from "@utils/sentry";

export const useMainChartData = (
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [isChartDataLoading, setIsChartDataLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<MainChartDataLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getMainChartData = useCallback(
    async (projectId: string, packageId?: string): Promise<void> => {
      try {
        setIsChartDataLoading(true);
        const response = await getApiData(
          "getMainChartData",
          "dashboard",
          projectId,
          { packageId },
        );

        const mainChartData = decodeMainChartData(
          response.data as MainChartData,
        );
        setChartData(mainChartData);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsChartDataLoading(false);
      }
    },
    [getApiData, decodeMainChartData, catchSentryError],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      getMainChartData(projectId, contractorPackageId).catch((error) => {
        setError("Could not fetch chart data");
        console.error(error);
      });
    }
  }, [projectId, contractorPackageId, getMainChartData]);

  return {
    chartData,
    error,
    isChartDataLoading,
  };
};