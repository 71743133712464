import { ContractorAnalyticsEnviroSustainabilityTable } from "./ContractorAnalyticsEnviroSustainabilityTable";
import { SustainabilityTargetAnalyticLineItem } from "social-pro-common/interfaces/analytics";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

interface ContractorAnalyticsEnvSustainabilityProps {
  loading: boolean;
  greenMetric?: GreenMetricLineItem;
  targets: SustainabilityTargetAnalyticLineItem[];
}

export const ContractorAnalyticsEnvSustainability = ({
  greenMetric,
  loading,
  targets,
}: ContractorAnalyticsEnvSustainabilityProps) => {
  return (
    <ContractorAnalyticsEnviroSustainabilityTable
      loading={loading}
      greenMetric={greenMetric}
      targets={targets}
    />
  );
};
