import { useFormik } from "formik";
import * as yup from "yup";
import { Country, ICountry, IState, State } from "country-state-city";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import {
  dateSuffix,
  dateToString,
  getReportMonths,
  stringToDate,
} from "social-pro-common/utils/date";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import dayjs from "dayjs";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { getLongitudeAndLatitude } from "@hooks/utils/useLocation";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import {
  calcRequiredDeemedHours,
  calculateTargetValueRealised,
  calculatedAvailableSpend,
} from "social-pro-common/utils/calc";
import { ProjectEstimateToggle } from "../ProjectEstimateToggle/ProjectEstimateToggle";
import { useEffect, useState } from "react";
import { FinancialLineItem } from "social-pro-common/interfaces/financial";
import InfoIcon from "@mui/icons-material/Info";
import { EstimateType } from "social-pro-common/entities/financial";
import { getBaseMultiplierForHours } from "social-pro-common/interfaces/projectLabourHourCommitment";
import { getBaseMultiplierForSpend } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import useIsDirty from "@hooks/utils/useIsDirty";
import { catchSentryError } from "@utils/sentry";

interface ProjectFormProps {
  loading: boolean;
  project: ProjectLineItem;
  handleNext: () => void;
  setProject: (project: ProjectLineItem) => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const ProjectForm = ({
  handleNext,
  loading,
  project,
  setDirtyOnChange,
  setProject,
}: ProjectFormProps) => {
  const [estimateMethod, setEstimateMethod] = useState<EstimateType>(
    project.financial.projectEstimateMethod,
  );
  const validationSchema = yup.object({
    addressLine1: yup.string().required("Address Line 1 is required"),
    addressLine2: yup.string(),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    deemedLabourHourlyRate:
      estimateMethod === EstimateType.DeemedHours
        ? yup.number().moreThan(0, "Must be greater than 0").required()
        : yup.number(),
    deemedLabourRatio:
      estimateMethod === EstimateType.DeemedHours
        ? yup
            .number()
            .moreThan(0, "Must be greater than 0")
            .max(100, "Cannot be larger than 100%")
            .required()
        : yup.number(),
    endDate: yup
      .date()
      .required("Completion is required")
      .test(
        "After start date",
        "Completion date must be after start date",
        function (item) {
          if (this.parent.startDate) {
            return this.parent.startDate < item;
          }
          return true;
        },
      ),
    estimatedBepHoursManagement:
      estimateMethod === EstimateType.EstimatedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),
    estimatedBepHoursNonTrade:
      estimateMethod === EstimateType.EstimatedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),
    estimatedBepHoursTrade:
      estimateMethod === EstimateType.EstimatedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),

    estimatedContractSpend:
      estimateMethod === EstimateType.EstimatedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),
    estimatedTotalWorkHours:
      estimateMethod === EstimateType.EstimatedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),
    margin: yup
      .number()
      .min(0)
      .required()
      .test(
        "Less than project total",
        "Margin should be less than total project value",
        function (item) {
          return this.parent.totalValue > item ? true : false;
        },
      ),
    postCode: yup
      .string()
      .required("Postcode is required")
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item) {
          if (postcodeValidatorExistsForCountry(this.parent.country)) {
            const valid = postcodeValidator(item, this.parent.country);
            return valid;
          }
          return true;
        },
      ),
    projectName: yup.string().required("Project name is required"),
    reportingDueDate: yup
      .number()
      .integer()
      .typeError("Must be a number between 1 and 28")
      .min(1, "Must be more than 0")
      .max(28, "Must be less than 29")
      .required("Reporting due date is required"),
    requiredHours:
      estimateMethod === EstimateType.DeemedHours
        ? yup.number().min(0, "Must be a positive number").required()
        : yup.number(),
    specialisedEquipmentValue: yup.number().min(0, "Must be a positive number"),
    startDate: yup.date().required("Start date due date is required"),
    state: yup.string().required("State is required"),
    totalValue: yup.number().min(0).required("Total value is required"),
  });

  const initialValues = {
    addressLine1: project.projectAddress.addressLine1,
    addressLine2: project.projectAddress.addressLine2,
    city: project.projectAddress.city,
    country: project.projectAddress.country,
    deemedLabourHourlyRate: project.financial.deemedLabourHourlyRate,
    deemedLabourRatio: project.financial.deemedLabourRatio,
    endDate: dayjs(stringToDate(project.projectEndDate)),
    estimatedBepHoursManagement: project.financial.estimatedBepHoursManagement,
    estimatedBepHoursNonTrade: project.financial.estimatedBepHoursNonTrade,
    estimatedBepHoursTrade: project.financial.estimatedBepHoursTrade,
    estimatedContractSpend: project.financial.estimatedContractSpend,

    estimatedTotalWorkHours: project.financial.estimatedTotalWorkHours,
    lessMargin: calculatedAvailableSpend(
      project.financial.totalValue,
      project.financial.margin,
      0,
      project.financial.specialisedEquipmentValue,
    ),
    margin: project.financial.margin,

    postCode: project.projectAddress.postCode,
    projectCode: project.projectCode,
    projectName: project.projectName,
    reportingDueDate: project.reportingDueDate,

    requiredHours: calcRequiredDeemedHours(
      calculatedAvailableSpend(
        project.financial.totalValue,
        project.financial.margin,
        project.financial.variations,
        project.financial.specialisedEquipmentValue,
      ),
      project.financial.deemedLabourRatio,
      project.financial.deemedLabourHourlyRate,
    ),
    specialisedEquipmentValue: project.financial.specialisedEquipmentValue,
    startDate: dayjs(stringToDate(project.projectStartDate)),
    state: project.projectAddress.state,
    totalValue: project.financial.totalValue,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      try {
        const postCodeLatLng = await getLongitudeAndLatitude(
          values.country,
          values.state,
          values.postCode,
          "",
        );
        const projectStartDate = values.startDate.toDate();
        const projectEndDate = values.endDate.toDate();

        project.commitmentsHours = project.commitmentsHours.map((ch) => {
          const baseMultiplier = getBaseMultiplierForHours(
            project.financial,
            ch,
          );
          return {
            ...ch,
            targetValueRealised: calculateTargetValueRealised(
              ch.targetValue,
              baseMultiplier,
            ),
          };
        });

        project.commitmentsSpend = project.commitmentsSpend.map((ch) => {
          const baseMultiplier = getBaseMultiplierForSpend(project.financial);
          return {
            ...ch,
            targetValueRealised: calculateTargetValueRealised(
              ch.targetValue,
              baseMultiplier,
            ),
          };
        });

        setProject({
          ...project,
          financial: {
            ...project.financial,
            deemedLabourHourlyRate: values.deemedLabourHourlyRate,
            deemedLabourRatio: values.deemedLabourRatio,
            estimatedBepHoursManagement: values.estimatedBepHoursManagement,
            estimatedBepHoursNonTrade: values.estimatedBepHoursNonTrade,
            estimatedBepHoursTrade: values.estimatedBepHoursTrade,
            estimatedContractSpend: values.estimatedContractSpend,
            estimatedTotalWorkHours: values.estimatedTotalWorkHours,
            margin: values.margin,
            projectEstimateMethod: estimateMethod,
            requiredHours: values.requiredHours,
            specialisedEquipmentValue: values.specialisedEquipmentValue,
            totalValue: values.totalValue,
          } as FinancialLineItem,
          projectAddress: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            country: values.country,
            geoLocationLat: postCodeLatLng.lat,
            geoLocationLong: postCodeLatLng.lng,
            id: project.projectAddress.id,
            postCode: values.postCode,
            state: values.state,
          },
          projectCode: values.projectCode,
          projectEndDate: dateToString(projectEndDate),
          projectName: values.projectName,
          projectReportMonths: getReportMonths(
            projectStartDate,
            projectEndDate,
          ),
          projectStartDate: dateToString(projectStartDate),
          reportingDueDate: values.reportingDueDate,
        });
        handleNext();
      } catch (error: any) {
        catchSentryError(error);
        alert("Something went wrong, please try again or contact support");
        formik.setSubmitting(false);
      }
    },
    validationSchema: validationSchema,
  });

  useIsDirty(initialValues, formik.values, setDirtyOnChange);

  useEffect(() => {
    if (project) {
      setEstimateMethod(project.financial.projectEstimateMethod);
    }
  }, [project]);

  useEffect(() => {
    formik.setFieldValue("estimatedContractSpend", formik.values.totalValue);
  }, [formik.values.totalValue]);

  useEffect(() => {
    if (estimateMethod === EstimateType.DeemedHours) {
      //Calculate lessMargin
      if (formik.values.totalValue > 0) {
        const lessMargin = calculatedAvailableSpend(
          formik.values.totalValue,
          formik.values.margin,
          0,
          formik.values.specialisedEquipmentValue,
        );
        formik.setFieldValue("lessMargin", lessMargin);
      }
    }
  }, [
    formik.values.totalValue,
    formik.values.margin,
    formik.values.specialisedEquipmentValue,
  ]);

  useEffect(() => {
    if (estimateMethod === EstimateType.DeemedHours) {
      //Calculate required hours
      if (
        formik.values.lessMargin > 0 &&
        formik.values.deemedLabourRatio > 0 &&
        formik.values.deemedLabourHourlyRate > 0
      ) {
        const requiredHours = calcRequiredDeemedHours(
          formik.values.lessMargin,
          formik.values.deemedLabourRatio,
          formik.values.deemedLabourHourlyRate,
        );
        formik.setFieldValue("requiredHours", Math.floor(requiredHours));
      }
    }
  }, [
    formik.values.lessMargin,
    formik.values.deemedLabourRatio,
    formik.values.deemedLabourHourlyRate,
  ]);

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  const disabled = formik.isSubmitting;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction={"row"}>
        <Grid item md={6}>
          <Grid
            container
            md={12}
            spacing={2}
            sx={{
              margin: "0",
              padding: "9px 40px 0px 24px",
            }}
          >
            <Grid item md={12}>
              <SubTitle loading={loading} title={"Project Details"} />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="projectCode"
                name="projectCode"
                label="Project Code"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectCode}
                error={
                  formik.touched.projectCode &&
                  Boolean(formik.errors.projectCode)
                }
                helperText={
                  formik.touched.projectCode ? formik.errors.projectCode : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="projectName"
                name="projectName"
                label="Project Name"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectName}
                error={
                  formik.touched.projectName &&
                  Boolean(formik.errors.projectName)
                }
                helperText={
                  formik.touched.projectName ? formik.errors.projectName : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="addressLine1"
                name="addressLine1"
                label="Address Line 1"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine1}
                error={
                  formik.touched.addressLine1 &&
                  Boolean(formik.errors.addressLine1)
                }
                helperText={
                  formik.touched.addressLine1 ? formik.errors.addressLine1 : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="addressLine2"
                name="addressLine2"
                label="Address Line 2"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addressLine2}
                error={
                  formik.touched.addressLine2 &&
                  Boolean(formik.errors.addressLine2)
                }
                helperText={
                  formik.touched.addressLine2 ? formik.errors.addressLine2 : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="country"
                  options={countries}
                  disabled={disabled}
                  getOptionLabel={(option) => option.name}
                  value={Country.getCountryByCode(formik.values.country)}
                  onChange={(e: any, value: ICountry | null) => {
                    formik.setFieldValue("country", value?.isoCode);
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <>
                      <TextField
                        data-test-id="country"
                        {...params}
                        name="country"
                        label="Country"
                        fullWidth
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country ? formik.errors.country : ""
                        }
                      />
                    </>
                  )}
                />
              )}
            </Grid>
            <Grid item md={6}>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="state"
                  options={states}
                  disabled={disabled}
                  getOptionLabel={(option) => option.name}
                  value={
                    State.getStateByCodeAndCountry(
                      formik.values.state,
                      formik.values.country,
                    ) || null
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onChange={(e: any, value: IState | null) => {
                    formik.setFieldValue("state", value?.isoCode);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="state"
                      {...params}
                      name="state"
                      label="State"
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={
                        formik.touched.state ? formik.errors.state : ""
                      }
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="city"
                name="city"
                fullWidth
                disabled={disabled}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city ? formik.errors.city : ""}
                label="City"
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="postCode"
                name="postCode"
                label="Postcode"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
                error={
                  formik.touched.postCode && Boolean(formik.errors.postCode)
                }
                helperText={
                  formik.touched.postCode ? formik.errors.postCode : ""
                }
              />
            </Grid>

            <Grid item md={6}>
              <StyledDatePicker
                loading={loading}
                id="startDate"
                name="startDate"
                label="Project Start Date"
                onChange={(value: string) => {
                  if (value) {
                    formik.setFieldValue("startDate", value, true);
                  }
                }}
                disabled={disabled}
                value={formik.values.startDate}
                maxDate={
                  project.firstPackageStartDate
                    ? dayjs(stringToDate(project.firstPackageStartDate))
                    : undefined
                }
                openTo="month"
                views={["year", "month"]}
                slotProps={{
                  textField: {
                    "data-test-id": "project-start-date",
                    error:
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate),
                    fullWidth: true,
                    helperText:
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                        ? "Invalid date"
                        : project.firstPackageStartDate
                          ? `First package start date: ${project.firstPackageStartDate}`
                          : "",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StyledDatePicker
                loading={loading}
                id="endDate"
                name="endDate"
                label="Practical Completion Date"
                onChange={(value: string) => {
                  if (value) {
                    formik.setFieldValue("endDate", value, true);
                  }
                }}
                disabled={disabled}
                value={formik.values.endDate}
                minDate={
                  project.lastPackageEndDate
                    ? dayjs(stringToDate(project.lastPackageEndDate))
                    : undefined
                }
                openTo="month"
                views={["year", "month"]}
                slotProps={{
                  textField: {
                    "data-test-id": "project-end-date",
                    error:
                      formik.touched.endDate && Boolean(formik.errors.endDate),
                    fullWidth: true,
                    helperText:
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                        ? "Invalid date"
                        : project.lastPackageEndDate
                          ? `Last package end date: ${project.lastPackageEndDate}`
                          : "",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                id="reportingDueDate"
                name="reportingDueDate"
                label="Reporting Due Date"
                fullWidth
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reportingDueDate}
                error={
                  formik.touched.reportingDueDate &&
                  Boolean(formik.errors.reportingDueDate)
                }
                helperText={
                  formik.errors.reportingDueDate
                    ? formik.errors.reportingDueDate
                    : "Day of the month"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      {formik.values.reportingDueDate
                        ? dateSuffix(formik.values.reportingDueDate)
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid
            container
            md={12}
            spacing={2}
            sx={{
              margin: "0",
              padding: "9px 40px 0px 40px",
            }}
          >
            <Grid item md={12}>
              <SubTitle loading={loading} title={"Project Financials"} />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="totalValue"
                name="totalValue"
                label="Total Project Value (ex. GST)"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.totalValue}
                error={
                  formik.touched.totalValue && Boolean(formik.errors.totalValue)
                }
                helperText={
                  formik.touched.totalValue ? formik.errors.totalValue : ""
                }
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="margin"
                name="margin"
                label="Margin"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.margin}
                error={formik.touched.margin && Boolean(formik.errors.margin)}
                helperText={formik.touched.margin ? formik.errors.margin : ""}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="specialisedEquipmentValue"
                name="specialisedEquipmentValue"
                label="Less Specialist Equipment"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specialisedEquipmentValue}
                error={
                  formik.touched.specialisedEquipmentValue &&
                  Boolean(formik.errors.specialisedEquipmentValue)
                }
                helperText={
                  formik.touched.specialisedEquipmentValue
                    ? formik.errors.specialisedEquipmentValue
                    : ""
                }
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                loading={loading}
                data-test-id="lessMargin"
                id="lessMargin"
                name="lessMargin"
                label="Less Margin"
                fullWidth
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lessMargin}
                error={
                  formik.touched.lessMargin && Boolean(formik.errors.lessMargin)
                }
                helperText={
                  formik.touched.lessMargin ? formik.errors.lessMargin : ""
                }
                sx={{
                  fieldset: {
                    backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                  },
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                        height: "100%",
                        m: 0,
                        maxHeight: "100%",
                        pr: "8px",
                      }}
                      position="start"
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Divider>
                {loading ? (
                  <Skeleton variant="rounded" animation="wave">
                    <Chip label={"Target Profiling"} variant="outlined" />
                  </Skeleton>
                ) : (
                  <Chip label={"Target Profiling"} variant="outlined" />
                )}
              </Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction={"row"} spacing={2}>
                <ProjectEstimateToggle
                  isLoading={loading}
                  disabled={disabled}
                  selectedMethod={estimateMethod}
                  setSelectedMethod={setEstimateMethod}
                />

                <Box sx={{ alignItems: "center", display: "flex" }}>
                  {loading ? (
                    <Skeleton variant="circular">
                      <InfoIcon color={"info"} />
                    </Skeleton>
                  ) : (
                    <Tooltip
                      placement="top"
                      title={
                        <div style={{ whiteSpace: "pre-line" }}>{`Deemed hours
                  For participation outcomes based on "MPSG Deemed Hours Formula" under the Local Jobs First Policy (LJFP).\n
                  Estimated hours
                  For participation outcomes based on "Estimated work hours" submitted to ICN.`}</div>
                      }
                      TransitionComponent={Zoom}
                    >
                      <InfoIcon color={"info"} />
                    </Tooltip>
                  )}
                </Box>
              </Stack>
            </Grid>
            {estimateMethod === EstimateType.DeemedHours ? (
              <>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="deemedLabourRatio"
                    name="deemedLabourRatio"
                    label="Deemed Labour Ratio"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deemedLabourRatio}
                    error={
                      formik.touched.deemedLabourRatio &&
                      Boolean(formik.errors.deemedLabourRatio)
                    }
                    helperText={
                      formik.touched.deemedLabourRatio
                        ? formik.errors.deemedLabourRatio
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          %
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="deemedLabourHourlyRate"
                    name="deemedLabourHourlyRate"
                    label="Deemed Hourly Rate"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deemedLabourHourlyRate}
                    error={
                      formik.touched.deemedLabourHourlyRate &&
                      Boolean(formik.errors.deemedLabourHourlyRate)
                    }
                    helperText={
                      formik.touched.deemedLabourHourlyRate
                        ? formik.errors.deemedLabourHourlyRate
                        : ""
                    }
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    id="requiredHours"
                    name="requiredHours"
                    label="Required Hours"
                    fullWidth
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.requiredHours}
                    error={
                      formik.touched.requiredHours &&
                      Boolean(formik.errors.requiredHours)
                    }
                    helperText={
                      formik.touched.requiredHours
                        ? formik.errors.requiredHours
                        : ""
                    }
                    sx={{
                      fieldset: {
                        backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{
                            backgroundColor:
                              "rgba(239, 239, 239, 0.3) !important;",
                            height: "100%",
                            m: 0,
                            maxHeight: "100%",
                            p: 0,
                          }}
                          position="end"
                        >
                          hours
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled
                    id="estimatedContractSpend"
                    name="estimatedContractSpend"
                    label="Estimated Contract Spend"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimatedContractSpend}
                    error={
                      formik.touched.estimatedContractSpend &&
                      Boolean(formik.errors.estimatedContractSpend)
                    }
                    helperText={
                      formik.touched.estimatedContractSpend
                        ? formik.errors.estimatedContractSpend
                        : ""
                    }
                    sx={{
                      fieldset: {
                        backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                      },
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                      startAdornment: (
                        <InputAdornment
                          sx={{
                            backgroundColor:
                              "rgba(239, 239, 239, 0.3) !important;",
                            height: "100%",
                            m: 0,
                            maxHeight: "100%",
                            pr: "8px",
                          }}
                          position="start"
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="estimatedTotalWorkHours"
                    name="estimatedTotalWorkHours"
                    label="Estimated Total Work Hours"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimatedTotalWorkHours}
                    error={
                      formik.touched.estimatedTotalWorkHours &&
                      Boolean(formik.errors.estimatedTotalWorkHours)
                    }
                    helperText={
                      formik.touched.estimatedTotalWorkHours
                        ? formik.errors.estimatedTotalWorkHours
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          hours
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="estimatedBepHoursTrade"
                    name="estimatedBepHoursTrade"
                    label="Estimated Trade Hours"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimatedBepHoursTrade}
                    error={
                      formik.touched.estimatedBepHoursTrade &&
                      Boolean(formik.errors.estimatedBepHoursTrade)
                    }
                    helperText={
                      formik.touched.estimatedBepHoursTrade
                        ? formik.errors.estimatedBepHoursTrade
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          hours
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="estimatedBepHoursNonTrade"
                    name="estimatedBepHoursNonTrade"
                    label="Estimated Non-trade Hours"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimatedBepHoursNonTrade}
                    error={
                      formik.touched.estimatedBepHoursNonTrade &&
                      Boolean(formik.errors.estimatedBepHoursNonTrade)
                    }
                    helperText={
                      formik.touched.estimatedBepHoursNonTrade
                        ? formik.errors.estimatedBepHoursNonTrade
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          hours
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="estimatedBepHoursManagement"
                    name="estimatedBepHoursManagement"
                    label="Estimated Management/Supervisory/Specialist Hours"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimatedBepHoursManagement}
                    error={
                      formik.touched.estimatedBepHoursManagement &&
                      Boolean(formik.errors.estimatedBepHoursManagement)
                    }
                    helperText={
                      formik.touched.estimatedBepHoursManagement
                        ? formik.errors.estimatedBepHoursManagement
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          hours
                        </InputAdornment>
                      ),
                      inputComponent: NumericFormatCustom as any,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          margin: "0",
          padding: "0px 40px 40px 40px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            alignItems: "flex-end",
            display: "flex !important",
            justifyContent: "end",
          }}
        />
        <StyledButton
          id="projectDetailsButtonSubmit"
          loading={loading}
          variant="contained"
          type="submit"
        >
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
