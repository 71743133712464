import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { OrganisationTableRowSkeleton } from "./OrganisationTableRowSkeleton";
import { OrganisationTableRow } from "./OrganisationTableRow";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

interface OrganisationTableProps {
  loading: boolean;
  userOrganisation?: OrganisationLineItem;
  organisations: OrganisationLineItem[];
  organisationCount: number;
  setPage: (page: number) => void;
  handleJoinOrganisation: (organisation: OrganisationLineItem) => void;
  handleUpdateOrganisationContractor: (
    organisation: OrganisationLineItem,
    isContractor: boolean,
  ) => void;
  handleUpdateMaxProjectCount: (
    organisation: OrganisationLineItem,
    maxActiveProjects: number,
  ) => void;
}

export const OrganisationTable = ({
  handleJoinOrganisation,
  loading,
  organisationCount,
  organisations,
  setPage,
  userOrganisation,
}: OrganisationTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const tableHeader = (
    <TableHead>
      <TableRow sx={{ marginTop: "0px !important" }}>
        <TableHeader
          loading={loading}
          title={"Organisation Name"}
          sx={{ width: "25%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Max Projects"}
          sx={{ width: "25%" }}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Is Contractor"}
          sx={{ width: "25%" }}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={""}
          sx={{ width: "25%" }}
          align="left"
        />
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <TableContainer>
        {loading ? (
          <Table stickyHeader aria-label="sticky table">
            {tableHeader}
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <OrganisationTableRowSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table
            data-test-id="contractor-package-table"
            stickyHeader
            aria-label="sticky table"
          >
            {tableHeader}
            <TableBody>
              {organisations.map((row: OrganisationLineItem, index: number) => (
                <OrganisationTableRow
                  key={`organisation-row-${index}`}
                  userOrganisation={userOrganisation}
                  organisation={row}
                  handleJoinOrganisation={handleJoinOrganisation}
                />
              ))}
              {organisationCount === 0 ? (
                <NoRows colSpan={4} title={"Organisations"} />
              ) : null}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {organisationCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(organisationCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </>
  );
};
