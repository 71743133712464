"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganisationName = exports.createDefaultOrganisation = void 0;
var uuid_1 = require("uuid");
var contactInfo_1 = require("../contactInfo");
var createDefaultOrganisation = function (email) {
    var defaultContactInfo = (0, contactInfo_1.createDefaultContactInfo)();
    return {
        abn: "",
        alertNotifications: true,
        contactInfo: defaultContactInfo,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        isSetup: false,
        maxActiveProjects: 0,
        organisationLogo: "",
        organisationLogoSrc: "",
        organisationName: "",
        planType: "Free",
        representativeEmail: email || "",
        representativeName: "",
        stripeId: "",
        tradingName: "",
        users: [],
    };
};
exports.createDefaultOrganisation = createDefaultOrganisation;
var getOrganisationName = function (org) {
    if (org.organisationName.length > 0) {
        return org.organisationName;
    }
    return org.representativeEmail;
};
exports.getOrganisationName = getOrganisationName;
