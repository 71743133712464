import * as React from "react";
import { SupportingDocumentLineItem } from "social-pro-common/interfaces/supportingDocument";
import {
  decodeSupportingDocument,
  encodeSupportingDocument,
} from "social-pro-common/decoders/supportingDocument";
import { deleteBatchApiData, listApiData } from "@hooks/utils/api";
import { SupportingDocument } from "social-pro-common/entities/supportingDocument";
import { catchSentryError } from "@utils/sentry";

export const useSupportingDocumentForEmployee = (employeeId: string) => {
  const [supportingDocuments, setSupportingDocuments] = React.useState<
    SupportingDocumentLineItem[]
  >([]);
  const [isSupportingDocumentLoading, setIsSupportingDocumentLoading] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const listSupportingDocumentsEmployee = React.useCallback(
    async (employeeId: string): Promise<void> => {
      try {
        setIsSupportingDocumentLoading(true);
        const supportingDocumentResult = await listApiData(
          "listSupportingDocumentsEmployee",
          "supportingDocument",
          employeeId,
        );
        setSupportingDocuments(
          supportingDocumentResult.data.map((p) =>
            decodeSupportingDocument(p as SupportingDocument),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list supportingDocuments");
      } finally {
        setIsSupportingDocumentLoading(false);
      }
    },
    [],
  );

  const deleteSupportingDocuments = React.useCallback(
    async (
      supportingDocumentsToDelete: SupportingDocumentLineItem[],
    ): Promise<void> => {
      try {
        setIsSupportingDocumentLoading(true);
        const encodedSupportingDocument = supportingDocumentsToDelete.map((e) =>
          encodeSupportingDocument(e),
        );
        await deleteBatchApiData(
          "deleteSupportingDocuments",
          "supportingDocument",
          encodedSupportingDocument,
        );

        const deletedRowIds = supportingDocumentsToDelete.map((e) => e.id);
        setSupportingDocuments(
          supportingDocuments.filter((e) => !deletedRowIds.includes(e.id)),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not delete supportingDocument");
      } finally {
        setIsSupportingDocumentLoading(false);
      }
    },
    [supportingDocuments],
  );

  React.useEffect(() => {
    if (employeeId) {
      listSupportingDocumentsEmployee(employeeId);
    } else {
      setIsSupportingDocumentLoading(false);
    }
  }, [employeeId, listSupportingDocumentsEmployee]);

  return {
    deleteSupportingDocuments,
    error,
    isSupportingDocumentLoading,
    listSupportingDocumentsEmployee,
    supportingDocuments,
  };
};
