"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricComment = void 0;
var uuid_1 = require("uuid");
var createDefaultGreenMetricComment = function (greenMetricId, contractorPackageId, reportId, comment) {
    return {
        comment: comment,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        greenMetricId: greenMetricId,
        id: (0, uuid_1.v4)(),
        reportId: reportId,
    };
};
exports.createDefaultGreenMetricComment = createDefaultGreenMetricComment;
