import { Skeleton, TableCell, TableRow } from "@mui/material";

export default function SupportingDocumentDialogRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="circular" />
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
}
