import { API } from "aws-amplify";
import { PlaceLatLng } from "social-pro-common/decoders/location";

type APIRequest = {
  response: true;
  body?: { [key: string]: any };
  queryStringParameters?: { [key: string]: any };
};

const API_NAME = "geo";

export const getLongitudeAndLatitude = async (
  locationInfo1: string,
  locationInfo2: string,
  locationInfo3: string,
  locationInfo4: string,
) => {
  const myInit = {
    queryStringParameters: {
      locationInfo1,
      locationInfo2,
      locationInfo3,
      locationInfo4,
    },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  } as APIRequest;
  const res = await API.get(API_NAME, "geoQuery", myInit);
  return res.data.geo as PlaceLatLng;
};
