import { Skeleton, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface QuickActionProps {
  backgroundColor?: string;
  isLoading: boolean;
  icon: React.ReactNode;
  title: string;
  overdue: boolean;
  message: string;
  action: () => void;
}

export const QuickAction = ({
  action,
  backgroundColor,
  icon,
  isLoading,
  message,
  overdue,
  title,
}: QuickActionProps) => {
  return (
    <div
      style={{
        alignItems: "center",
        background: backgroundColor ?? "#fff",
        borderRadius: "12px",
        cursor: "pointer",
        display: "flex",
        padding: "7px",
        transition: "0.3s all",
        width: "100%",
      }}
      className="quick-action-links"
      onClick={action}
    >
      <div
        style={{
          alignItems: "center",
          background: "transparent",
          borderRadius: "8px",
          display: "flex",
          height: "35px",
          justifyContent: "center",
          width: "35px",
        }}
      >
        {isLoading ? (
          <Skeleton animation="wave" variant="rounded">
            {icon}
          </Skeleton>
        ) : (
          icon
        )}
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          gap: "3px",
          justifyContent: "space-between",
          paddingLeft: "10px",
          width: "calc(100% - 40px)",
        }}
      >
        <div>
          <Stack spacing={1}>
            {isLoading ? (
              <Skeleton animation="wave">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "14px",
                  }}
                >
                  {title}
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "300",
                  lineHeight: "14px",
                  textAlign: "start",
                }}
              >
                {title}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton animation="wave">
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{ color: "black", display: "flex", fontSize: "10px" }}
                >
                  {message}
                </Typography>
              </Skeleton>
            ) : overdue ? (
              <Typography
                variant="h6"
                component="h6"
                sx={{ color: "#C60030", display: "flex", fontSize: "14px" }}
              >
                Overdue
              </Typography>
            ) : (
              <Typography
                variant="h6"
                component="h6"
                sx={{ color: "black", display: "flex", fontSize: "14px" }}
              >
                {message}
              </Typography>
            )}
          </Stack>
        </div>
        {isLoading ? (
          <Skeleton animation="wave" variant="circular">
            <ArrowForwardIcon
              style={{
                alignSelf: "center",
                color: "#000",
                transition: "0.3s all",
              }}
              className="arrow"
            />
          </Skeleton>
        ) : (
          <ArrowForwardIcon
            style={{
              alignSelf: "center",
              color: "#000",
              transition: "0.3s all",
            }}
            className="arrow"
          />
        )}
      </div>
    </div>
  );
};
