import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import {
  PackageLocalContentLineItem,
  aggregateLocalContentItems,
} from "social-pro-common/interfaces/packageLocalContent";
import { LidpTableSumRow } from "../LidpTableSumRow/LidpTableSumRow";
import { ContractorAnalyticsLocalContentRow } from "./PackageAnalyticsLocalContentRow";
import { useState } from "react";
import { LidpTabs } from "../LidpTabs/LidpTabs";
import { NoRows } from "../ListTable/NoRows";
import { LidpTableSumRowSkeleton } from "../LidpTableSumRow/LidpTableSumRowSkeleton";
import { ContractorAnalyticsLocalContentRowSkeleton } from "./PackageAnalyticsLocalContentRowSkeleton";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { writeXlsxLidp } from "@utils/xlsx/localContent";
import DownloadIcon from "@mui/icons-material/Download";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { usePackageLocalContentAcc } from "@hooks/crud/packageLocalContent/usePackageLocalContentAcc";

interface PackageAnalyticsLocalContentProps {
  loading: boolean;
  reportId: string;
  isPrimaryPackage: boolean;
  contractorPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
}

export const PackageAnalyticsLocalContent = ({
  contractorPackage,
  isPrimaryPackage,
  loading,
  localContents,
  reportId,
}: PackageAnalyticsLocalContentProps) => {
  const [value, setValue] = useState(0);

  const { isPackageLocalContentLoading, packageLocalContents } =
    usePackageLocalContentAcc(
      contractorPackage?.projectId,
      contractorPackage?.id,
      reportId,
    );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const showActual = value === 1;

  const itemsToDisplay = showActual
    ? localContents
    : aggregateLocalContentItems(packageLocalContents || []);

  const isLoading = loading || isPackageLocalContentLoading;

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={isLoading}
          title={"Item Description"}
          align="left"
        />
        <TableHeader loading={isLoading} title={"Manufacturer"} align="left" />
        <TableHeader loading={isLoading} title={"Supplier"} align="left" />
        <TableHeader loading={isLoading} title={"Value ($)"} align="center" />
        <TableHeader
          loading={isLoading}
          title={"Steel Mass (T)"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Local Value Add"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Import Value Add"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Contract Content"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"ANZ value-add activity (%)"}
          align="center"
        />
        <TableHeader loading={isLoading} title={"SME Count"} align="center" />
        <TableHeader
          loading={isLoading}
          title={"Supplier Count"}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0 0 20px", padding: "0 !important", width: "100%" }}
      >
        <Grid item md={12}>
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">{"Local Content"}</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">{"Local Content"}</Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0", width: "100%" }}
      >
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ marginTop: "0", padding: "0 !important" }}
            >
              {isPrimaryPackage &&
              contractorPackage &&
              contractorPackage.packageType == PackageType.PrimaryPackage ? (
                <LidpTabs
                  isLoading={isLoading}
                  value={value}
                  handleChange={handleChange}
                />
              ) : null}
            </Grid>
            <Grid
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <StyledButton
                data-test-id="export-targets-button"
                className="blackBtn grey-outline-btn"
                loading={isLoading}
                disabled={loading || !itemsToDisplay || !itemsToDisplay.length}
                variant="contained"
                onClick={() => {
                  if (itemsToDisplay) {
                    writeXlsxLidp(itemsToDisplay);
                  }
                }}
                startIcon={<DownloadIcon />}
              >
                Export Targets
              </StyledButton>
            </Grid>
          </Grid>
          {isLoading ? (
            <Table>
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <ContractorAnalyticsLocalContentRowSkeleton key={index} />
                ))}
                <LidpTableSumRowSkeleton />
              </TableBody>
            </Table>
          ) : (
            <Table data-test-id="localContent-commitment-table">
              {tableHeader}
              <TableBody>
                {!loading &&
                  itemsToDisplay.map((lc) => {
                    return (
                      <ContractorAnalyticsLocalContentRow
                        key={`local-content-table-row-${lc.id}`}
                        localContent={lc}
                      />
                    );
                  })}
                {itemsToDisplay.length > 0 ? (
                  <LidpTableSumRow
                    localContents={itemsToDisplay}
                    hasButtonColumn={false}
                  />
                ) : (
                  <NoRows title={"Local Content"} colSpan={11} />
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </Grid>
    </>
  );
};
