// third-party

import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { FolderOpen } from "lucide-react";

export const getDirectoryNav = (projectId?: string): NavItemType => {
  const theme = useTheme();
  return {
    children: [
      {
        icon: <FolderOpen />,
        id: "directory",
        title: "Smart Directory",
        type: "item",
        url: projectId ? RouteHelper.directory(projectId) : "",
      },
    ],
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "directory",
    title: "Discover",
    type: "group",
  };
};
