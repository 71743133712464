import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid } from "@mui/material";
import { useState } from "react";
import { ContractorAnalyticsSocialSpend } from "@stories/molecules/ContractorAnalyticsSocialSpend/ContractorAnalyticsSocialSpend";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { dateToString, stringToDate } from "social-pro-common/utils/date";
import * as RouteHelper from "@utils/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useSocialSpendAnalytics } from "@hooks/crud/analytics/useSocialSpendAnalytics";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import DownloadIcon from "@mui/icons-material/Download";
import { writeXlsxSocialSpendTargets } from "@utils/xlsx/spend";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const AnalyticsSpend = () => {
  const navigate = useNavigate();

  const { reportId } = useParams();
  if (!reportId) {
    throw Error("No report id");
  }
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const [selectedReportId, setReportDate] = useState<string>(reportId);

  const { isAnalysisLoading, socialSpendAnalytics } = useSocialSpendAnalytics(
    selectedReportId,
    selectedProject?.id,
    contractorPackage?.id,
  );

  const onSocialSpendTargetChange = (targetId: string) => {
    if (selectedProject && selectedReportId) {
      navigate(
        RouteHelper.analyticsViewSpend(
          selectedProject.id,
          selectedReportId,
          targetId,
        ),
      );
    }
  };

  const loading = isProjectLoading || isAnalysisLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Social Spend"
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
        />

        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <MonthSelect
            data-test-id="month-select"
            loading={loading}
            project={selectedProject}
            contractorPackage={contractorPackage}
            isPrimaryPackage={isPrimaryPackage}
            setDate={(date: Date) => {
              setReportDate(dateToString(date));
            }}
            date={stringToDate(selectedReportId)}
          />
          <StyledButton
            data-test-id="export-targets-button"
            className="blackBtn grey-outline-btn topBarBtn"
            loading={loading}
            disabled={
              loading ||
              !socialSpendAnalytics ||
              !socialSpendAnalytics?.targets.length
            }
            variant="contained"
            onClick={() => {
              if (socialSpendAnalytics) {
                writeXlsxSocialSpendTargets(socialSpendAnalytics.targets);
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Targets
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "5px 40px !important" }}
        >
          <ContractorAnalyticsSocialSpend
            loading={loading}
            socialSpendAnalysis={socialSpendAnalytics}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
