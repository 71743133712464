import {
  Typography,
  Skeleton,
  Stack,
  LinearProgress,
  Box,
} from "@mui/material";

interface DashboardProgressbarProps {
  loading: boolean;
  progress: number;
  startDate: string;
  endDate: string;
  prefixTestId?: string;
  suffixTestId?: string;
  progressTestId?: string;
}

export const DashboardProgressbar = ({
  endDate,
  loading,
  prefixTestId,
  progress,
  progressTestId,
  startDate,
  suffixTestId,
}: DashboardProgressbarProps) => {
  if (loading) {
    return (
      <Stack>
        <Skeleton animation="wave" variant="rounded" sx={{ width: "100%" }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton animation="wave">
            <Typography variant="caption">Jan 21</Typography>
          </Skeleton>
          <Skeleton animation="wave">
            <Typography variant="caption">Jun 26</Typography>
          </Skeleton>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ borderRadius: "5px", height: "1.2em" }}
        data-test-id={progressTestId}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption" data-test-id={prefixTestId}>
          {startDate}
        </Typography>
        <Typography variant="caption" data-test-id={suffixTestId}>
          {endDate}
        </Typography>
      </Box>
    </Stack>
  );
};
