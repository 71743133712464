import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { EmployeeHoursSubTableSkeleton } from "./EmployeeHoursSubTableSkeleton";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "../ListTable/NoRows";
import { formatChartDate, stringToDate } from "social-pro-common/utils/date";
import { useLabourHourForEmployee } from "@hooks/crud/labourHours/useLabourHoursForEmployee";
import { useState } from "react";
import { downloadFile } from "@hooks/utils/useUpload";
import { getDocumentName } from "social-pro-common/utils/string";

interface EmployeeLabourHoursSubTableProps {
  employee: EmployeeLineItem;
}

export const EmployeeLabourHoursSubTable = ({
  employee,
}: EmployeeLabourHoursSubTableProps) => {
  const { isLabourHourLoading, labourHours } = useLabourHourForEmployee(
    employee.id,
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const loading = isLabourHourLoading;
  return (
    <>
      <TableContainer className="glb-table-sty">
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableHeader
                loading={loading}
                title="Date"
                sx={{ width: "20%" }}
                align="left"
              />
              <TableHeader
                loading={loading}
                title="Project"
                sx={{ width: "20%" }}
                align="left"
              />
              <TableHeader
                loading={loading}
                title="Hours"
                sx={{ width: "15%" }}
                align="center"
              />
              <TableHeader
                loading={loading}
                title="Studied"
                sx={{ width: "15%" }}
                align="center"
              />
              <TableHeader
                loading={loading}
                title=""
                sx={{ width: "10%" }}
                align="center"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading
              ? labourHours.map((c, index) => {
                  return (
                    <TableRow
                      key={`labour-hour-table-row-${index}`}
                      sx={{ background: "gray" }}
                    >
                      <TableCell align="left">
                        <Typography align="left">
                          {formatChartDate(stringToDate(c.reportId))}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography align="left">
                          {`${c.projectName}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography align="center">
                          {`${c.amountOfHoursWorked}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography align="center">
                          {`${c.amountOfHoursStudied}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <TableCell align="center" className="right">
                          {c?.uri ? (
                            isDownloading ? (
                              <CircularProgress />
                            ) : (
                              <Button
                                color="primary"
                                size="large"
                                disabled={isDownloading}
                                onClick={() =>
                                  c.uri
                                    ? onDownload(
                                        c.uri,
                                        getDocumentName(
                                          `${c.projectName}-${c.reportId}`,
                                          c.uri,
                                        ),
                                      )
                                    : undefined
                                }
                                className="blackBtn grey-outline-btn"
                                sx={{
                                  color: "white",
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  padding: "10px 15px",
                                }}
                              >
                                Download
                              </Button>
                            )
                          ) : null}
                        </TableCell>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}

            {labourHours?.length === 0 && !loading ? (
              <NoRows colSpan={4} title={"Labour hours for employee"} />
            ) : null}
            {loading ? (
              <>
                <EmployeeHoursSubTableSkeleton />
                <EmployeeHoursSubTableSkeleton />
                <EmployeeHoursSubTableSkeleton />
                <EmployeeHoursSubTableSkeleton />
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
