import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  StyledButton,
  StyledLoadingButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PackageSustainabilityTableRow } from "../PackageSustainabilityTableRow/PackageSustainabilityTableRow";
import { PackageSustainabilityTableRowSkeleton } from "../PackageSustainabilityTableRow/PackageSustainabilityTableRowSkeleton";

interface PackageSustainabilityRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  handleNext: (contractorPackageSetup: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
}

export const PackageSustainabilityRequirementForm = ({
  contractorPackageSetup,
  handleBack,
  handleNext,
  loading,
  project,
}: PackageSustainabilityRequirementFormProps) => {
  const handleSubmit = () => {
    handleNext(contractorPackageSetup);
  };

  return (
    <Box sx={{ padding: "25px 40px 0px !important" }}>
      <Grid container spacing={2} md={12}>
        <Grid
          item
          md={7}
          sx={{
            alignContent: "left",
            display: "flex",
            justifyContent: "left",
          }}
        >
          <SubTitle title={"Sustainability"} loading={loading} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <Table data-test-id="package-hours-commitment-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="70%">
                    Target Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from({ length: 6 }).map((_, i) => (
                      <PackageSustainabilityTableRowSkeleton key={i} />
                    ))
                  : project.greenMetrics.map((metric) => {
                      return (
                        <PackageSustainabilityTableRow
                          greenMetric={metric}
                          key={metric.id}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            marginTop: "16px",
            padding: "0px 0px 40px 0px",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={loading}
              onClick={handleBack}
              disabled={loading}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledLoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </StyledLoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
