import { Card, Table, TableBody, TableHead } from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { GreenMetricCategorySubmissionLineItem } from "social-pro-common/interfaces/greenMetricCategorySubmission";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { SustainabilityRowSkeleton } from "./SustainabilityTableRowSkeleton";
import { SustainabilityRow } from "./SustainabilityTableRow";

interface SustainabilityTableProps {
  loading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  project?: ProjectLineItem;
  greenMetric?: GreenMetricLineItem;
  reportId: string;
  readOnly?: boolean;
  greenMetricCategorySubmissions: GreenMetricCategorySubmissionLineItem[];
  createGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
  updateGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
}

export const SustainabilityTable = ({
  contractorPackage,
  createGreenMetricCategorySubmission,
  greenMetric,
  greenMetricCategorySubmissions,
  loading,
  project,
  readOnly,
  reportId,
  updateGreenMetricCategorySubmission,
}: SustainabilityTableProps) => {
  const tableHeader = (
    <TableHead>
      <TableHeader
        loading={loading}
        title={"Target Category"}
        align="left"
        sx={{ width: "20%" }}
      />
      <TableHeader
        loading={loading}
        title={"Sub Category"}
        align="left"
        sx={{ width: "30%" }}
      />
      <TableHeader
        loading={loading}
        title={"Quantity"}
        align="left"
        sx={{ width: "20%" }}
      />
      <TableHeader
        loading={loading}
        title={"Supporting Document"}
        align="right"
        sx={{ width: "10%" }}
      />
    </TableHead>
  );

  return (
    <Card>
      {loading || !greenMetric ? (
        <Table size="small">
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <SustainabilityRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table size="small" data-test-id="green-metrics-table">
          {tableHeader}
          <TableBody>
            {!loading &&
              greenMetric &&
              greenMetric.reportCategories.map((p) => {
                const greenMetricCategorySubmission =
                  greenMetricCategorySubmissions.find(
                    (pp) => pp.greenMetricCategoryId === p.id,
                  );
                return (
                  <SustainabilityRow
                    key={p.id}
                    reportId={reportId}
                    greenMetricCategory={p}
                    greenMetricCategorySubmission={
                      greenMetricCategorySubmission
                    }
                    project={project}
                    contractorPackage={contractorPackage}
                    createGreenMetricCategorySubmission={
                      createGreenMetricCategorySubmission
                    }
                    updateGreenMetricCategorySubmission={
                      updateGreenMetricCategorySubmission
                    }
                    readOnly={readOnly}
                  />
                );
              })}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};
