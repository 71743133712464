"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProjectReportSubmission = void 0;
var uuid_1 = require("uuid");
var projectReportSubmission_1 = require("../../entities/projectReportSubmission");
var createDefaultProjectReportSubmission = function (projectId, reportId) {
    return {
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId: reportId,
        reportStatus: projectReportSubmission_1.ReportStatus.Upcoming,
    };
};
exports.createDefaultProjectReportSubmission = createDefaultProjectReportSubmission;
