import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { ContractorPackageAnalyticsTableRow } from "./ContractorPackageAnalyticsTableRow";
import { ContractorPackageAnalyticsTableRowSkeleton } from "./ContractorPackageAnalyticsTableRowSkeleton";

interface ContractorPackageAnalyticsTableProps {
  loading: boolean;
  contractorPackages: ContractorPackageLineItem[];
  filterValue?: string;
  viewPackageDetails: (contractorPackage: ContractorPackageLineItem) => void;
}

export const ContractorPackageAnalyticsTable = ({
  contractorPackages,
  filterValue,
  loading,
  viewPackageDetails,
}: ContractorPackageAnalyticsTableProps) => {
  const filteredPackages = contractorPackages.filter((contractorPackage) => {
    if (filterValue) {
      return (
        contractorPackage.contractorName
          .toLowerCase()
          .includes(filterValue.toLowerCase()) ||
        contractorPackage.packageDescription ||
        packageTypeToString(contractorPackage.packageType)
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
    return true;
  });

  filteredPackages.sort(
    (a: ContractorPackageLineItem, b: ContractorPackageLineItem) => {
      if (a.packageNumber < b.packageNumber) return -1;
      if (a.packageNumber > b.packageNumber) return 1;
      return 0;
    },
  );

  const tableHeaders = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"No."}
          sx={{ width: "5%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Subcontractor"}
          sx={{ width: "30%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Work Package"}
          sx={{ width: "20%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Start Date"}
          sx={{ width: "20%" }}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Target Date"}
          sx={{ width: "20%" }}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Action"}
          sx={{ width: "5%" }}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
  return loading ? (
    <Table stickyHeader aria-label="sticky table">
      {tableHeaders}
      <TableBody>
        {Array.from({ length: 10 }, (_, index) => (
          <ContractorPackageAnalyticsTableRowSkeleton key={index} />
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table
      stickyHeader
      data-test-id="localContent-package-table"
      aria-label="sticky table"
    >
      {tableHeaders}
      <TableBody>
        {filteredPackages &&
          filteredPackages.map(
            (row: ContractorPackageLineItem, index: number) => (
              <ContractorPackageAnalyticsTableRow
                key={`contractor-package-row-${index}`}
                contractorPackage={row}
                viewPackageDetails={() => {
                  viewPackageDetails(row);
                }}
              />
            ),
          )}
        {filteredPackages.length === 0 ? (
          <NoRows colSpan={6} title={"Packages"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
