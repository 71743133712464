import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Skeleton,
  Button,
} from "@mui/material";
interface LidpTabsProps {
  isLoading: boolean;
  value: ChartRangeType;
  handleChange: (event: React.SyntheticEvent, newValue: ChartRangeType) => void;
}

export enum ChartRangeType {
  TwelveMonth = "tweleve-month_chart-range",
  AllTime = "all-time_chart-range",
}

export const DashboardChartRangeTabs = ({
  handleChange,
  isLoading,
  value,
}: LidpTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings

  return (
    <Box marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>12 Month</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>All time</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value={ChartRangeType.TwelveMonth}
            size="small"
            data-test-id="annual-toggle"
          >
            12 Months
          </ToggleButton>
          <ToggleButton
            value={ChartRangeType.AllTime}
            size="small"
            data-test-id="all-time-toggle"
          >
            All time
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
