import { Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import {
  HammertechCredentials,
  HammertechModal,
} from "./HammertechModal/HammertechModal";
import { useState } from "react";
import {
  createDefaultIntegrationInfoLineItem,
  IntegrationInfoLineItem,
  IntegrationType,
} from "social-pro-common/interfaces/integration";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

interface HammertechProps {
  loading: boolean;
  integration?: IntegrationInfoLineItem;
  userOrganisation?: OrganisationLineItem;
  handleIntegrationChange: (integration: IntegrationInfoLineItem) => void;
}
export const Hammertech = ({
  handleIntegrationChange,
  integration,
  loading,
  userOrganisation,
}: HammertechProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card
        className="direct-card"
        variant="outlined"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
        onClick={() => setOpen(true)}
      >
        <CardHeader
          avatar={
            <img
              style={{ backgroundColor: "#8FC9F9" }}
              width={80}
              alt={"HammerTech"}
              src={"hammertech.jpeg"}
            />
          }
          title={"HammerTech"}
        />
        <CardContent>
          <Typography
            variant="caption"
            component="p"
            id="fullDescription"
            mb={2}
            sx={{
              color: "#000",
              fontSize: "14px",
              lineHeight: "24px",
              marginTop: "5px",
            }}
          >
            Import employees from via the HammerTech API
          </Typography>
          <Chip
            label={integration?.enabled ? "Enabled" : "Not enabled"}
            color={integration?.enabled ? "success" : "warning"}
          />
        </CardContent>
      </Card>
      {open && userOrganisation ? (
        <HammertechModal
          loading={loading}
          open={open}
          credentials={
            (integration?.config as unknown as HammertechCredentials) || {
              enabled: false,
              password: "",
              tennant: "",
              username: "",
            }
          }
          handleClose={() => setOpen(false)}
          onSubmit={async (
            credentials: HammertechCredentials,
          ): Promise<void> => {
            if (integration) {
              await handleIntegrationChange({
                ...integration,
                config: {
                  password: credentials.password,
                  tennant: credentials.tennant,
                  username: credentials.username,
                },
                enabled: credentials.enabled,
              });
            } else {
              const newIntegration = createDefaultIntegrationInfoLineItem(
                userOrganisation.id,
                IntegrationType.HammerTech,
              );
              await handleIntegrationChange({
                ...newIntegration,
                config: {
                  enabled: credentials.enabled,
                  password: credentials.password,
                  tennant: credentials.tennant,
                  username: credentials.username,
                },
              });
            }
            setOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
