import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import TrainingContractModal from "@stories/organisms/TrainingContractModal/TrainingContractModal";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewTrainingContractModal from "@stories/organisms/TrainingContractModal/ViewTrainingContractModal";
import EditIcon from "@mui/icons-material/Edit";

interface EmployeeSupportingDocsFormRowSelectTraineeContractProps {
  uniqueStudentId?: string;
  traineeNumberValue?: string;
  qualificationValue?: string;
  educationalInstituteValue?: string;
  traineeContractEndDate?: Date;
  errorMessage?: string;
  setUniqueStudentId: (value: string) => void;
  setTraineeNumberValue: (value: string) => void;
  setQualificationValue: (value: string) => void;
  setEducationalInstituteValue: (value: string) => void;
  setTraineeContractEndDate: (value: Date) => void;
}

export const EmployeeSupportingDocsFormRowSelectTraineeContract = ({
  educationalInstituteValue,
  qualificationValue,
  setEducationalInstituteValue,
  setQualificationValue,
  setTraineeContractEndDate,
  setTraineeNumberValue,
  setUniqueStudentId,
  traineeContractEndDate,
  traineeNumberValue,
  uniqueStudentId,
}: EmployeeSupportingDocsFormRowSelectTraineeContractProps) => {
  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [openViewModal, setViewModal] = React.useState(false);

  const clearTraineeDetails = () => {
    setUniqueStudentId("");
    setTraineeNumberValue("");
    setQualificationValue("");
    setEducationalInstituteValue("");
    setTraineeContractEndDate(new Date());
  };
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan={1}>
          <Typography>{"Trainee/Apprentice Contract Details"}</Typography>
          <Stack>
            <Typography variant="caption">
              Unique Student ID:{" "}
              {uniqueStudentId ? (
                uniqueStudentId
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Training contract number:{" "}
              {traineeNumberValue ? (
                traineeNumberValue
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Qualification:{" "}
              {qualificationValue ? (
                qualificationValue
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Educational Institution:{" "}
              {educationalInstituteValue ? (
                educationalInstituteValue
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Contract End Date:{" "}
              {traineeContractEndDate ? (
                traineeContractEndDate.toLocaleDateString("en-GB")
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                size="large"
                onClick={() => setModalIsOpen(true)}
                data-test-id="edit-trainee-contract"
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="View">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                disabled={Boolean(!uniqueStudentId)}
                size="large"
                onClick={() => {
                  setViewModal(true);
                }}
              >
                <RemoveRedEyeIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={"Delete"}
            >
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.error.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.error.dark,
                }}
                disabled={Boolean(!uniqueStudentId)}
                size="large"
                onClick={() => clearTraineeDetails()}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TrainingContractModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        uniqueStudentId={uniqueStudentId}
        traineeNumberValue={traineeNumberValue}
        qualificationValue={qualificationValue}
        educationalInstituteValue={educationalInstituteValue}
        traineeContractEndDate={traineeContractEndDate}
        setUniqueStudentId={setUniqueStudentId}
        setTraineeNumberValue={setTraineeNumberValue}
        setQualificationValue={setQualificationValue}
        setEducationalInstituteValue={setEducationalInstituteValue}
        setTraineeContractEndDate={setTraineeContractEndDate}
      />
      <ViewTrainingContractModal
        open={openViewModal}
        setOpen={setViewModal}
        uniqueStudentId={uniqueStudentId}
        traineeNumberValue={traineeNumberValue}
        qualificationValue={qualificationValue}
        educationalInstituteValue={educationalInstituteValue}
        traineeContractEndDate={traineeContractEndDate}
      />
    </>
  );
};
