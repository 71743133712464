"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileGenerationType = exports.FileGenerationStatus = void 0;
var FileGenerationStatus;
(function (FileGenerationStatus) {
    FileGenerationStatus["Idle"] = "file-generation-status_idle";
    FileGenerationStatus["Fetching"] = "file-generation-status_fetching";
    FileGenerationStatus["Collecting"] = "file-generation-status_collecting";
    FileGenerationStatus["Generating"] = "file-generation-status_generating";
    FileGenerationStatus["Generated"] = "file-generation-status_generated";
    FileGenerationStatus["Downloading"] = "file-generation-status_downloading";
    FileGenerationStatus["Zipping"] = "file-generation-status_zipping";
    FileGenerationStatus["Error"] = "file-generation-status_error";
})(FileGenerationStatus || (exports.FileGenerationStatus = FileGenerationStatus = {}));
var FileGenerationType;
(function (FileGenerationType) {
    FileGenerationType["ReportSocial"] = "report-social";
    FileGenerationType["ReportSustainability"] = "report-sustainability";
    FileGenerationType["Employee"] = "employee";
    FileGenerationType["LabourHourTarget"] = "labour-hour-target";
    FileGenerationType["SocialSpendTarget"] = "social-spend-target";
    FileGenerationType["LabourHourExport"] = "labour-hour-export";
})(FileGenerationType || (exports.FileGenerationType = FileGenerationType = {}));
