import { useCallback, useState, useEffect } from "react";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";
import {
  decodeLabourHour,
  encodeLabourHour,
} from "social-pro-common/decoders/labourHour";
import {
  deleteBatchApiData,
  getApiData,
  listApiData,
  postApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import { LabourHour } from "social-pro-common/entities/labourHour";
import { catchSentryError } from "@utils/sentry";

export const useLabourHour = (
  projectId?: string,
  reportId?: string,
  contractorPackageId?: string,
) => {
  const [labourHours, setLabourHours] = useState<LabourHourLineItem[]>([]);
  const [isLabourHourLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getLabourHour = useCallback(
    async (id: string): Promise<LabourHourLineItem | undefined> => {
      try {
        setIsLoading(true);
        const labourHourResult = await getApiData(
          "getLabourHour",
          "labourHour",
          id,
        );
        if (
          !labourHourResult.data ||
          Object.keys(labourHourResult.data).length === 0
        ) {
          return undefined;
        }
        return decodeLabourHour(labourHourResult.data as LabourHour);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch labourHour");
      } finally {
        setIsLoading(false);
      }
    },
    [labourHours],
  );

  const listLabourHours = useCallback(
    async (
      projectId: string,
      reportId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const labourHourResult = await listApiData(
          "listLabourHours",
          "labourHour",
          projectId,
          { contractorPackageId, reportId },
        );
        const labourHours = labourHourResult.data.map((p) =>
          decodeLabourHour(p as LabourHour),
        );
        setLabourHours(labourHours);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list labourHours");
      } finally {
        setIsLoading(false);
      }
    },
    [labourHours],
  );

  const createLabourHour = useCallback(
    async (labourHour: LabourHourLineItem): Promise<LabourHourLineItem> => {
      try {
        setIsLoading(true);
        const encodedLabourHour = encodeLabourHour(labourHour);
        const upsertedLabourHour = await postApiData(
          "createLabourHour",
          "labourHour",
          encodedLabourHour,
        );
        const decodedLabourHour = decodeLabourHour(
          upsertedLabourHour.data as LabourHour,
        );
        setLabourHours((prevLabourHours) => [
          ...prevLabourHours,
          decodedLabourHour,
        ]);
        return decodedLabourHour;
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create labourHour");
      } finally {
        setIsLoading(false);
      }
      return labourHour;
    },
    [],
  );

  const updateLabourHour = useCallback(
    async (labourHour: LabourHourLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedLabourHour = encodeLabourHour(labourHour);
        await postApiData("updateLabourHour", "labourHour", encodedLabourHour);
        setLabourHours((prevLabourHours) =>
          prevLabourHours.map((c) => (c.id === labourHour.id ? labourHour : c)),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update labourHour");
      } finally {
        setIsLoading(false);
      }
    },
    [labourHours],
  );

  const upsertLabourHours = useCallback(
    async (labourHoursToUpsert: LabourHourLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedLabourHours = labourHoursToUpsert.map((e) =>
          encodeLabourHour(e),
        );
        await postBatchApiData(
          "upsertLabourHours",
          "labourHour",
          encodedLabourHours,
        );

        const updatedLabourHours = labourHours.reduce((acc, labourHour) => {
          const updatedLabourHour = labourHoursToUpsert.find(
            (e) => e.id === labourHour.id,
          );
          if (updatedLabourHour) {
            return [...acc, updatedLabourHour];
          }
          return [...acc, labourHour];
        }, [] as LabourHourLineItem[]);

        const createdLabourHours = labourHoursToUpsert.filter(
          (labourHour) =>
            !updatedLabourHours.find((lh) => lh.id === labourHour.id),
        );

        setLabourHours([...updatedLabourHours, ...createdLabourHours]);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not upsert labour hours");
      } finally {
        setIsLoading(false);
      }
    },
    [labourHours],
  );

  const deleteLabourHours = useCallback(
    async (labourHoursToDelete: LabourHourLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedLabourHour = labourHoursToDelete.map((e) =>
          encodeLabourHour(e),
        );
        await deleteBatchApiData(
          "deleteLabourHours",
          "labourHour",
          encodedLabourHour,
        );

        const deletedRowIds = labourHoursToDelete.map((e) => e.id);
        setLabourHours((prevLabourHours) =>
          prevLabourHours.filter((e) => !deletedRowIds.includes(e.id)),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete labourHours");
      } finally {
        setIsLoading(false);
      }
    },
    [labourHours],
  );

  useEffect(() => {
    if (projectId && reportId) {
      listLabourHours(projectId, reportId, contractorPackageId);
    }
  }, [projectId, contractorPackageId, reportId]);

  return {
    createLabourHour,
    deleteLabourHours,
    error,
    getLabourHour,
    isLabourHourLoading,
    labourHours,
    listLabourHours,
    updateLabourHour,
    upsertLabourHours,
  };
};
