import { NavItemType } from "types";

export const getProjectsNav = (projectName: string): NavItemType => {
  return {
    children: [
      {
        breadcrumbs: false,
        id: "list-projects",
        static: true,
        title: projectName,
        type: "item",
      },
    ],
    id: "projects",
    title: "",
    type: "group",
  };
};

{
  /* <Box style={{color:'#fff',borderRadius:'99px',padding:'8px 20px 10px',position:'absolute',left:'50%', 
width:'calc(100% - 26px)' ,transform:'translate(-50%)', background:'rgb(7 30 60 / 14%)', fontSize:'14px', fontWeight:'500', marginTop:'12px'}}>
  Project Name</Box> */
}
