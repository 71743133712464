import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TrainingContractForm } from "@stories/molecules/TrainingContractForm/TrainingContractForm";

interface TrainingContractModalProps {
  open: boolean;
  uniqueStudentId?: string;
  traineeNumberValue?: string;
  qualificationValue?: string;
  educationalInstituteValue?: string;
  traineeContractEndDate?: Date;
  setOpen: (value: boolean) => void;
  setUniqueStudentId: (value: string) => void;
  setTraineeNumberValue: (value: string) => void;
  setQualificationValue: (value: string) => void;
  setEducationalInstituteValue: (value: string) => void;
  setTraineeContractEndDate: (value: Date) => void;
}

export default function TrainingContractModal({
  educationalInstituteValue,
  open,
  qualificationValue,
  setEducationalInstituteValue,
  setOpen,
  setQualificationValue,
  setTraineeContractEndDate,
  setTraineeNumberValue,
  setUniqueStudentId,
  traineeContractEndDate,
  traineeNumberValue,
  uniqueStudentId,
}: TrainingContractModalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {"Add Training Contract Details"}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <TrainingContractForm
          uniqueStudentId={uniqueStudentId}
          traineeNumberValue={traineeNumberValue}
          qualificationValue={qualificationValue}
          educationalInstituteValue={educationalInstituteValue}
          traineeContractEndDate={traineeContractEndDate}
          setUniqueStudentId={setUniqueStudentId}
          setTraineeNumberValue={setTraineeNumberValue}
          setQualificationValue={setQualificationValue}
          setEducationalInstituteValue={setEducationalInstituteValue}
          setTraineeContractEndDate={setTraineeContractEndDate}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
