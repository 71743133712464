import { Box, Grid, Typography, Button } from "@mui/material";

interface ViewTrainingContractFormProps {
  uniqueStudentId?: string;
  traineeNumberValue?: string;
  qualificationValue?: string;
  educationalInstituteValue?: string;
  traineeContractEndDate?: Date;
  handleClose: () => void;
}

export const ViewTrainingContractForm = ({
  educationalInstituteValue,
  handleClose,
  qualificationValue,
  traineeContractEndDate,
  traineeNumberValue,
  uniqueStudentId,
}: ViewTrainingContractFormProps) => {
  return (
    <Box marginTop="3%">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Unique Student Id:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">{uniqueStudentId}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Number:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">{traineeNumberValue}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Qualification:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">{qualificationValue}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Education Institute:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">{educationalInstituteValue}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Contract End Date:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {traineeContractEndDate && (
              <Typography variant="body2">
                {traineeContractEndDate.toString()}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "center !important",
            paddingTop: "16px !important",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
