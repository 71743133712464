import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack } from "@mui/material";
import { useState } from "react";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { dateToString, stringToDate } from "social-pro-common/utils/date";
import { useNavigate, useParams } from "react-router-dom";
import { ToolsSocialSpendTable } from "@stories/organisms/PackageTools/ToolsSocialSpendTable";
import * as RouteHelper from "@utils/routes";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useSocialSpendForTargets } from "@hooks/crud/socialSpend/useSocialSpendForTargets";
import { useSocialSpendExport } from "@hooks/crud/downloadFiles/useSocialSpendExport";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const AnalyticsSpendTarget = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    handleRequestSort: (property: number) => {
      setSortInfo((prev: SortInfo) => {
        const isAsc = prev.orderBy === property && prev.order === "asc";
        return {
          ...sortInfo,
          order: isAsc ? "desc" : "asc",
          orderBy: property,
        };
      });
    },
    index: 0,
    order: "asc",
    orderBy: 0,
  });

  const { projectId, reportId, targetId } = useParams();
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getSocialSpendExcel } =
    useSocialSpendExport();

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const [selectedReportId, setSelectedReportId] = useState<string>(
    reportId || dateToString(new Date()),
  );

  const { isSocialSpendLoading, socialSpendCount, socialSpends } =
    useSocialSpendForTargets(
      projectId,
      targetId,
      selectedReportId,
      isPrimaryPackage ? undefined : contractorPackage?.id,
      page,
      sortInfo.orderBy,
      sortInfo.order,
    );

  const handlOnBack = () => {
    if (projectId && reportId) {
      navigate(RouteHelper.analyticsSpend(projectId, reportId));
    }
  };
  const loading = isProjectLoading || isSocialSpendLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );

  const filteredSpend = searchValue
    ? socialSpends.filter((p) =>
        p.organisationName.toLowerCase().includes(inputValue.toLowerCase()),
      )
    : socialSpends;

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Spend - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                socialSpendCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
          handlOnBack={handlOnBack}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <MonthSelect
              data-test-id="month-select"
              loading={loading}
              project={selectedProject}
              contractorPackage={contractorPackage}
              isPrimaryPackage={isPrimaryPackage}
              setDate={(date: Date) => {
                setSelectedReportId(dateToString(date));
              }}
              date={stringToDate(selectedReportId)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
              <StyledButton
                data-test-id="export-targets-button"
                className="blackBtn grey-outline-btn topBarBtn"
                loading={loading}
                disabled={loading || !socialSpends || !socialSpends.length}
                variant="contained"
                onClick={() => {
                  //TODO: Fix this to remove redundant data
                  if (projectId && reportId && targetId) {
                    getSocialSpendExcel(
                      projectId,
                      reportId,
                      targetId,
                      isPrimaryPackage ? undefined : contractorPackage?.id,
                    );
                  }
                }}
                startIcon={<DownloadIcon />}
              >
                Export Targets
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "30px 40px !important" }}
        >
          <ToolsSocialSpendTable
            loading={loading}
            socialSpends={filteredSpend}
            socialSpendCount={socialSpendCount}
            sortInfo={sortInfo}
            setPage={setPage}
          />
        </Grid>
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Social Spend Export"}
      />
    </>
  );
};
