import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

interface PageHeaderProps {
  loading: boolean;
  title: string;
  subTitle?: string;
  handlOnBack?: () => void;
}

export const PageHeader = ({
  handlOnBack,
  loading,
  subTitle,
  title,
}: PageHeaderProps): JSX.Element => {
  return (
    <Grid
      item
      md={12}
      sx={{ margin: "0px", padding: "20px 40px !important" }}
      className="borderBottom"
    >
      {loading ? (
        <>
          <Stack direction="row" spacing={2}>
            {handlOnBack ? (
              <Skeleton variant="circular">
                <IconButton color="primary" size="large">
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
              </Skeleton>
            ) : null}
            <Stack>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  sx={{
                    lineHeight: "21px",
                    paddingBottom: "6px",
                  }}
                >
                  {title}
                </Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography
                  variant="subtitle1"
                  sx={{
                    lineHeight: "3px",
                    paddingBottom: "6px",
                    paddingTop: "7px",
                  }}
                >
                  {subTitle}
                </Typography>
              </Skeleton>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            {handlOnBack ? (
              <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
                <IconButton
                  data-test-id="back-button-top"
                  size="large"
                  onClick={handlOnBack}
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
            ) : null}
            <Stack>
              <Typography
                variant="h1"
                sx={{
                  lineHeight: "21px",
                  paddingBottom: "6px",
                }}
              >
                {title}
              </Typography>
              {subTitle && <Typography
                variant="subtitle1"
                sx={{
                  lineHeight: "3px",
                  paddingBottom: "6px",
                  paddingTop: "7px",
                }}
              >
                {subTitle}
              </Typography>}
            </Stack>
          </Stack>
        </>
      )}
    </Grid>
  );
};
