"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricCategory = void 0;
var uuid_1 = require("uuid");
var createDefaultGreenMetricCategory = function (greenMetricId) {
    return {
        created: new Date(),
        greenMetricId: greenMetricId,
        id: (0, uuid_1.v4)(),
        metricType: "",
        targetCategory: "",
        targetType: "",
    };
};
exports.createDefaultGreenMetricCategory = createDefaultGreenMetricCategory;
