import { useProfile } from "@hooks/crud/profile/useProfile";
import { UserTable } from "@stories/molecules/UserTable/UserTable";
import { useEffect, useState } from "react";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { useProfileContext } from "@hooks/context/useProfileContext";
import UserModal from "@stories/organisms/UserModal/UserModal";
import { createDefaultUser } from "social-pro-common/interfaces/user";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { isBMD } from "init";
import { UserTabOption, UserTabs } from "@stories/molecules/UserTabs/UserTabs";
import { ProfileGroups } from "../ProfileGroups/ProfileGroups";
import { useSearchParams } from "react-router-dom";
import { useProject } from "@hooks/crud/project/useProject";
import { useProfileProjectAccess } from "@hooks/crud/profileProjectAccess/profileProjectAccess";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isContractor } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const { isProjectLoading, projects } = useProject(userProfile);

  const [selectedProfile, setSelectedProfile] = useState<
    ProfileLineItem | undefined
  >(undefined);
  const [selectedTab, setSelectedTab] = useState<UserTabOption>(
    UserTabOption.Users,
  );
  const {
    deleteProfile,
    inviteProfile,
    isProfileLoading,
    profiles,
    updateProfile,
  } = useProfile(userOrganisation?.id);

  const {
    isProfileProjectAccessLoading,
    profileProjectAccessLevels,
    updateProfileProjectAccess,
  } = useProfileProjectAccess(userOrganisation?.id);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(undefined);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreateUser = async (profile: ProfileLineItem) => {
    if (userProfile?.isAdmin) {
      const newUser = createDefaultUser(profile.email, isContractor);
      await inviteProfile({
        ...profile,
        id: newUser.id,
      });
    }
  };

  const handleEditUser = (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    handleOpen();
  };

  const handleUpdateUser = async (profile: ProfileLineItem) => {
    if (userProfile?.isAdmin) {
      await updateProfile(profile);
    }
  };
  const handleDeleteUser = async (profile: ProfileLineItem) => {
    if (userProfile?.isAdmin) {
      await deleteProfile(profile);
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (selectedTab === UserTabOption.Users && tab) {
      setSelectedTab(tab ? UserTabOption.Permissions : UserTabOption.Users);
    }
    setSearchParams();
  }, []);

  const loading =
    isAuthProfileLoading ||
    isProfileLoading ||
    isProjectLoading ||
    isProfileProjectAccessLoading;

  const content = (() => {
    switch (selectedTab) {
      case UserTabOption.Users:
        return (
          <UserTable
            loading={loading}
            profiles={profiles}
            filterName={searchValue}
            handleOpen={handleOpen}
            handleEditUser={handleEditUser}
            handleDeleteUser={handleDeleteUser}
          />
        );
      case UserTabOption.Permissions:
        return (
          <ProfileGroups
            loading={loading}
            searchQuery={searchValue}
            projects={projects}
            profiles={profiles}
            profileProjectAccessLevels={profileProjectAccessLevels}
            isProfileProjectAccessLoading={isProfileProjectAccessLoading}
            updateProfileProjectAccess={updateProfileProjectAccess}
          />
        );
    }
  })();
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0",
        }}
      >
        <PageHeader
          loading={loading}
          title="Users and Permissions"
          subTitle={`${selectedTab === UserTabOption.Users ? "Manage users" : "Manage permissions"} `}
        />
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            padding: "0 !important",
            textAlign: "left",
          }}
        >
          <UserTabs
            isLoading={loading}
            value={selectedTab}
            setUserTab={setSelectedTab}
          />
        </Grid>
        <Grid
          item
          xs={10}
          md={10}
          sx={{
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <SearchInput
              loading={loading}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleSearchChange={handleSearchChange}
            />
            {!isBMD() && selectedTab == UserTabOption.Users ? (
              <StyledButton
                data-test-id="add-user-button"
                className="blackBtn"
                loading={loading}
                variant="contained"
                startIcon={<PersonAddIcon />}
                onClick={handleOpen}
              >
                Add User
              </StyledButton>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
      {content}
      {userProfile && userOrganisation ? (
        <UserModal
          open={open}
          loading={loading}
          organisation={userOrganisation}
          profiles={profiles}
          handleClose={handleClose}
          createUser={handleCreateUser}
          updateUser={handleUpdateUser}
          existingUser={selectedProfile}
        />
      ) : null}
    </>
  );
};
