import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";

export enum UserTabOption {
  Users = "User",
  Permissions = "Permissions",
}
interface UserTabsProps {
  isLoading: boolean;
  value: UserTabOption;
  setUserTab: (newValue: UserTabOption) => void;
}

export const UserTabs = ({
  isLoading,
  setUserTab,
  value,
}: UserTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: UserTabOption,
  ) => {
    if (newValue) {
      setUserTab(newValue);
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Users</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Permissions</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            data-test-id="standard-button"
            value={UserTabOption.Users}
            size="small"
          >
            Users
          </ToggleButton>
          <ToggleButton
            data-test-id="trainee-button"
            value={UserTabOption.Permissions}
            size="small"
          >
            Permissions
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
