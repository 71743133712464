import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import { Hammertech } from "./Hammertech/Hammertech";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { DamstraImport } from "./DamstraImport/DamstraImport";
import { useIntegration } from "@hooks/crud/integration/useIntegration";
import {
  IntegrationInfoLineItem,
  IntegrationType,
} from "social-pro-common/interfaces/integration";

export const Integrations = () => {
  const { isAuthProfileLoading, setUserOrganisation, userOrganisation } =
    useProfileContext();

  const { createIntegration, isIntegrationLoading, updateIntegration } =
    useIntegration();

  const handleUpdateIntegration = async (
    integration: IntegrationInfoLineItem,
  ) => {
    if (userOrganisation) {
      const matchingIntegration = userOrganisation?.integrations?.find(
        (i) => i.id == integration.id,
      );
      if (matchingIntegration) {
        await updateIntegration(integration);
        setUserOrganisation({
          ...userOrganisation,
          integrations: userOrganisation.integrations?.map((i) =>
            i.id == integration.id ? integration : i,
          ),
        });
      } else {
        await createIntegration(integration);
        setUserOrganisation({
          ...userOrganisation,
          integrations: [...(userOrganisation.integrations || []), integration],
        });
      }
    }
  };
  const loading = isAuthProfileLoading || isIntegrationLoading;
  return (
    <Grid
      container
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Integrations"
        subTitle={"Manage 3rd party integrations"}
      />
      <Grid
        item
        md={4}
        sx={{
          padding: "20px 40px 10px",
          textAlign: "left",
        }}
      >
        <Hammertech
          loading={loading}
          integration={userOrganisation?.integrations?.find(
            (i) => i.integrationType === IntegrationType.HammerTech,
          )}
          handleIntegrationChange={handleUpdateIntegration}
          userOrganisation={userOrganisation}
        />
      </Grid>
      <Grid
        item
        md={4}
        sx={{
          padding: "20px 40px 10px",
          textAlign: "left",
        }}
      >
        <DamstraImport
          loading={loading}
          integration={userOrganisation?.integrations?.find(
            (i) => i.integrationType === IntegrationType.DamstraImport,
          )}
          handleIntegrationChange={handleUpdateIntegration}
          userOrganisation={userOrganisation}
        />
      </Grid>
    </Grid>
  );
};
