import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Dispatch, useEffect, useState } from "react";
import {
  EmployeeAuxillaryInformation,
  EmployeeLineItem,
  isDisabilityFactor,
  isDisadvantagedFactor,
  isInvalidMPSG,
  isLongTermUnemployed,
  traineeContractRequired,
} from "social-pro-common/interfaces/contractorEmployee";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { EmployeeSupportingDocsFormRow } from "../EmployeeSupportingDocsFormRow/EmployeeSupportingDocsFormRow";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import { ToastOptions, toast } from "react-toastify";
import {
  SupportingDocumentLineItem,
  createDefaultSupportingDocument,
} from "social-pro-common/interfaces/supportingDocument";
import { EmployeeSupportingDocsFormRowSkeleton } from "../EmployeeSupportingDocsFormRow/EmployeeSupportingDocsFormRowSkeleton";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { EmployeeIdentityDocsFormRow } from "../EmployeeSupportingDocsFormRow/EmployeeIdentityDocsFormRow";
import { EmployeeSupportingDocsFormRowSelectTraineeContract } from "../EmployeeSupportingDocsFormRow/EmployeeSupportingDocsFormRowSelectTraineeContract";
import { EmployeeSupportingDocsFormRowSelectUnemployment } from "../EmployeeSupportingDocsFormRow/EmployeeSupportingDocsFormRowSelectUnemployment";
import { EmployeeSupportingDocsFormRowSelectDisablity } from "../EmployeeSupportingDocsFormRow/EmployeeSupportingDocsFormRowSelectDisability";
import {
  EmployeeSocialFactor,
  EmploymentLevel,
} from "social-pro-common/entities/contractorEmployee";
import { useSupportingDocumentForEmployee } from "@hooks/crud/supportingDocuments/useSupportingDocumentForEmployee";
import { EmployeeDisadvantagedCostEntryFormRow } from "../EmployeeSupportingDocsFormRow/EmployeeDisadvantagedCostEntryFormRow";
import { catchSentryError } from "@utils/sentry";

interface EmployeeSocialFactorFormProps {
  loading: boolean;
  employee: EmployeeLineItem;
  setEmployee: Dispatch<React.SetStateAction<EmployeeLineItem>>;
  handleNext: () => void;
  handleBack: () => void;
}

export const EmployeeSocialFactorForm = ({
  employee,
  handleBack,
  handleNext,
  loading,
  setEmployee,
}: EmployeeSocialFactorFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedTargetId, setSelectedTargetId] = useState<
    EmployeeSocialFactor | EmployeeAuxillaryInformation
  >();

  const [currentSocialFactors, setCurrentSocialFactors] = useState<
    EmployeeSocialFactor[]
  >(employee.socialFactors);

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const {
    deleteSupportingDocuments,
    isSupportingDocumentLoading,
    supportingDocuments,
  } = useSupportingDocumentForEmployee(employee.id);

  useEffect(() => {
    setEmployee({
      ...employee,
      supportingDocuments: [...supportingDocuments],
    });
  }, [supportingDocuments]);

  const combinedSupportingDocs = [
    ...employee.supportingDocuments,
    ...supportingDocuments,
  ].reduce((unique, o) => {
    if (!unique.some((obj) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, [] as SupportingDocumentLineItem[]);

  const onUniqueStudentId = (uniqueStudentId: string) => {
    setEmployee((prevState: EmployeeLineItem) => {
      return { ...prevState, uniqueStudentId } as EmployeeLineItem;
    });
  };
  const onTraineeNumberChange = (trainingContractNumber: string) => {
    setEmployee((prevState: EmployeeLineItem) => {
      return { ...prevState, trainingContractNumber } as EmployeeLineItem;
    });
  };

  const onQualificationChange = (qualification: string) => {
    setEmployee((prevState: EmployeeLineItem) => {
      return { ...prevState, qualification } as EmployeeLineItem;
    });
  };

  const onEducationalInstituteChange = (educationalInstitute: string) => {
    setEmployee((prevState: EmployeeLineItem) => {
      return { ...prevState, educationalInstitute } as EmployeeLineItem;
    });
  };

  const onTraineeContractEndDate = (traineeContractEndDate: Date) => {
    setEmployee((prevState: EmployeeLineItem) => {
      return { ...prevState, traineeContractEndDate } as EmployeeLineItem;
    });
  };

  const onDownload = async (uri: string, policyName: string) => {
    setDownloadProgress(0);
    setErrorMessage(undefined);
    setIsDownloading(true);
    await downloadFile(uri, policyName, (progress: any) => {
      const total = (progress.loaded / progress.total) * 100;
      setDownloadProgress(total);
    });
    setIsDownloading(false);
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined);
    setUploadProgress(0);
    if (
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0 &&
      selectedTargetId
    ) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);

        const fileKey = `supporting_docs/employee/${employee.id}/${selectedTargetId}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          (error: any) => {
            setUploadProgress(0);
            catchSentryError(error);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );

        const matchingSupportingDocument =
          employee.supportingDocuments.find(
            (sd) => sd.supportingDocumentType === selectedTargetId,
          ) ||
          supportingDocuments.find(
            (sd) => sd.supportingDocumentType === selectedTargetId,
          );

        if (matchingSupportingDocument) {
          setEmployee({
            ...employee,
            supportingDocuments: combinedSupportingDocs,
          });
        } else {
          const newSupportingDocument = createDefaultSupportingDocument(
            employee.id,
            selectedTargetId,
            fileKey,
          );
          const allSupportingDocs = [
            ...combinedSupportingDocs,
            newSupportingDocument,
          ];
          setEmployee({
            ...employee,
            supportingDocuments: allSupportingDocs,
          });
        }
        setIsUploading(false);
      }
    }
  };

  const mergeDocuments = (
    oldList: SupportingDocumentLineItem[],
    newList: SupportingDocumentLineItem[],
  ): SupportingDocumentLineItem[] => {
    // Create a map from the old list
    const itemMap = new Map<string, SupportingDocumentLineItem>();

    oldList.forEach((item) => {
      itemMap.set(item.id, item);
    });

    // Update or add items from the new list
    newList.forEach((item) => {
      itemMap.set(item.id, item);
    });

    // Convert the map back to an array
    return Array.from(itemMap.values());
  };

  const handleSubmit = () => {
    setEmployee({
      ...employee,
      socialFactors: currentSocialFactors,
      supportingDocuments: mergeDocuments(
        supportingDocuments,
        employee.supportingDocuments,
      ),
    });
    handleNext();
  };

  const handleDeleteSupportingDocument = (
    supportingDocument: SupportingDocumentLineItem,
  ) => {
    const remoteDelete = supportingDocuments.find(
      (d) => d.id === supportingDocument.id,
    );
    if (remoteDelete) {
      deleteSupportingDocuments([supportingDocument]);
    }

    setCurrentSocialFactors((current) => {
      return current.filter(
        (c) => c !== supportingDocument.supportingDocumentType,
      );
    });

    setEmployee({
      ...employee,
      supportingDocuments: employee.supportingDocuments.filter(
        (sd) => sd.id !== supportingDocument.id,
      ),
    });
  };

  const formLoading = isSupportingDocumentLoading || loading;

  const isMpsg = employee.employmentLevel !== EmploymentLevel.FullEmployment;
  const isDisabled = loading || (isMpsg && isInvalidMPSG(employee));

  const requiresTrainingContract = traineeContractRequired(
    employee.employmentLevel,
  );

  const isLongTermUnemployedFactor = isLongTermUnemployed(
    employee.socialFactors,
  );

  const isDisablityFactor = isDisabilityFactor(employee.socialFactors);

  const isDisadvantaged = isDisadvantagedFactor(employee.socialFactors);

  const excludedFactors = [
    EmployeeSocialFactor.LongTermUnemployed,
    EmployeeSocialFactor.PersonWithDisability,
  ];
  return (
    <Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader
                loading={formLoading}
                title="Supporting Document"
                align="left"
              />
              <TableHeader
                loading={formLoading}
                title="Upload"
                sx={{ width: "10%" }}
              />
              <TableHeader
                loading={formLoading}
                title="View"
                sx={{ width: "10%" }}
              />
              <TableHeader
                loading={formLoading}
                title="Delete"
                sx={{ width: "10%" }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!formLoading ? (
              <>
                <EmployeeIdentityDocsFormRow
                  employee={employee}
                  setEmployee={setEmployee}
                  supportingDocuments={combinedSupportingDocs}
                  setSelectedTargetId={setSelectedTargetId}
                  selectedTargetId={selectedTargetId}
                  isUploading={isUploading}
                  isDownloading={isDownloading}
                  uploadProgress={uploadProgress}
                  downloadProgress={downloadProgress}
                  onUpload={onUpload}
                  onDownload={onDownload}
                  handleDeleteSupportingDocument={
                    handleDeleteSupportingDocument
                  }
                />
                {requiresTrainingContract ? (
                  <EmployeeSupportingDocsFormRowSelectTraineeContract
                    uniqueStudentId={employee.uniqueStudentId}
                    traineeNumberValue={employee.trainingContractNumber}
                    qualificationValue={employee.qualification}
                    educationalInstituteValue={employee.educationalInstitute}
                    traineeContractEndDate={employee.traineeContractEndDate}
                    setUniqueStudentId={onUniqueStudentId}
                    setTraineeNumberValue={onTraineeNumberChange}
                    setQualificationValue={onQualificationChange}
                    setEducationalInstituteValue={onEducationalInstituteChange}
                    setTraineeContractEndDate={onTraineeContractEndDate}
                  />
                ) : null}
                {isLongTermUnemployedFactor ? (
                  <EmployeeSupportingDocsFormRowSelectUnemployment
                    employee={employee}
                    setEmployee={setEmployee}
                  />
                ) : null}

                {isDisablityFactor ? (
                  <EmployeeSupportingDocsFormRowSelectDisablity
                    employee={employee}
                    setEmployee={setEmployee}
                  />
                ) : null}

                {currentSocialFactors
                  .filter((csf) => !excludedFactors.includes(csf))
                  .map((csf, index) => {
                    return (
                      <EmployeeSupportingDocsFormRow
                        key={`social-factor-table-row-${index}`}
                        employee={employee}
                        setEmployee={setEmployee}
                        socialFactor={csf}
                        supportingDocuments={combinedSupportingDocs}
                        setSelectedTargetId={setSelectedTargetId}
                        selectedTargetId={selectedTargetId}
                        isUploading={isUploading}
                        isDownloading={isDownloading}
                        uploadProgress={uploadProgress}
                        downloadProgress={downloadProgress}
                        onUpload={onUpload}
                        onDownload={onDownload}
                        handleDeleteSupportingDocument={
                          handleDeleteSupportingDocument
                        }
                      />
                    );
                  })}
                {isDisadvantaged ? (
                  <EmployeeDisadvantagedCostEntryFormRow
                    loading={loading}
                    employee={employee}
                    setEmployee={setEmployee}
                  />
                ) : null}
              </>
            ) : null}
            {loading || formLoading
              ? Array.from({ length: 3 }, (_, index) => (
                  <EmployeeSupportingDocsFormRowSkeleton key={index} />
                ))
              : null}

            {errorMessage ? (
              <TableCell colSpan={4} align="center">
                <Typography color={"error"}>{errorMessage}</Typography>
              </TableCell>
            ) : null}
          </TableBody>
        </Table>
      </Box>
      <Grid container mt={1}>
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={loading}
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledButton
              loading={loading}
              disabled={isDisabled}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
