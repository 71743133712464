import { TableHeaderCell } from "social-pro-common/interfaces/tables";
import { Grid, Table, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { UserTableSkeleton } from "./UserTableSkeleton";
import { UserTableRow } from "./UserTableRow";

interface UserTableProps {
  loading: boolean;
  profiles: ProfileLineItem[];
  filterName?: string;
  handleOpen: () => void;
  handleEditUser: (profile: ProfileLineItem) => void;
  handleDeleteUser: (profile: ProfileLineItem) => void;
}

export const UserTable = ({
  filterName,
  handleDeleteUser,
  handleEditUser,
  loading,
  profiles,
}: UserTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { align: "left", id: "employee-name", label: "Name" },
    { align: "left", id: "employee-email", label: "Email" },
    { align: "left", id: "employee-admin", label: "Account Type" },
    { id: "" },
  ];

  const filteredUsers = filterName?.length
    ? profiles.filter(
      (t) => t.name.toLowerCase() === filterName.toLocaleLowerCase(),
    )
    : profiles;

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 40px",
          padding: "5px 0 !important",
        }}
      >
        {loading ? (
          <Table>
            <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <UserTableSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table data-test-id="users-table">
            <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
            <TableBody>
              {filteredUsers.length > 0
                ? filteredUsers.map((row) => {
                  return (
                    <UserTableRow
                      key={row.id}
                      handleDeleteUser={handleDeleteUser}
                      handleEditUser={handleEditUser}
                      profile={row}
                    />
                  );
                })
                : null}

              {isNotFound && (
                <NoResultsFoundRow
                  colSpan={TABLE_HEAD.length}
                  filterName={"filterName"}
                />
              )}

              {profiles.length === 0 ? (
                <NoRows colSpan={TABLE_HEAD.length} title={"Users"} />
              ) : null}
            </TableBody>
          </Table>
        )}
      </Grid>
    </>
  );
};
