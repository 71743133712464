import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import LidpModal from "@stories/organisms/LidpModal/LidpModal";
import { LidpTableRow } from "../LidpTableRow/LidpTableRow";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { LidpTableSumRow } from "../LidpTableSumRow/LidpTableSumRow";
import { NoRows } from "../ListTable/NoRows";
import { LidpTableRowSkeleton } from "../LidpTableRow/LidpTableRowSkeleton";
import { LidpTableSumRowSkeleton } from "../LidpTableSumRow/LidpTableSumRowSkeleton";
import LidpImport, { ImportStep } from "../LidpImport/LidpImport";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { calculateLocalPackageAnzTotal } from "social-pro-common/utils/calc";

interface LidpRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  isPrimaryPackage: boolean;
  projectLocalContent: PackageLocalContentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const LidpRequirementForm = ({
  handleBack,
  handleNext,
  isPrimaryPackage,
  loading,
  project,
  projectLocalContent,
  setDirtyOnChange,
  setProject,
}: LidpRequirementFormProps) => {
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [localContents, setLocalContents] =
    useState<PackageLocalContentLineItem[]>(projectLocalContent);
  const [selectedLocalContent, setSelectedLocalContent] = useState<
    PackageLocalContentLineItem | undefined
  >();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const createLocalContent = async (
    lc: PackageLocalContentLineItem[],
  ): Promise<void> => {
    setLocalContents([...localContents, ...lc]);
    setDirtyOnChange(true);
  };

  const updateLocalContent = async (
    lc: PackageLocalContentLineItem[],
  ): Promise<void> => {
    setLocalContents(
      localContents.map((f) => {
        const updated = lc.find((l) => l.id === f.id);
        return updated ? updated : f;
      }),
    );
    setDirtyOnChange(true);
  };

  const goBack = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length > 0 ? calculateLocalPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleBack();
  };

  const setProjectLocalContents = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length > 0 ? calculateLocalPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleNext();
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%"
            // margin: "",
          }}>

          <SubTitle title={"Agreed Local Content"} />


          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              data-test-id="import-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              onClick={handleOpenImportModal}
              startIcon={<CloudUploadIcon />}
            >
              Import Local Content
            </StyledButton>
            <StyledButton
              data-test-id="add-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Local Content
            </StyledButton>
          </Stack>
        </Box>

        <Grid item md={12} >
          <Table data-test-id="localContent-commitment-table">
            <TableHead>
              <TableRow>
                <TableHeader
                  loading={loading}
                  title={"Item Description"}
                  align="left"
                />
                <TableHeader
                  loading={loading}
                  title={"Manufacturer"}
                  align="left"
                />
                <TableHeader
                  loading={loading}
                  title={"Supplier"}
                  align="left"
                />
                <TableHeader loading={loading} title={"Value ($)"} />
                <TableHeader loading={loading} title={"Steel Mass (T)"} />
                <TableHeader loading={loading} title={"Local Value Add"} />
                <TableHeader loading={loading} title={"Import Value Add"} />
                <TableHeader loading={loading} title={"Contract Content"} />
                <TableHeader
                  loading={loading}
                  title={"ANZ value-add activity (%)"}
                />
                <TableHeader loading={loading} title={"SME Count"} />
                <TableHeader loading={loading} title={"Supplier Count"} />
                <TableHeader loading={loading} title={"Action"} align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading
                ? localContents.map((lc) => {
                  const handleEdit = () => {
                    setSelectedLocalContent(lc);
                    setOpen(true);
                  };
                  return (
                    <LidpTableRow
                      key={`local-content-table-row-${lc.id}`}
                      loading={loading}
                      localContent={lc}
                      showExpected={false}
                      handleEdit={handleEdit}
                      onDelete={() => {
                        setLocalContents(
                          localContents.filter((f) => f.id !== lc.id),
                        );
                        setDirtyOnChange(true);
                      }}
                    />
                  );
                })
                : null}
              {loading
                ? Array.from({ length: 10 }, (_, index) => (
                  <LidpTableRowSkeleton key={index} />
                ))
                : null}
              {localContents.length == 0 ? (
                <NoRows title="Agreed Local Content" colSpan={11} />
              ) : null}
              {loading ? (
                <LidpTableSumRowSkeleton />
              ) : (
                <LidpTableSumRow
                  localContents={localContents}
                  hasButtonColumn={true}
                />
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          padding: "20px 40px 40px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={() => goBack(localContents)}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledButton
            loading={loading}
            onClick={() => setProjectLocalContents(localContents)}
            variant="contained"
            type="submit"
          >
            Next
          </StyledButton>
        </Stack>
      </Grid>

      {open ? (
        <LidpModal
          open={open}
          loading={loading}
          projectId={project.id}
          isCreateModal={true}
          isPrimaryPackage={isPrimaryPackage}
          existingLocalContent={selectedLocalContent}
          packageValue={project.financial.totalValue || 0}
          createLocalContent={createLocalContent}
          updateLocalContent={updateLocalContent}
          handleClose={handleClose}
        />
      ) : null}
      <LidpImport
        open={openImportModal}
        step={step}
        project={project}
        setIsOpen={setOpenImportModal}
        setStep={setStep}
        upsertLidp={createLocalContent}
      />
    </>
  );
};
