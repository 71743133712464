import React from "react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Skeleton,
  Button,
} from "@mui/material";

interface SubContractorTabsProps {
  isLoading: boolean;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const SubContractorTabs = ({
  handleChange,
  isLoading,
  value,
}: SubContractorTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings

  return (
    <Box marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Packages</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Suppliers</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton data-test-id="packages-button" value={0} size="small">
            Packages
          </ToggleButton>
          <ToggleButton data-test-id="one-time-button" value={1} size="small">
            Suppliers
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
