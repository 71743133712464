import { useCallback, useState, useEffect } from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import {
  decodeGreenMetricComment,
  encodeGreenMetricComment,
} from "social-pro-common/decoders/greenMetricComment";
import { GreenMetricCommentLineItem } from "social-pro-common/interfaces/greenMetricComment";
import { GreenMetricComment } from "social-pro-common/entities/greenMetricComment";
import { catchSentryError } from "@utils/sentry";

export const useGreenMetricComment = (
  contractorPackageId?: string,
  reportId?: string,
) => {
  const [greenMetricComments, setGreenMetricComments] = useState<
    GreenMetricCommentLineItem[]
  >([]);
  const [isGreenMetricCommentsLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listGreenMetricComments = useCallback(
    async (packageId: string, reportId: string) => {
      try {
        setIsLoading(true);
        const greenMetricResult = await listApiData(
          "listGreenMetricComments",
          "greenMetricComment",
          packageId,
          { reportId },
        );
        setGreenMetricComments(
          greenMetricResult.data.map((p) =>
            decodeGreenMetricComment(p as GreenMetricComment),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list greenMetrics");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeGreenMetricComment, setGreenMetricComments, setIsLoading, setError],
  );

  const createGreenMetricComment = useCallback(
    async (greenMetricComment: GreenMetricCommentLineItem) => {
      try {
        setIsLoading(true);
        const encodedGreenMetricComment =
          encodeGreenMetricComment(greenMetricComment);
        const upsertedGreenMetric = await postApiData(
          "createGreenMetricComment",
          "greenMetricComment",
          encodedGreenMetricComment,
        );
        const decodedGreenMetric = decodeGreenMetricComment(
          upsertedGreenMetric.data as GreenMetricComment,
        );
        setGreenMetricComments((prevComments) => [
          ...prevComments,
          decodedGreenMetric,
        ]);
      } catch (e) {
        catchSentryError(e);
        setError("Could not create greenMetric comment");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeGreenMetricComment, decodeGreenMetricComment, setGreenMetricComments, setIsLoading, setError],
  );

  const updateGreenMetricComment = useCallback(
    async (greenMetric: GreenMetricCommentLineItem) => {
      try {
        setIsLoading(true);
        const encodedGreenMetric = encodeGreenMetricComment(greenMetric);
        await postApiData(
          "updateGreenMetricComment",
          "greenMetricComment",
          encodedGreenMetric,
        );
        setGreenMetricComments((prevComments) =>
          prevComments.map((g) => (g.id === greenMetric.id ? greenMetric : g)),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update greenMetric comment");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeGreenMetricComment, setGreenMetricComments, setIsLoading, setError],
  );

  useEffect(() => {
    if (contractorPackageId && reportId) {
      listGreenMetricComments(contractorPackageId, reportId);
    }
  }, [contractorPackageId, reportId, listGreenMetricComments]);

  return {
    createGreenMetricComment,
    error,
    greenMetricComments,
    isGreenMetricCommentsLoading,
    listGreenMetricComments,
    updateGreenMetricComment,
  };
};