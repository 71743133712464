import { TableCell, TableRow, Button, Skeleton } from "@mui/material";
export const SustainabilityRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="left">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="left">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="right">
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ marginLeft: "auto" }}
          >
            <Button
              color="primary"
              size="large"
              data-test-id="sustainability-upload-button"
              className={"blackBtn"}
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              {"Upload"}
            </Button>
          </Skeleton>
        </TableCell>
      </TableRow>
    </>
  );
};
