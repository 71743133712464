import { Skeleton, TextField, TextFieldProps } from "@mui/material";

type StyledTextFieldProps = TextFieldProps & {
  loading: boolean;
  skeletonHeight?: string;
  maxLength?: number;
};
export const StyledTextField = (props: StyledTextFieldProps) => {
  const { loading, maxLength, ...otherProps } = props;
  if (loading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width={props.fullWidth ? "100%" : undefined}
        height={props.skeletonHeight ? props.skeletonHeight : undefined}
        sx={{
          ...otherProps.sx,
          maxHeight: "51px",
        }}
      >
        <TextField
          className={props.className}
          label="DEFAULT TEXT FIELD CONTENT - LOADING"
          placeholder={props.placeholder}
          margin="dense"
          fullWidth
          size="small"
        />
      </Skeleton>
    );
  }
  return (
    <TextField
      {...otherProps}
      inputProps={{
        list: otherProps.type === "color" ? "colourPresets" : undefined,
        maxLength: maxLength || 100,
      }}
      InputProps={{
        ...props.InputProps,
      }}
      sx={{ ...props.sx }}
    />
  );
};
