import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Stack,
  InputAdornment,
  Alert,
  Skeleton,
  TextField,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Country, ICountry, State } from "country-state-city";
import {
  SocialSpendLineItem,
  socialSpendCertificateToString,
  socialSpendEntityTypeToString,
  socialSpendPurchaserTypeToString,
  socialSpendServiceCategoryToString,
} from "social-pro-common/interfaces/socialSpend";
import { validateABN } from "social-pro-common/utils/abn";
import dayjs from "dayjs";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useEffect, useRef, useState } from "react";
import { uploadFile } from "@hooks/utils/useUpload";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledLoadingButton } from "@stories/atoms/StyledButton/StyledButton";
import { ToastOptions, toast } from "react-toastify";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import { UploadButton } from "@stories/atoms/UploadButton/UploadButton";
import { validatePostCode } from "@utils/location";
import { cleanString, getFileName } from "social-pro-common/utils/string";
import { getSocialSpendFactors } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  PurchaserType,
  SocialSpendCertificate,
  SocialSpendEntityType,
  SocialSpendServiceCategory,
} from "social-pro-common/entities/socialSpend";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { useSocialBusiness } from "@hooks/crud/socialBusiness/useSocialBusiness";
import { isValidABN } from "@utils/employeSocialFactors";
import SearchIcon from "@mui/icons-material/Search";
import { SocialBusinessLineItem } from "social-pro-common/interfaces/socialBusiness";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { catchSentryError } from "@utils/sentry";
interface SocialSpendFormProps {
  loading: boolean;
  project: ProjectLineItem;
  userOrganisation: OrganisationLineItem;
  socialSpend: SocialSpendLineItem;
  handleSubmit: (profile: SocialSpendLineItem) => Promise<void>;
}

export const SocialSpendForm = ({
  handleSubmit,
  loading,
  project,
  socialSpend,
  userOrganisation,
}: SocialSpendFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [selectedSocialBusiness, setSelectedSocialBusiness] =
    useState<SocialBusinessLineItem>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedCertifications, setSelectedCertifications] = useState<
    SocialSpendCertificate[]
  >(socialSpend.certifications);
  const [natureOfExpenditure, setSelectedNatureOfExpenditure] =
    useState<SocialSpendServiceCategory>(socialSpend.natureOfExpenditure);
  const { isSocialBusinessLoading, searchSocialBusinessesByABN } =
    useSocialBusiness();

  const validationSchema = yup.object({
    abn: yup
      .string()
      .required("ABN is required")
      .test(
        "Valid ABN",
        "Not a valid ABN - i.e. 43 665 205 185",
        function (item) {
          return validateABN(item);
        },
      ),
    certifications: yup.array(),
    country: yup.string().required("Country is required"),
    entityType: yup.string().required("Entity type is required"),
    invoiceValue: yup.number().required("Invoice value is required").min(0),
    natureOfExpenditure: yup
      .string()
      .required("Nature of expenditure is required"),
    postCode: yup
      .string()
      .required("Postcode is required")
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item: any) {
          return validatePostCode(this.parent.country, item);
        },
      ),
    purchaseType: yup.string().required("Purchase type is required"),
    purchaserOrganisationAbn: yup
      .string()
      .required("Purchaser ABN is required")
      .test(
        "Valid Purchaser ABN",
        "Not a valid ABN - i.e. 43 665 205 185",
        function (item: any) {
          return validateABN(item);
        },
      ),
    purchaserOrganisationName: yup
      .string()
      .required("Purchaser Organisation name is required"),
    state: yup.string().required("State is required"),
    supplier: yup.string().required("Supplier is required"),

    transactionDate: yup.date().required("Transaction date is required"),
    uri: yup.string(),
    victorianPostCode: yup
      .string()
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item: any) {
          if (item) {
            return validatePostCode(this.parent.country, item);
          }
          return true;
        },
      ),
  });
  const formik = useFormik({
    initialValues: {
      abn: socialSpend.abn,
      certifications: socialSpend.certifications,
      country: socialSpend.supplierCountry,
      created: socialSpend.created,
      entityType: socialSpend.entityType,
      invoiceValue: socialSpend.invoiceValue,
      natureOfExpenditure: natureOfExpenditure,
      postCode: socialSpend.supplierPostCode,
      purchaseType: socialSpend.purchaseType,
      purchaserOrganisationAbn: socialSpend.purchaserOrganisationAbn,
      purchaserOrganisationName: socialSpend.purchaserOrganisationName,
      state: socialSpend.supplierState,
      supplier: socialSpend.supplier,
      transactionDate: dayjs(socialSpend.transactionDate),
      uri: socialSpend.uri,
      victorianPostCode: socialSpend.victorianPostCode,
    },
    onSubmit: async (values) => {
      try {
        const newSocialSpend = {
          ...socialSpend,
          abn: cleanString(values.abn),
          certifications: selectedCertifications,
          created: values.created,
          entityType: values.entityType,
          invoiceValue: values.invoiceValue,
          natureOfExpenditure: values.natureOfExpenditure,
          purchaserOrganisationAbn: cleanString(
            values.purchaserOrganisationAbn,
          ),
          purchaserOrganisationName: values.purchaserOrganisationName,
          registered: selectedSocialBusiness?.registered || false,
          supplier: values.supplier,
          supplierCountry: values.country,
          supplierPostCode: values.postCode,
          supplierState: values.state,
          transactionDate: values.transactionDate.toDate(),
          uri: values.uri,
          victorianPostCode: values.victorianPostCode,
        } as SocialSpendLineItem;
        newSocialSpend.socialFactors = getSocialSpendFactors(
          newSocialSpend,
          project,
        ).map((sf) => sf.id);
        await handleSubmit({
          ...newSocialSpend,
        });
      } catch (error: any) {
        catchSentryError(error);
        alert("Something went wrong, please try again or contact support");
        formik.setSubmitting(false);
      }
    },
    validationSchema: validationSchema,
  });

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);
        //TODO: Make this URL better;
        const fileKey = `social-procurement/${socialSpend.id}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
          },
          () => {
            setUploadProgress(0);

            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );
        await formik.setFieldValue("uri", fileKey);
        setIsUploading(false);
      }
    }
  };

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  useEffect(() => {
    const setFields = async () => {
      if (formik.values.purchaseType === PurchaserType.Direct) {
        await formik.setFieldValue(
          "purchaserOrganisationName",
          userOrganisation.organisationName,
          false,
        );
        await formik.setFieldValue(
          "purchaserOrganisationAbn",
          userOrganisation.abn,
          false,
        );
        await formik.validateField("purchaserOrganisationName");
        await formik.validateField("purchaserOrganisationAbn");
      } else {
        await formik.setFieldValue("purchaserOrganisationName", "", false);
        await formik.setFieldValue("purchaserOrganisationAbn", "", false);
      }
    };
    setFields();
  }, [formik.values.purchaseType]);

  useEffect(() => {
    const searchAbn = async () => {
      if (formik.values.abn && formik.touched.abn && !formik.errors.abn) {
        const isValid = validateABN(formik.values.abn);
        if (isValid) {
          const socialBusiness = await searchSocialBusinessesByABN(
            formik.values.abn,
          );

          if (socialBusiness) {
            await formik.setFieldValue(
              "supplier",
              socialBusiness?.socialBusinessName,
              false,
            );
            await formik.setFieldValue(
              "country",
              socialBusiness?.socialBusinessCountry,
              false,
            );
            await formik.setFieldValue(
              "postCode",
              socialBusiness?.socialBusinessPostCode,
              false,
            );

            if (socialBusiness.socialBusinessState === "VIC") {
              await formik.setFieldValue(
                "victorianPostCode",
                socialBusiness?.socialBusinessPostCode,
                false,
              );
            }
            setSelectedSocialBusiness(socialBusiness);
          }
        } else {
          setSelectedSocialBusiness(undefined);
        }
      } else {
        setSelectedSocialBusiness(undefined);
      }
    };
    searchAbn();
  }, [formik.values.abn, formik.errors.abn, formik.touched.abn]);

  useEffect(() => {
    const findSocialBusinessForSpend = async () => {
      if (socialSpend.abn && isValidABN(socialSpend.abn)) {
        const socialBusiness = await searchSocialBusinessesByABN(
          socialSpend.abn,
        );

        if (socialBusiness) {
          setSelectedSocialBusiness(socialBusiness);
        }
      }
    };
    findSocialBusinessForSpend();
  }, [socialSpend]);

  const isDisabled =
    loading || isUploading || isSocialBusinessLoading || formik.isSubmitting;

  // const isNotSocialProABN = (abn: string) => {
  //   const cleanAbn = abn.replace(/\s/g, "");
  //   return cleanAbn !== "43665205185"
  // };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const disabled = formik.isSubmitting;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        direction={"row"}
        sx={{
          margin: "0",
          padding: "0px 20px 0px 0px",
          width: "100%",
        }}
      >
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="abn"
            name="abn"
            label="Supplier ABN"
            data-test-id="supplier-abn"
            fullWidth
            disabled={isDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.abn}
            error={formik.touched.abn && Boolean(formik.errors.abn)}
            helperText={formik.touched.abn ? formik.errors.abn : ""}
            InputProps={{
              endAdornment: isSocialBusinessLoading ? (
                <InputAdornment
                  position="start"
                  sx={{
                    color: isDisabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : undefined,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="supplier"
            name="supplier"
            label="Supplier"
            data-test-id="supplier"
            fullWidth
            disabled={isDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.supplier}
            error={formik.touched.supplier && Boolean(formik.errors.supplier)}
            helperText={formik.touched.supplier ? formik.errors.supplier : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loading ? (
            <Skeleton animation="wave" variant="rounded" width={"100%"}>
              <TextField
                label="DEFAULT TEXT FIELD CONTENT - LOADING"
                fullWidth
              />
            </Skeleton>
          ) : (
            <Autocomplete
              fullWidth
              id="country"
              options={countries}
              disabled={isDisabled}
              getOptionLabel={(option) => option.name}
              value={Country.getCountryByCode(formik.values.country)}
              onChange={(e: any, value: ICountry | null) => {
                formik.setFieldValue("country", value?.isoCode);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  data-test-id="country"
                  {...params}
                  name="country"
                  label="Country"
                  fullWidth
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={
                    formik.touched.country ? formik.errors.country : ""
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {loading ? (
            <Skeleton animation="wave" variant="rounded" width={"100%"}>
              <TextField
                label="DEFAULT TEXT FIELD CONTENT - LOADING"
                fullWidth
              />
            </Skeleton>
          ) : (
            <Autocomplete
              fullWidth
              id="state"
              disabled={isDisabled}
              options={states}
              getOptionLabel={(option) => option.name}
              value={State.getStateByCodeAndCountry(
                formik.values.state,
                formik.values.country,
              )}
              onChange={(event) => {
                const state = states.find(
                  (s) => s.name === event.currentTarget.textContent,
                );
                formik.setFieldValue("state", state?.isoCode || "");
                // formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  data-test-id="state"
                  {...params}
                  name="state"
                  label="State"
                  fullWidth
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state ? formik.errors.state : ""}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            loading={loading}
            disabled={isDisabled}
            id="postCode"
            name="postCode"
            label="Postcode"
            data-test-id="post-code"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postCode}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={formik.touched.postCode ? formik.errors.postCode : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            loading={loading}
            id="victorianPostCode"
            name="victorianPostCode"
            label="Victorian Office Postcode"
            data-test-id="victorian-post-code"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.victorianPostCode}
            error={
              formik.touched.victorianPostCode &&
              Boolean(formik.errors.victorianPostCode)
            }
            helperText={
              formik.touched.victorianPostCode
                ? formik.errors.victorianPostCode
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          {loading ? (
            <Skeleton animation="wave" variant="rounded" width={"100%"}>
              <TextField
                label="DEFAULT TEXT FIELD CONTENT - LOADING"
                fullWidth
              />
            </Skeleton>
          ) : (
            <Autocomplete
              fullWidth
              disabled={isDisabled}
              id="purchaseType"
              options={Object.values(PurchaserType)}
              getOptionLabel={(option) =>
                socialSpendPurchaserTypeToString(option) || ""
              }
              value={formik.values.purchaseType}
              onChange={(event: any, value: PurchaserType | null) => {
                formik.setFieldValue("purchaseType", value);
                // formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="purchaseType"
                  label="Purchaser Type"
                  fullWidth
                  error={
                    formik.touched.purchaseType &&
                    Boolean(formik.errors.purchaseType)
                  }
                  helperText={
                    formik.touched.purchaseType
                      ? formik.errors.purchaseType
                      : ""
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="purchaserOrganisationName"
            name="purchaserOrganisationName"
            label="Purchaser Organisation Name"
            data-test-id="purchaser-organisation-name"
            fullWidth
            disabled={
              isDisabled || formik.values.purchaseType === PurchaserType.Direct
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.purchaserOrganisationName}
            error={
              formik.touched.purchaserOrganisationName &&
              Boolean(formik.errors.purchaserOrganisationName)
            }
            helperText={
              formik.touched.purchaserOrganisationName
                ? formik.errors.purchaserOrganisationName
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="purchaserOrganisationAbn"
            name="purchaserOrganisationAbn"
            label="Purchaser Organisation ABN"
            data-test-id="purchaser-organisation-abn"
            fullWidth
            disabled={
              isDisabled || formik.values.purchaseType === PurchaserType.Direct
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.purchaserOrganisationAbn}
            error={
              formik.touched.purchaserOrganisationAbn &&
              Boolean(formik.errors.purchaserOrganisationAbn)
            }
            helperText={
              formik.touched.purchaserOrganisationAbn
                ? formik.errors.purchaserOrganisationAbn
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          {loading ? (
            // <Skeleton
            //   animation="wave"
            //   variant="rounded"
            //   height={50}
            //   width={"100%"}
            // >
            //   <TextField />
            // </Skeleton>
            <Skeleton animation="wave" variant="rounded" width={"100%"}>
              <TextField
                label="DEFAULT TEXT FIELD CONTENT - LOADING"
                fullWidth
              />
            </Skeleton>
          ) : (
            <>
              <Autocomplete
                fullWidth
                multiple
                disabled={isDisabled}
                disableCloseOnSelect
                id="certifications"
                options={Object.values(SocialSpendCertificate)}
                getOptionLabel={(option) =>
                  socialSpendCertificateToString(option)
                }
                defaultValue={formik.values.certifications}
                onChange={(
                  event: any,
                  newValue: SocialSpendCertificate[] | null,
                ) => {
                  setSelectedCertifications(newValue || []);
                  // formik.handleChange(event);
                }}
                renderTags={(value) => {
                  // Join all selected values into a single string with commas
                  const text = value
                    .map((v) => socialSpendCertificateToString(v))
                    .join(", ");

                  // Check if the text length exceeds 20 characters
                  if (text.length > 20) {
                    // Truncate and add ellipsis
                    return `${text.substring(0, 17)}... ${
                      value.length > 1 ? `+${value.length - 1}` : ""
                    }`;
                  }

                  // If under 20 characters, return the full text
                  return text;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {socialSpendCertificateToString(option)}
                  </li>
                )}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    data-test-id="certifications"
                    {...params}
                    name="certifications"
                    label="Certifications"
                    fullWidth
                    error={
                      formik.touched.certifications &&
                      Boolean(formik.errors.certifications)
                    }
                    helperText={
                      formik.touched.certifications
                        ? formik.errors.certifications
                        : ""
                    }
                  />
                )}
              />
            </>
          )}
        </Grid>
        <Grid item md={6}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              height={50}
              width={"100%"}
            >
              <TextField />
            </Skeleton>
          ) : (
            <Autocomplete
              fullWidth
              disableCloseOnSelect
              id="entityType"
              options={Object.values(SocialSpendEntityType)}
              defaultValue={formik.values.entityType}
              disabled={loading || formik.isSubmitting}
              getOptionLabel={(option) => socialSpendEntityTypeToString(option)}
              renderOption={(props, option) => (
                <li {...props}>{socialSpendEntityTypeToString(option)}</li>
              )}
              renderTags={(value) => {
                // Join all selected values into a single string with commas
                const text = value
                  .map((v) => socialSpendEntityTypeToString(v))
                  .join(", ");

                // Check if the text length exceeds 20 characters
                if (text.length > 20) {
                  // Truncate and add ellipsis
                  return `${text.substring(0, 17)}... ${
                    value.length > 1 ? `+${value.length - 1}` : ""
                  }`;
                }

                // If under 20 characters, return the full text
                return text;
              }}
              onChange={(
                event: any,
                newValue: SocialSpendEntityType | null,
              ) => {
                formik.setFieldValue("entityType", newValue);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  data-test-id="entityType"
                  {...params}
                  name="entityType"
                  label="Entity Type"
                  fullWidth
                  error={
                    formik.touched.entityType &&
                    Boolean(formik.errors.entityType)
                  }
                  helperText={
                    formik.touched.entityType ? formik.errors.entityType : ""
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item md={6}>
          <StyledDatePicker
            label="Transaction Date"
            loading={loading}
            disabled={isDisabled}
            fullWidth
            onChange={async (value: dayjs.Dayjs) => {
              if (value) {
                await formik.setFieldValue("transactionDate", value, true);
              }
            }}
            value={formik.values.transactionDate}
            format={"DD MMMM YYYY"}
            slotProps={{
              textField: {
                "data-test-id": "transaction-date-picker",
                error:
                  formik.touched.transactionDate &&
                  Boolean(formik.errors.transactionDate),
                fullWidth: true,
                helperText:
                  formik.touched.transactionDate &&
                  Boolean(formik.errors.transactionDate)
                    ? "Invalid date"
                    : "",
              },
            }}
          />
        </Grid>
        <Grid item md={6}>
          <StyledTextField
            loading={loading}
            id="invoiceValue"
            name="invoiceValue"
            label="Invoice Value (excl. GST)"
            fullWidth
            disabled={isDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.invoiceValue}
            error={
              formik.touched.invoiceValue && Boolean(formik.errors.invoiceValue)
            }
            helperText={
              formik.touched.invoiceValue ? formik.errors.invoiceValue : ""
            }
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  $
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          {loading ? (
            <Skeleton animation="wave" variant="rounded" width={"100%"}>
              <TextField
                label="DEFAULT TEXT FIELD CONTENT - LOADING"
                fullWidth
              />
            </Skeleton>
          ) : (
            <Autocomplete
              fullWidth
              id="natureOfExpenditure"
              defaultValue={formik.values.natureOfExpenditure}
              options={Object.values(SocialSpendServiceCategory)}
              getOptionLabel={(option) =>
                socialSpendServiceCategoryToString(option) || ""
              }
              renderTags={(value) => {
                // Join all selected values into a single string with commas
                const text = value
                  .map((v) => socialSpendServiceCategoryToString(v))
                  .join(", ");

                // Check if the text length exceeds 20 characters
                if (text.length > 20) {
                  // Truncate and add ellipsis
                  return `${text.substring(0, 17)}... ${
                    value.length > 2 ? `+${value.length - 1}` : ""
                  }`;
                }

                // If under 20 characters, return the full text
                return text;
              }}
              disabled={loading || formik.isSubmitting}
              value={formik.values.natureOfExpenditure}
              onChange={(
                event: any,
                newValue: SocialSpendServiceCategory | null,
              ) => {
                setSelectedNatureOfExpenditure(
                  newValue || SocialSpendServiceCategory.None,
                );
                formik.setFieldValue("natureOfExpenditure", newValue);
                // formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="natureOfExpenditure"
                  label="Nature of Expenditure"
                  fullWidth
                  error={
                    formik.touched.natureOfExpenditure &&
                    Boolean(formik.errors.natureOfExpenditure)
                  }
                  helperText={
                    formik.touched.natureOfExpenditure
                      ? formik.errors.natureOfExpenditure
                      : ""
                  }
                />
              )}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: "flex !important",
            marginRight: "auto",
          }}
        >
          <Stack direction={"row"} sx={{ flexGrow: 1 }} spacing={2}>
            {isUploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : (
              <Stack direction="row" justifyContent="left" alignItems="left">
                <input
                  ref={fileInput}
                  hidden
                  accept="*/*"
                  type="file"
                  style={{
                    cursor: "pointer",
                    height: "100px",
                    opacity: 0,
                    padding: 0.5,
                    position: "fixed",
                    width: "100px",
                    zIndex: 1,
                  }}
                  onChange={onUpload}
                />
                <UploadButton
                  loading={loading}
                  title={"Upload Invoice"}
                  disabled={isDisabled}
                  onClickUpload={onFileInput}
                />
                {!loading && formik.values.uri ? (
                  <Alert severity="success">
                    {getFileName(formik.values.uri)}
                  </Alert>
                ) : null}
              </Stack>
            )}
          </Stack>
        </Grid>
        {/* {selectedSocialBusiness &&
        !selectedSocialBusiness.registered &&
        isNotSocialProABN(formik.values.abn) ? (
          <Grid item xs={12} sm={12}>
            <Alert severity="warning">
              This ABN is not a certified social business on SocialPro. Prior to
              submitting, please check to ensure this is suitable for your
              selected social spend target.
            </Alert>
          </Grid>
        ) : null} */}
        <Grid
          item
          xs={6}
          sm={6}
          sx={{
            display: "flex !important",
            justifyContent: "left !important;",
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledLoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            disabled={isDisabled}
          >
            Save
          </StyledLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
