import * as RouteHelper from "@utils/routes";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid } from "@mui/material";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { ContractorPackageAnalyticsTable } from "@stories/organisms/Tables/ContractorPackageAnalyticsTable";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";

export const AnalyticsEnviroSustainability = () => {
  const { reportId } = useParams();

  const navigate = useNavigate();

  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const loading = isProjectLoading || isContractorPackageLoading;
  return (
    <Grid
      container
      md={12}
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Sustainability"
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
      />

      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <SearchInput
            loading={loading}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSearchChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table glb-table-sty"
        sx={{ padding: "30px 40px !important" }}
      >
        <ContractorPackageAnalyticsTable
          loading={loading}
          contractorPackages={contractorPackages}
          filterValue={searchValue}
          viewPackageDetails={(
            contractorPackage: ContractorPackageLineItem,
          ) => {
            if (selectedProject) {
              navigate(
                RouteHelper.analyticsEnviroSustainabilityDetails(
                  selectedProject.id,
                  contractorPackage.id,
                  reportId || "",
                ),
              );
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
