import { ReactNode } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import MuiAvatar, { AvatarProps } from "@mui/material/Avatar";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { LinkTarget } from "types";

// ==============================|| AVATAR ||============================== //

interface avatarProps extends AvatarProps {
  alt?: string;
  src?: string;
  color?: string;
  component?: OverridableComponent<any> /** Any component can override */;
  target?: LinkTarget;
  href?: string;
  sx?: AvatarProps["sx"];
  children?: ReactNode;
  outline?: boolean;
  size?: "badge" | "xs" | "sm" | "md" | "lg" | "xl";
}

const Avatar = ({ color, outline, size, sx, ...others }: avatarProps) => {
  const theme = useTheme();

  const colorSX = color &&
    !outline && {
      bgcolor: `${color}.main`,
      color: theme.palette.background.paper,
    };
  const outlineSX = outline && {
    bgcolor: theme.palette.background.paper,
    border: "2px solid",
    borderColor: color ? `${color}.main` : "primary.main",
    color: color ? `${color}.main` : "primary.main",
  };
  let sizeSX = {};
  switch (size) {
    case "badge":
      sizeSX = {
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      };
      break;
    case "xs":
      sizeSX = {
        height: theme.spacing(4.25),
        width: theme.spacing(4.25),
      };
      break;
    case "sm":
      sizeSX = {
        height: theme.spacing(5),
        width: theme.spacing(5),
      };
      break;
    case "lg":
      sizeSX = {
        height: theme.spacing(9),
        width: theme.spacing(9),
      };
      break;
    case "xl":
      sizeSX = {
        height: theme.spacing(10.25),
        width: theme.spacing(10.25),
      };
      break;
    case "md":
      sizeSX = {
        height: theme.spacing(7.5),
        width: theme.spacing(7.5),
      };
      break;
    default:
      sizeSX = {};
  }

  return (
    <MuiAvatar
      sx={{ ...colorSX, ...outlineSX, ...sizeSX, ...sx }}
      {...others}
    />
  );
};

export default Avatar;
