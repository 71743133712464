import { Skeleton, TableCell, TableRow } from "@mui/material";

export const ContractorAnalyticsEnviroSustainabilityRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="left" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
